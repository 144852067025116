/**
 * Copyright PrimeVR 2020
 * @author roskelld https://github.com/roskelld
 */

class Sprite {
    constructor( arg ) {
        const image = new Image();
        image.src = ( arg.sheet ) ? arg.sheet : console.error( 'Sprite missing Sprite sheet source' );
        this.sheet = image;
        this.frame_width = ( arg.frame_width ) ? arg.frame_width : console.error( 'Sprite missing Frame Width' );
        this.frame_height = ( arg.frame_height ) ? arg.frame_height : console.error( 'Sprite missing Frame Height' );
        this.scale = ( arg.scale ) ? arg.scale : 1;
        this.frame_rate = ( arg.frame_rate ) ? arg.frame_rate : console.error( 'Sprite missing frame rate' );
        this.ctx = ( arg.ctx ) ? arg.ctx : console.error( 'Sprite missing canvas ctx' );
        this.animation = {};
        if ( !arg.animations ) {
            console.error('Sprite missing animations' );
            return;
        }

        Object.entries( arg.animations ).forEach( anim => {
            const frames = anim[1];
            let current_frame = frames[0];
            let current_frame_index = 0;

            this.animation[anim[0]] = {};

            this.animation[anim[0]].setFrame = ( frame ) => {
                if ( isNaN(frame) ) { return; }
                if ( frame > frames.length - 1 ) {
                    current_frame_index = 0;
                } else {
                    this.animation[anim[0]].last_frame = current_frame_index = frame;
                }
            };

            this.animation[anim[0]].getFrame = () => {
                return this.animation[anim[0]].last_frame;
            };

            this.animation[anim[0]].play = (x, y) => {
                this.draw( frames[current_frame_index], x, y );
            };

            this.animation[anim[0]].update = () => {
                current_frame_index = this.update( frames, current_frame_index );
                this.animation[anim[0]].last_frame = current_frame_index;
                //Has animation reached end?
                if ( current_frame_index === 0 ) {
                    return true;
                } else {
                    return false;
                }
            };

            this.animation[anim[0]].has_finished = () => {
                //Has animation reached end?
                if ( this.animation[anim[0]].last_frame === frames.length - 1 ) {
                    return true;
                } else {
                    return false;
                }
            };
        });
    }

    set CurrentAnim( frame ) {

    }

    get CurrentAnim() {

    }

    update( frames, last_frame ) {
        if ( last_frame >= frames.length - 1 ) {
            return 0;
        } else {
            return last_frame + 1;
        }
    }

    draw( frame, x, y ) {
        this.ctx.drawImage( this.sheet,
                            frame * this.frame_width,
                            0,
                            this.frame_width,
                            this.frame_height,
                            x,
                            y,
                            this.scale,
                            this.scale );

    }
}

exports.Sprite = Sprite;
