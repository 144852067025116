/**
 * Copyright PrimeVR 2020
 * @author       roskelld https://github.com/roskelld
 * @description  Upload Art and Metadata to WebSocket server
 *               via form fields.
 *               Includes field validation
 * @version      0.1
 */

const Utils = require('./utils.js').Utils;
const ProgressCircle = require('./progress-circle.js').ProgressCircle;
const PixelTileAnim = require('./animation.js').PixelTileAnim;
const verify = require("bitcoinjs-message").verify;
const Payout = require("./payout.js").Payout;

class Upload {
	constructor( sparkshot ) {
		this.Payout = new Payout();

        this.sparkshot = sparkshot;
		this.MAX_EMAIL_LENGTH = 128;
		this.MAX_TWITTER_LENGTH = 15;
		this.MIN_TITLE_LENGTH = 3;
		this.MAX_TITLE_LENGTH = 63;
		this.MIN_DESCRIPTION_LENGTH = 0;
		this.MAX_DESCRIPTION_LENGTH = 255;
		this.MIN_URL_LENGTH = 3;
		this.MAX_URL_LENGTH = 63;
		this.MIN_ART_WIDTH = 1;
		this.MAX_ART_WIDTH = 1000;
		this.MIN_ART_HEIGHT = 1;
		this.MAX_ART_HEIGHT = 1000;
		this.MAX_ART_FILENAME_LENGTH = 100;
		this.MAX_ART_FILE_SIZE = 10485760;
		this.MAX_PRICE_FILENAME_LENGTH = 100;
		this.MAX_TAGS = 8;
		this.MIN_TAG_LENGTH = 3;
		this.MAX_TAG_LENGTH = 31;

		this.URL_TAKEN_ERROR = "Taken"

		this.TITLE_SAMPLE_TEXT = "Mona Lisa";
		this.DESCRIPTION_SAMPLE_TEXT = "Painting of Lisa Gheradini";
		this.URL_SAMPLE_TEXT = "mona-lisa";
		this.EMAIL_SAMPLE_TEXT = "leonardo@davinci.com";

		this.ART_SAMPLE_TEXT = "mona-lisa.png";
		this.PRICE_SAMPLE_TEXT = "mona-lisa-price.png";

		this.MAX_BTC_ADDRESS_LENGTH = 35;
		this.BTC_SAMPLE_TEXT = "1BLeonardoDaVinciheartsbitcoin";
		this.MAX_SIGNATURE_LENGTH = 100;
		this.SIGNATURE_SAMPLE_TEXT = ""

		this.SEED_COUNTDOWN_TIME = 30;

		this.size_categories = {
			very_small:	{
				max_size: 1024,
				fan_size: 1,
				accept_btn_text: `I'M GOOD TO GO!`
			},
			small:	{
				max_size: 20024,
				fan_size: 30,
				accept_btn_text: `I'M GOOD TO GO!`
			},
			medium:	{
				max_size: 100000,
				fan_size: 100,
				accept_btn_text: `I'M GOOD TO GO!`
			},
			large:	{
				max_size: 250000,
				fan_size: 400,
				accept_btn_text: `<span class="long-text">I CONFIRM I HAVE 400 ACTIVE FANS WHO CAN HELP REVEAL THIS ART</span>`
			},
			giant:	{
				max_size: 500000,
				fan_size: 1000,
				accept_btn_text: `<span class="long-text">I CONFIRM I HAVE 1000 ACTIVE FANS WHO CAN HELP REVEAL THIS ART</span>`
			},
			epic:	{
				max_size: 1000000,
				fan_size: 3000,
				accept_btn_text: `<span class="long-text">I UNDERSTAND THIS ART WILL REQUIRE AROUND 3000 ACTIVE FANS TO FULLY FUND</span>`
			}
		};

		this.AVERAGE_PIXEL_PER_TX = 40;

		this.generated_price_value = 0;
		this.SPARKSHOT_PERCENT_FEE = 30;

		this.form = {
			isSubmitValid: 			false,
			isUrlValid: 			false,
			isTitleValid: 			false,
			isDescriptionValid: 	false,
			instance:               this.sparkshot.UI.modalInstances.find( ( obj ) => { return obj.id === 'ui-upload-modal'; } ),
			form: 					document.querySelector(`#upload-form`),
			buttons: {
				accept_info: document.querySelector(`#upload-info-accept-btn`),
				cancel_info: document.querySelector(`#upload-info-close-btn`),
				accept_evaluation: document.querySelector(`#upload-evaluate-accept-btn`),
				cancel_evaluation: document.querySelector(`#upload-evaluate-close-btn`),
				information_tab: document.querySelector(`#upload-information-tab-btn`),
                art_file: document.querySelector(`#ui-upload-image-file`),
				extra_tab: document.querySelector(`#upload-extra-tab-btn`),
				files_tab: document.querySelector(`#upload-files-tab-btn`),
				message_tab: document.querySelector(`#upload-message-tab-btn`),
				submit_tab: document.querySelector(`#upload-submit-tab-btn`),
				copymessage: document.querySelector(`#upload-copy-message-btn`),
				close: document.querySelector(`#ui-upload-close-btn`),
				close_submit: document.querySelector(`#ui-upload-close-submit-btn`),
                details_accept: document.querySelector(`#upload-details-accept-btn`),
				auto_accept: document.querySelector(`#upload-auto-accept-btn`),
				manual_accept: document.querySelector(`#upload-manual-accept-btn`),
				seed_confirm: document.querySelector(`#upload-seed-confirm-btn`),
                submit: document.querySelector(`#upload-submit-btn`),
				sign_type_back: document.querySelector(`#upload-sign-type-back-btn`),
				seed_back: document.querySelector(`#upload-seed-back-btn`),
				manual_back: document.querySelector(`#upload-manual-back-btn`),
				reset_form_details: document.querySelector(`#upload-reset-1-btn`),
				reset_form_submit: document.querySelector(`#upload-reset-3-btn`),
				reset_form_uploaded: document.querySelector(`#upload-reset-end-btn`),
			},
			fields: {
				evaluate_art_advice: document.querySelector('#upload-evaluate-art-advice'),
				evaluate_art_size: document.querySelector('#upload-evaluate-art-size'),
				evaluate_art_pixels: document.querySelector('#upload-evaluate-art-pixels'),
                drag_file_add: document.querySelector('.upload-file-overlay'),
                art_sample: document.querySelector(`#ui-upload-image-view`),
				price_sample: document.querySelector(`#ui-upload-price-view`),
				art_browse: document.querySelector( `#ui-upload-image-file-browse` ),
				price_browse: document.querySelector( `#ui-upload-price-file-browse` ),
				title: document.querySelector(`#upload-title-field`),
				description: document.querySelector(`#upload-description-field`),
				btc_address: document.querySelector(`#upload-btc-address-field`),
				art_file: document.querySelector(`#upload-image-file`),
				price_file: document.querySelector(`#upload-price-file-field`),
				url: document.querySelector(`#upload-url-field`),
				email: document.querySelector(`#upload-email-field`),
                twitter: document.querySelector(`#upload-twitter-field`),
				tags: M.Chips.getInstance(document.querySelector(`#upload-tags-field`)),
				tag_entry: document.querySelector(`#upload-tags-field`),
				generated_price: document.querySelector(`#ui-upload-calc-sat-price`),
				message: document.querySelector(`#upload-message-field`),
				url_msg: document.querySelector(`#upload-url-msg-field`),
				signed_msg: document.querySelector(`#upload-signed-message-field`),
				art_uploading: document.querySelector(`#pixel-tile-anim`),
				art_complete: document.querySelector(`#upload-complete-image`),
				seed: document.querySelector(`#upload-seed-auto`),
				seed_select_block: document.querySelector(`#upload-seed-copy-message`),
				art_title: document.querySelectorAll(`.upload-title`),
				auto_address: document.querySelector(`#upload-auto-artist`),
				seed_address: document.querySelector(`#upload-auto-seed-artist`),
			},
			progress: {
				title: new ProgressCircle({
											size: 16,
                                            stroke_width: 2,
                                            id: 'upload-title-field-progress-circle',
                                            parent_id: 'upload-title-field-progress',
                                            manual_color_set: false,
											reverse_color: true,
                                            background_color: 'none'
                                        }),
				description: new ProgressCircle({
											size: 16,
                                            stroke_width: 2,
                                            id: 'upload-description-field-progress-circle',
                                            parent_id: 'upload-description-field-progress',
                                            manual_color_set: false,
											reverse_color: true,
                                            background_color: 'none'
                                        }),
				tags: new ProgressCircle({
											size: 16,
                                            stroke_width: 2,
                                            id: 'upload-tags-field-progress-circle',
                                            parent_id: 'upload-tags-field-progress',
                                            manual_color_set: false,
											reverse_color: true,
                                            background_color: 'none'
                                        }),
		},
			valid: {
				title: document.querySelector( `#ui-upload-title-field-confirm` ),
				description: document.querySelector( `#ui-upload-description-field-confirm` ),
				url: document.querySelector( `#ui-upload-url-field-confirm` ),
				price_file: document.querySelector( `#ui-upload-price-file-confirm` ),
				btc_address: document.querySelector( `#ui-upload-btc-address-field-confirm` ),
				signed_msg: document.querySelector( `#ui-upload-signed-message-field-confirm` ),
			},
			files: {
				art: { name: null, sha256: null, base64: null, width: null, height: null },
				price: { name: null, sha256: null, base64: null, width: null, height: null },
			},
			submit: {
				progress: document.querySelector(`#ui-upload-progress`),
				complete: document.querySelector(`#ui-upload-complete-title`),
				complete_body: document.querySelector(`#ui-upload-complete-body`),
				failed: document.querySelector(`#ui-upload-error-title`),
				failed_body: document.querySelector(`#ui-upload-error-body`),
				failed_msg: document.querySelector(`#upload-failed-message`),
			},
			tabs: {
				info: document.querySelector( '#ui-upload-panel-info' ),
                art: document.querySelector( '#ui-upload-panel-art' ),
				evaluate: document.querySelector( '#ui-upload-panel-evaluate' ),
                details: document.querySelector( '#ui-upload-panel-details' ),
				sign_type_select: document.querySelector( '#ui-upload-panel-sign-choice' ),
				seed: document.querySelector( '#ui-upload-panel-seed' ),
                message: document.querySelector( '#ui-upload-panel-manual' ),
                submit: document.querySelector( '#ui-upload-panel-submit' ),
			}
		}

		// Set Data
		this.form.fields.title.dataset.length = this.MAX_TITLE_LENGTH;
		this.form.fields.description.dataset.length = this.MAX_DESCRIPTION_LENGTH;
		this.form.fields.url.dataset.length = this.MAX_URL_LENGTH;

		this.form.fields.title.placeholder = this.TITLE_SAMPLE_TEXT;
		this.form.fields.description.placeholder = this.DESCRIPTION_SAMPLE_TEXT;
		this.form.fields.url.placeholder = this.URL_SAMPLE_TEXT;

		this.animate_upload = null;

    	this._upload_enabled = true;
        this._upload_category = 'art';
        this._upload_valid_categories = ['art', 'price'];

		this.form.buttons.accept_info.tabIndex = -1;
		this.form.buttons.cancel_info.tabIndex = -1;

		this.form.buttons.details_accept.tabIndex = -1;
		this.form.buttons.reset_form_details.tabIndex = -1;
		this.form.buttons.reset_form_submit.tabIndex = -1;
		this.form.buttons.reset_form_uploaded.tabIndex = -1;
		this.form.buttons.manual_back.tabIndex = -1;
		this.form.buttons.seed_back.tabIndex = -1;
		this.form.buttons.auto_accept.tabIndex = -1;
		this.form.buttons.sign_type_back.tabIndex = -1;
		this.form.buttons.manual_accept.tabIndex = -1;
		this.form.buttons.submit.tabIndex = -1;
		this.form.buttons.seed_confirm.tabIndex = -1;
		this.form.buttons.close_submit.tabIndex = -1;
	}

	init() {
		// Clear fields
		this.validateForm();

		// Configure Valid File Types
		this.form.fields.art_browse.accept = ".png";
		this.form.fields.price_browse.accept = ".png";

		// Setup event listeners
		this.addEventListeners();
	}

	addEventListeners() {

		this.form.instance.options.onOpenStart = () => {
			this.sparkshot.UI.closeAllOtherModals(this.form.instance.id);
			this.sparkshot.UI.blocker( true );
			this.openMenu();
        };

        // If closed by any call
		this.form.instance.options.onCloseStart = () => {
			this.sparkshot.UI.blocker( false );
			this.closeMenu();
		};

		// Close Modal Button
		this.form.buttons.close.addEventListener("click", () => {
			this.form.instance.close();
		});

		// Close Modal Button
		this.form.buttons.close_submit.addEventListener("click", () => {
			if ( this.form.buttons.close_submit.classList.contains( 'disabled' ) ) return;
			this.form.instance.close();
		});

		this.form.buttons.close_submit.addEventListener( "keydown", (e) => {
			if ( e.key === "Tab" ) {
				e.preventDefault();
				this.form.buttons.reset_form_uploaded.focus();
			}
		}, false);

		this.form.buttons.close_submit.addEventListener( "keyup", (e) => {
			if ( e.keyCode !== 13 ) return;
			e.preventDefault();
			if ( this.form.buttons.close_submit.classList.contains( 'disabled' ) ) return;
			this.form.instance.close();
		}, false );

        ////////////////////////////////////////////////////////////////////////
		// Panel Navigation

		// FIRST UPLOAD PAGE WITH ADVICE

		// ACCEPT INFO BUTTON
		this.form.buttons.accept_info.addEventListener( 'click', () => {
			if ( this.form.buttons.accept_evaluation.classList.contains( 'disabled' ) ) return;
			this.switchPage( 'art' );
        }, false );

		this.form.buttons.accept_info.addEventListener( "keydown", (e) => {
			if ( e.key === "Tab" ) {
				e.preventDefault();
				this.form.buttons.cancel_info.focus();
			}
		}, false);

		this.form.buttons.accept_info.addEventListener( "keyup", (e) => {
			if ( e.keyCode !== 13 ) return;
			e.preventDefault();
			if ( this.form.buttons.accept_info.classList.contains( 'disabled' ) ) return;
			this.switchPage( 'art' );
		}, false );


		// CANCEL INFO BUTTON
		this.form.buttons.cancel_info.addEventListener( 'click', () => {
			if ( this.form.buttons.cancel_evaluation.classList.contains( 'disabled' ) ) return;
			this.form.instance.close();
        }, false );

		this.form.buttons.cancel_info.addEventListener( "keydown", (e) => {
			if ( e.key === "Tab" ) {
				e.preventDefault();
				this.form.buttons.accept_info.focus();
			}
		}, false);

		this.form.buttons.cancel_info.addEventListener( "keyup", (e) => {
			if ( e.keyCode !== 13 ) return;
			e.preventDefault();
			this.form.instance.close();
		}, false );

		// EVALUATION PAGE (CURRENTLY DISABLED)

		// Accept Evaluation
		this.form.buttons.accept_evaluation.addEventListener( 'click', () => {
			if ( this.form.buttons.accept_evaluation.classList.contains( 'disabled' ) ) return;
			this.switchPage( 'details' );
        }, false );

		// Cancel Evaluation
		this.form.buttons.cancel_evaluation.addEventListener( 'click', () => {
			if ( this.form.buttons.cancel_evaluation.classList.contains( 'disabled' ) ) return;
			this.form.instance.close();
        }, false );


		// CONFIRM DETAILS BUTTON
		this.form.buttons.details_accept.addEventListener( 'click', () => {
			if ( this.form.buttons.details_accept.classList.contains( 'disabled' ) ) {return;}
			this.switchPage( 'sign_type_select' );

		}, false );

		this.form.buttons.details_accept.addEventListener( "keydown", (e) => {
			if ( e.key === "Tab" ) {
				e.preventDefault();
				if ( e.shiftKey === true ) {
					this.form.fields.email.focus();
				} else {
					this.form.buttons.reset_form_details.focus();
				}
			}
		}, false);

		this.form.buttons.details_accept.addEventListener( "keyup", (e) => {
			if ( e.keyCode !== 13 ) return;
			e.preventDefault();
			if ( this.form.buttons.details_accept.classList.contains( 'disabled' ) ) return;
			this.switchPage( 'sign_type_select' );
		}, false );



		// Select Auto Sign Panel
		this.form.buttons.auto_accept.addEventListener( 'click', () => {
			if ( this.form.buttons.auto_accept.classList.contains( 'disabled' ) ) return;
			if ( this.sparkshot.Wallet.get_wrote_seed_status() ) {
				// AUTO SUBMIT
				// Geneate Message
				const msg = this.generateNonBreakingSpaceFixMessage();
				const wif = this.sparkshot.Wallet.get_address_wif().wif;
				const sig = this.sparkshot.Wallet.get_signature(msg, wif);
				const add = this.sparkshot.Wallet.get_address_wif().address;

				this.submitArt( add, sig );
				this.switchPage( 'submit' );
			} else {
				this.switchPage( 'seed' );
			}
        }, false );

		this.form.buttons.auto_accept.addEventListener( "keydown", (e) => {
			if ( e.key === "Tab" ) {
				e.preventDefault();
				if ( e.shiftKey === true ) {
					this.form.buttons.sign_type_back.focus();
				} else {
					this.form.buttons.manual_accept.focus();
				}
			}
		}, false);

		this.form.buttons.auto_accept.addEventListener( "keyup", (e) => {
			if ( e.keyCode !== 13 ) return;
			e.preventDefault();
			if ( this.form.buttons.auto_accept.classList.contains( 'disabled' ) ) return;

			if ( this.sparkshot.Wallet.get_wrote_seed_status() ) {
				// Auto submit art
				// Geneate Message
				const msg = this.generateNonBreakingSpaceFixMessage();
				const wif = this.sparkshot.Wallet.get_address_wif().wif;
				const sig = this.sparkshot.Wallet.get_signature(msg, wif);
				const add = this.sparkshot.Wallet.get_address_wif().address;

				this.submitArt( add, sig );

				this.switchPage( 'submit' );
			} else {
				this.switchPage( 'seed' );
			}

		}, false );

		// Confirm seed phrase written down
		this.form.buttons.seed_confirm.addEventListener( 'click', () => {
			if ( this.form.buttons.seed_confirm.classList.contains( 'disabled' ) ) return;

			// Save that USER confirmed writing down the seed
			this.sparkshot.Wallet.set_wrote_seed();

			// Auto submit art
			// Geneate Message
			const msg = this.generateMessage();
			const wif = this.sparkshot.Wallet.get_address_wif().wif;
			const sig = this.sparkshot.Wallet.get_signature(msg, wif);
			const add = this.sparkshot.Wallet.get_address_wif().address;

			this.submitArt( add, sig );

			this.switchPage('submit');
		}, false );

		this.form.buttons.seed_confirm.addEventListener( "keydown", (e) => {
			if ( e.key === "Tab" ) {
				e.preventDefault();
				this.form.buttons.seed_back.focus();
			}
		}, false);

		this.form.buttons.seed_confirm.addEventListener( "keyup", (e) => {
			if ( e.keyCode !== 13 ) return;
			e.preventDefault();

			if ( this.form.buttons.seed_confirm.classList.contains( 'disabled' ) ) return;

			// Save that USER confirmed writing down the seed
			this.sparkshot.Wallet.set_wrote_seed();

			// Auto submit art
			// Geneate Message
			const msg = this.generateMessage();
			const wif = this.sparkshot.Wallet.get_address_wif().wif;
			const sig = this.sparkshot.Wallet.get_signature(msg, wif);
			const add = this.sparkshot.Wallet.get_address_wif().address;

			this.submitArt( add, sig );

			this.switchPage('submit');

		}, false );

		// Block text copy
		this.form.fields.seed.addEventListener( 'click', () => {

			this.form.fields.seed_select_block.classList.remove('hide');

			// Clear any exising timer
			clearInterval(this.preventSelectTimer);

			this.preventSelectTimer = setInterval( () => {
				this.form.fields.seed_select_block.classList.add('hide')
				clearInterval(this.preventSelectTimer);
			}, 3000 );

		}, false );

		// Select Manual Sign Panel
		this.form.buttons.manual_accept.addEventListener( 'click', () => {

			if ( this.form.buttons.auto_accept.classList.contains( 'disabled' ) ) return;
			this.switchPage( 'message' );
        }, false );

		this.form.buttons.manual_accept.addEventListener( "keydown", (e) => {
			if ( e.key === "Tab" ) {
				e.preventDefault();
				if ( e.shiftKey === true ) {
					this.form.buttons.auto_accept.focus();
				} else {
					this.form.buttons.sign_type_back.focus();
				}
			}
		}, false);

		this.form.buttons.manual_accept.addEventListener( "keyup", (e) => {
			if ( e.keyCode !== 13 ) return;
			e.preventDefault();
			if ( this.form.buttons.auto_accept.classList.contains( 'disabled' ) ) return;
			this.switchPage( 'message' );
		}, false );

		// Confirm Manual Sign
		this.form.buttons.submit.addEventListener( 'click', () => {
			if ( this.form.buttons.submit.classList.contains( 'disabled' ) ) return;

			// Start submit before switching page to prevent fields from getting
			// bad data injected due to them not being rendered
			this.submitArt();

			this.switchPage( 'submit' );
		}, false );

		this.form.buttons.submit.addEventListener( "keydown", (e) => {
			if ( e.key === "Tab" ) {
				e.preventDefault();
				if ( e.shiftKey === true ) {
					this.form.fields.signed_msg.focus();
				} else {
					this.form.buttons.manual_back.focus();
				}
			}
		}, false);

		this.form.buttons.submit.addEventListener( "keyup", (e) => {
			if ( e.keyCode !== 13 ) return;
			e.preventDefault();

			if ( this.form.buttons.submit.classList.contains( 'disabled' ) ) return;

			// Start submit before switching page to prevent fields from getting
			// bad data injected due to them not being rendered
			this.submitArt();
			this.switchPage( 'submit' );
		}, false );

		////////////////////////////////////////////////////////////////////////
		// Reset Form

		this.form.buttons.reset_form_details.addEventListener( 'click', () => {
			this.openMenu();
        }, false );

		this.form.buttons.reset_form_details.addEventListener( "keydown", (e) => {
			if ( e.key === "Tab" ) {
				e.preventDefault();
				if ( e.shiftKey === true ) {
					this.form.buttons.details_accept.focus();
				} else {
					this.form.fields.title.focus();
				}
			}
		}, false);

		this.form.buttons.reset_form_details.addEventListener( "keyup", (e) => {
			if ( e.keyCode !== 13 ) return;
			e.preventDefault();
			this.openMenu();
		}, false );

		this.form.buttons.reset_form_submit.addEventListener( 'click', e => {
			e.preventDefault();
			this.switchPage( 'details' );
		}, false );

		this.form.buttons.reset_form_submit.addEventListener( "keyup", (e) => {
			if ( e.keyCode !== 13 ) return;
			e.preventDefault();
			this.switchPage( 'details' );
		}, false );

		this.form.buttons.reset_form_uploaded.addEventListener( 'click', e => {
			e.preventDefault();
			this.openMenu();
		}, false );

		this.form.buttons.reset_form_uploaded.addEventListener( "keydown", (e) => {
			if ( e.key === "Tab" ) {
				e.preventDefault();
				this.form.buttons.close_submit.focus();
			}
		}, false);

		this.form.buttons.reset_form_uploaded.addEventListener( "keyup", (e) => {
			if ( e.keyCode !== 13 ) return;
			e.preventDefault();
			this.openMenu();
		}, false );

		////////////////////////////////////////////////////////////////////////
		// Navigation Back Buttons

		this.form.buttons.sign_type_back.addEventListener( 'click', () => {
			this.switchPage( 'details' );
		}, false );

		this.form.buttons.sign_type_back.addEventListener( "keydown", (e) => {
			if ( e.key === "Tab" ) {
				e.preventDefault();
				if ( e.shiftKey === true ) {
					this.form.buttons.manual_accept.focus();
				} else {
					this.form.buttons.auto_accept.focus();
				}
			}
		}, false);

		this.form.buttons.sign_type_back.addEventListener( "keyup", (e) => {
			if ( e.keyCode !== 13 ) return;
			e.preventDefault();
			if ( this.form.buttons.details_accept.classList.contains( 'disabled' ) ) return;
			this.switchPage( 'details' );
		}, false );


		this.form.buttons.seed_back.addEventListener( 'click', () => {
			if ( this.form.buttons.seed_back.classList.contains( 'disabled' ) ) return;
			this.switchPage( 'sign_type_select' );
		}, false );

		this.form.buttons.seed_back.addEventListener( "keydown", (e) => {
			if ( e.key === "Tab" ) {
				e.preventDefault();
				this.form.buttons.seed_confirm.focus();
			}
		}, false);

		this.form.buttons.seed_back.addEventListener( "keyup", (e) => {
			if ( e.keyCode !== 13 ) return;
			e.preventDefault();
			if ( this.form.buttons.seed_back.classList.contains( 'disabled' ) ) return;
			this.switchPage( 'sign_type_select' );
		}, false );



		this.form.buttons.manual_back.addEventListener( 'click', () => {
			this.switchPage( 'sign_type_select' );
        }, false );

		this.form.buttons.manual_back.addEventListener( "keydown", (e) => {
			if ( e.key === "Tab" ) {
				e.preventDefault();
				if ( e.shiftKey === true ) {
					this.form.buttons.submit.focus();
				} else {
					this.form.fields.btc_address.focus();
				}
			}
		}, false);

		this.form.buttons.manual_back.addEventListener( "keyup", (e) => {
			if ( e.keyCode !== 13 ) return;
			e.preventDefault();
			if ( this.form.buttons.details_accept.classList.contains( 'disabled' ) ) return;
			this.switchPage( 'sign_type_select' );
		}, false );

		///////////////////////////////////////////////////////////////////////////
		// Input Title Text

		this.form.fields.title.addEventListener( "keydown", (e) => {
			if ( e.key === "Enter" ) { e.preventDefault(); }

			if ( e.key === "Tab" ) {
				e.preventDefault();
				if ( e.shiftKey === true ) {
					this.form.buttons.reset_form_details.focus();
				} else {
					this.form.fields.description.focus();
				}
			}

		}, false );

		this.form.fields.title.addEventListener( "keyup", () => {
			this.validateTitleField();
			this.generateURL( this.form.fields.title.textContent );

		}, false );

		this.form.fields.title.addEventListener( "input", (e) => {
			e.preventDefault();
			if ( e.inputType !== 'insertFromPaste' ) {
				this.validateTitleField();
			}
			this.validateForm();
		}, false );

		this.form.fields.title.addEventListener( "paste", (e) => {
			// cancel paste
			e.preventDefault();

			// get text representation of clipboard
			const text = (e.originalEvent || e).clipboardData.getData('text/plain');

			// insert text manually
			document.execCommand("insertHTML", false, text);

			// Remove any trailing white spaces
			this.form.fields.title.textContent = this.form.fields.title.textContent.trim();

			this.validateTitleField();
			this.validateForm();
		}, false );

		this.form.fields.title.addEventListener( "focus", (e) => {
			// Select current text range
			Utils.selectRange( e.target );

			this.form.progress.title.el.classList.remove( 'hide' );
		}, false );

		this.form.fields.title.addEventListener( "blur", () => {
			this.form.progress.title.el.classList.add( 'hide' );
			// Strip out the junk that Firefox adds if space is pressed
			this.form.fields.title.textContent = this.form.fields.title.textContent.replace('<br>', '');
			// Remove any trailing white spaces
			this.form.fields.title.textContent = this.form.fields.title.textContent.trim();
			this.validateTitleField();
			this.validateForm();
		}, false );

		///////////////////////////////////////////////////////////////////////////
		// Input Description Text

		this.form.fields.description.addEventListener( "keydown", (e) => {
			if ( e.key === "Enter" ) { e.preventDefault(); }

			if ( e.key === "Tab" ) {
				e.preventDefault();
				if ( e.shiftKey === true ) {
					this.form.fields.title.focus();
				} else {
					this.form.fields.url.focus();
				}
			}

		}, false );

		this.form.fields.description.addEventListener( "keyup", () => {
			this.validateDescriptionField();
		}, false );

		this.form.fields.description.addEventListener( "input", (e) => {
			e.preventDefault();
			if ( e.inputType !== 'insertFromPaste' ) {
				this.validateDescriptionField();
			}
			this.validateForm();
		}, false );

		this.form.fields.description.addEventListener( "paste", (e) => {
			// cancel paste
		    e.preventDefault();

		    // get text representation of clipboard
		    const text = (e.originalEvent || e).clipboardData.getData('text/plain');

		    // insert text manually
		    document.execCommand("insertHTML", false, text);
			// Remove any trailing white spaces
			this.form.fields.description.textContent = this.form.fields.description.textContent.trim();
			this.validateDescriptionField();
			this.validateForm();
		}, false );

		this.form.fields.description.addEventListener( "focus", (e) => {
			// Select current text range
			Utils.selectRange( e.target );

			this.form.progress.description.el.classList.remove( 'hide' );
		}, false );

		this.form.fields.description.addEventListener( "blur", () => {
			this.form.progress.description.el.classList.add( 'hide' );
			// Strip out the junk that Firefox adds if space is pressed
			this.form.fields.description.textContent = this.form.fields.description.textContent.replace('<br>', '');
			// Remove any trailing white spaces
			this.form.fields.description.textContent = this.form.fields.description.textContent.trim();
			this.validateForm();
		}, false );

		///////////////////////////////////////////////////////////////////////////
		// Url Input

		this.form.fields.url.addEventListener("keydown", (e) => {
			if ( e.key === "Enter" ) { e.preventDefault(); }

			if ( e.key === "Tab" ) {
				e.preventDefault();
				if ( e.shiftKey === true ) {
					this.form.fields.description.focus();
				} else {
					this.form.fields.tag_entry.lastChild.focus();
				}
			}

			if ( !Utils.validateCharacter( e.key, /([a-z0-9\-])/ ) ) {
				e.preventDefault();
			}
		});

		this.form.fields.url.addEventListener("keyup", () => {
			this.validateURLField();
			this.validateForm();
		});

		this.form.fields.url.addEventListener( "input", () => {
		}, false );

		this.form.fields.url.addEventListener( "paste", (e) => {
			// cancel paste
		    e.preventDefault();

		    // get text representation of clipboard
		    let text = (e.originalEvent || e).clipboardData.getData('text/plain');
			text = text.replace( /[^a-zA-Z0-9-]/gm, '');

		    // insert text manually
		    document.execCommand("insertHTML", false, text);

			this.validateURLField();
			this.sparkshot.WS.request_check_url( this.form.fields.url.textContent );
		}, false );

		this.form.fields.url.addEventListener( "focus", (e) => {
			// Select current text range
			Utils.selectRange( e.target );
		}, false );

		this.form.fields.url.addEventListener("blur", () => {
			this.sparkshot.WS.request_check_url( this.form.fields.url.textContent );
		});

		///////////////////////////////////////////////////////////////////////////
		// Tag Input

		this.form.fields.tag_entry.addEventListener( "keydown", (e) => {
			if ( e.key === "Delete" || e.key === "Backspace" || e.key === "Tab") {
			} else if ( this.form.fields.tags.chipsData.length >= this.MAX_TAGS ) {
				e.preventDefault();
			}

			// Allow , and Tab for adding tags
			if ( e.key === "," || e.key === "Tab") {

				if ( this.form.fields.tag_entry.lastChild.value === "") {
					e.preventDefault();
					e.preventDefault();
					if ( e.shiftKey === true ) {
						this.form.fields.url.focus();
					} else {
						this.form.fields.twitter.focus();
					}
				} else {
					this.form.fields.tag_entry.M_Chips.addChip({
						tag: this.form.fields.tag_entry.lastChild.value
					});
					this.form.fields.tag_entry.lastChild.value = "";
					e.preventDefault();
				}
			}


			if ( this.form.fields.tag_entry.lastChild.value.length >= 32 ) { e.preventDefault(); }
		}, false );

		this.form.fields.tag_entry.addEventListener( "keyup", () => {
			this.validateTagsField();
		}, false );

		this.form.fields.tag_entry.addEventListener( "input", (e) => {
			e.preventDefault();
		}, false );

		this.form.fields.tag_entry.lastElementChild.addEventListener( "input", () => {
			// this.form.fields.tag_entry.lastElementChild.value = this.form.fields.tag_entry.lastElementChild.value.replace( /[^a-zA-Z0-9 ]/gm, '');
			this.validateTagsField();
		}, false );

		this.form.fields.tag_entry.firstChild.addEventListener( "focus", () => {
			this.form.progress.tags.el.classList.remove( 'hide' );
			this.validateTagsField();
		}, false );

		this.form.fields.tag_entry.firstChild.addEventListener( "blur", () => {
			this.form.progress.tags.el.classList.add( 'hide' );

			if ( this.form.fields.tags.chipsData.length < this.MAX_TAGS && this.form.fields.tag_entry.lastChild.value.length > 0  ) {
				this.form.fields.tag_entry.M_Chips.addChip({
					tag: this.form.fields.tag_entry.lastChild.value
				});
				this.form.fields.tag_entry.lastChild.value = "";
			}


			this.validateTagsField();
		}, false );

		///////////////////////////////////////////////////////////////////////////
		// Email Input

		this.form.fields.email.addEventListener( "keydown", (e) => {
			if ( !Utils.validateCharacter( e.key, /[a-zA-Z0-9@\+.-]/gm ) ) {
				e.preventDefault();
			}

			if ( e.key === "Tab" ) {
				e.preventDefault();
				if ( e.shiftKey === true ) {
					this.form.fields.twitter.focus();
				} else {
					this.form.buttons.details_accept.focus();
				}
			}

			this.validateEmailField( false );
		});

		this.form.fields.email.addEventListener( "input", () => {
		}, false );

		this.form.fields.email.addEventListener( "paste", (e) => {
			// cancel paste
			e.preventDefault();

			// get text representation of clipboard
			let text = (e.originalEvent || e).clipboardData.getData('text/plain');

			text = text.replace( /[^a-zA-Z0-9@\+.-]/gm, '');

			// insert text manually
			document.execCommand("insertHTML", false, text);

			this.validateEmailField();
		}, false );

		this.form.fields.email.addEventListener( "focus", (e) => {
			// Select current text range
			Utils.selectRange( e.target );
		}, false );

		this.form.fields.email.addEventListener( "blur", () => {
			this.validateEmailField();
		}, false );

		///////////////////////////////////////////////////////////////////////////
		// Twitter Input

		this.form.fields.twitter.addEventListener( "keydown", (e) => {
			if ( !Utils.validateCharacter( e.key, /[a-zA-Z0-9_@]/gm ) ) {
				e.preventDefault();
			}

			if ( e.key === "Tab" ) {
				e.preventDefault();
				if ( e.shiftKey === true ) {
					this.form.fields.tag_entry.lastChild.focus();
				} else {
					this.form.fields.email.focus();
				}
			}

			this.validateTwitterField( false );
		});

		this.form.fields.twitter.addEventListener( "input", () => {
		}, false );

		this.form.fields.twitter.addEventListener( "paste", (e) => {
			// cancel paste
			e.preventDefault();

			// get text representation of clipboard
			let text = (e.originalEvent || e).clipboardData.getData('text/plain');

			text = text.replace( /[^a-zA-Z0-9_@]/gm, '');

			// insert text manually
			document.execCommand("insertHTML", false, text);

			this.validateTwitterField();
		}, false );

		this.form.fields.twitter.addEventListener( "focus", (e) => {
			// Select current text range
			Utils.selectRange( e.target );
		}, false );

		this.form.fields.twitter.addEventListener( "blur", () => {
			if ( this.form.fields.twitter.textContent.length === 0 ) return;

			// Add an @ if one isn't there
			if ( this.form.fields.twitter.textContent.substring( 0, 1 ) !== '@' ) {
				this.form.fields.twitter.textContent = '@' + this.form.fields.twitter.textContent;
			}

			this.validateTwitterField();
		}, false );

		////////////////////////////////////////////////////////////////////////
		// Input Bitcoin Address

		this.form.fields.btc_address.addEventListener( "keydown", (e) => {

			if ( e.key === "Tab" ) {
				e.preventDefault();
				if ( e.shiftKey === true ) {
					this.form.buttons.manual_back.focus();
				} else {
					this.form.fields.signed_msg.focus();
				}
			}

			// Prevent new lines
			if ( this.form.fields.btc_address.textContent.length >= this.MAX_BTC_ADDRESS_LENGTH &&
				 !Utils.isNavigationKey(e) ) {
					 e.preventDefault();
					 return;
				 }
			if ( Utils.isNewLineChar( e.key ) ) { e.preventDefault(); }
			if ( !Utils.validateCharacter( e.key ) ) { e.preventDefault(); }
		}, false );

		this.form.fields.btc_address.addEventListener( "keyup", () => {
			this.validateBitcoinAddressField();
			this.validateForm();
		}, false );

		this.form.fields.btc_address.addEventListener( "paste", () => {
			setTimeout( () => {
				this.validateBitcoinAddressField();
				this.validateForm();
			}, 10);
			this.validateBitcoinAddressField();
			this.validateForm();
		}, false );

		this.form.fields.btc_address.addEventListener( "blur", () => {
			this.validateBitcoinAddressField();
			this.validateForm();
		}, false );

		this.form.fields.btc_address.addEventListener( "auxclick", () => {
			this.validateBitcoinAddressField();
			this.validateForm();
		}, false );


		////////////////////////////////////////////////////////////////////////
		// Input Signature

		this.form.fields.signed_msg.addEventListener( "keydown", (e) => {
			// Prevent new lines
			if ( this.form.fields.btc_address.textContent.length >= this.MAX_SIGNATURE_LENGTH &&
				 !Utils.isNavigationKey(e) ) {
					 e.preventDefault();
					 return;
				 }
			if ( Utils.isNewLineChar( e.key ) ) { e.preventDefault(); }
			if ( !Utils.validateCharacter( e.key ) ) { e.preventDefault(); }
		}, false );

		this.form.fields.signed_msg.addEventListener( "keyup", () => {
			this.validateSignatureField();
			this.validateForm();
		}, false );

		this.form.fields.signed_msg.addEventListener( "paste", () => {
			setTimeout( () => {
				this.validateSignatureField();
				this.validateForm();
			}, 10);
		}, false );

		this.form.fields.signed_msg.addEventListener( "blur", () => {
			this.validateSignatureField();
			this.validateForm();
		}, false );

		this.form.fields.signed_msg.addEventListener( "auxclick", () => {
			this.validateSignatureField();
			this.validateForm();
		}, false );

		////////////////////////////////////////////////////////////////////////
		// Message

		this.form.buttons.copymessage.addEventListener( "click", () => {

			this.form.fields.message.parentElement.addEventListener("transitionend", () => {
				this.form.fields.message.parentElement.classList.remove('copied');
				this.form.fields.message.parentElement.removeEventListener("transitionend", () => {});
			});

			Utils.copyText( this.generateNonBreakingSpaceFixMessage() );

			this.form.fields.message.parentElement.classList.add( 'copied' );
		});

		this.form.buttons.copymessage.addEventListener( "keyup", (e) => {
			if ( e.keyCode !== 13 ) return;
			e.preventDefault();
			this.form.fields.message.parentElement.addEventListener("transitionend", () => {
				this.form.fields.message.parentElement.classList.remove('copied');
				this.form.fields.message.parentElement.removeEventListener("transitionend", () => {});
			});

			Utils.copyText( this.generateNonBreakingSpaceFixMessage() );

			this.form.fields.message.parentElement.classList.add( 'copied' );
		}, false );

        ////////////////////////////////////////////////////////////////////////
		// DRAG AND DROP FILE ADD EVENTS

        // Detect Drag Enter
		document.addEventListener("dragenter", e => {
			e.preventDefault();
            // Ignore if uploader is disabled
            if ( !this.getUploadEnabled() ) {
				this.form.fields.drag_file_add.classList.add('hide');
				return;
			}

			// If it's a file then show the drag and drop UI
			if ( e.dataTransfer.items[0].kind === 'file' ) {
				this.form.fields.drag_file_add.classList.remove('hide');
			}
		}, false);

		this.form.fields.drag_file_add.addEventListener("dragleave", e => {
			e.preventDefault();

			// Remove the drag and drop UI
			// This timer will be killed if the browser detects a file being
			// dragged via "dragover"
			// The timer is to ensure that we get this event last
			this.drag_over_timeout = setTimeout( () => {
				this.form.fields.drag_file_add.classList.add('hide');
			}, 200 );
		}, false);

        // Detect Drag Over
		document.addEventListener("dragover", e => {
			e.preventDefault();

			// Clear the leave timeout so the panel does not get hidden
			// during drag over
			clearTimeout(this.leaveTimout);

            // Ignore if uploader is disabled
            if ( e.dataTransfer.items[0].kind === 'file' && this.getUploadEnabled() ) {

				// If it's a file then show the drag and drop UI
				this.form.fields.drag_file_add.classList.remove('hide');
				return;
			} else {
				this.form.fields.drag_file_add.classList.add('hide');
			}
		}, false);

        // DETECT FILE DROP
		document.addEventListener( "drop", e => {
			// prevent default action (open as link for some elements)
			e.preventDefault();

			// After dropping the file cancel file dragging
			this.file_dragging = false;

			// Stop if dropped item is not a file
			if ( typeof e.dataTransfer.files[0] == 'undefined' ) {
				this.drag_over_timeout = setTimeout( () => {
					this.form.fields.drag_file_add.classList.add('hide');
				}, 200 );
				return;
			}

            // Ignore if uploader is disabled
            if ( !this.getUploadEnabled() ) return;

			if ( !this.form.instance.isOpen ) {
				this.form.instance.open();
			}

			this.setFile( e.dataTransfer, this.form.files[this.getUploadCategory()] )
				.then( (resolve, reject) => {
					if ( resolve ) {
						// If the art is valid then proceed
						switch (this.getUploadCategory()) {
							case 'art':
								this.switchPage( 'details' );
								break;
							case 'price':
								this.setBase64ImageOnCanvas('upload-price-canvas',
															this.form.files.price.base64,
															this.form.files.art.width,
															this.form.files.art.height );
								break;
							default:
						}

						// Set the price field of the art
						this.form.fields.generated_price.textContent = this.calculatePrice();
						this.form.fields.drag_file_add.classList.add('hide');
					} else {
						this.form.fields.drag_file_add.classList.add('hide');
					}
				})
				.catch( reject => {
					console.error( reject );
				});

		}, false);

		////////////////////////////////////////////////////////////////////////
		// BROWSE FILE ADD EVENTS

		// Art File
		this.form.fields.art_browse.addEventListener( 'change', e => {
			e.preventDefault();
			if ( e.target.files.length > 0 ) {
				this.setFile( e.target, this.form.files[this.getUploadCategory()] )
					.then( (resolve, reject) => {
						if ( resolve ) {
							this.setBase64ImageOnCanvas('upload-image-canvas',
														this.form.files.art.base64,
														this.form.files.art.width,
														this.form.files.art.height );
							this.switchPage( 'details' );
						}
					})
					.catch( reject => console.error( reject ) );
			}

		}, false );

		this.form.fields.art_browse.addEventListener( "keyup", (e) => {
			if ( e.keyCode !== 13 ) return;
			e.preventDefault();

			this.form.fields.art_browse.click();

		}, false );

		// Price File
		this.form.fields.price_browse.addEventListener( 'change', e => {
			e.preventDefault();

			if ( e.target.files.length > 0 ) {
				this.setFile( e.target, this.form.files[this.getUploadCategory()] )
					.then( (resolve, reject) => {
						if ( resolve ) {
							this.setBase64ImageOnCanvas('upload-price-canvas',
														this.form.files.price.base64,
														this.form.files.art.width,
														this.form.files.art.height );
						}
					})
					.catch( reject => console.error( reject ) );
			}

		}, false );

		this.form.fields.price_browse.addEventListener( "keyup", (e) => {
			if ( e.keyCode !== 13 ) return;
			e.preventDefault();

			this.form.fields.price_browse.click();

		}, false );
	}


    // #####################################################################
	// .....................................................................
	// PAGE NAV

	/**
	 * Opens Upload Menu
	 */
	openMenu() {
		// if ( !this.isOpen() ) {
		// 	this.sparkshot.UI.blocker( true );
		// }

		// Configure Menu
		this.resetUploadForm();

		// Set initial page
		this.switchPage( 'info' );

		// Remove hints
		this.sparkshot.Tutorial.removeAllHints();
    }

	isOpen() {
		return this.form.instance.isOpen;
	}

	switchPage( page ) {

		// Default Hide all panels
		this.form.tabs.info.classList.add( 'hide' );
		this.form.tabs.art.classList.add( 'hide' );
		this.form.tabs.evaluate.classList.add( 'hide' );
		this.form.tabs.details.classList.add( 'hide' );
		this.form.tabs.sign_type_select.classList.add( 'hide' );
		this.form.tabs.message.classList.add( 'hide' );
		this.form.tabs.seed.classList.add( 'hide' );
		this.form.tabs.submit.classList.add( 'hide' );

		// Clear fields
		this.form.fields.seed.textContent = "";

		// Check if stored wallet is setup
		if ( !this.sparkshot.Wallet.has_mnemonic() ) {
			this.sparkshot.Wallet.generate_store_mnemonic();
		}
		const address = this.sparkshot.Wallet.get_address_wif().address;

        switch ( page ) {
			case 'info':
				this.resetSubmitPanel();
				// Configure File upload
				this.setFileUploadCategory( 'art' );
				this.setUploadEnabled( false );

				// Reveal panel
				this.form.tabs.info.classList.remove( 'hide' );

				// Set focus element
				setTimeout( () => {	this.form.buttons.accept_info.focus();}, 0);

				this.setElementEnabled( this.form.buttons.submit, false);
				break;
            case 'art':
				this.resetSubmitPanel();
				// Configure File upload
				this.setFileUploadCategory( 'art' );
				this.setUploadEnabled( true );

				// Reveal panel
                this.form.tabs.art.classList.remove( 'hide' );

				// Set focus element
				setTimeout( () => {	this.form.fields.art_browse.focus();}, 0);

				this.setElementEnabled( this.form.buttons.submit, false);
                break;
            case 'evaluate':
				this.resetSubmitPanel();
				// Configure File upload
				// this.setFileUploadCategory( 'art' );
				this.setUploadEnabled( false );

				// Set Text
				this.populateArtEvaluation();

				// Reveal panel
				this.form.tabs.evaluate.classList.remove( 'hide' );

				this.setElementEnabled( this.form.buttons.submit, false);
				break;
            case 'details':
				this.resetSubmitPanel();
				// Populate Image
				this.setBase64ImageOnCanvas( 'upload-image-canvas',
											this.form.files.art.base64,
											this.form.files.art.width,
											this.form.files.art.height );

				// Configure File upload
				this.setFileUploadCategory( 'price' );
				this.setUploadEnabled( true );

				// Set Generated Price
				this.form.fields.generated_price.textContent = this.calculatePrice();

				// Set focus element
				setTimeout( () => {	this.form.fields.title.focus();}, 0);

				// Reveal panel
                this.form.tabs.details.classList.remove( 'hide' );

				this.setElementEnabled( this.form.buttons.submit, false);

                break;
			case 'sign_type_select':
				this.resetSubmitPanel();
				this.setUploadEnabled( false );
				this.setElementEnabled( this.form.buttons.submit, false);

				// Set fields
				this.form.fields.art_title.forEach( e => {
					e.textContent = this.form.fields.title.textContent;
				})

				if ( address ) {
					this.form.fields.auto_address.textContent = address;
					// Set URI
					const uri = `${window.location.href}?=${address}`;
					this.form.fields.auto_address.href = uri;
				} else {
					console.error('UPLOAD FAILED TO GENERERATE WALLET');
				}

				// Set panel visibility
				this.form.tabs.sign_type_select.classList.remove( 'hide' );

				// Set focus element
				setTimeout( () => {	this.form.buttons.auto_accept.focus();}, 0);

                break;
            case 'message':
				this.setElementEnabled( this.form.buttons.submit, false);
				this.resetSubmitPanel();
				this.generateMessage();

				this.setUploadEnabled( false );

				// Set panel visibility
                this.form.tabs.message.classList.remove( 'hide' );

				// Set text field tab order
				this.form.fields.btc_address.tabIndex = 1;
				this.form.fields.signed_msg.tabIndex = 2;
				this.form.buttons.submit.tabIndex = 3;

				// Set focus on first text field
				this.form.fields.btc_address.focus();

                break;
			case 'seed':
				this.resetSubmitPanel();
				this.setUploadEnabled( false );

				if ( address ) {
					this.form.fields.seed_address.textContent = address;
					// Set URI
					const uri = `${window.location.href}?=${address}`;
					this.form.fields.seed_address.href = uri;
				} else {
					console.error('UPLOAD FAILED TO GENERERATE WALLET');
				}

				// Get the current seed phrase from storage
				this.populateSeedPanel();

				// Set panel visibility
				this.form.tabs.seed.classList.remove( 'hide' );

				// Disable button on timer
				this.setButtonEnableTimer( this.form.buttons.seed_confirm, this.SEED_COUNTDOWN_TIME);

				// Set focus element
				setTimeout( () => {	this.form.buttons.seed_confirm.focus();}, 0);
                break;
            case 'submit':
				this.setElementEnabled( this.form.buttons.submit, false);
				this.setUploadEnabled( false );
                this.form.tabs.submit.classList.remove( 'hide' );
				// Start upload after the page has been revealed so the size can be captured
				// ensure it happens not on the same frame as the DOM update
				setTimeout( () => { this.setUploadAnimation(); }, 1);

				setTimeout( () => {
					// Set focus element
					this.form.buttons.close_submit.focus();
				}, 800);
                break;
            default:
        }
		this.refreshPreviewCanvas();
		// Make sure panel is at top
		this.form.form.parentElement.scrollTo(0,0);
	}

	// #####################################################################
	// .....................................................................
	// UPLOAD METHODS

    /**
	 * Gets enabled state of file upload system
	 * @return {boolean} state of upload function
	 */
    getUploadEnabled() {
        return this._upload_enabled;
    }

    /**
	 * Sets enabled state of file upload system
	 * @param  {boolean}    state - state of upload function
     * @return {boolean} state of upload function
	 */
    setUploadEnabled( state ) {
        this._upload_enabled = state;
        return this._upload_enabled;
    }

    /**
	 * Sets the type of file being uploaded from hard coded categories
	 * @param  {String}     category - string type of category
     * @return {String}     returns confirmed category
	 */
    setFileUploadCategory( category ) {
        if ( !this._upload_valid_categories.includes( category ) ) {
            console.error( 'BAD UPLOAD CATEGORY' );
            return;
        }

        this._upload_category = category;
        return this._upload_category;
    }

    /**
	 * Gets the type of file being uploaded from hard coded categories
     * @return {String}     returns confirmed category
	 */
    getUploadCategory() {
        return this._upload_category;
    }

	/**
	 * Sets the state of the upload UI response.
	 * True:informs user of complete upload
	 * False: informs user upload failed
 	 * @param  {boolean}    state - state of upload
	 */
	uploadResponse(state) {
		if (state) {
			this.form.submit.progress.classList.add('hide');
			this.form.submit.complete.classList.remove('hide');
			this.resetUploadForm();
		} else {
			this.form.submit.progress.classList.add('hide');
			this.form.submit.failed.classList.add('hide');
			this.form.buttons.submit.classList.remove('disabled');
		}
	}

	/**
	 * Clears all form fields and sets the form back
	 */
	resetUploadForm() {
		this.form.form.reset();

		this.setElementEnabled( this.form.buttons.details_accept, false );
		this.setElementEnabled( this.form.buttons.submit, false );

		this.form.fields.title.textContent = '';
		this.form.isTitleValid = false;
		this.form.fields.title.classList.remove( 'red' );
		this.form.progress.title.setProgress( 0 );

		this.form.fields.description.textContent = '';
		this.form.isDescriptionValid = false;
		this.form.fields.description.classList.remove( 'red' );
		this.form.progress.description.setProgress( 0 );

		this.form.fields.url.textContent = '';
		this.setValidCheckBox( this.form.valid.url, false );
		this.form.isUrlValid = false;
		this.form.fields.url.classList.remove( 'red' );

		this.form.fields.email.textContent = '';
		this.form.fields.email.classList.remove( 'red' );

		this.form.fields.twitter.textContent = '';
		this.form.fields.twitter.classList.remove( 'red' );

		this.form.files.art = { name: null, sha256: null, base64: null, width: null, height: null };
		this.form.files.price = { name: null, sha256: null, base64: null, width: null, height: null };

		this.form.fields.message.textContent = '';

		this.form.fields.btc_address.textContent = '';
		this.setValidCheckBox( this.form.valid.btc_address, false );

		this.form.fields.signed_msg.textContent = '';
		this.setValidCheckBox( this.form.valid.signed_msg, false );

		const chips = this.form.fields.tags.chipsData.length
		for (let i = 0; i < chips; i++) {
			this.form.fields.tags.deleteChip(0);
		}
		this.form.progress.tags.setProgress( 0 );
		this.form.progress.tags.el.classList.add( 'hide' );

		this.form.fields.art_uploading.classList.remove( 'hide' );
		this.form.fields.art_uploading.removeAttribute("style");
		this.form.fields.art_uploading.innerHTML = "";
		this.form.fields.art_complete.classList.add( 'hide' );

		this.clearCanvas('upload-image-canvas');
		this.clearCanvas('upload-price-canvas');

		this.resetSubmitPanel();

		this.validateForm();
	}

	resetSubmitPanel() {
		if ( this.animate_upload !== null ) {
			this.animate_upload.destroy( this.animate_upload );
			this.animate_upload = null;
		}

		this.form.submit.progress.classList.remove( 'hide' );
		this.form.submit.failed.classList.add( 'hide' );
		this.form.submit.failed_body.classList.add( 'hide' );
		this.form.submit.complete.classList.add( 'hide' );
		this.form.submit.complete_body.classList.add( 'hide' );
		this.form.submit.failed_msg.textContent = '';
		this.form.fields.url_msg.textContent = '';
	}

	clearFileInput(field) {
		// field.type = "text";
		// field.type = "file";
		// field.parentElement.parentElement.getElementsByTagName('input')[1].value = null;
	}

	clearFileStorage(storage) {
		storage.name = null;
		storage.sha256 = null;
		storage.base64 = null;
	}

	setFile(field, storage) {
		const file = field.files[0];
		if ( typeof file === "undefined" ) return;

		return new Promise( (resolve, reject) => {

			// check file size
			if ( !Utils.isValidateFileSize(file, this.MAX_ART_FILE_SIZE) ) {
				this.sparkshot.UI.showMessage( 'Error', `The size of the file exceeds 10MB` );
				this.clearFileInput(field);
				this.clearFileStorage(storage);
				this.validateForm();
				reject( `The size of the file exceeds 10MB` );
			} else {
				resolve( file )
			}
		})
		.then( file => {
			return Utils.loadFileAsArrayBuffer( file )
		})
		.then( array => {
			return new Promise( (res, rej) => {
				if (!Utils.isValidPNG(new Uint8Array(array))) {
					this.sparkshot.UI.showMessage('Error', `This is not a supported file type. Please submit a .png.`);
					this.clearFileInput( field );
					this.clearFileStorage( storage );
					this.validateForm();
					rej( 'File Type Not Supported' );
					return false;
				} else {
					res(array);
				}
			});
		})
		.then(array => {
			return Utils.loadFileAsDataUrl(file);
		})
		.then(image => {
			return Utils.loadImage(image);
		})
		.then(image => {
			return new Promise((res, rej) => {
				if (image.width > this.MAX_ART_WIDTH || image.height > this.MAX_ART_HEIGHT) {
					this.sparkshot.UI.showMessage('Error', `${image.width} x ${image.height} is not a supported resolution. Please submit an image of ${this.MAX_ART_WIDTH} x ${this.MAX_ART_HEIGHT} or less.`);
					this.clearFileInput( field );
					this.clearFileStorage( storage );
					this.validateForm();
					rej( 'Image resolution Not Supported' );
					return false;
				} else {
					res(image);
				}
			});
		})
		.then( image => {
			// Check Files Size Comparison
			return new Promise((res, rej) => {
				const form = this.form;

				// If we can check against the art file
				if ( form.files.art.sha256 !== null ) {

					if ( image.width !== form.files.art.width || image.height !== form.files.art.height) {
						this.sparkshot.UI.showMessage('Error', `Art ${form.files.art.width} x ${form.files.art.height} and Price ${image.width} x ${image.height} Image resolution does not match.`);
						this.clearFileInput(field);
						this.clearFileStorage(storage);
						this.validateForm();
						rej('Image Resolution Mismatch');
						return false;
					} else {
						storage.width = image.width;
						storage.height = image.height;
						res( image );
					}
				} else {
					storage.width = image.width;
					storage.height = image.height;
					res( image );
				}

			});
		})
		.then(image => {
			const base64 = image.src.replace(/^data:image\/[a-z]+;base64,/, "");
			storage.base64 = base64;
		})
		.then(() => {
			return Utils.loadFileAsArrayBuffer( file );
		})
		.then(array => {
			return Utils.sha256File( array );
		})
		.then(hash => {
			storage.name = file.name;
			storage.sha256 = hash;
		})
		.then(() => {
			this.validateForm();
			return true;
		})
		.catch(err => {
			console.error(err);
			return false;
		});
	}

	refreshPreviewCanvas() {
		document.querySelector("#upload-image-canvas").focus();
		document.querySelector("#upload-image-canvas").click();
	}

	setBase64ImageOnCanvas(field, base64, width, height){
		const canvas = document.querySelector(`#${field}`);
		if (field === null) {
			console.error(`Image Canvas Element ${field} can not be found`);
			return;
		}

		const ctx = canvas.getContext('2d');
		const image = new Image();
		image.onload = () => {

			// Set Canvas size to match the html size
			ctx.canvas.width = canvas.clientWidth;
			ctx.canvas.height = canvas.clientHeight;

			// Clear canvas ready for image
			ctx.fillStyle = "#ff0000";
			ctx.clearRect( 0, 0, canvas.width, canvas.height );

			const scale = Math.min(
				( ctx.canvas.width / width ),
				( ctx.canvas.height / height )
			);

			// Set smoothing based on scale of image
			ctx.imageSmoothingEnabled = ( width > ctx.canvas.width || height > ctx.canvas.height ) ? true : false;

			const adjustedWidth = width * scale;
			const adjustedHeight = height * scale;

			// Work out offset to center image
			const offsetX = (adjustedWidth < ctx.canvas.width) ? (ctx.canvas.width - adjustedWidth) / 2 : 0;
			const offsetY = (adjustedHeight < ctx.canvas.height) ? (ctx.canvas.height - adjustedHeight) / 2 : 0;


			ctx.drawImage(
				// Image Data
				image,
				// Image top left corner offset
				0, 0,
				// Image Size
				width, height,
				// Canvas top left offset
				offsetX, offsetY,
				// Image on canvas size
				adjustedWidth, adjustedHeight
			);

			// Get the cavas version of the image
			const imageData = ctx.getImageData(offsetX, offsetY, adjustedWidth, adjustedHeight);

			if ( this.getUploadCategory() === "price " ) {
				this.generated_price_value = 0;
			}

			// Cycle through the pixels to clear any transparent
			for (let i = 0; i < imageData.data.length; i += 4) {

				if ( imageData.data[i + 3] < 255 ) {
					imageData.data[i]		= 255;
					imageData.data[i + 1]	= 255;
					imageData.data[i + 2]	= 255;
					imageData.data[i + 3]   = 255;
				}

				// Calculate the price from the price image
				// TODO: THIS CODE DOES NOT YET PRODUCE THE RIGHT PRICE
				// CHECK lib/art.py for method

				if ( this.getUploadCategory() === "price" ) {
					if ( imageData.data[i] === 0 ) {
						this.generated_price_value += 16;
					} else if ( imageData.data[i] === 34 ){
						this.generated_price_value += 8;
					} else if ( imageData.data[i] === 68 ){
						this.generated_price_value += 4;
					} else if ( imageData.data[i] === 102 ){
						this.generated_price_value += 2;
					} else if ( imageData.data[i] === 136 ){
						this.generated_price_value += 1;
					} else if ( imageData.data[i] === 170 ){
						this.generated_price_value += 0.5;
					} else if ( imageData.data[i] === 204 ){
						this.generated_price_value += 0.25;
					} else if ( imageData.data[i] === 255 ){
						this.generated_price_value += 0.125;
					}
				}


			}

			// Replace image with update
			ctx.putImageData(imageData, offsetX, offsetY);


			this.sparkshot.Upload.test = ctx;
		};


		// Set Image data
		image.src = `data:image/png;base64,${base64}`;

	}

	clearCanvas(field) {
		const canvas = document.querySelector(`#${field}`);
		if (field === null) {
			console.error(`Image Canvas Element ${field} can not be found`);
			return;
		}
		const ctx = canvas.getContext('2d');

		// Clear canvas ready for image
		ctx.fillStyle = "#ff0000";
		ctx.clearRect( 0, 0, canvas.width, canvas.height );
	}

	setUploadAnimation() {
		// Calculate size
		const scale = Math.min(
			this.form.fields.art_uploading.clientWidth / this.form.files.art.width,
			360 / this.form.files.art.height
		);

		const adjustedWidth = this.form.files.art.width * scale;
		const adjustedHeight =  this.form.files.art.height * scale;

		this.animate_upload = new PixelTileAnim( {
			parent: this.form.fields.art_uploading,
			img: { src: `data:image/png;base64,${this.form.files.art.base64}` },
			width: adjustedWidth,
			height: adjustedHeight,
			size: this.form.files.art.base64.length,
			auto: true
		} );
	}

	// Use the Price file or Image size to generate a base price for the art
	calculatePrice() {
		if ( this.form.files.art.base64 === null ) return 0;

		// Has price file been added?
		if ( this.form.files.price.base64 === null ) {
			const pixels = this.form.files.art.width * this.form.files.art.height;
			const value = pixels * 0.7;
			return Utils.NumToCommas( value );
		} else {
			const value = Math.round(this.generated_price_value * 0.7);
			return Utils.NumToCommas( value );
		}
	}

	/**
	 * Takes input string and forms a valid url slug
	 * Sends the valid url to the server for availablility checking
     * @param {String}     text - text to generate URL from
	 */
	generateURL( text = '' ) {

		let encode = text
			.replace( /\s/g, '-' )
			.replace( /([^\-A-Za-z0-9])/g, '' )
			.replace( /^[\-]*/g, '' )
			.replace( /[\-]*$/g, '' )
			.replace( /[\-]/g, '-' )

		// if (encode.length > this.MAX_URL_LENGTH) encode = encode.substring(0, this.MAX_URL_LENGTH);
		this.form.fields.url.textContent = `${encode.toLowerCase()}`;
		this.validateURLField();
	}

	/**
	 * Gets the stored seed phrase and populates the DOM element
	 */
	populateSeedPanel() {
		// If there's no stored seed generate one
		if ( !this.sparkshot.Wallet.has_mnemonic() ) {
            this.sparkshot.Wallet.generate_store_mnemonic();
        }

		this.form.fields.seed.textContent = this.sparkshot.Wallet.get_mnemonic();
	}

	/**
	 * Checks validity of url field
     * @return {boolean} returns state of field
	 */
	validateURLField() {
		const length = this.form.fields.url.textContent.length;
		if ( length > this.MAX_URL_LENGTH ) {
			Utils.flashElement( this.form.fields.url );
			this.setValidCheckBox( this.form.valid.url, false );
			this.form.isUrlValid = false;
			this.form.fields.url.classList.add( 'red' );
		} else if ( length === 0 ) {
			this.form.fields.url.classList.remove( 'red' );
			this.setValidCheckBox( this.form.valid.url, false );
			this.form.isUrlValid = false;
			return false;
		} else if ( length >= this.MIN_URL_LENGTH ){
			this.sparkshot.WS.request_check_url( this.form.fields.url.textContent );
			this.form.fields.url.classList.remove( 'red' );
		} else {
			this.setValidCheckBox( this.form.valid.url, false );
			this.form.isUrlValid = false;
		}

		if ( this.form.fields.url.innerHTML === '<br>' )
			this.form.fields.url.innerHTML = '';

		return this.isUrlValid;
	}

	/**
	 * Sets validity of url field and state
     * @param {boolean} available - is URL available
	 */
	setURLState( available ) {
		this.form.isUrlValid = available;
		this.setValidCheckBox( this.form.valid.url, available );
		if ( !available ) {
			this.sparkshot.UI.showToast( `ERROR: URL is not valid or taken` );
			this.form.fields.url.classList.add( 'red' );
		}
	}

	populateArtEvaluation() {
		const art = this.form.files.art;
		const pixels = art.width * art.height;
		const pixel_size_string = Utils.NumToCommas(pixels);
		const image_size_data = this.getImageCategory(pixels);
		const transactions_string = Utils.NumToCommas(Math.ceil(pixels / this.AVERAGE_PIXEL_PER_TX));
		const average_tx_per_fan = Math.ceil(pixels / this.AVERAGE_PIXEL_PER_TX / image_size_data.fan_size);

		// const fan_size = this.getImageCategory(pixels).fan_size;
		// this.form.fields.evaluate_art_pixels.innerHTML =
		// 	`Looking at your image, we see it is <span class="spark-blue-text bold">${pixel_size_string}</span> pixels in size (${art.width} x ${art.height}).
		// 	 Fans reveal on average around ${this.AVERAGE_PIXEL_PER_TX} pixels per transaction. We predict this art will take a fanbase of <span class="spark-blue-text bold">${image_size_data.fan_size}</span> fans approximately <span class="spark-blue-text bold">${average_tx_per_fan}</span> transactions each to complete, or around <span class="spark-blue-text bold">${transactions_string}</span> transactions in total.`;
		this.form.fields.evaluate_art_pixels.innerHTML = `<span class="bold">${this.form.files.art.name}</span> is <span class="spark-blue-text bold">${pixel_size_string}</span> pixels in size (${art.width} x ${art.height}), which is classified as <span class="bold">${image_size_data.size}</span> size on Sparkshot.`
		this.form.fields.evaluate_art_size.textContent = `${image_size_data.size} SIZE ART`;
		this.form.fields.evaluate_art_advice.innerHTML = `${image_size_data.message}`;
		this.form.buttons.accept_evaluation.innerHTML = image_size_data.button_text;
	}

	/**
	 * Checks validity of tags field
	 */
	validateTagsField() {
		const percent = this.form.fields.tags.chipsData.length / this.MAX_TAGS * 100;
		this.form.progress.tags.setProgress( percent );
	}

	/**
	 * Checks validity of title field
     * @return {boolean} returns state of field
	 */
	validateTitleField() {
		const bytes = Utils.getStringByteSize( this.form.fields.title.textContent );
		const percent = bytes / this.MAX_TITLE_LENGTH * 100;

		this.form.progress.title.setProgress( percent - 1 );
		this.generateURL( this.form.fields.title.textContent );

		if ( percent > 100 || this.form.progress.title < this.MIN_TITLE_LENGTH ) {
			this.form.isTitleValid = false;
			Utils.flashElement( this.form.fields.title );
			this.form.fields.title.classList.add( 'red' );
		} else {
			this.form.isTitleValid = true;
			this.form.fields.title.classList.remove( 'red' );
		}


		if ( this.form.fields.title.innerHTML === '<br>' )
			this.form.fields.title.innerHTML = '';

		return this.form.isTitleValid;
	}

	/**
	 * Checks validity of description field
     * @return {boolean} returns state of field
	 */
	validateDescriptionField() {
		const bytes = Utils.getStringByteSize( this.form.fields.description.textContent );
		const percent = bytes / this.MAX_DESCRIPTION_LENGTH * 100;

		this.form.progress.description.setProgress( percent - 1 );

		if ( percent > 100 ) {
			this.form.isDescriptionValid = false;
			Utils.flashElement( this.form.fields.description );
			this.form.fields.description.classList.add( 'red' );
		} else {
			this.form.isDescriptionValid = true;
			this.form.fields.description.classList.remove( 'red' );
		}

		if ( this.form.fields.description.innerHTML === '<br>' )
			this.form.fields.description.innerHTML = '';

		return this.form.isDescriptionValid;
	}

	/**
	 * Checks validity of email field
  	 * @param {boolean} test_content - Toggle email structure test
     * @return {boolean} returns state of field
	 */
	validateEmailField( test_content = true ) {
		if ( this.form.fields.email.textContent.length === 0 ) return;
		if ( this.form.fields.email.textContent.length > this.MAX_EMAIL_LENGTH ) {
			Utils.flashElement( this.form.fields.email );
			this.form.fields.email.classList.add( 'red' );
		} else {
			this.form.fields.email.classList.remove( 'red' );
		}

		// End if not testing content validity
		if ( !test_content ) return;
		const valid = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

		if ( valid.test( this.form.fields.email.textContent ) ) {
			this.form.fields.email.classList.remove( 'red' );
		} else {
			this.form.fields.email.classList.add( 'red' );
		}

		if ( this.form.fields.email.innerHTML === '<br>' )
			this.form.fields.email.innerHTML = '';
	}

	validateTwitterField( test_content = true ) {
		if ( this.form.fields.twitter.textContent.length > this.MAX_TWITTER_LENGTH ) {
			Utils.flashElement( this.form.fields.twitter );
			this.form.fields.twitter.classList.add( 'red' );
		} else {
			this.form.fields.twitter.classList.remove( 'red' );
		}

		if ( this.form.fields.twitter.innerHTML === '<br>' )
		this.form.fields.twitter.innerHTML = '';

		// End if not testing content validity
		if ( !test_content ) return;
		const valid = /^@?(\w){1,15}$/gm;

		if ( valid.test( this.form.fields.twitter.textContent ) ) {
			this.form.fields.twitter.classList.remove( 'red' );
		} else {
			this.form.fields.twitter.classList.add( 'red' );
		}

	}

	/**
	 * Checks validity of bitcoin address field
	 */
	validateBitcoinAddressField() {
		// Gets rid of the dummy br added by Materialise I guess
		if ( this.form.fields.btc_address.innerHTML === '<br>' ) {
			this.form.fields.btc_address.innerHTML = '';
			this.setValidCheckBox( this.form.valid.btc_address, false );
			return false;
		}

		// Don't bother checking if too short
		if ( this.form.fields.btc_address.textContent.length < 23 ) {
			this.setValidCheckBox( this.form.valid.btc_address, false );
			return false;
		}

		const address = this.form.fields.btc_address.textContent;

		const verified = this.Payout.address_is_valid( address );
		if ( verified ) {
			this.setValidCheckBox( this.form.valid.btc_address, true );
			return true;
		} else {
			this.setValidCheckBox( this.form.valid.btc_address, false );
			return false;
		}
	}

	/**
	 * Checks validity of bitcoin signature field
	 */
	validateSignatureField() {
		// Gets rid of the dummy br added by Materialise I guess
		if ( this.form.fields.signed_msg.innerHTML === '<br>' ) {
			this.form.fields.signed_msg.innerHTML = '';
			this.setValidCheckBox( this.form.valid.signed_msg, false );
			return false;
		}

		// Don't bother checking if address too short
		if ( this.form.fields.btc_address.textContent.length < 23 ) {
			this.setValidCheckBox( this.form.valid.signed_msg, false );
			return false;
		}

		// Don't bother checking if sig too short
		if ( this.form.fields.signed_msg.textContent.length < 15 ) {
			this.setValidCheckBox( this.form.valid.signed_msg, false );
			return false;
		}

		const msg = this.generateNonBreakingSpaceFixMessage();
		const address = this.form.fields.btc_address.textContent;
		const signed = this.form.fields.signed_msg.textContent;

		const verified = this.Payout.verify_signed_message( msg, address, signed );
		if ( verified ) {
			this.setValidCheckBox( this.form.valid.signed_msg, true );
			return true;
		} else {
			this.setValidCheckBox( this.form.valid.signed_msg, false );
			return false;
		}
	}

	/**
	 * Checks validity of form fields
     * @return {boolean} returns state of form
	 */
	validateForm() {
		// Check each field is valid
		const form = this.form;

		// Set art image
		if ( form.files.art.base64 === null ) {
			return false;
		}

		// Set Price
		if ( form.files.price.base64 !== null ) {
			this.setValidCheckBox( this.form.valid.price_file, true );
		} else {
			this.setValidCheckBox( this.form.valid.price_file, false );
		}

		// Check Files Size Comparison
		if (form.files.price.sha256 !== null) {
			if (form.files.price.width !== form.files.art.width || form.files.price.height !== form.files.art.height) {
				return false;
			}
		}

		// TITLE
		if ( form.fields.title.textContent.length > this.MIN_TITLE_LENGTH && form.isTitleValid )
			this.setValidCheckBox( this.form.valid.title, true );
		else
			this.setValidCheckBox( this.form.valid.title, false );

		// DESCRIPTION
		if ( form.fields.description.textContent.length > 0 && form.isDescriptionValid )
			this.setValidCheckBox( this.form.valid.description, true );
		else
			this.setValidCheckBox( this.form.valid.description, false );

		// TITLE
		if (form.fields.title.textContent.length === 0 ||
			form.fields.description.textContent.length === 0 ||
			form.files.art.sha256 === null ||
			form.fields.url.textContent.length === 0 ||
			!form.isUrlValid ||
			!form.isTitleValid ||
		 	!form.isDescriptionValid ) {

			// Disable ability to proceed
			this.setElementEnabled( form.buttons.details_accept, false );
			return false;
		}

		this.setValidCheckBox( this.form.valid.title, true );
		this.setElementEnabled( form.buttons.details_accept, true );


		if ( !this.validateBitcoinAddressField() ) {
			this.setElementEnabled( form.buttons.submit, false );
			return false;
		}

		if ( !this.validateSignatureField() ) {
			this.setElementEnabled( form.buttons.submit, false );
			return false;
		}

		this.setElementEnabled(form.buttons.submit, true);
		return true;
	}

	/**
	 * Sets the UI checkbox to be valid or not
     * @param {HTMLElement} el - Checkbox element
	 * @param {boolean}    	state - state to set box
	 */
	setValidCheckBox( el, state ) {
		if ( state ) {
			el.firstElementChild.classList.add( 'spark-blue-text' );
			el.firstElementChild.classList.remove( 'grey-text' );
		} else {
			el.firstElementChild.classList.add( 'grey-text' );
			el.firstElementChild.classList.remove( 'spark-blue-text' );
		}
	}

	// Enable or Disable an element
	setElementEnabled( element, state = false ) {
		if (state) {
			element.classList.remove('disabled');
		} else {
			element.classList.add('disabled');
		}
	}

	// Return image size string based on total pixel size
	getImageCategory( pixel ) {
		if ( pixel <= this.size_categories.very_small.max_size ) {
			return {
				size: "VERY SMALL",
				fan_size: this.size_categories.very_small.fan_size,
				message: `This art size is <span class="bold">perfect</span> for artists just getting started on Sparkshot and are looking to build complete art pieces in the Sparkshot Gallery in order to attract new fans.`,
				button_text: this.size_categories.very_small.accept_btn_text,
			};
		} else if ( pixel <= this.size_categories.small.max_size ) {
			return {
				size: "SMALL",
				fan_size: this.size_categories.small.fan_size,
				message: `This art size is great for artists that are starting to gain traction having around <span class="spark-blue-text bold">${this.size_categories.small.fan_size} active fans</span> supporting their work, and perhaps already have one or more complete art pieces in the Sparkshot Gallery.`,
				button_text: this.size_categories.small.accept_btn_text
			};
		} else if ( pixel <= this.size_categories.medium.max_size ) {
			return {
				size: "MEDIUM",
				fan_size: this.size_categories.medium.fan_size,
				message: `This art size is great for artists that have around <span class="spark-blue-text bold">${this.size_categories.medium.fan_size} active fans</span> and multiple complete art pieces the Sparkshot gallery.`,
				button_text: this.size_categories.medium.accept_btn_text
			};
		} else if ( pixel <= this.size_categories.large.max_size ) {
			return {
				size: "LARGE", fan_size: this.size_categories.large.fan_size,
				message: `This art size is good for artists who have around <span class="spark-blue-text bold">${this.size_categories.large.fan_size} active fans</span> supporting their work, and many pieces of complete art in the Sparkshot gallery.`,
				button_text: this.size_categories.large.accept_btn_text
			};
		} else if ( pixel <= this.size_categories.giant.max_size ) {
			return {
				size: "GIANT",
				fan_size: this.size_categories.giant.fan_size,
				message: `This art size is <span class="bold">ONLY RECOMMENDED</span> for artists who around <span class="bold">${this.size_categories.giant.fan_size} active fans</span> and already have many LARGE size art pieces complete in the Sparkshot gallery.`,
				button_text: this.size_categories.giant.accept_btn_text
			};
		} else if ( pixel <= this.size_categories.epic.max_size ) {
			return {
					size: "EPIC",
					fan_size: this.size_categories.epic.fan_size,
					// message: `<span class="bold">ALERT:</span> This size is <span class="bold">ONLY RECOMMENDED</span> to the most successful artists on Sparkshot with around <span class="spark-blue-text bold">${this.size_categories.epic.fan_size} active fans</span>, and many VERY LARGE complete art pieces in the Sparkshot gallery.`,
					message: `<span class="bold">ALERT:</span> This size is <span class="bold">ONLY RECOMMENDED</span> to the most successful artists on Sparkshot with around <span class="spark-blue-text bold">${this.size_categories.epic.fan_size} active fans</span>, and many VERY LARGE complete art pieces in the Sparkshot gallery.`,
					button_text: this.size_categories.epic.accept_btn_text
				 };
		} else {
			return { size: "Unknown", fanbase: "Unknown", message: "Unknown.", button_test: "Unknown" };
		}
	}

	// Generate a message string for signing with private key
	generateMessage() {
		this.form.fields.signed_msg.innerHTML = '';
		this.setValidCheckBox( this.form.valid.signed_msg, false );

		const price = (this.form.files.price.sha256 === null) ? null : this.form.files.price.sha256;

		const title = JSON.stringify( this.form.fields.title.textContent );
		const description = JSON.stringify( this.form.fields.description.textContent );

		return this.form.fields.message.textContent = `{"title": ${title}, "description": ${description}, "art_sha256": "${this.form.files.art.sha256}", "price_sha256": "${price}"}`;
	}

	generateNonBreakingSpaceFixMessage() {
		const price = (this.form.files.price.sha256 === null) ? null : this.form.files.price.sha256;
		// remove non-breaking spaces
		const title  = JSON.stringify(this.form.fields.title.textContent.replace(/[\u202F\u00A0\u2000\u2001\u2003]/g, ' '));
		const description  = JSON.stringify(this.form.fields.description.textContent.replace(/[\u202F\u00A0\u2000\u2001\u2003]/g, ' '));

		return `{"title": ${title}, "description": ${description}, "art_sha256": "${this.form.files.art.sha256}", "price_sha256": "${price}"}`;
	}

	// Generate submit message with NonBreakingSpaceFix
	generateSubmitMessage( address, signed ) {
		const price = (this.form.files.price.sha256 === null) ? null : this.form.files.price.sha256;

		// remove non-breaking spaces
		const title  = JSON.stringify(this.form.fields.title.textContent.replace(/[\u202F\u00A0\u2000\u2001\u2003]/g, ' '));
		const description  = JSON.stringify(this.form.fields.description.textContent.replace(/[\u202F\u00A0\u2000\u2001\u2003]/g, ' '));

		address = (address) ? address : this.form.fields.btc_address.innerText;
		signed = (signed) ? signed : this.form.fields.signed_msg.innerText;

        const message = this.generateNonBreakingSpaceFixMessage();

        if (!this.Payout.verify_signed_message(message, address, signed)) console.error('SIGNATURE DOES NOT VERIFY');

		// sanitize text fields
		const signedMSG = `-----BEGIN BITCOIN SIGNED MESSAGE-----\n{"title": ${title}, "description": ${description}, "art_sha256": "${this.form.files.art.sha256}", "price_sha256": "${price}"}\n-----BEGIN SIGNATURE-----\n${address}\n${signed}\n-----END BITCOIN SIGNED MESSAGE-----`;

		return signedMSG;
	}

	// Submit signed art data
	submitArt( address, signed ) {

		// WS data to server
		const form = this.form.fields;

		// Generate Tag Array
		const tags = [];
		this.form.fields.tags.chipsData.forEach(tag => {
			tags.push(tag.tag.toLowerCase());
		});

		const email = Utils.isValidEmail( form.email.textContent ) ? form.email.textContent : 'no@email.com';
		const twitter = ( form.twitter.textContent != '' ) ? form.twitter.textContent : 'NOTWITTER';

		const elements = this.form.form.elements;

		const submit_msg = (address) ? this.generateSubmitMessage(address, signed) : this.generateSubmitMessage();

		// remove non-breaking spaces
		const title_fix  = this.form.fields.title.textContent.replace(/[\u202F\u00A0\u2000\u2001\u2003]/g, ' ');
		const description_fix  = this.form.fields.description.textContent.replace(/[\u202F\u00A0\u2000\u2001\u2003]/g, ' ');

		const data = {
			request: "UPLOAD_ART",
			art_id: this.form.fields.url.textContent,
			title: title_fix,
			description: description_fix,
			email: email, // can optionally be 'null'
			twitter: twitter, // can optionally be 'null'
			tags: tags,
			art: {
				filename: this.form.files.art.name,
				base64: this.form.files.art.base64,
			},
			price: { // can optionally be 'null'
				filename: ( this.form.files.price.name ) ? this.form.files.price.name : null,
				base64: ( this.form.files.price.base64 ) ? this.form.files.price.base64 : null,
			},
			signed_msg: submit_msg,
			btc_address: (address) ? address : this.form.fields.btc_address.value,
		}

		// this.form.submit.progress.classList.remove('hide');
		this.sparkshot.WS.request_upload_art( data );
	}

	uploadComplete(payload) {
		this.form.fields.art_uploading.classList.add( 'hide' );
		this.form.fields.art_complete.classList.remove( 'hide' );
		this.form.submit.progress.classList.add( 'hide' );
		this.form.submit.complete.classList.remove( 'hide' );
		this.form.submit.complete_body.classList.remove( 'hide' );
		this.form.fields.url_msg.href = `${window.location.origin}/${this.form.fields.url.textContent}`;
		this.form.fields.url_msg.textContent = `${window.location.origin}/${this.form.fields.url.textContent}`;
	}

	uploadFailed(payload) {
		this.form.submit.progress.classList.add( 'hide' );
		this.form.submit.failed.classList.remove( 'hide' );
		this.form.submit.failed_body.classList.remove( 'hide' );

		if ( payload.hint ) {
			this.form.submit.failed_msg.textContent = payload.hint;
		} else if ( typeof payload !== 'undefined' ) {
			this.form.submit.failed_msg.textContent = payload;
		} else {
			this.form.submit.failed_msg.textContent = "Unknown issue";
		}

		this.animate_upload.error();
	}

	testSubmission() {

		this.form.fields.title.value = "Mona Lisa";
		this.form.fields.description.value = "A Painting of Mona Lisa";
		this.form.fields.url.value = "mona-lisa";
		this.form.fields.email.value = "leonardo@davinci.com";
		this.form.fields.btc_address.value = "bc1quwdkmpcqaqlvjdccfr5hfmjj0hnuzdngvvymup";
		this.form.fields.signed_msg.value = "H0ppBOhETfxuHxr2EMTc7j97MTDDvq1JouVD83iNOI5qRWfLDBz40ego2esMNgZzVFnlXAHmta56sd+WdVpL1DI=";

	}

    closeMenu() {
		this.resetUploadForm();
		this.setUploadEnabled( true );

		// Init hints
		this.sparkshot.Tutorial.initHints();
    }

	setButtonEnableTimer( button, time, keepTextDuringCountdown = false ) {

		// @TODO: This needs to be spawned as a new object or handle the text
		// differently because it'll capture the number as text if you loop around

		// Clear any exising timer
		clearInterval(this.enableButtonTimer);

		// Disable button
		button.classList.add('disabled');
		// Get button text
		if ( !button.og_text ) button.og_text = button.textContent;
		const text = button.og_text;
		let count = time;

		// Set Default Timer Text
		button.textContent = keepTextDuringCountdown ? `(${count}) ${text}` : `(${count})`;

		// Add countdown timer
		this.enableButtonTimer = setInterval( () => {

			count--;
			button.textContent = keepTextDuringCountdown ? `(${count}) ${text}` : `(${count})`;

			if (count <= 0) {
				// Fix Text
				button.textContent = text;

				button.classList.remove('disabled');
				clearInterval(this.enableButtonTimer);
			}
		}, 1000 );

	}
}

exports.Upload = Upload
