/*
 * Copyright PrimeVR 2020
 * @author roskelld https://github.com/roskelld
 */
const SideNav = require("./sidenav.js").SideNav;
const Payout = require("./payout.js").Payout;
const Utils = require("./utils.js").Utils;
const AccountingHistory = require("./accounting-history.js").AccountingHistory;

const { Parser } = require('json2csv');

const DEFAULT_BLOCK_TARGET = 10;
const MIN_BLOCK_TARGET = 1;
const MAX_BLOCK_TARGET = 1000;
const MIN_CHAIN_WITHDRAW = 2000;
const MIN_LN_WITHDRAW = 1000;
const MAX_NAME_LENGTH = 25;
const MAX_SEED_WORD_LENGTH = 20;
const MIN_ADDRESS_LENGTH = 25;
const MAX_ADDRESS_LENGTH = 35;
const MAX_SIGNATURE_LENGTH = 100;
const ERROR_MAX_CHARACTERS = 'Max characters reached';
const ERROR_BAD_CHARACTER = 'Bad Character';
const ERROR_NO_NAME = 'We need a name';

class Profile {
    constructor(sparkshot) {
        this.sparkshot = sparkshot;

        // Use SideNav to control panel tabs
        this.sideNav = new SideNav();

        this.Payout = new Payout();

        // Main Panel
        this.panel = document.querySelector('#ui-profile-modal');
        this.instance = this.sparkshot.UI.modalInstances.find( ( obj ) => { return obj.id === 'ui-profile-modal'; } ),

        // Panel Body Space
        this.body = document.querySelector('#ui-profile-body');

        // Panel Control Buttons
        this.button = {
            close_panel: document.querySelector('#ui-profile-close-btn')
        };

        // WALLET
        this.wallet = {
            button: {
                gen_key:        document.querySelector('#wallet-artist-gen-private-btn'),
                enter_seed:     document.querySelector('#wallet-artist-enter-seed-btn'),
                cancel_seed:    document.querySelector('#wallet-artist-cancel-seed-btn'),
                save_seed:      document.querySelector('#wallet-artist-save-seed-btn'),
                delete_seed:    document.querySelector('#wallet-artist-delete-private-btn'),
                confirm_delete: document.querySelector('#wallet-artist-confirm-delete-private-btn'),
                cancel_delete:  document.querySelector('#wallet-artist-cancel-delete-private-btn'),
                show_priv_key:  document.querySelector('#wallet-artist-show-private-btn'),
                hide_priv_key:  document.querySelector('#wallet-artist-hide-private-btn'),
            },

            section: {
                empty:      document.querySelector('#ui-wallet-artist-empty'),
                seed:       document.querySelector('#ui-wallet-artist-seed'),
                main:       document.querySelector('#ui-wallet-artist-main'),
                details:    document.querySelector('#ui-wallet-artist-main-details'),
                delete:     document.querySelector('#ui-wallet-artist-main-delete'),

            },

            field: {
                address:      document.querySelector('#wallet-artist-address'),
                uri:          document.querySelector('#wallet-artist-address-uri'),
                private_key:  document.querySelector('#wallet-artist-private-key'),
                seed:         document.querySelector('#wallet-artist-seed'),
                delete_seed:  document.querySelector('#wallet-artist-delete-seed'),
                reason:       document.querySelector('#wallet-artist-seed-reason'),
            },
        };

        this.payments = {
            button: {
                show_balance: document.querySelector('#balance-show-btn'),
                download_history: document.querySelector('#balance-history-btn'),
                copy_lookup_msg:  document.querySelector('#balance-lookup-copy-message-btn'),
                request_bal:  document.querySelector('#balance-manual-request-btn'),
                request_back: document.querySelector('#balance-manual-back-btn'),
                use_wallet_address: document.querySelector('#balance-wallet-artist-address-btn'),
                refresh_balance: document.querySelector('#balance-refresh-btn'),
                ln: {
                    show_withdraw: document.querySelector('#balance-withdraw-ln-btn'),
                    copy_withdraw_msg: document.querySelector('#balance-ln-withdraw-copy-msg-btn'),
                    withdraw_back: document.querySelector('#balance-withdraw-ln-back-btn'),
                    submit:     document.querySelector('#balance-withdraw-ln-submit-btn'),
                },
                chain: {
                    show_withdraw:     document.querySelector('#balance-withdraw-onchain-btn'),
                    copy_withdraw_msg: document.querySelector('#balance-withdraw-chain-copy-msg-btn'),
                    max_withdraw:      document.querySelector('#balance-withdraw-chain-max-btn'),
                    data_back:         document.querySelector('#balance-withdraw-onchain-data-back-btn'),
                    data_submit:       document.querySelector('#balance-withdraw-onchain-data-submit-btn'),
                    withdraw_back:     document.querySelector('#balance-withdraw-onchain-back-btn'),
                    withdraw_submit:   document.querySelector('#balance-withdraw-onchain-submit-btn'),

                },
            },
            section: {
                request:        document.querySelector('#ui-balance-request'),
                sign:           document.querySelector('#ui-balance-sign'),
                data:           document.querySelector('#ui-balance-data'),
                history_req:    document.querySelector('#ui-balance-history-request'),
                history:        document.querySelector('#ui-balance-history'),
                ln: {
                    withdraw:   document.querySelector('#ui-balance-withdraw-ln'),
                    bolt11:     document.querySelector('#ui-balance-withdraw-ln-bolt11'),
                    withdraw_manual :document.querySelector('#ui-balance-withdraw-ln-manual'),
                },
                chain: {
                    withdraw: document.querySelector('#ui-balance-withdraw-chain'),
                    details:  document.querySelector('#ui-balance-withdraw-chain-details'),
                    sign:     document.querySelector('#ui-balance-withdraw-chain-sign-manual'),
                }
            },
            field: {
                address:            document.querySelector('#balance-address-field'),
                net:                document.querySelector('#balance-earned-data'),
                pending_paid:       document.querySelector('#balance-pending-paid-data'),
                withdrawable:       document.querySelector('#balance-withdrawable-data'),
                art_num:            document.querySelector('#balance-art-data'),
                gross:              document.querySelector('#balance-gross-data'),
                fee:                document.querySelector('#balance-fee-data'),
                net_earned:         document.querySelector('#balance-net-data'),
                date:               document.querySelector('#balance-date-data'),
                address_data:       document.querySelector('#balance-address-data'),
                pending:            document.querySelector('#balance-pending-data'),
                paid:               document.querySelector('#balance-paid-data'),
                failed:             document.querySelector('#balance-failed-data'),
                history:            document.querySelector('#balance-history-data'),
                gen_lookup_msg:     document.querySelector('#balance-generated-lookup-field'),
                signed_lookup:      document.querySelector('#balance-signed-lookup-field'),
                signed_addr:        document.querySelector('#balance-signed-address-field'),
                ln: {
                    max_withdraw:   document.querySelector('#balance-withdrawable-ln-field'),
                    bolt11:         document.querySelector('#withdraw-bolt11-field'),
                    withdraw_msg:   document.querySelector('#balance-ln-withdraw-message-field'),
                    signed_msg:      document.querySelector('#withdraw-signed-message-field'),
                },
                chain: {
                    min_withdraw:    document.querySelector('#balance-min-withdrawable-chain-field'),
                    max_withdraw:    document.querySelector('#balance-withdrawable-chain-field'),
                    min_blocks:      document.querySelector('#balance-withdrawable-chain-min-blocks-field'),
                    max_blocks:      document.querySelector('#balance-withdrawable-chain-max-blocks-field'),
                    withdraw_amount: document.querySelector('#balance-withdraw-chain-amount-field'),
                    target_block:    document.querySelector('#balance-withdraw-chain-blocks-field'),
                    dest_address:    document.querySelector('#balance-withdraw-chain-address-field'),
                    withdraw_msg:    document.querySelector('#balance-chain-withdraw-message-field'),
                    signed_msg:      document.querySelector('#balance-withdraw-chain-signed-message-field'),
                }
            }
        };

        this.user = {
            section: {
                main:       document.querySelector('#ui-profile-user-main'),
            },
            field: {
                username:   document.querySelector('#ui-profile-username-field'),
            },
            button: {
                nsfw:       document.querySelector('#ui-profile-nsfw-btn'),
                art_ui:     document.querySelector('#ui-profile-art-ui-btn'),
                save:       document.querySelector('#ui-profile-save-btn'),
                cancel:     document.querySelector('#ui-profile-cancel-btn')
            }
        };

        // Showed Artist Address Warning Message
        this.warned_user_about_address = false;
    }

    init() {
        // PROFILE PANEL BUTTON
        this.button.close_panel.addEventListener( "click", e => {
            e.preventDefault();
            this.instance.close();
        }, false );

        this.instance.options.onOpenStart = () => {
            this.sparkshot.UI.blocker( true );
            this.open();
        };

        // If closed by any call
        this.instance.options.onCloseStart = () => {
            this.sparkshot.UI.blocker( false );
            this.close();
        };

        // Set Body panel height
        this.body.style.height = "70vh";

        // Set Default Panel
        this.sideNav.selectTab("artist");

        // #####################################################################
        // TAB EVENTS
        // #####################################################################

        Array.from(this.sideNav.tabs).forEach( tab => {
            switch (tab.dataset.tab) {
                case 'user':
                    tab.addEventListener("open", () => {
                        this.open_user_main_section();
                    }, false);

                    tab.addEventListener("close", () => {
                        this.close_all_sections();
                    }, false);
                    break;
                case 'artist':
                    tab.addEventListener("open", () => {
                        this.open_wallet_main_section();
                    }, false);

                    tab.addEventListener("close", () => {
                        this.close_all_sections();
                    }, false);
                    break;
                case 'payments':
                    tab.addEventListener("open", () => {
                        this.open_payments_main_section();
                    }, false);

                    tab.addEventListener("close", () => {
                        this.close_all_sections();
                    }, false);
                    break;
                default:
            }
        });


        // #####################################################################
        // USER EVENT LISTENERS
        // #####################################################################

        this.user.button.cancel.addEventListener( "click", e => {
            e.preventDefault();
            // Reset state
            this.load_user_data();
        });

        this.user.button.save.addEventListener( "click", e => {
            e.preventDefault();
            if ( this.user.button.save.classList.contains( 'disabled' ) ) {
                return;
            }
            this.save_user_data();
        });

        // #####################################################################
        // USER NAME FIELD
        // #####################################################################

        this.user.field.username.addEventListener( "focus", () => {
            this.sparkshot.Tutorial.removeHint( "set_chat_name" );     // Remove Name Hint
        }, false);

        this.user.field.username.addEventListener( "keydown", e => {
            // Prevent new lines
            if ( this.user.field.username.textContent.length >= MAX_NAME_LENGTH &&
                 !Utils.isNavigationKey(e) ) {
                     e.preventDefault();
                     return;
                 }
            if ( Utils.isNewLineChar( e.key ) ) { e.preventDefault(); }
            if ( !Utils.validateCharacter( e.key ) ) { e.preventDefault(); }
        }, false);

        this.user.field.username.addEventListener( "keyup", e => {
            e.preventDefault();
            this.validate_profile_save();
        }, false );

        this.user.field.username.addEventListener( "paste", e => {
            e.preventDefault();
            // get text representation of clipboard
            const text = e.clipboardData.getData("text/plain");

            // insert text manually
            document.execCommand("insertHTML", false, text);

            Utils.enforceMessageLength( this.user.field.username, MAX_NAME_LENGTH );
            Utils.setCaretPosition( this.user.field.username, this.user.field.username.textContent.length );

            this.validate_profile_save();
            this.sparkshot.Tutorial.removeHint( "set_chat_name" );     // Remove Name Hint
        }, false );

        // #####################################################################
        // USER NSFW FIELD
        // #####################################################################

        this.user.button.nsfw.addEventListener( "change", () => {
            this.validate_profile_save();
        }, false);

        this.user.button.art_ui.addEventListener( "change", () => {
            this.validate_profile_save();
        }, false);

        // #####################################################################
        // WALLET EVENT LISTENERS
        // #####################################################################

        this.wallet.button.gen_key.addEventListener( "click", e => {
            e.preventDefault();
            this.request_gen_key();
        }, false);

        this.wallet.button.enter_seed.addEventListener( "click", e => {
            e.preventDefault();
            this.open_seed_section();
        }, false);

        this.wallet.button.cancel_seed.addEventListener( "click", e => {
            e.preventDefault();
            this.close_seed_section();
        }, false);

        this.wallet.button.save_seed.addEventListener( "click", e => {
            e.preventDefault();
            this.request_save_seed();
        }, false);

        this.wallet.button.delete_seed.addEventListener( "click", e => {
            e.preventDefault();
            this.request_delete_seed();
        }, false);

        this.wallet.button.confirm_delete.addEventListener( "click", e => {
            e.preventDefault();
            this.confirm_delete_seed();
        }, false);

        this.wallet.button.cancel_delete.addEventListener( "click", e => {
            e.preventDefault();
            this.cancel_delete_seed();
        }, false);

        this.wallet.button.show_priv_key.addEventListener( "click", e => {
            e.preventDefault();
            this.open_private_key_section();
        }, false);

        this.wallet.button.hide_priv_key.addEventListener( "click", e => {
            e.preventDefault();
            this.close_private_key_section();
        }, false);

        // #####################################################################
        // BALANCE BUTTONS
        // #####################################################################

        this.payments.button.refresh_balance.addEventListener( "click", e => {
            e.preventDefault();
            this.request_artist_balance(  this.data.address );
        }, false);

        this.payments.button.use_wallet_address.addEventListener( "click", e => {
            e.preventDefault();
            if ( this.payments.button.use_wallet_address.classList.contains('disabled') ) {
                return;
            } else {
                if (this.sparkshot.Wallet.has_mnemonic()) {
                    // Set Address
                    const a = this.sparkshot.Wallet.get_address_wif().address;
                    if (!a) { console.error('Wallet Error: No Seed Found'); }
                    this.payments.field.address.textContent = a;

                    // Check address status and enable button
                    const address = this.payments.field.address.textContent;
                    const verified = this.Payout.address_is_valid( address );
                    if ( verified ) {
                        this.payments.button.show_balance.classList.remove('disabled');
                    } else {
                        this.payments.button.show_balance.classList.add('disabled');
                    }
                } else {
                    this.sparkshot.UI.showMessage('Alert', 'No local wallet found. Go to Profile > Wallet to set one up.');
                }
            }
        }, false);

        this.payments.button.show_balance.addEventListener( "click", e => {
            e.preventDefault();
            if ( this.payments.button.show_balance.classList.contains('disabled') ) {
                return;
            } else {
                this.payments.button.show_balance.classList.add('disabled');
                this.payments.button.show_balance.innerHTML = `<i class="fas fa-sync white-text spinner"></i>`;
                this.request_artist_balance();
            }

        }, false);

        this.payments.button.download_history.addEventListener( "click", e => {
            e.preventDefault();
            if (!this.data) { return; }
            //this.download_history();

            let ah = new AccountingHistory(this.data.events,
                                           this.payments.field.address_data.text);
            let th = ah.generate_text_history();
            Utils.export_to_file( th, `${this.payments.field.address_data.text} - ${new Date().toDateString()}.txt`, "text/plain" );

            //let jh = ah.generate_json_history();
            //Utils.export_to_file( jh, `${this.payments.field.address_data.text} - ${new Date().toDateString()}.json`, "application/json" );

        }, false);

        this.payments.button.copy_lookup_msg.addEventListener( "click", e => {
            e.preventDefault();
            this.payments.field.gen_lookup_msg.parentElement.addEventListener("transitionend", () => {
                this.payments.field.gen_lookup_msg.parentElement.classList.remove('copied');
                this.payments.field.gen_lookup_msg.parentElement.removeEventListener("transitionend", () => {});
            });

            Utils.copyText( this.payments.field.gen_lookup_msg.textContent );
            this.payments.field.gen_lookup_msg.parentElement.classList.add( 'copied' );
        });

        this.payments.button.request_bal.addEventListener( "click", e => {
            e.preventDefault();
            this.request_artist_manual_balance();
        }, false);

        this.payments.button.request_back.addEventListener( "click", e => {
            e.preventDefault();
            this.open_payments_main_section();
        }, false);

        this.payments.button.ln.show_withdraw.addEventListener( "click", e => {
            e.preventDefault();
            this.open_payments_ln_withdraw_section();
        }, false);

        this.payments.button.ln.withdraw_back.addEventListener( "click", e => {
            e.preventDefault();
            this.close_balance_withdraw_section();

            // Scroll back to the top
            this.scroll.scrollTo( 0, 0 );

        }, false);

        // #####################################################################
        // BALANCE ARTIST ADDRESS FIELD
        // #####################################################################

        this.payments.field.address.addEventListener( "keydown", e => {
            // Prevent new lines
			if ( this.payments.field.address.textContent.length >= MAX_ADDRESS_LENGTH &&
				 !Utils.isNavigationKey(e) ) {
					 e.preventDefault();
					 return;
				 }
			if ( Utils.isNewLineChar( e.key ) ) { e.preventDefault(); }
			if ( !Utils.validateCharacter( e.key ) ) { e.preventDefault(); }
        }, false);

        this.payments.field.address.addEventListener( "keyup", e => {
            e.preventDefault();

            // If the address is clearly not inputted then don't do a full check
            if ( this.payments.field.address.textContent.length < MIN_ADDRESS_LENGTH ) {
                this.payments.button.show_balance.classList.add('disabled');
                return;
            }

            this.validate_artist_address_field();

        }, false );

        this.payments.field.address.addEventListener( "paste", e => {
            e.preventDefault();

            // get text representation of clipboard
            const text = e.clipboardData.getData("text/plain");

            // insert text manually
            document.execCommand("insertHTML", false, text);

            // If the bolt11 is clearly not inputted then don't do a full check
            if ( this.payments.field.address.textContent.length < MIN_ADDRESS_LENGTH ) {
                this.payments.button.show_balance.classList.add('disabled');
                return;
            }

            this.validate_artist_address_field();
        }, false );

        this.payments.field.address.addEventListener( "blur", e => {
            e.preventDefault();

            // If the bolt11 is clearly not inputted then don't do a full check
            if ( this.payments.field.address.textContent.length < MIN_ADDRESS_LENGTH ) {
                this.payments.button.show_balance.classList.add('disabled');
                return;
            }

            const address = this.payments.field.address.textContent;

            const verified = this.Payout.address_is_valid( address );
            if ( verified ) {
                this.payments.button.show_balance.classList.remove('disabled');
            } else {
                this.payments.button.show_balance.classList.add('disabled');
            }
        }, false );

        // #####################################################################
        // BALANCE MANUAL SIGN MESSAGE
        // #####################################################################

        this.payments.field.signed_lookup.addEventListener( "keydown", e => {
            // Prevent new lines
			if ( this.payments.field.signed_lookup.textContent.length >= MAX_SIGNATURE_LENGTH &&
				 !Utils.isNavigationKey(e) ) {
					 e.preventDefault();
					 return;
				 }
			if ( Utils.isNewLineChar( e.key ) ) { e.preventDefault(); }
			if ( !Utils.validateCharacter( e.key ) ) { e.preventDefault(); }
        }, false);

        this.payments.field.signed_lookup.addEventListener( "keyup", e => {
            e.preventDefault();

            // If the bolt11 is clearly not inputted then don't do a full check
            if ( this.payments.field.signed_lookup.textContent.length < 20 ) {
                this.payments.button.request_bal.classList.add('disabled');
                return;
            }
            const msg = this.payments.field.gen_lookup_msg.textContent;
            const address = this.payments.field.signed_addr.textContent;
            const signed = this.payments.field.signed_lookup.textContent;

            const verified = this.Payout.verify_signed_message( msg, address, signed );
            if ( verified ) {
                this.payments.button.request_bal.classList.remove('disabled');
            }
        }, false );

        this.payments.field.signed_lookup.addEventListener( "paste", e => {
            e.preventDefault();

            // get text representation of clipboard
            const text = e.clipboardData.getData("text/plain");

            // insert text manually
            document.execCommand("insertHTML", false, text);

            // If the bolt11 is clearly not inputted then don't do a full check
            if ( this.payments.field.signed_lookup.textContent.length < 20 ) {
                this.payments.button.request_bal.classList.add('disabled');
                return;
            }

            const msg = this.payments.field.gen_lookup_msg.textContent;
            const address = this.payments.field.signed_addr.textContent;
            const signed = this.payments.field.signed_lookup.textContent;

            const verified = this.Payout.verify_signed_message( msg, address, signed );
            if ( verified ) {
                this.payments.button.request_bal.classList.remove('disabled');
            } else {
                this.sparkshot.UI.showMessage( "Error", "Bad signed message, please check the message was copied correctly and the address matches." );
            }
        }, false );

        this.payments.field.signed_lookup.addEventListener( "blur", e => {
            e.preventDefault();

            // If the bolt11 is clearly not inputted then don't do a full check
            if ( this.payments.field.signed_lookup.textContent.length < 20 ) {
                this.payments.button.request_bal.classList.add('disabled');
                return;
            }

            const msg = this.payments.field.gen_lookup_msg.textContent;
            const address = this.payments.field.signed_addr.textContent;
            const signed = this.payments.field.signed_lookup.textContent;

            const verified = this.Payout.verify_signed_message( msg, address, signed );
            if ( verified ) {
                this.payments.button.request_bal.classList.remove('disabled');
            } else {
                this.sparkshot.UI.showMessage( "Error", "Bad signed message, please check the message was copied correctly and the address matches." );
            }
        }, false );

        // #####################################################################
        // BOLT11
        // #####################################################################

        this.payments.field.ln.bolt11.addEventListener( "keydown", e => {
            // Prevent new lines
            if ( Utils.isNewLineChar( e.key ) ) { e.preventDefault(); }
        }, false);

        this.payments.field.ln.bolt11.addEventListener( "keyup", e => {
            e.preventDefault();

            // If the bolt11 is clearly not inputted then don't do a full check
            if ( this.payments.field.ln.bolt11.textContent.length < 50 ) {
                this.payments.button.ln.submit.classList.add('disabled');
                return;
            }

            // Check BOLT11 is valid
            const bolt11 = this.payments.field.ln.bolt11.textContent;
            const result = this.Payout.check_withdraw_bolt11( bolt11 );
            if ( result.valid ) {
                this.open_ln_message_section();
            }
        }, false );

        this.payments.field.ln.bolt11.addEventListener( "paste", e => {
            e.preventDefault();
            // get text representation of clipboard
            const text = e.clipboardData.getData("text/plain");

            // insert text manually
            document.execCommand("insertHTML", false, text);

            // If the bolt11 is clearly not inputted then don't do a full check
            if ( this.payments.field.ln.bolt11.textContent.length < 50 ) {
                this.payments.button.ln.submit.classList.add('disabled');
                return;
            }

            // Check BOLT11 is valid
            const bolt11 = this.payments.field.ln.bolt11.textContent;
            const result = this.Payout.check_withdraw_bolt11( bolt11 );

            if ( result.valid ) {
                this.open_ln_message_section();
            } else {
                this.sparkshot.UI.showMessage( "Bolt11 Error", result.reason );
                this.payments.section.ln.withdraw_manual.classList.add('hide');
                this.payments.button.ln.submit.classList.add('disabled');
            }
        }, false );

        this.payments.field.ln.bolt11.addEventListener( "blur", e => {
            e.preventDefault();

            // If the bolt11 is clearly not inputted then don't do a full check
            if ( this.payments.field.ln.bolt11.textContent.length < 50 ) {
                this.payments.button.ln.submit.classList.add('disabled');
                return;
            }

            // Check BOLT11 is valid
            const bolt11 = this.payments.field.ln.bolt11.textContent;
            const result = this.Payout.check_withdraw_bolt11( bolt11 );

            if ( result.valid ) {
                this.open_ln_message_section();
            } else {
                this.sparkshot.UI.showMessage( "Bolt11 Error", result.reason );
                this.payments.section.ln.withdraw_manual.classList.add('hide');
                this.payments.button.ln.submit.classList.add('disabled');
            }
        }, false );

        this.payments.field.ln.bolt11.addEventListener( "auxclick", () => {

            // If the bolt11 is clearly not inputted then don't do a full check
            if ( this.payments.field.ln.bolt11.textContent.length < 50 ) {
                this.payments.button.ln.submit.classList.add('disabled');
                return;
            }

            // Check BOLT11 is valid
            const bolt11 = this.payments.field.ln.bolt11.textContent;
            const result = this.Payout.check_withdraw_bolt11( bolt11 );

            if ( result.valid ) {
                this.open_ln_message_section();
            } else {
                this.sparkshot.UI.showMessage( "Bolt11 Error", result.reason );
                this.payments.section.ln.withdraw_manual.classList.add('hide');
                this.payments.button.ln.submit.classList.add('disabled');
            }
        }, false );

        // #####################################################################
        // BOLT11 SIGNED MESSAGE EVENTS
        // #####################################################################

        this.payments.field.ln.signed_msg.addEventListener( "keydown", e => {
            // Prevent new lines
			if ( this.payments.field.ln.signed_msg.textContent.length >= MAX_SIGNATURE_LENGTH &&
				 !Utils.isNavigationKey(e) ) {
					 e.preventDefault();
					 return;
				 }
			if ( Utils.isNewLineChar( e.key ) ) { e.preventDefault(); }
			if ( !Utils.validateCharacter( e.key ) ) { e.preventDefault(); }
        }, false);

        this.payments.field.ln.signed_msg.addEventListener( "keyup", e => {
            e.preventDefault();

        }, false );

        this.payments.field.ln.signed_msg.addEventListener( "paste", e => {
            e.preventDefault();
            // get text representation of clipboard
            const text = e.clipboardData.getData("text/plain");

            // insert text manually
            document.execCommand("insertHTML", false, text);

            // If the bolt11 is clearly not inputted then don't do a full check
            if ( this.payments.field.ln.signed_msg.textContent.length < 50 ) {
                this.payments.button.ln.submit.classList.add('disabled');
                return;
            }

            const msg = this.payments.field.ln.withdraw_msg.textContent;
            const address = this.payments.field.address_data.textContent;
            const signed = this.payments.field.ln.signed_msg.textContent;

            const verified = this.Payout.verify_signed_message( msg, address, signed );
            if ( verified ) {
                this.payments.button.ln.submit.classList.remove('disabled');
            } else {
                this.payments.button.ln.submit.classList.add('disabled');
                this.sparkshot.UI.showMessage( "Error", "Bad Signature" );
            }
        }, false );

        this.payments.field.ln.signed_msg.addEventListener( "blur", e => {
            e.preventDefault();

            if ( this.payments.field.ln.signed_msg.textContent.length < 50 ) {
                this.payments.button.ln.submit.classList.add('disabled');
                return;
            }

            const msg = this.payments.field.ln.withdraw_msg.textContent;
            const address = this.payments.field.address_data.textContent;
            const signed = this.payments.field.ln.signed_msg.textContent;

            const verified = this.Payout.verify_signed_message( msg, address, signed );
            if ( verified ) {
                this.payments.button.ln.submit.classList.remove('disabled');
            } else {
                this.sparkshot.UI.showMessage( "Error", "Bad Signature" );
                this.payments.button.ln.submit.classList.add('disabled');
            }
        }, false );

        // COPY LIGHTNING NETWORK WITHDRAW MESSAGE
        this.payments.button.ln.copy_withdraw_msg.addEventListener( "click", e => {
            e.preventDefault();
            this.payments.field.ln.withdraw_msg.parentElement.addEventListener("transitionend", () => {
                this.payments.field.ln.withdraw_msg.parentElement.classList.remove('copied');
                this.payments.field.ln.withdraw_msg.parentElement.removeEventListener("transitionend", () => {});
            });

            Utils.copyText( this.payments.field.ln.withdraw_msg.textContent );
            this.payments.field.ln.withdraw_msg.parentElement.classList.add( 'copied' );
        });

        this.payments.button.ln.submit.addEventListener( "click", e => {
            e.preventDefault();
            this.request_ln_withdraw();
        }, false);

        // #####################################################################
        // CHAIN WITHDRAW SHOW SECTION BUTTON
        // #####################################################################

        this.payments.button.chain.show_withdraw.addEventListener( "click", e => {
            e.preventDefault();
            this.open_payments_chain_withdraw_section();
        }, false);

        // #####################################################################
        // CHAIN WITHDRAW AMOUNT
        // #####################################################################

        this.payments.field.chain.withdraw_amount.addEventListener( "keydown", e => {
            if ( this.payments.field.chain.withdraw_amount.textContent.length >= 30 &&
                 !Utils.isNavigationKey(e) ) {
                     e.preventDefault();
                 }
            const result = Utils.limitInputToType( this.payments.field.chain.withdraw_amount, e, "digitsOnly" );
            if ( !result ) {
                e.preventDefault();
            }
            // Check data is valid for submit button enable
            this.validate_withdraw_data_button();
        }, false );

        this.payments.field.chain.withdraw_amount.addEventListener( "paste", e => {
            e.preventDefault();

            // get text representation of clipboard
            const text = e.clipboardData.getData("text/plain");

            // test text contains no letters
            if ( /^[0-9]+$/g.test( text ) ) {
                document.execCommand("insertHTML", false, text);
            }
            // Check data is valid for submit button enable
            this.validate_withdraw_data_button();
        }, false );

        this.payments.field.chain.withdraw_amount.addEventListener( "blur", e => {
            e.preventDefault();

            const amount = Number(this.payments.field.chain.withdraw_amount.textContent);
            const max = this.data.earned_net_sat - this.data.requested_good_sat;

            if (!isNaN(amount) && !isNaN(max)) {
                if (amount > max) {
                    this.payments.field.chain.withdraw_amount.textContent = Math.floor(max);
                }

                if (amount < MIN_CHAIN_WITHDRAW ) {
                    this.payments.field.chain.withdraw_amount.textContent = MIN_CHAIN_WITHDRAW;
                    this.sparkshot.UI.showToast(`Changed withdrawal amount to minimum (${MIN_CHAIN_WITHDRAW})`);
                }
            }

            // Check data is valid for submit button enable
            this.validate_withdraw_data_button();
        }, false );

        // #####################################################################
        // CHAIN BLOCK TARGET
        // #####################################################################

        this.payments.field.chain.target_block.addEventListener( "keydown", e => {
            if ( this.payments.field.chain.target_block.textContent.length >= 4 &&
                 !Utils.isNavigationKey(e) ) {
                     e.preventDefault();
                 }
            // Prevent new lines
            if ( Utils.isNewLineChar( e.key ) ) { e.preventDefault(); }

            const result = Utils.limitInputToType( this.payments.field.chain.target_block, e, "digitsOnly" );
            if ( !result ) {
                e.preventDefault();
            }
            // Check data is valid for submit button enable
            this.validate_withdraw_data_button();
        }, false );

        this.payments.field.chain.target_block.addEventListener( "paste", e => {
            e.preventDefault();

            // get text representation of clipboard
            const text = e.clipboardData.getData("text/plain");

            // test text contains no letters
            if ( /^[0-9]+$/g.test( text ) ) {
                document.execCommand("insertHTML", false, text);
            }
            // Check data is valid for submit button enable
            this.validate_withdraw_data_button();
        }, false );

        this.payments.field.chain.target_block.addEventListener( "blur", e => {
            e.preventDefault();

            // Ensure Block is within acceptable range
            if ( this.payments.field.chain.target_block.textContent === "" ) {
                this.payments.field.chain.target_block.textContent = DEFAULT_BLOCK_TARGET;
            } else {
                const block = Number(this.payments.field.chain.target_block.textContent);
                if ( !isNaN(block) ) {
                    if ( block < MIN_BLOCK_TARGET ) {
                        this.payments.field.chain.target_block.textContent = MIN_BLOCK_TARGET;
                    } else if ( block > MAX_BLOCK_TARGET ) {
                        this.payments.field.chain.target_block.textContent = MAX_BLOCK_TARGET;
                    }
                }
            }

            // Check data is valid for submit button enable
            this.validate_withdraw_data_button();
        }, false );

        // #####################################################################
        // CHAIN DESTINATION ADDRESS
        // #####################################################################

        this.payments.field.chain.dest_address.addEventListener( "keydown", e => {
            // Prevent new lines
			if ( this.payments.field.chain.dest_address.textContent.length >= this.MAX_BTC_ADDRESS_LENGTH &&
				 !Utils.isNavigationKey(e) ) {
					 e.preventDefault();
					 return;
				 }
			if ( Utils.isNewLineChar( e.key ) ) { e.preventDefault(); }
			if ( !Utils.validateCharacter( e.key ) ) { e.preventDefault(); }
        }, false );

        this.payments.field.chain.dest_address.addEventListener( "keyup", () => {
            // Check data is valid for submit button enable
            this.validate_withdraw_data_button();
        }, false );

        this.payments.field.chain.dest_address.addEventListener( "paste", () => {
            	setTimeout( () => {
                    // Check data is valid for submit button enable
                    this.validate_withdraw_data_button();
    			}, 10);
        }, false );

        this.payments.field.chain.dest_address.addEventListener( "blur", e => {
            e.preventDefault();
            // Check data is valid for submit button enable
            this.validate_withdraw_data_button();
        }, false );

        this.payments.field.chain.dest_address.addEventListener( "auxclick", () => {
            // Check data is valid for submit button enable
            this.validate_withdraw_data_button();
        }, false );

        // #####################################################################
        // CHAIN WITHDRAW DATA BACK BUTTON
        // #####################################################################

        this.payments.button.chain.data_back.addEventListener( "click", e => {
            e.preventDefault();
            this.close_balance_withdraw_section();
        }, false);

        // #####################################################################
        // CHAIN WITHDRAW MAX AMOUNT BUTTON
        // #####################################################################

        this.payments.button.chain.max_withdraw.addEventListener( "click", e => {
            e.preventDefault();
            const max = this.data.earned_net_sat - this.data.requested_good_sat;
            this.payments.field.chain.withdraw_amount.textContent = Math.floor(max);
        }, false);


        // #####################################################################
        // CHAIN WITHDRAW DATA SUBMIT BUTTON
        // #####################################################################

        this.payments.button.chain.data_submit.addEventListener( "click", e => {
            e.preventDefault();
            this.open_payments_chain_sign_section();
        }, false);

        // #####################################################################
        // CHAIN MESSAGE TO SIGN
        // #####################################################################

        // Copy Button
        this.payments.button.chain.copy_withdraw_msg.addEventListener( "click", e => {
            e.preventDefault();
            this.payments.field.chain.withdraw_msg.parentElement.addEventListener("transitionend", () => {
                this.payments.field.chain.withdraw_msg.parentElement.classList.remove('copied');
                this.payments.field.chain.withdraw_msg.parentElement.removeEventListener("transitionend", () => {});
            });

            Utils.copyText( this.payments.field.chain.withdraw_msg.textContent );
            this.payments.field.chain.withdraw_msg.parentElement.classList.add( 'copied' );
        });

        // #####################################################################
        // CHAIN SIGNED MESSAGE
        // #####################################################################

        this.payments.field.chain.signed_msg.addEventListener( "keydown", e => {
            // Prevent new lines
			if ( this.payments.field.chain.signed_msg.textContent.length >= MAX_SIGNATURE_LENGTH &&
				 !Utils.isNavigationKey(e) ) {
					 e.preventDefault();
					 return;
				 }
			if ( Utils.isNewLineChar( e.key ) ) { e.preventDefault(); }
			if ( !Utils.validateCharacter( e.key ) ) { e.preventDefault(); }
        }, false );

        this.payments.field.chain.signed_msg.addEventListener( "paste", e => {
            e.preventDefault();
            // get text representation of clipboard
            const text = e.clipboardData.getData("text/plain");

            // insert text manually
            document.execCommand("insertHTML", false, text);

            // If the bolt11 is clearly not inputted then don't do a full check
            if ( this.payments.field.chain.signed_msg.textContent.length < 50 ) {
                this.payments.button.chain.withdraw_submit.classList.add('disabled');
                return;
            }

            const msg = this.payments.field.chain.withdraw_msg.textContent;
            const address = this.payments.field.address_data.textContent;
            const signed = this.payments.field.chain.signed_msg.textContent;

            const verified = this.Payout.verify_signed_message( msg, address, signed );
            if ( verified ) {
                this.payments.button.chain.withdraw_submit.classList.remove('disabled');
            } else {
                this.payments.button.chain.withdraw_submit.classList.add('disabled');
                this.sparkshot.UI.showMessage( "Error", "Bad Signature" );
            }
        }, false );

        this.payments.field.chain.signed_msg.addEventListener( "blur", e => {
            e.preventDefault();

            if ( this.payments.field.chain.signed_msg.textContent.length < 50 ) {
                this.payments.button.chain.withdraw_submit.classList.add('disabled');
                return;
            }

            const msg = this.payments.field.chain.withdraw_msg.textContent;
            const address = this.payments.field.address_data.textContent;
            const signed = this.payments.field.chain.signed_msg.textContent;

            const verified = this.Payout.verify_signed_message( msg, address, signed );
            if ( verified ) {
                this.payments.button.chain.withdraw_submit.classList.remove('disabled');
            } else {
                this.sparkshot.UI.showMessage( "Error", "Bad Signature" );
                this.payments.button.chain.withdraw_submit.classList.add('disabled');
            }
        }, false );

        // #####################################################################
        // CHAIN WITHDRAW BACK BUTTON
        // #####################################################################

        this.payments.button.chain.withdraw_back.addEventListener( "click", e => {
            e.preventDefault();
            this.close_balance_chain_sign_section();

            // Scroll back to the top
            this.scroll.scrollTo( 0, 0 );
        }, false);

        // #####################################################################
        // CHAIN WITHDRAW DATA SUBMIT BUTTON
        // #####################################################################

        this.payments.button.chain.withdraw_submit.addEventListener( "click", e => {
            e.preventDefault();
            this.request_chain_withdraw();
        }, false);
    }

    open() {
        this.sideNav.renderCurrentTab();

        // #####################################################################
        // SCROLLER
        // #####################################################################
        this.scroll = this.body.querySelector(".simplebar-content-wrapper");
    }

    close() {
        // Clears all fields
        this.close_all_sections();
    }

    // #####################################################################
    // SETTINGS METHODS
    // #####################################################################

    close_all_sections() {
        // Set all wallet sections as hidden
        for (const x in this.wallet.section) {
            if (x) {
                this.wallet.section[x].classList.add('hide');
            } else {
                console.error('Profile: Wallet has bad section reference');
            }
        }

        // Set all balance sections and child sections as hidden
        for (const x in this.payments.section) {
            if (x) {
                if (this.payments.section[x].classList !== undefined) {
                    this.payments.section[x].classList.add('hide');
                } else {
                    for (const y in this.payments.section[x]) {
                        if (y) {
                            this.payments.section[x][y].classList.add('hide');
                        }
                    }
                }
            } else {
                console.error('Profile: Payments has bad section reference');
            }
        }

        for (const x in this.payments.field) {
            if (x) {
                if (this.payments.field[x].classList !== undefined) {
                    this.payments.field[x].textContent = '';
                } else {
                    for (const y in this.payments.field[x]) {
                        if (y) {
                            this.payments.field[x][y].textContent = '';
                        }
                    }
                }
            } else {
                console.error('Profile: Payments has bad field reference');
            }
        }

        // Button Reset
        this.payments.button.request_bal.classList.add('disabled');
        this.payments.button.ln.submit.classList.add('disabled');
        this.payments.button.chain.withdraw_submit.classList.add('disabled');
        this.payments.button.show_balance.classList.remove('disabled');
        this.payments.button.show_balance.innerHTML =
        this.payments.button.show_balance.textContent = "SHOW BALANCE";

        // Clear all wallet text fields
        for (const x in this.wallet.field) {
            if (x) {
                this.wallet.field[x].textContent = '';
            } else {
                console.error('Profile: Wallet has bad field reference');
            }
        }

        // hide all buttons
        for (const x in this.wallet.button) {
            if (x) {
                this.wallet.button[x].classList.add('hide');
            } else {
                console.error('Profile: Wallet has bad button reference');
            }
        }

        // Remove hints
        this.sparkshot.Tutorial.removeAllHints();

        // Cancel the time update
        this.cancel_data_retreaval_time();
    }

    // #####################################################################
    // USER  METHODS
    // #####################################################################

    open_user_main_section() {
        this.close_all_sections();

        this.load_user_data();
        this.validate_profile_save();

        this.user.section.main.classList.remove('hide');
    }

    validate_profile_save() {
        const settings = this.sparkshot.Settings;

        this.user.button.save.classList.add('disabled');

        // If there's no name, then state is not valid
        if ( this.user.field.username.textContent.length === 0  ) {
            this.sparkshot.Tutorial.addHint( "set_chat_name" );     // Show Add Name Hint
            return;
        }

        // If any data changed
        if ( this.user.field.username.textContent !== settings.getLocalData( 'name' ) ) {
            this.user.button.save.classList.remove('disabled');
            return;
        }

        if ( this.user.button.nsfw.checked !== settings.getShowNSFWContentStatus() ) {
            this.user.button.save.classList.remove('disabled');
            return;
        }

        if ( this.user.button.art_ui.checked !== settings.getArtControlsStatus() ) {
            this.user.button.save.classList.remove('disabled');
            return;
        }

    }

    validateNameField( value ) {
        if ( value === null || typeof value === 'undefined' ) return false;

        const enc = new TextEncoder( 'utf-8' ).encode( value );
        const name = new TextEncoder( 'utf-8' ).encode( value );

        if ( name.byteLength > MAX_NAME_LENGTH ) {
            // this.flashError( this.error.name, this.ERROR_MAX_CHARACTERS );
            const new_name = name.slice( 0, name.length - enc.length );
            const text = new TextDecoder( 'utf-8' ).decode( new_name );
            return { text: text, valid: true };
        }

        if ( !Utils.validateText( value, /([^:#\n\t])/ ) ) {
            return { text: value.slice(0, -1), valid: false };
            // this.flashError( this.error.name, this.ERROR_BAD_CHARACTER );
        }
    }

    load_user_data() {
        const settings = this.sparkshot.Settings;
        // Fetch Saved Data
        this.user.field.username.textContent = settings.getLocalData( 'name' );
        this.user.button.nsfw.checked = settings.getShowNSFWContentStatus();
        this.user.button.art_ui.checked = settings.getArtControlsStatus();
    }

    save_user_data() {
        const settings = this.sparkshot.Settings;

        // Save Fields
        if ( this.user.field.username.textContent.length !== 0 ) {
            settings.saveLocalData( 'name', this.user.field.username.textContent );
            this.sparkshot.Tutorial.completeHint( "set_chat_name" );     // Complete Name Hint
        }
        settings.saveLocalData( 'nsfw', this.user.button.nsfw.checked );
        settings.saveLocalData( 'art_controls', this.user.button.art_ui.checked );

        // Set name on server
        this.sparkshot.WS.request_trollbox_name( settings.getLocalData( 'name' ) );

        this.sparkshot.UI.showToast( 'Saved' );

        // Update setup
        this.sparkshot.UI.setArtControlVisibility( this.user.button.art_ui.checked );
    }

    // #####################################################################
    // WALLET METHODS
    // #####################################################################


    open_wallet_main_section() {
        this.close_all_sections();
        this.clear_seed_fields();

        if (this.sparkshot.Wallet.has_mnemonic()) {

            // Set Address
            const a = this.sparkshot.Wallet.get_address_wif().address;
            if (!a) { console.error('Wallet Error: No Seed Found'); }
            this.wallet.field.address.textContent = a;

            // Set URI
            const uri = `${window.location.origin}?=${a}`;
            this.wallet.field.uri.href = uri;
            this.wallet.field.uri.textContent = uri;

            // Configure Buttons
            this.wallet.button.show_priv_key.classList.remove('hide');
            this.wallet.button.hide_priv_key.classList.add('hide');

            this.wallet.section.main.classList.remove('hide');

            if (!this.sparkshot.Wallet.get_wrote_seed_status()) {
                this.save_seed_hint();
            }

        } else {
            this.open_empty_section();
        }
    }

    clear_seed_fields() {
        // Clear all content
        this.wallet.field.reason.textContent = '';
        const parent = this.wallet.section.seed.querySelector('.row'); // Get first row
        if (parent.childElementCount > 0 ) {
            [...parent.childNodes].forEach(el => el.remove());
        }
    }

    open_private_key_section() {

        // Remove hint
        this.complete_save_seed_hint();

        this.sparkshot.Wallet.set_wrote_seed();

        // Setup Sections
        this.open_wallet_main_section();
        this.wallet.section.details.classList.remove('hide');

        // Populate Fields
        const a = this.sparkshot.Wallet.get_address_wif().wif;
        if (!a) { console.error('Wallet Error: No WIF Private Key Found'); }
        this.wallet.field.private_key.textContent = a;

        const b = this.sparkshot.Wallet.get_mnemonic();
        if (!b) { console.error('Wallet Error: No Seed Found'); }
        this.wallet.field.seed.textContent = b;

        // Configure Buttons
        this.wallet.button.hide_priv_key.classList.remove('hide');
        this.wallet.button.delete_seed.classList.remove('hide');
        this.wallet.button.show_priv_key.classList.add('hide');
    }

    close_private_key_section() {
        // Setup Sections
        this.open_wallet_main_section();
    }

    open_empty_section() {
        this.close_all_sections();
        this.wallet.button.gen_key.classList.remove('hide');
        this.wallet.button.enter_seed.classList.remove('hide');
        this.wallet.section.empty.classList.remove('hide');
    }

    request_gen_key() {
        if ( !this.sparkshot.Wallet.has_mnemonic() ) {
            this.sparkshot.Wallet.generate_store_mnemonic();
        }

        this.open_wallet_main_section();
    }

    request_save_seed() {
        const result = this.sparkshot.Wallet.check_seed_phrase(this.seed_words);
        if (result.valid) {
            this.sparkshot.Wallet.set_wrote_seed();
            this.open_wallet_main_section();
        } else {
            this.wallet.field.reason.textContent = result.reason;
        }
    }

    request_delete_seed() {
        this.close_all_sections();

        if (this.sparkshot.Wallet.has_mnemonic()) {

            // Set Address
            const a = this.sparkshot.Wallet.get_mnemonic();
            if (!a) { console.error('Wallet Error: No Seed Found'); }
            this.wallet.field.delete_seed.textContent = a;

            // Configure Buttons
            this.wallet.button.confirm_delete.classList.remove('hide');
            this.wallet.button.cancel_delete.classList.remove('hide');
            this.wallet.section.delete.classList.remove('hide');

            // Clear wrote down seed state
            this.sparkshot.Wallet.clear_wrote_seed();
        } else {
            this.open_wallet_main_section();
        }
    }

    confirm_delete_seed() {
        this.sparkshot.Wallet.delete_mnemonic();

        this.open_empty_section();
    }

    cancel_delete_seed() {
        this.open_private_key_section();
    }

    open_seed_section() {
        this.close_all_sections();

        // Default disable save button
        this.wallet.button.save_seed.classList.add('disabled');

        // Add Seed Input fields
        // #####################################################################
        // SEED INPUT FIELDS
        // #####################################################################

        // Generate Seed Input Fields
        const parent = this.wallet.section.seed.querySelector('.row'); // Get first row
        const num_words = this.sparkshot.Wallet.get_word_count();
        const frag = document.createDocumentFragment();

        this.seed_words = [];

        for (let i = 0; i < num_words; i++) {
            const div = document.createElement('div');
            div.classList.add('col', 's12', 'm6');

            const label = document.createElement('div');
            label.classList.add('input-text-field-label', 'no-select');
            label.textContent = `WORD ${i+1} `;

            const text_field = document.createElement('div');
            text_field.classList.add('input-text-field');
            text_field.contentEditable = true;
            text_field.dataset.default = `WORD ${i+1}`;
            // text_field.textContent = `WORD ${i+1}`;

            const sub_field = document.createElement('div');
            sub_field.classList.add('data-sub-field', 'spark-blue-text', 'one-line');
            sub_field.textContent = '';

            // Increment array length
            this.seed_words.push('');

            text_field.addEventListener( "keydown", e => {
                // Prevent new lines
                if ( text_field.textContent.length >= MAX_SEED_WORD_LENGTH &&
                     !Utils.isNavigationKey(e) ) {
                         e.preventDefault();
                         return;
                     }
                if ( Utils.isNewLineChar( e.key ) ) { e.preventDefault(); }
                if ( !Utils.validateCharacter( e.key ) ) { e.preventDefault(); }
            }, false);

            // Check Text on Key Up
            text_field.addEventListener('keyup', e => {
                this.seed_words[i] = text_field.textContent;
                if (text_field.textContent === '') {
                    // Check to see if all fields have been cleared
                    if (this.seed_words.filter( e => e === '').length === this.sparkshot.Wallet.get_word_count()) {
                        this.sparkshot.Wallet.clear_language();
                        sub_field.textContent = '';
                    }
                } else {
                    // If all fields have words then allow submission
                    if (this.seed_words.filter( e => e === '').length === 0) {
                        this.wallet.button.save_seed.classList.remove('disabled');
                    } else {
                        this.wallet.button.save_seed.classList.add('disabled');
                        this.wallet.field.reason.textContent = '';
                    }
                }
            }, false);

            // Check text on unfocus
            text_field.addEventListener('focusout', e => {
                // Write the entered word to the word list
                this.seed_words[i] = text_field.textContent;

                // Check the field is not empty before testing the word
                if (text_field.textContent !== '') {
                    // Check if it's the only word and clear language
                    if (this.seed_words.filter( e => e === '').length === this.sparkshot.Wallet.get_word_count() - 1 ) {
                        this.sparkshot.Wallet.clear_language();
                    }
                    const result = this.sparkshot.Wallet.check_word( text_field.textContent );
                    sub_field.textContent = (result.reason) ? result.reason : '';
                } else {
                    // Check to see if all fields have been cleared
                    if (this.seed_words.filter( e => e === '').length === this.sparkshot.Wallet.get_word_count()) {
                        this.sparkshot.Wallet.clear_language();
                    }
                    sub_field.textContent = '';
                    text_field.innerHTML = '';
                }

                // If all have text then allow save
                if (this.seed_words.filter( e => e === '').length === 0) {
                    this.wallet.button.save_seed.classList.remove('disabled');
                } else {
                    this.wallet.button.save_seed.classList.add('disabled');
                    this.wallet.field.reason.textContent = '';
                }
            });

            div.appendChild(label);
            div.appendChild(text_field);
            div.appendChild(sub_field);

            frag.appendChild(div);
        }

        parent.appendChild(frag);

        // Configure Buttons
        this.wallet.button.save_seed.classList.remove('hide');
        this.wallet.button.cancel_seed.classList.remove('hide');

        this.wallet.section.seed.classList.remove('hide');
    }

    close_seed_section() {
        this.open_wallet_main_section();
    }

    save_seed_hint() {
        // Check if user has written down seed
        // SHOW HINT
        this.sparkshot.Tutorial.addHint( "show_seed_phrase" );
    }

    complete_save_seed_hint() {
        this.sparkshot.Tutorial.completeHint( "show_seed_phrase" );
    }

    // #####################################################################
    // BALANCE METHODS
    // #####################################################################

    open_payments_main_section() {

        // Configure laytout
        this.close_all_sections();
        this.payments.section.request.classList.remove('hide');

        // Check if internal wallet is setup and enable button
        if (this.sparkshot.Wallet.has_mnemonic()) {
            // Set Address
            const a = this.sparkshot.Wallet.get_address_wif().address;
            if (a) {
                this.payments.button.use_wallet_address.classList.remove('disabled');
            } else {
                this.payments.button.use_wallet_address.classList.add('disabled');
            }
        }

        this.validate_artist_address_field();
    }

    validate_artist_address_field() {
        const address = this.payments.field.address.textContent;

        const verified = this.Payout.address_is_valid( address );
        if ( verified ) {
            this.payments.button.show_balance.classList.remove('disabled');
        } else {
            this.payments.button.show_balance.classList.add('disabled');
        }
    }

    open_payments_manual_section( address = "") {
        // Get Address
        if (address === "") {
            address = this.payments.field.address.textContent;
        }

        // Configure laytout
        this.close_all_sections();

        // Populate lookup message field
        const msg = this.Payout.generate_lookup_message();
        this.payments.field.gen_lookup_msg.textContent = msg;

        this.payments.field.signed_addr.textContent = address;

        // Show section
        // this.payments.section.request.classList.remove('hide');
        this.payments.section.sign.classList.remove('hide');
    }

    open_payments_data_section( data ) {
        // Store data for other sections
        this.data = data;

        // Configure laytout
        this.close_all_sections();

        // Populate tables
        // Generate Date
        this.payments.field.date.textContent = this.sparkshot.Moment(Utils.time_converter(this.data.earned_timestamp)).fromNow();

        // Set auto date update
        this.set_data_retreval_time();

        // Set URI
        const uri = `${window.location.origin}?=${data.address}`;
        this.payments.field.address_data.href = uri;
        this.payments.field.address_data.textContent = data.address;

        this.payments.field.net.textContent =
            this.get_formatted_msat_string(data.earned_net_sat);

        this.payments.field.pending_paid.textContent =
            this.get_formatted_msat_string(data.requested_good_sat);

        this.payments.field.withdrawable.textContent =
            this.get_formatted_msat_string(data.earned_net_sat - data.requested_good_sat);

        this.payments.field.art_num.textContent = data.art_pieces;

        this.payments.field.gross.textContent =
            this.get_formatted_msat_string(data.earned_gross_sat);

        this.payments.field.fee.textContent =
            this.get_formatted_msat_string(data.earned_platform_sat);

        this.payments.field.net_earned.textContent =
            this.get_formatted_msat_string(data.earned_net_sat);


        this.payments.field.pending.textContent =
            this.get_formatted_msat_string(data.requested_pending_sat);

        this.payments.field.paid.textContent =
            this.get_formatted_msat_string(data.requested_good_sat);

        this.payments.field.failed.textContent =
            this.get_formatted_msat_string(data.requested_failed_sat);

        // Set Withdraw fields
        this.payments.field.ln.max_withdraw.textContent =
            this.get_formatted_msat_string(data.earned_net_sat - data.requested_good_sat);

        // Show section
        this.payments.section.data.classList.remove('hide');
        this.payments.section.history_req.classList.remove('hide');
    }

    download_history() {
        if (!this.data) { return; }
        this.merged = this.data.withdraw_requests.concat( this.data.lookup_requests );
        const data = this.generate_history_csv( this.merged );
        Utils.export_to_file( data, `${this.payments.field.address_data.text} - ${new Date().toDateString()}.csv`, "text/csv" );

    }

    generate_history_csv( data ) {

        const fields = [{
            label:      "artist_address",
            value:      "artist_address",
            default:    '-'
        }, {
            label:      "recv_time",
            value:      (field) => (field.recv_time !== undefined) ? Utils.time_converter( field.recv_time ) : field.default,
            default:    '-'
        }, {
            label:      "request_uuid",
            value:      "request_uuid",
            default:    '-'
        }, {
            label:      "msg_txt",
            value:      "msg_txt",
            default:    '-'
        }, {
            label:      "msg_time",
            value:      (field) => (field.msg_time !== undefined) ? Utils.time_converter( field.msg_time ) : field.default,
            default:    '-'
        }, {
            label:      "msg_uuid",
            value:      "msg_uuid",
            default:    '-'
        }, {
            label:      "msg_app",
            value:      "msg_app",
            default:    '-'
        }, {
            label:      "msg_call",
            value:      "msg_call",
            default:    '-'
        }, {
            label:      "withdraw_type",
            value:      "withdraw_type",
            default:    '-'
        }, {
            label:      "bolt11",
            value:      "bolt11",
            default:    '-'
        }, {
            label:      "bolt11_msatoshi",
            value:      "bolt11_msatoshi",
            default:    '-'
        }, {
            label:      "bolt11_created_at",
            value:      (field) => (field.bolt11_created_at !== undefined) ? Utils.time_converter( field.bolt11_created_at ) : field.default,
            default:    '-'
        }, {
            label:      "bolt11_expiry",
            value:      (field) => (field.bolt11_expiry !== undefined) ? Utils.time_converter( field.bolt11_created_at + field.bolt11_expiry ) : field.default,
            default:    '-'
        }, {
            label:      "bolt11_payment_hash",
            value:      "bolt11_payment_hash",
            default:    '-'
        }, {
            label:      "satoshis",
            value:      "satoshis",
            default:    '-'
        }, {
            label:      "conf_target",
            value:      "conf_target",
            default:    '-'
        }, {
            label:      "event",
            value:      "event",
            default:    '-'
        }, {
            label:      "fail_reason",
            value:      "fail_reason",
            default:    '-'
        }, {
            label:      "time",
            value:      (field) => (field.time !== undefined) ? Utils.time_converter( field.time ) : field.default,
            default:    '-'
        }];

        const json2csvParser = new Parser({ fields });
        return json2csvParser.parse( data );
    }

    add_history_lookup_row( data ) {
        if (!data) { return; }

        const row = this.payments.field.history.insertRow(-1);

        const date_cell = row.insertCell(0);
        date_cell.textContent = Utils.time_converter( data.time );

        const action_cell = row.insertCell(1);
        action_cell.textContent = "Lookup Request";

        const id_cell = row.insertCell(2);
        id_cell.textContent = data.uuid;

        const sats_cell = row.insertCell(3);
        sats_cell.textContent = "-";

        const details_cell = row.insertCell(4);
        details_cell.textContent = data.info.msg_txt;
    }

    add_history_withdraw_row( data ) {

        const row = this.payments.field.history.insertRow(-1);

        const date_cell = row.insertCell(0);
        date_cell.textContent = Utils.time_converter( data.time );

        const action_cell = row.insertCell(1);
        action_cell.textContent = "Withdraw Request";

        const id_cell = row.insertCell(2);
        id_cell.textContent = data.uuid;

        const sats_cell = row.insertCell(3);
        sats_cell.textContent = `${data.info.satoshis} sats`;

        const details_cell = row.insertCell(4);
        details_cell.textContent = data.info.msg_txt;
    }

    add_history_outgoing_row( data ) {

        const row = this.payments.field.history.insertRow(-1);

        const date_cell = row.insertCell(0);
        row.insertCell(1);
        const id_cell = row.insertCell(2);
        const sats_cell = row.insertCell(3);
        const details_cell = row.insertCell(4);

        date_cell.textContent = Utils.time_converter( data.time );

        const action_cell = row.insertCell(1);

        switch ( data.info.event ) {
            case 'OVERDRAWN':
                action_cell.textContent = "Overdrawn";

                break;
            case 'REQUEST':
                action_cell.textContent = "Started Withdrawal";
                details_cell.textContent = '-';
                break;
            case 'FAILED_ATTEMPT':
                action_cell.textContent = "Failed Attempt";
                details_cell.textContent = data.info.fail_reason;
                break;
            case 'FINAL_FAILURE':
                action_cell.textContent = "Final Failure";
                details_cell.textContent = data.info.fail_reason;
                break;
            case 'SUCCESS':
                action_cell.textContent = "Successfully Paid";
                if (data.info.txid !== '') {
                    details_cell.textContent = `txid: ${data.info.txid}`;
                } else {
                    details_cell.textContent = `preimage: ${data.info.preimage}`;
                }
                break;
            default:
                details_cell.textContent = '';
                break;
        }

        id_cell.textContent = data.uuid;

        sats_cell.textContent = "-";
    }

    open_payments_error_section( data ) {
        this.sparkshot.UI.showMessage( "ERROR", data.err );
        this.open_payments_main_section();
    }

    clear_balance_data() {
        this.payments.field.date.textContent = "-";

        // Set URI
        this.payments.field.address_data.href = "-";
        this.payments.field.address_data.textContent = "-";

        this.payments.field.net.textContent = "-";
        this.payments.field.pending_paid.textContent = "-";
        this.payments.field.withdrawable.textContent = "-";

        this.payments.field.art_num.textContent = "-";

        this.payments.field.gross.textContent = "-";

        this.payments.field.fee.textContent = "-";

        this.payments.field.net_earned.textContent = "-";

        this.payments.field.pending.textContent = "-";
        this.payments.field.paid.textContent = "-";
        this.payments.field.failed.textContent = "-";
    }

    request_artist_balance( address = "") {
        this.clear_balance_data();

        if (address === "") {
            address = this.payments.field.address.textContent;
        }
        if (address === "") {
            console.error("No Address Provided");
            this.sparkshot.UI.showMessage('Error', "No Artist Address Entered");
        } else {
            // IS SPARKSHOT WALLET ADDRESS
            const spark_addr = this.sparkshot.Wallet.get_address_wif().address;
            if (address === spark_addr) {
                const msg = this.Payout.generate_lookup_message();
                const wif = this.sparkshot.Wallet.get_address_wif().wif;
                const sig = this.sparkshot.Wallet.get_signature( msg, wif );

                const signed = this.Payout.generate_lookup_submit_message(msg, address, sig);

                this.Payout.send_lookup( signed,
                    this.open_payments_data_section.bind(this),
                    this.open_payments_error_section.bind(this) );
            } else {
                this.open_payments_manual_section( address );
            }
        }
    }

    request_artist_manual_balance() {
        const addr = this.payments.field.signed_addr.textContent;
        const msg = this.payments.field.gen_lookup_msg.textContent;
        const sig = this.payments.field.signed_lookup.textContent;

        const signed = this.Payout.generate_lookup_submit_message(msg, addr, sig);

        this.Payout.send_lookup( signed,
            this.open_payments_data_section.bind(this),
            this.open_payments_error_section.bind(this) );
    }

    set_data_retreval_time( update_now = 0 ) {
        // Get update tick
        this.update_tick = Math.min((update_now - ( this.last_update_time || update_now)), 66)/1000;
        this.last_update_time = update_now;

        // Set next update time
        this.next_update = (isNaN(this.next_update)) ? 0 : this.next_update += this.update_tick;

        if ( this.next_update >= 10 ) {
            this.payments.field.date.textContent = this.sparkshot.Moment(Utils.time_converter(this.data.earned_timestamp)).fromNow();
            this.next_update = 0;
        }
        this.data_retreval_time = window.requestAnimationFrame( this.set_data_retreval_time.bind(this) );
    }

    cancel_data_retreaval_time() {
        window.cancelAnimationFrame(this.data_retreval_time);
    }

    // #####################################################################
    // BALANCE LN WITHDRAW
    // #####################################################################

    open_payments_ln_withdraw_section() {
        // Close Chain Withdraw section
        this.close_balance_withdraw_section();

        if (!this.data) { console.error('No artist balance data'); }

        const sat = (this.data.earned_net_sat - this.data.requested_good_sat).toFixed(3);
        const max_withdraw = sat;

        // Check if there's enough to withdraw
        if (max_withdraw < MIN_LN_WITHDRAW) {
            // THROW SOME SIGNAL THAT USER CANNOT PROCEED
            Utils.disabled_field_input(this.payments.field.ln.bolt11);
            this.sparkshot.UI.showMessage( `WARNING`, `You need to have at least ${MIN_LN_WITHDRAW} satoshi avaliable to be able to withdraw.`);
        } else {
            Utils.enable_field_input(this.payments.field.ln.bolt11);
        }

        // Setup display sections
        this.payments.section.ln.withdraw.classList.remove('hide');
        this.payments.section.ln.bolt11.classList.remove('hide');

        this.scroll_to( this.payments.section.ln.withdraw );
    }

    open_ln_message_section() {
        // Generate Sign Message
        const addr = this.payments.field.address_data.textContent;
        if (!addr) {
            console.error("No Address Provided");
            this.sparkshot.UI.showMessage('Error', "No Artist Address Entered");
            return;
        }

        // IS SPARKSHOT WALLET ADDRESS
        const spark_addr = this.sparkshot.Wallet.get_address_wif().address;
        if (addr === spark_addr) {
            this.payments.button.ln.submit.classList.remove('disabled');

        } else {
            const bolt11 = this.payments.field.ln.bolt11.textContent;
            const msg = this.Payout.generate_ln_withdraw_message( bolt11 );
            // Hide Bolt11 section
            this.payments.section.ln.bolt11.classList.add('hide');
            this.payments.button.ln.submit.classList.add('disabled');
            this.payments.field.ln.withdraw_msg.textContent = msg;
            this.payments.section.ln.withdraw_manual.classList.remove('hide');
        }
    }

    request_ln_withdraw() {
        // IS SPARKSHOT WALLET ADDRESS
        const addr = this.payments.field.address_data.textContent;
        const spark_addr = this.sparkshot.Wallet.get_address_wif().address;
        let msg, sig;
        if (addr === spark_addr) {
            const bolt11 = this.payments.field.ln.bolt11.textContent;
            msg = this.Payout.generate_ln_withdraw_message( bolt11 );
            const wif = this.sparkshot.Wallet.get_address_wif().wif;
            sig = this.sparkshot.Wallet.get_signature( msg, wif );

        } else {
            msg = this.payments.field.ln.withdraw_msg.textContent;
            sig = this.payments.field.ln.signed_msg.textContent;
        }

        const signed = this.Payout.generate_lookup_submit_message(msg, addr, sig);

        this.Payout.send_ln_withdraw( signed,
            this.open_success_ln_withdraw.bind(this),
            this.open_error_ln_withdraw.bind(this) );
    }

    open_success_ln_withdraw() {
        this.sparkshot.UI.showHTMLMessage( "PayDay!", `Payment request sent. Payout may take up to a few minutes. See <a href="/faq#how-long-till-ln-payment" target="_blank">FAQ</a> for more information.` );
        this.close_balance_withdraw_section();
    }

    open_error_ln_withdraw( data ) {
        this.sparkshot.UI.showMessage( "ERROR", data.err );

        // Clear Fields
        this.payments.field.ln.bolt11.textContent = '';
        this.payments.field.ln.signed_msg.textContent = '';

        // Setup display sections
        this.payments.section.ln.withdraw_manual.classList.add('hide');
        this.payments.section.ln.bolt11.classList.remove('hide');
    }

    close_balance_withdraw_section() {

        // Clear Fields
        this.payments.field.ln.bolt11.textContent = '';
        this.payments.field.ln.signed_msg.textContent = '';

        for (const x in this.payments.field.chain) {
            if (x) { this.payments.field.chain[x].textContent = ''; }
        }

        this.payments.section.ln.withdraw_manual.classList.add('hide');
        this.payments.button.ln.submit.classList.add('disabled');
        this.payments.section.ln.withdraw.classList.add('hide');
        this.payments.section.chain.withdraw.classList.add('hide');

        this.close_balance_chain_sign_section();

        // Reset warning
        this.warned_user_about_address = false;
    }

    // #####################################################################
    // BALANCE CHAIN WITHDRAW
    // #####################################################################

    open_payments_chain_withdraw_section() {
        // Close Chain Withdraw section
        this.close_balance_withdraw_section();

        if (!this.data) { console.error('No artist balance data'); }

        const max_withdraw = Math.floor(this.data.earned_net_sat - this.data.requested_good_sat);
        this.payments.field.chain.max_withdraw.textContent = `${max_withdraw} sat`;
        this.payments.field.chain.min_withdraw.textContent = MIN_CHAIN_WITHDRAW;

        // Blocks
        this.payments.field.chain.min_blocks.textContent = MIN_BLOCK_TARGET;
        this.payments.field.chain.max_blocks.textContent = MAX_BLOCK_TARGET;

        // Check if there's enough to withdraw
        if (max_withdraw >= MIN_CHAIN_WITHDRAW) {
            Utils.enable_field_input(this.payments.field.chain.withdraw_amount);
            Utils.enable_field_input(this.payments.field.chain.target_block);
            Utils.enable_field_input(this.payments.field.chain.dest_address);
            this.payments.field.chain.withdraw_amount.textContent = MIN_CHAIN_WITHDRAW;
        } else {
            // THROW SOME SIGNAL THAT USER CANNOT PROCEED
            this.payments.field.chain.withdraw_amount.textContent = 0;
            Utils.disabled_field_input(this.payments.field.chain.withdraw_amount);
            Utils.disabled_field_input(this.payments.field.chain.target_block);
            Utils.disabled_field_input(this.payments.field.chain.dest_address);
            this.sparkshot.UI.showMessage( `WARNING`, `You need to have at least ${MIN_CHAIN_WITHDRAW} satoshi avaliable to be able to withdraw via onchain.`);
        }

        this.payments.field.chain.target_block.textContent = DEFAULT_BLOCK_TARGET;

        // Setup display sections
        this.payments.section.chain.sign.classList.add('hide');

        this.payments.section.chain.details.classList.remove('hide');
        this.payments.section.chain.withdraw.classList.remove('hide');

        this.scroll_to( this.payments.section.chain.withdraw );
    }

    // Set the state of the submit withdraw date to the message generator
    validate_withdraw_data_button() {
        let amount_valid = false;
        let block_valid = false;
        let address_valid = false;

        const amount = Number(this.payments.field.chain.withdraw_amount.textContent);
        if (!isNaN(amount) && amount !== 0 ) {
            amount_valid = true;
        }

        const block = Number(this.payments.field.chain.target_block.textContent);
        if (!isNaN(block)) { block_valid = true; }

        const address = this.payments.field.chain.dest_address.textContent;
        if ( this.Payout.address_is_valid(address) ) {
            // Check if it's the spark wallet address and warn user
            const spark_addr = this.sparkshot.Wallet.get_address_wif().address;
            if (address === spark_addr && !this.warned_user_about_address) {
                this.sparkshot.UI.showMessage("WARNING",`The chosen destination address is stored in your Sparkshot wallet. We do not recommend sending any Bitcoin to an Artist address or an address that has been stored in the Sparkshot wallet.`);
                this.warned_user_about_address = true;
            } else if ( address === this.data.address ) {
                this.sparkshot.UI.showMessage("WARNING",`The chosen destination address matches the artist address. We recommend sending Bitcoin to a seperate non-artist address that is stored in an offline wallet.`);
                this.warned_user_about_address = true;
            }
            address_valid = true;
        }

        if ( amount_valid && block_valid && address_valid ) {
            this.payments.button.chain.data_submit.classList.remove('disabled');
        } else {
            this.payments.button.chain.data_submit.classList.add('disabled');
        }
    }

    open_payments_chain_sign_section() {

        // Test Withdraw amount
        if ( this.payments.field.chain.withdraw_amount.textContent === '' ) { return; }
        let amount = Number(this.payments.field.chain.withdraw_amount.textContent);
        const max = this.data.earned_net_sat - this.data.requested_good_sat;
        if (isNaN(amount) || isNaN(max)) { return; }

        // Handle Min Withdraw
        if ( amount >= 0 && amount < MIN_CHAIN_WITHDRAW ) {
            // Test if amount value is enough
            if ( max < MIN_CHAIN_WITHDRAW ) {
                this.sparkshot.UI.showMessage('Balance Too Low', `The minumum withdrawal is ${MIN_CHAIN_WITHDRAW} sat `);
                this.close_balance_chain_sign_section();
                this.payments.section.chain.details.classList.remove('hide');
                return;
            } else {
                amount = MIN_CHAIN_WITHDRAW;
            }
        }

        // Handle Max Withdraw
        if (amount > max) {
            amount = Math.floor(max);
        }

        // Update field
        this.payments.field.chain.withdraw_amount.textContent = amount;

        // Test Block
        const block = Number(this.payments.field.chain.target_block.textContent);
        if ( !isNaN(block) ) {
            if (block < 1) {
                this.payments.field.chain.target_block.textContent = "1";
            } else if (block > MAX_BLOCK_TARGET) {
                this.payments.field.chain.target_block.textContent = MAX_BLOCK_TARGET;
            }
        } else {
            this.close_balance_chain_sign_section();
            this.payments.section.chain.details.classList.remove('hide');
            return;
        }

        // Test Address

        // Is Artist Address in Sparkshot Wallet is artist address of withdrawal
        const spark_addr = this.sparkshot.Wallet.get_address_wif().address;
        const artist_addr = this.data.address;

        // destination
        const address = this.payments.field.chain.dest_address.textContent;

        if (!this.Payout.address_is_valid(address)) {
            this.close_balance_chain_sign_section();
            this.payments.section.chain.details.classList.remove('hide');
            return;
        }
        // Message to sign

        const msg = this.Payout.generate_chain_withdraw_message( amount, address, block );

        // If sparkshot wallet artist then use auto sign message
        if ( spark_addr === artist_addr ) {
            const wif = this.sparkshot.Wallet.get_address_wif().wif;
            const sig = this.sparkshot.Wallet.get_signature( msg, wif );

            const signed = this.Payout.generate_lookup_submit_message(msg, artist_addr, sig);

            this.Payout.send_chain_withdraw( signed,
                this.open_success_chain_withdraw.bind(this),
                this.open_error_chain_withdraw.bind(this) );
        } else {
            if (msg) {
                this.payments.field.chain.withdraw_msg.textContent = msg;
            }

            // Block data input
            Utils.disabled_field_input(this.payments.field.chain.withdraw_amount);
            Utils.disabled_field_input(this.payments.field.chain.target_block);
            Utils.disabled_field_input(this.payments.field.chain.dest_address);

            // open sign panel
            this.payments.section.chain.sign.classList.remove('hide');
        }

        // close data panel
        this.payments.section.chain.details.classList.add('hide');
    }

    close_balance_chain_sign_section() {
        // Bring back the details section
        this.payments.section.chain.details.classList.remove('hide');

        this.payments.section.chain.sign.classList.add('hide');
        this.payments.field.chain.withdraw_msg.textContent = '';
        this.payments.field.chain.signed_msg.textContent = '';

        this.validate_withdraw_data_button();

        // Unblock data input
        Utils.enable_field_input(this.payments.field.chain.withdraw_amount);
        Utils.enable_field_input(this.payments.field.chain.target_block);
        Utils.enable_field_input(this.payments.field.chain.dest_address);
    }

    request_chain_withdraw() {
        const msg = this.payments.field.chain.withdraw_msg.textContent;
        const addr = this.data.address;
        const sig = this.payments.field.chain.signed_msg.textContent;

        const signed = this.Payout.generate_lookup_submit_message(msg, addr, sig);

        this.Payout.send_chain_withdraw( signed,
            this.open_success_chain_withdraw.bind(this),
            this.open_error_chain_withdraw.bind(this) );

        this.close_balance_chain_sign_section();
    }

    open_success_chain_withdraw() {
        this.sparkshot.UI.showHTMLMessage( "PayDay!", `Payment request sent. Please note that payment will aim to arrive within your chosen ${this.payments.field.chain.target_block.textContent} block target, however on-chain payments can take longer due to market activity.` );
        this.close_balance_withdraw_section();
    }

    open_error_chain_withdraw( data ) {
        this.sparkshot.UI.showMessage('Dang it!', data.err );
    }

    // #####################################################################
    // UTILS
    // #####################################################################

    scroll_to( el, animate = true ) {
        if (el === null ) { return; }

        const destination = el.offsetTop;
        if ( isNaN(destination) ) { console.error("scroll_to: destination not valid"); return; }

        if ( animate ) {
            this.scroll.scrollTo( { top: destination, left: 0, behavior: 'smooth' });
        } else {
            this.scroll.scrollTo( 0, destination );
        }

    }

    get_formatted_msat_string( value ) {
        return `${Utils.NumToCommas((value).toFixed(3))} sat`;
    }

}

exports.Profile = Profile;
