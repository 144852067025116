/*
 * Copyright PrimeVR 2020
 * @author roskelld https://github.com/roskelld
 */
 const Utils = require("./utils.js").Utils;

class Tutorial {
    constructor( sparkshot ) {
        this.sparkshot = sparkshot;
        this.buttons = {};

        // General Site hints
        this.hints = {
            zoom_in: {
                complete:       false,
                text:           "CLICK TO ZOOM",
                id:             "ui-viewer-controls-zoom-in-hint",
                el:             null,
                position:       { x: 0, y: 0 },
                tip_position:   "top",
                delay:          5000,
                timer:          null,
                tool_tip:       null,
            },
            select_pixels: {
                complete:       false,
                text:           "SELECT EMPTY PIXELS",
                id:             "ui-canvas",
                el:             null,
                position:       { x: 0, y: 0 },
                tip_position:   "center",
                delay:          10000,
                timer:          null,
                tool_tip:       null,
            },
            add_message: {
                complete:       false,
                text:           "ADD MESSAGE",
                id:             "ui-tools-message-hint",
                el:             null,
                position:       { x: 0, y: 0 },
                tip_position:   "top",
                delay:          15000,
                timer:          null,
                tool_tip:       null,
            },
            request_invoice: {
                complete:       false,
                text:           "CHECK OUT",
                id:             "invoice-button-hint",
                el:             null,
                position:       { x: 0, y: 0 },
                tip_position:   "top",
                delay:          15000,
                timer:          null,
                tool_tip:       null,
            },
            pay_invoice: {
                complete:       false,
                text:           "NEED HELP?",
                id:             "help-button-hint",
                el:             null,
                position:       { x: 0, y: 0 },
                tip_position:   "top",
                delay:          2000,
                timer:          null,
                tool_tip:       null,
            },
            set_chat_name: {
                complete:       false,
                text:           "SET NAME TO POST MESSAGES IN LIVE CHAT",
                id:             "ui-profile-username-field",
                el:             null,
                position:       { x: 0, y: 0 },
                tip_position:   "top",
                delay:          300,
                timer:          null,
                tool_tip:       null,
            },
            show_seed_phrase: {
                complete:       false,
                text:           "CLICK TO REVEAL SECRET SEED PHRASE",
                id:             "wallet-artist-show-private-btn",
                el:             null,
                position:       { x: 0, y: 0 },
                tip_position:   "bottom",
                delay:          600,
                timer:          null,
                tool_tip:       null,
            },
        };

        this.all_hints_complete = false;
    }

    init() {
        // Check to see if accepted
        this.data = this.sparkshot.Settings.getLocalData( 'tutorial' );

        if ( this.data === null || this.data === false ) {
            this.showWelcomePopUp();
        } else {
            this.onAccept();
        }

        // Check hint save status and setup
        // Load hints based on desktop or mobile
        if ( !Utils.mobileCheck() ) {
            this.loadHintStatus();
        } else {
            return;
        }
    }

    loadHintStatus() {
        // Update local references with save data
        for (let [key, value] of Object.entries(this.hints)) {
            const res = this.sparkshot.Settings.getLocalData( `hint_${key}` );

            this.all_hints_complete = true;

            const state = Utils.convert_to_boolean( res );
            if ( state === null ) {
                value.complete = false;
                this.all_hints_complete = false;
            } else {
                value.complete = state;
            }
        }
    }

    initHints() {
        // This is for desktop hints only
        if ( Utils.mobileCheck() ) { return; }
        if ( this.all_hints_complete ) { return; }

        // Is Art Open
        if ( this.sparkshot.State.getState() === "viewer" ) {

            // Art State
            const art_complete = ( this.sparkshot.Viewer.data.height *
                                   this.sparkshot.Viewer.data.width ===
                                   this.sparkshot.Viewer._totalPurchasedPixels ) ?
                                   true : false;
            // ZOOM
            if ( !this.hints.zoom_in.complete &&
                !this.sparkshot.UI.modal.payment.instance.isOpen) {
                if ( this.sparkshot.Viewer.getZoomLevel() > 5 ) {
                    this.addHint( this.hints.zoom_in );
                }
            } else if ( !this.hints.select_pixels.complete &&
                !art_complete ) {
                this.addHint( this.hints.select_pixels );

            } else if ( !this.hints.add_message.complete &&
                this.sparkshot.Viewer._selected.length >= 5 &&
                this.sparkshot.UI.current_message_encode.byteLength === 0 &&
                !this.sparkshot.UI.modal.payment.instance.isOpen ) {

                this.addHint( this.hints.add_message );

            } else if ( !this.hints.request_invoice.complete &&
                this.sparkshot.Viewer._selected.length >= 5 ) {

                this.addHint( this.hints.request_invoice );
            } else if ( !this.hints.pay_invoice.complete &&
                this.sparkshot.UI.modal.payment.instance.isOpen ) {
                this.addHint( this.hints.pay_invoice );
            }
        }
    }

    addHint( hint ) {
        if ( hint === "undefined" ) {
            console.error("removeHint missing hint");
        }

        let ref = hint;
        if (typeof(hint) === "string") {
            ref = this.hints[hint];
        }

        if ( !ref.complete && ref.timer === null ) {
            ref.el = document.querySelector(`#${ref.id}`);
            if (ref.el === null) {
                console.error(`Cannot find element of id ${ref.id} in addHint`);
                return;
            }
            ref.position.x = window.scrollX + ref.el.getBoundingClientRect().x;
            ref.position.y = window.scrollX + ref.el.getBoundingClientRect().y;

            const options =   {
                position: ref.tip_position,
                classList: ["gradient-2", "spark-grey-text", "bold", "slow-pulse"],
                parentClassList: ["shadow-pulse"],
                coords:     { x: ref.position.x, y: ref.position.y }
            };

            // Add position override via class
            if ( ref.tip_position === "center" ) {
                options.classList.push("center");
            }

            ref.timer = setTimeout( () => {
                ref.tool_tip = this.sparkshot.UI.addStaticToolTip( ref.el, ref.text, options);
                ref.tool_tip.open();
            }, ref.delay);
        }
    }

    completeHint( hint ) {
        if ( hint === "undefined" ) {
            console.error("removeHint missing hint");
        }

        let ref = hint;
        if (typeof(hint) === "string") {
            ref = this.hints[hint];
        }

        if ( !ref.complete ) {
            ref.complete = true;
            // Save
            this.sparkshot.Settings.saveLocalData( `hint_${hint}`, true );
            this.removeHint( ref );
        }
        this.initHints();
    }

    removeAllHints() {
        for (let [key, value] of Object.entries(this.hints)) {
            this.removeHint( value );
        }
    }

    removeHint( hint ) {
        if ( hint === "undefined" ) {
            console.error("removeHint missing hint");
        }

        let ref = hint;
        if (typeof(hint) === "string") {
            ref = this.hints[hint];
        }

        if (ref.timer !== null) {
            clearTimeout( ref.timer );
            ref.timer = null;
        }

        if ( ref.el !== null ) {
            this.sparkshot.UI.removeStaticToolTip( ref.el, ["shadow-pulse"]);
            ref.tool_tip = null;
        }
    }

    closePopup() {
        if ( this.div ) {
            this.div.classList.add('hide');
        }
    }

    closeWelcomePopup() {
        this.accept();
        if ( this.box ) {
            M.Modal.getInstance( this.box ).destroy();
        }
    }

    accept() {
        this.sparkshot.Settings.saveLocalData( 'tutorial', true );
        this.closePopup();
        this.onAccept();
    }

    onAccept() {
        // If Array of functions are given, then run each of them
        if ( Array.isArray(this.accepted) ) {
            this.accepted.forEach( (obj, idx) => {
                if ( typeof obj.host[obj.call] === 'function' ) {
                    // Call the fuction from the hosting object
                    // keep intended scope
                    obj.host[obj.call]();
                } else {
                    console.error(`Privacy (Index ${idx}): Cannot call ${obj.call} not a function`);
                }
            } );
        }
    }

    showWelcomePopUp() {
        // Generate Modal
        this.div = document.createElement('div');
        this.div.id = "tutorial-accept-modal";
        this.div.classList.add("modal", "bottom", "left", "gradient-1", "z-depth-4");
        this.div.innerHTML = `
            <div class="row">
                <div class="col s12">
                    <h4 class="center">
                        <img class="" src="/img/logo-horizontal-trans@64px.png"></img>
                    </h4>
                    <h6 class="white-text center">
                        Want to know how Sparkshot works?
                    </h6>
                    <div class="center">
                        <a id="close-welcome-tutorial-btn" class="waves-effect waves-light btn grey" style="width: 40%;">NO</a>
                        <a id="open-welcome-tutorial-btn" class="waves-effect waves-light btn gradient-4" style="width: 40%;">SURE!</a>
                    </div>
                </div>
            </div>`;
        document.body.appendChild(this.div);

        this.box = document.querySelector('#tutorial-accept-modal');

        M.Modal.init( this.box, {} );
        M.Modal.getInstance( this.box ).open();
        this.buttons = {
            show: document.querySelector( '#open-welcome-tutorial-btn' ),
            close: document.querySelector( '#close-welcome-tutorial-btn' ),
        };

        this.buttons.show.addEventListener( 'click', () => {
            this.addWelcomePanelHTML();
            this.closeWelcomePopup();
        }, false );

        this.buttons.close.addEventListener( 'click', () => {
            this.closeWelcomePopup();
        }, false );
    }

    closeWelcome() {
        if ( !this.welcomeDiv ) { return; }
        this.welcomeDiv.classList.add('hide');
        this.welcomeDiv.innerHTML = '';

        this.sparkshot.Settings.saveLocalData( 'tutorial', true );
        this.onAccept();

        // Start hint system
        this.initHints();
    }

    addWelcomePanelHTML() {
        // Generate Modal
        this.welcomeDiv = document.createElement('div');
        this.welcomeDiv.id = "welcome-panel";
        this.welcomeDiv.classList.add( 'hide', 'flex-full-screen', 'gradient-1' );
        this.welcomeDiv.style.zIndex = "+1010";
        this.welcomeDiv.innerHTML = `
            <div class="slim">
                <section class="flex-row">
                    <div class="flex-column">

                        <!-- FIRST LOAD CONTENT -->
                        <section id="" class="">
                            <div class="row no-margin">
                                <div class="col s12 m3 l12"></div>
                                <div class="col s12 m6 l6 white-text ">
                                    <h4 class="spark-blue-text center" style="font-weight: 1000;">
                                        SOCIAL ART IN A WHOLE NEW WAY.
                                    </h4>

                                    <div class="row hide-on-large-only">
                                    <div class="col s12" style="position: relative; padding: 42px 0; pointer-events: none;">

                                    <div class="center" style="width: 50vw; background-image: url('/img/logo/logo@0.75x.png'); background-repeat: no-repeat; background-size: contain; box-shadow: -5px 5px #39bced80, -10px 10px #68c4e794, -15px 15px #5accf763, -20px 20px #86d4f159; background-color: #272727; padding: 0px; margin: auto;">
                                    <div class="" style="height: 50vw; width: 50vw;">

                                        <div style="padding:100% 0 0 0;position:relative;">
                                        <iframe src="https://player.vimeo.com/video/422045963?autoplay=1&muted=1&loop=1&title=0&byline=0&portrait=0&autopause=0" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay;" allowfullscreen></iframe>
                                        </div><script src="https://player.vimeo.com/api/player.js"></script>



                                    </div>
                                    </div>
                                    </div>
                                    </div>
                                    <div class="row no-margin">
                                        <div class="col s12">

                                            <p class="" style="line-height: 2.5rem; font-size: 1.1rem; padding: 0 1rem 0 1rem; letter-spacing: 0.05rem; text-align: justify;">
                                                Discover new art together with <img src="/img/sparkshot_horizontal_name_logo.svg" style="margin: 0px 3px -5px 0px; width: 6.6rem;"> where artists release their creations <span class="pixelate">shrouded</span> from view and fans <span class="gold-text">crowdfund</span> the reveal <span class="monospace text-middle text-gradient-2 no-break">pixel-by-pixel.</span>
                                            </p>
                                            <p class="" style="line-height: 2.5rem; font-size: 1.1rem; padding: 0rem 1rem 2rem 1rem; letter-spacing: 0.05rem; text-align: justify;">
                                                <span class="text-gradient-1">Everyone, get creative-</span> fans express their own talents by choosing <span class="monospace text-middle text-gradient-2">pixels</span> and adding a <span class="monospace typewriter spark-blue-text" style="color: #42ffc7;">message</span> to each <span class="long-shadow">reveal</span>, turning every art launch into a unique event led by the <span class="fanbase">fanbase</span>.
                                            </p>
                                        </div>
                                    </div>




                                    <div class="row no-margin">
                                        <div class="col s12">
                                            <p class="center">
                                                Have questions or want to learn more?
                                            </p>
                                            <p class="center">
                                             Read the FAQ, follow us on Twitter and Join our Telegram
                                            </p>
                                        </div>
                                    </div>

                                    <div class="row no-margin" style="padding: 1rem 0 2rem 0;">
                                        <div class="col s4">
                                            <div class="center">
                                                <a class="spark-blue-text" target="_blank" href="/faq">
                                                    <span class="fa-layers fa-3x">
                                                        <i class="fas fa-certificate"></i>
                                                        <span class="fa-layers-text fa-inverse spark-grey-text" data-fa-transform="shrink-11.5 rotate--30" style="font-weight:700">FAQ</span>
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="col s4">
                                            <div class="center">
                                                <a class="spark-blue-text waves-light" target="_blank" href="https://twitter.com/sparkshot_io"><i class="fab fa-twitter fa-3x"></i></a>
                                            </div>
                                        </div>
                                        <div class="col s4">
                                            <div class="center">
                                                <a class="spark-blue-text waves-light" target="_blank" href="https://t.me/sparkshot"><i class="fab fa-telegram-plane fa-3x"></i></a>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col s12">
                                            <div class="center">
                                                <a id="welcome-panel-close-btn" class="waves-effect waves-light btn" style="width: 80%; background: linear-gradient(to left, #3ef791, #0083a1);">Let's Go!</a>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <!-- Right side video -->
                                <div class="col l6 hide-on-med-and-down white-text ">
                                    <div class="row">
                                        <div class="col s12" style="position: relative; height: 565px; padding: 42px 0; pointer-events: none; margin: 3rem;">
                                            <div class="center" style="height: 100%; background-image: url('/img/logo/logo@0.75x.png'); background-repeat: no-repeat; background-size: contain; box-shadow: -5px 5px #39bced80, -10px 10px #68c4e794, -15px 15px #5accf763, -20px 20px #86d4f159; background-color: #272727; padding: 0px;">
                                                <div class="delayed-fadein-8" style="height: 100%; width: 100%;">
                                                <div style="padding:100% 0 0 0;position:relative;">
                                                <iframe src="https://player.vimeo.com/video/422045963?autoplay=1&muted=1&loop=1&title=0&byline=0&portrait=0&autopause=0" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay;" allowfullscreen></iframe>
                                                </div><script src="https://player.vimeo.com/api/player.js"></script>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col s12 m3 l12"></div>
                            </div>
                        </section>
                    </div>
                </section>
            </div>`;
        document.body.appendChild( this.welcomeDiv );

        this.buttons.closeWelcome = document.querySelector( '#welcome-panel-close-btn' );

        this.buttons.closeWelcome.addEventListener( 'click', () => {
            this.closeWelcome();
        }, false );

        window.setTimeout( () => {
            document.querySelector( '#welcome-panel' ).classList.remove( 'hide' );
        }, 20 );

        // Prevent any site hints from showing
        this.removeAllHints();
    }


}

exports.Tutorial = Tutorial;
