/*
 * Copyright PrimeVR 2020
 * @author roskelld https://github.com/roskelld
 */
const Utils = require('./utils.js').Utils;
class Beta {
    constructor() {
        this.frame = null;
    }

    init() {

        // Add Style Sheet
        this.style =    `/* ***************************************** */
                        /* BETA */
                        /* ***************************************** */

                        #beta-panel {
                            position: absolute;
                            display: block;
                            right: 30px;
                            top: 70px;
                            z-index: 1010;
                            color: white;
                        }

                        @media only screen and (max-width: 600px) {
                            #beta-panel {
                                right: -5px;
                                bottom: 75px;
                                top: inherit;
                            }
                        }

                        .beta-btn {
                            position: relative;
                            padding: 4px 0 0 0 !important;
                            cursor: pointer;
                            margin: 0.5em;
                        }

                        .beta-btn:hover {
                            -webkit-transform: scale(1.2);
                               -moz-transform: scale(1.2);
                                -ms-transform: scale(1.2);
                                 -o-transform: scale(1.2);
                                    transform: scale(1.2);
                        }

                        .beta-btn.btn-rounded {
                            border-radius: 0.5em;
                        }

                        .beta-frame {
                            position: absolute;
                            width: 60%;
                            height: 80%;
                            left: 20%;
                            top: 5%;
                            z-index: 1500;
                            border: 3px solid grey;
                            background-color: grey;
                            border-radius: 5px;
                            transition-duration: 0.3s;
                            transition: left 0.3s ease-out, top 0.3s ease-out, width 0.3s ease-out, height 0.3s ease-out;
                        }

                        .beta-frame.minimize {
                            width: 40px !important;
                            height: 20px !important;
                            top: 95% !important;
                            left: 10% !important;
                            overflow: hidden !important;
                            transition-duration: 0.3s;
                            transition: left 0.3s ease-out, top 0.3s ease-out, width 0.3s ease-out, height 0.3s ease-out;
                            border: 10px solid grey;
                        }`;

        const stylesheet = document.createElement( 'style' );
        stylesheet.type = "text/css";
        stylesheet.innerText = this.style;
        document.head.appendChild( stylesheet );

        this.icons = {
            open:   'fas fa-bug',
            close:  'fas fa-times'
        }

        const div = document.createElement( 'div' );
        div.id = 'beta-panel';
        div.innerHTML = `<div id="beta-open-btn" class="beta-btn btn-round-large red z-depth-5" title="Beta Bug Report"><div class="center"><i class=" ${this.icons.open} fa-2x"></i><i class="hide ${this.icons.close} fa-2x"></i></div></div>
                         <div id="beta-min-btn" class="beta-btn btn-rounded grey z-depth-5 hide"><div class="center"><i class="far fa-window-minimize"></i></div></div>
                         <div id="beta-max-btn" class="beta-btn btn-rounded grey z-depth-5 hide"><div class="center"><i class="fas fa-window-maximize"></i></div></div>`;

        document.body.appendChild( div );

        this.link = 'https://docs.google.com/forms/d/e/1FAIpQLSfD137o99tlc1seh502FwDqYt2Uw1Di8Y8WS6bJNISETXVflA/viewform?usp=sf_link';

        this.buttons = {
            bug:        document.querySelector( '#beta-open-btn' ),
            min:        document.querySelector( '#beta-min-btn' ),
            max:        document.querySelector( '#beta-max-btn' ),
        }

        this.open = false;
        this.minimize = false;

        this.frame = '';

        // LOGOS
        this.logos = document.querySelectorAll( '.logo' );
        this.logos.forEach( e => {
            e.src = e.src.replace( 'logo', 'beta-logo' );
        })

        // Add EventListeners
        this.buttons.bug.addEventListener( 'click', () => { this.toggleButton() }, false );
        this.buttons.min.addEventListener( 'click', () => { this.minimizePanel() }, false );
        this.buttons.max.addEventListener( 'click', () => { this.maximizePanel() }, false );

    }

    toggleButton() {
        if ( !Utils.mobileCheck() ) {
            if ( this.open ) {
                this.buttons.bug.children[0].children[1].classList.add( 'hide' );
                this.buttons.bug.children[0].children[0].classList.remove( 'hide' );

                this.buttons.min.classList.add( 'hide' );
                this.buttons.max.classList.add( 'hide' );
                this.minimize = false;

                this.closeReport();
                this.open = false;
            } else {
                this.buttons.bug.children[0].children[0].classList.add( 'hide' );
                this.buttons.bug.children[0].children[1].classList.remove( 'hide' );

                this.buttons.min.classList.remove( 'hide' );

                this.openReport();
                this.open = true;
            }
        } else {
            this.openReport();
        }
    }

    openReport() {
        console.log( 'open report' );

        if ( !Utils.mobileCheck() ) {
            const div = document.createElement( 'iframe' );
            div.src = this.link;
            div.name = 'beta_bug_frame';
            div.classList.add( 'beta-frame', 'z-depth-3' );

            document.body.appendChild( div );
            this.frame = div;
        } else {
            window.open( this.link );
        }
    }

    closeReport() {
        this.frame.parentElement.removeChild( this.frame );
    }

    minimizePanel() {
        this.frame.classList.add( 'minimize' );

        this.buttons.min.classList.add( 'hide' );
        this.buttons.max.classList.remove( 'hide' );

        this.minimize = true;
    }

    maximizePanel() {
        this.frame.classList.remove( 'minimize' );

        this.buttons.max.classList.add( 'hide' );
        this.buttons.min.classList.remove( 'hide' );

        this.minimize = false;
    }
}

exports.Beta = Beta
