/**
 * Copyright PrimeVR 2018
 * @author roskelld https://github.com/roskelld
 */

const Utils = require('./utils.js').Utils;
const InfoBox = require('./infobox.js').InfoBox;
const Sprite = require('./sprite.js').Sprite;
const PriceRadar = require('./price-radar.js').PriceRadar;
const easing = require('./import/easing.js').easing;

class Viewer {
	constructor( sparkshot ) {
        this.sparkshot = sparkshot;

		// ZOOM
		this.ZOOM_MIN = null;
		this.ZOOM_MAX = 17;

		this.MAX_ZOOM = 97;

		this.ZOOM_LEVEL_FAR = 20;
		this.ZOOM_LEVEL = [ 101, 80, 50, 40, 30, 20, 10, 5 ];

		this.ZOOM_PRICE_RADAR_LEVEL = 4;
		this.PRICE_PROBE_MIN_ZOOM = 4;
		this.MAX_PIXEL_SELECTION = 300;
		this.DRAG_TOLERANCE = 0.2;

		// CENTER CAMERA PARAMS
		this.PAN_VELOCITY = 0.03;
		this.ZOOM_VELOCITY = 0.001;

		this.CAMERA_SETTLE_TOLERANCE = 0.001;

		this.INFOBOX_SHOW_DELAY = 100;

		this.NO_USER_MSG = '[NO MESSAGE]';
		this.DEFAULT_MESSAGE = '➤';

		// INPUT STATE
		this.INPUT = {
			DEFAULT: 	0,
			ADD: 		1,
			REMOVE:		2,
			INFO:		3,
			DRAG:		4,
			OOB:		5,
			NONE:		6
		}

		this.input_state = this.INPUT.NONE;

		// GRID HIGHLIGHTER
		this.highlight = {
			default: new Image(),
			purchased: new Image(),
			picker: new Image(),
			add: new Image(),
			remove: new Image(),
			blocked: new Image()
		}

		this.highlight.default.src = "/img/sprites/highlight_default.png";
		this.highlight.default.color = "white";
		this.highlight.default.linedash = [];

		this.highlight.blocked.src = "/img/sprites/highlight_blocked.png";
		this.highlight.blocked.color = "red";
		this.highlight.blocked.linedash = [];

		this.highlight.add.src = "/img/sprites/highlight_add.png";
		this.highlight.add.color = "green";
		this.highlight.add.linedash = [];

		this.highlight.remove.src = "/img/sprites/highlight_remove.png";
		this.highlight.remove.color = "red";
		this.highlight.remove.linedash = [];

		this.highlight.purchased.src = "/img/sprites/highlight_purchased.png";
		this.highlight.purchased.color = "white";
		this.highlight.purchased.linedash = [0.225, 0.16, 0.225, 0.165, 0.225];
		// this.highlight.purchased.linedash = [0.1125, 0.08, 0.1125, 0.825, 0.1125];

		this.highlight.picker.src = "/img/sprites/highlight_probe.png";
		this.highlight.picker.color = "orange";
		this.highlight.picker.linedash = [];

		this.current_highlight = "default"

		this.purchase_group_edge_color = 'rgba(207, 113, 46, 1)';
		// this.purchase_group_body_color = 'rgba(0, 230, 118, 1)';
		this.purchase_group_body_color = "#e8df37";
		this.purchase_group_body_bg_color = 'rgba(0, 0, 0, 1)';
		this.purchase_group_body_bg_zoom_1_color = "#999327";
		this.purchase_group_body_bg_zoom_2_color = "#ccc432";

		this.selected_pixel_color = 'rgba(0, 230, 118, 1)';

		// GEM SPRITES
		this.sprites = {
			pixels: new Image(),
			frame: 0,
			max_frame: 4,
		}

		// PIXEL MARKERS
		this.sprites.pixels.src = "/img/sprites/gem_green.png";

		this.anim_frame_index = 0;
		this.anim_tick_count = 0;
		this.anim_tick_per_frame = 1;

		// View Mode
		this.modes = [
			'PURCHASE',
			'PROBE'
		];

		this.pause_ui = true;

		// Canvas
		// Rendered Canvas used for transform and interaction
		this._canvas = document.createElement('canvas');

		this._canvas.oncontextmenu = () => false; // Block context menu
		this._ctx = this._canvas.getContext('2d');
		this.dirty_image = true;
		this.smoothImagePixels( false );

		// UI
		// Drawn over the art layer
		// Moves with  image
		this._ui_canvas = document.createElement('canvas');
		this._ui_canvas.id = 'ui-canvas';
		this._ui_ctx = this._ui_canvas.getContext( '2d' );
		this.dirty_ui = true;
		this.smoothUIPixels( false );

		// HUD
		// Drawn over the canvas screen
		// Does not move with image
		this._hud_canvas = document.createElement('canvas');
		this._hud_canvas.id = 'hud-canvas';
		this._hud_canvas.oncontextmenu = () => false; // Block context menu
		this._hud_ctx = this._hud_canvas.getContext( '2d' );
		this.smoothHUDPixels( false );

		// PRICE RADAR
		this.price_radar = new PriceRadar( this._ui_ctx );

		// Canvas Transform Parameters
		this._scaleFactor = 1.1;
		this._zoomMax = 90;
		this._zoomMin = 0.5;
		this._x = this._canvas.width / 2,
		this._y = this._canvas.height / 2;
		this._lastX = this._canvas.width / 2,
		this._lastY = this._canvas.height / 2;
		this.zoom_factor = null;

		// Selection Color
		this.select_color = {
			0: 		"rgba( 5, 181, 118, 1 )",
			1: 		"rgba( 5, 120, 181, 1 )",
			2: 		"rgba( 181,  5, 49, 1 )",
			3: 		"rgba( 235, 169, 8, 1 )",
		}

		// MOUSE ICONS
		this.mouse_icons = {
			current: 'default',
			previous: 'default',
			spawn_complete: false,
			sprites: {
				default: 	 new Sprite({
					sheet:  "/img/sprites/mouse_cursor_default.png",
					frame_width: 32,
					frame_height: 32,
					frame_rate: 128,
					ctx: this._hud_ctx,
					scale: 32,
					animations: {
						loop: [ 0 ],
						spawn: [ 0 ],
						despawn: [ 0 ]
					}
				}),
				move: 	 new Sprite({
					sheet:  "/img/sprites/mouse_cursor_move.png",
					frame_width: 32,
					frame_height: 32,
					frame_rate: 128,
					ctx: this._hud_ctx,
					scale: 32,
					animations: {
						loop: [ 6 ],
						spawn: [ 0, 0, 1, 1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 6 ],
						despawn: [ 6, 5, 4, 3, 2, 1, 0 ]
					}
				}),
				purchased: 	 new Sprite({
					sheet:  "/img/sprites/mouse_cursor_info.png",
					frame_width: 32,
					frame_height: 32,
					frame_rate: 128,
					ctx: this._hud_ctx,
					scale: 32,
					animations: {
						loop: [ 0 ],
						spawn: [ 0 ],
						despawn: [ 0 ]
					}
				}),
				add: 	 new Sprite({
					sheet:  "/img/sprites/mouse_cursor_add.png",
					frame_width: 32,
					frame_height: 32,
					frame_rate: 128,
					ctx: this._hud_ctx,
					scale: 32,
					animations: {
						loop: [ 0 ],
						spawn: [ 0 ],
						despawn: [ 0 ]
					}
				}),
				remove: 	 new Sprite({
					sheet:  "/img/sprites/mouse_cursor_remove.png",
					frame_width: 32,
					frame_height: 32,
					frame_rate: 128,
					ctx: this._hud_ctx,
					scale: 32,
					animations: {
						loop: [ 0 ],
						spawn: [ 0 ],
						despawn: [ 0 ]
					}
				}),
				blocked: 	 new Sprite({
					sheet:  "/img/sprites/mouse_cursor_blocked.png",
					frame_width: 32,
					frame_height: 32,
					frame_rate: 128,
					ctx: this._hud_ctx,
					scale: 32,
					animations: {
						loop: [ 0 ],
						spawn: [ 0 ],
						despawn: [ 0 ]
					}
				}),
			}
		}
		this.mouse_icons.current = "blank";

		// Art Data
		this._imageData = null;

		this._artX = 0;
		this._artY = 0;
		this.data = null;

		// Art Canvas
		// Used to hold the art pixel data
		this._artcanvas = document.createElement('canvas');
		this._artctx = this._artcanvas.getContext('2d');
		this._artctx.imageSmoothingEnabled = false;
		this._artctx.webkitImageSmoothingEnabled = false;

		// UI
		this._heightPad			 = document.getElementsByTagName('header')[0];
		this._footerPad			 = document.getElementsByTagName('footer')[0];
		this._status			 = document.querySelector('#ui-status');
		this.infobox			 = new InfoBox( sparkshot );

		// Canvas boundary points for the art position
		this.ART_BOUNDS_AMOUNT = 10;
		// this.ART_BOUNDS = {
		// 	top_left: { x: this._canvas.width / this.ART_BOUNDS_AMOUNT, y: this._canvas.height / this.ART_BOUNDS_AMOUNT },
		// 	top_right: { x: this._canvas.width - ( this._canvas.width / this.ART_BOUNDS_AMOUNT ), y: this._canvas.height / this.ART_BOUNDS_AMOUNT },
		// 	bottom_left: { x: this._canvas.width / this.ART_BOUNDS_AMOUNT, y: this._canvas.height - ( this._canvas.height / this.ART_BOUNDS_AMOUNT ) },
		// 	bottom_right: { x: this._canvas.width - ( this._canvas.width / this.ART_BOUNDS_AMOUNT ), y: this._canvas.height - ( this._canvas.height / this.ART_BOUNDS_AMOUNT ) },
		// };

		this.ART_BOUNDS = {
			top_left: { x: 50, y: 50 },
			top_right: { x: 720, y: 50 },
			bottom_left: { x: 50, y: 720 },
			bottom_right: { x: 720, y: 720 },
		};

		this.mousePosition = {
			x: 0,
			y: 0,
		};

		this._cursor_on_canvas = false;

		// Total Pixels Bought
		this._totalPurchasedPixels = 0;

		// Last Active Pixel location
		this._coord = { x: -1, y: -1 };

		// Highlight
		this.clearHightlight = false;
		this._lastHover = { x: -1, y: -1 };

		// Selected Pixels
		// this._lockSelection = false;
		this._selected = [];
		this._price = 0;
		this._message_price = 0;
		this._deleteQueue = [];
		this._lastDeleted = null;
		this._floatTextQueue = [];

		// DRAG ADD / REMOVE FLOAT TEXT PRICE BUFFER
		this._floatPrice = 0;

		// Highlight Group
		this._highlightEdgeGroup = [];

		// Locked Pixels
		this._locked = [];

		// Mouse click and drag
		this._dragStart = false;

		// Event Inputs
		this._canvas.addEventListener('contextmenu', e => e.preventDefault(), false );

		// SETUP DESKTOP LISTENERS
		if ( !Utils.mobileCheck() ) {
			this._canvas.addEventListener('mousedown', e => { this.inputDown(e); }, { passive: true });
			this._canvas.addEventListener('mouseup', e => { this.inputUp(e); }, { passive: true });
			this._canvas.addEventListener('mousemove', e => { e.preventDefault(); this.inputMove(e); }, false );
			this._canvas.addEventListener('DOMMouseScroll', e => { this.inputScroll(e); }, false );
			this._canvas.addEventListener('mousewheel', e => { this.inputScroll(e); }, false );
			this._canvas.addEventListener('mouseenter', e => {
				// When cursor enters the canvas clear the browser cursor
				this._cursor_on_canvas = true;
				document.body.style.cursor = 'none';
				if ( e.buttons === 1 ) {
					this.input_state = this.INPUT.DEFAULT;
					return;
				}

				if ( e.buttons === 2 ) {
					this.input_state = this.INPUT.DRAG;
					this._dragStart = this._ctx.transformedPoint( this._lastX, this._lastY );
					return;
				}

			}, false );
			this._canvas.addEventListener('mouseout', () => {
				// When cursor leaves the canvas reset the setup
				document.body.style.cursor = 'default';
				this.input_state = this.INPUT.NONE;
				this.infobox.clear();
				this.clearShowInfoBoxTimer();
				this.setLastCoord({ x: -1, y: -1 });
				this.showDragSelectPriceFloatText();
			}, false );
			document.addEventListener( 'mousemove', e => { this.lastMousePosition(e); }, false );
		}

		this.background = {
			selected: null,
			source: [
				'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAYAAABytg0kAAAAGElEQVQImQXBAQEAAACCoP6v9QHBoLIKHIwrD5djvKmtAAAAAElFTkSuQmCC',
				'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAYAAABytg0kAAAAGklEQVQImWPw9/f/7+Dg8J/BwcHhv7+//38APcsHV/VMDx4AAAAASUVORK5CYII=',
				'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAI0lEQVQYV2NUVFT8z8HBwfDjxw8GEM2oqan5H8YB0YwEVQAA+EgXvdcoyScAAAAASUVORK5CYII=',
				'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAYAAABytg0kAAAAGUlEQVQImQXBAQEAAACCIF74f2kBrJpq2AEjEwSHaxF3tgAAAABJRU5ErkJggg==',
				'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAYAAABytg0kAAAAGklEQVQImWP4UKf+vyov9T9DVV7q/w916v8BVnYJwU4lg9kAAAAASUVORK5CYII=',
				'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAYAAABytg0kAAAAG0lEQVQImWPYHB39f6Gs7H+GhbKy/zdHR/8HAEq4CIXpVJAHAAAAAElFTkSuQmCC',
				'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVQImWMQdAr5DwACtgGnAVZaJgAAAABJRU5ErkJggg==',
				// 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAI0lEQVQYV2NsFRb+/+HLFwYBHh4GEM1Yys7+H8YB0YwEVQAAdScYXaQmrEgAAAAASUVORK5CYII=',
				// 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAASUlEQVQYVxXKOwrAIBAFwPdEs0iwCdhYeBaxz/0PEtL42Q2ZeljrbXsPkCfMXjDnZt4nrPVA5AJLaUYKVA84N/7RLQTFnECMCR8d5xUOm+aG5gAAAABJRU5ErkJggg==',
				'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAI0lEQVQYV2OUlnb///nzRwZeXn4GEM3Ix2fxH8YB0YwEVQAAVT0Xo5Lhx04AAAAASUVORK5CYII=',
				'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAI0lEQVQYV2MMUhf4/+T3PwYZViYGEM1opsT3H8YB0YwEVQAAYKEX30sMEbMAAAAASUVORK5CYII=',
				'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAYAAABytg0kAAAAG0lEQVQImWPIyVr9v7lpzn+G5qY5/3OyVv8HAFjKCkGO7n2DAAAAAElFTkSuQmCC',
			],
			pattern: [],
		}

		// Animation
		this._totalFrames = 600;
		this._lastFrame = 0;
		this.stopAnimation = false;
		this.isCenterOnCanvasAnimPlaying = false;

		this.step = 0;
		this.update_tick = 0;
		this.last_update_time = null;

		this.cameraTargetX = 0;
		this.cameraTargetY = 0;
		this.cameraTargetZ = 0;

		this.initBackgroundButtons();
	}

	close() {
		// Clear all settings
		this.clearShowInfoBoxTimer();
		this.infobox.clear();
		this._dragStart = false;
		this.setLastCoord( { x: -1, y: -1 } );

		// Last Active Pixel location
		this._coord = { x: -1, y: -1 };

		// Highlight
		this.clearHightlight = false;

		this.clearSelection();
		this.sparkshot.UI.clearUserMessage();

		// Highlight Group
		this._highlightGroup = [];
		this._highlightEdgeGroup = [];

		// Locked Pixels
		this._locked = [];
		// this._lockSelection = false;

		// Mouse click and drag
		// this._dragged = false;
		this._dragStart = false;

		this._selected = [];
		this._lastHover = { x: -1, y: -1 };
		this._price = 0;
		this._message_price = 0;
		this._deleteQueue = [];
		this._lastDeleted = null;
		this._floatTextQueue = [];

		// TURN OFF RENDERING
		this.dirty_ui = false;

		// Remove the UI Elements
		this.sparkshot.UI.resetArtMetaPanel();

		this.sparkshot.Chat.clear();

		this.setPriceProbeEnabled( false );

		this.stopAnimation = true;
		this.isCenterOnCanvasAnimPlaying = false;

		// CLOSE MOBILE
		this.sparkshot.MobileViewer.close();

		// Close pop out menu elements if open
		M.FloatingActionButton.getInstance(document.querySelector('#ui-viewer-controls-background-color')).close()

		document.body.style.cursor = 'default';

		document.querySelector( '#app' ).classList.remove( "no-scroller" );
	}

	// #####################################################################
	// Input Data Handling
	// #####################################################################

	// Process the full art entry
	processContent( entry ) {
		this.Mode = 'PURCHASE';
		this.sparkshot.UI.bottom_nav.panel.classList.remove('hide');
		// console.log( 'processContent');

		// Grab the total pixels from the lobby art data
		this._totalPurchasedPixels = entry.paid_px_count;
		const totalPixels = entry.height * entry.width;

		// Set Meta Data
		this.sparkshot.UI.setArtMetaData({
									title: 			entry.title,
									description: 	entry.description,
									views: 			0,
									sold:			this._totalPurchasedPixels,
									total:			totalPixels,
									artist:			entry.btc_addr,
									merkle_root:	entry.merkle_root,
									signatureFile: 	`${entry.signed_message_rel}`,
									tags:			entry.tags
								});
		// this.sparkshot.UI.revealArtMetaPanel( 2 );
		this.sparkshot.UI.toggleArtMetaPanel();
		this.sparkshot.UI.setHoverHide( true );
		this.sparkshot.UI.openArtMetaPanel();
		this.sparkshot.UI.closeSortPanel();

		// Add Chat
		this.createEntry( entry );
		this.sparkshot.Chat.addReceipts( entry );
		this.sparkshot.Chat.scrollToBottom();

		// Set Cursor
		this.current_highlight = "default";

		// CHECK IF ART IS NOW COMPLETE
		if ( false ) { this.sparkshot.UI.showArtDownloadButton(); }

	}

	// Process a partial update to current art entry
	processUpdate( data ) {
		// console.log( 'PROCESS UPDATE');
		// console.log( data );
		// Check update is for current art
		if ( data.art_id !== this.data.art_id ) { return;}

		this.sparkshot.WS._updateCount--;

		// ADD DATA TO LOCAL STORAGE
		// ADD REPLAY DATA
		this.data.replays.push( {
			timestamp:  	data.timestamp,
			coords:    		data.coords,
		} );

		// ADD STRING IF THERE IS ONE
		if ( data.user_string !== '' ) {
			this.data.user_strings.push( {
				replay_index:    this.data.replays.length -1,
				is_coord:        true,
				x:               ( data.user_string_coord !== null ) ? data.user_string_coord.x : -1,
				y:               ( data.user_string_coord !== null ) ? data.user_string_coord.y : -1,
				user_string:     ( data.user_string !== null ) ? data.user_string : this.DEFAULT_MESSAGE,
				user_string_discrete: ( data.user_string_discrete !== null ) ? data.user_string_discrete : -1,
			});
		}

		// Update number of pixels purchased
		const num_entries = data.coords.length;
		this._totalPurchasedPixels += num_entries;
		this.sparkshot.UI.setArtMetaSoldCount( this._totalPurchasedPixels );
		if ( ( this.data.height * this.data.width ) === this._totalPurchasedPixels ) {
			this.sparkshot.UI.showArtDownloadButton();
			Utils.flashElement( this.sparkshot.UI.modal.art_info.buttons.download, 'white' );
		}

		// Update each pixel entry
		for (let i = 0; i < num_entries; i++) {
			// Data from Server using Server Coordinates
			const pixel = this.getLocalPixelInfo( data.coords[i].x, data.coords[i].y, false );
			this.removePixelFromSelection( pixel.canvas.x, pixel.canvas.y );
			this.unlockPixels( pixel.canvas.x, pixel.canvas.y );

			const update = {
				bought: 			true,
				overpay: 			data.overpays[i],
				has_user_string:	( data.user_string === '' ) ? false : true,
				index:				( data.user_string === '' ) ? this.data.replays.length - 1 : this.data.user_strings.length - 1,
				color: 				data.colors[i],
			}

			this.setLocalPixelInfo( pixel.data.x, pixel.data.y, update );
		}

		// ADD TO CHAT MESSAGE LOG
		this.sparkshot.Chat.addReceiptByIndex( this.data.replays.length - 1 );

		Utils.UpdateImageData( data, this._artcanvas );

		// Set the render updates to dirty to force rerendering of the art
		this.dirty_ui = true;
		this.dirty_image = true;

		// CHECK IF ART IS NOW COMPLETE
		if ( false ) { this.sparkshot.UI.showArtDownloadButton(); }
	}

	// Clicking on Entry requests art to open
	openEntry(entry) {
		this.sparkshot.WS.request_goto_art(entry);
	}

	// Generate DOM element for entry
	createEntry( entry ) {
		this.sparkshot.Chat.enableChat();

		this.setCanvasSize();

		this.sparkshot.UI.main.classList.add( "no-scroller" );


		// Store data
		this.data = entry;

		// Clear Current Main Window
		this.sparkshot.UI.main.innerHTML = '';
		this.sparkshot.UI.main.appendChild( this._canvas );
		this.sparkshot.UI.main.appendChild( this._ui_canvas );
		this.sparkshot.UI.main.appendChild( this._hud_canvas );

		// Generate Art
		this._imageData = entry.image_data;

		// Convert Data to Image
		this._artcanvas.width = this._imageData.width;
		this._artcanvas.height = this._imageData.height;

		this._artctx.putImageData( this._imageData, 0, 0 );

		// Start Position
		this._trackTransforms( this._ctx );

		this.setDefaultCanvasZoom();

		// Initial Draw
		this.dirty_image = true;
		this.smoothImagePixels( false );
		this.smoothUIPixels( false );
		this.smoothHUDPixels( false );

		// Launch Mobile UI
		if ( Utils.mobileCheck() ) {
			this.sparkshot.MobileViewer.init();
		} else {
			this.sparkshot.Chat.openChat();
		}

		this.updateCanvasSize();
	}

	// #####################################################################
	// Canvas
	// #####################################################################

	drawImage() {
		if ( !this.dirty_image ) { return; }
		// Clear the entire canvas
		const p1 = this._ctx.transformedPoint( 0, 0 );
		const p2 = this._ctx.transformedPoint( this._canvas.width, this._canvas.height );
		this._ctx.clearRect( p1.x, p1.y, p2.x - p1.x, p2.y - p1.y );

		// Draw Background
		this.drawBackground();

		// Draw current art state
		this._ctx.drawImage( this._artcanvas, 0, 0 );

		this.dirty_image = false;
	}

	smoothImagePixels( state = false ) {
		this.dirty_image = true;
		this._ctx.imageSmoothingEnabled = state;
		this._ctx.webkitImageSmoothingEnabled = state;
		this._ctx.imageSmoothingQuality = "high";
	}

	smoothUIPixels( state = false ) {
		this.dirty_ui = true;
		this._ui_ctx.imageSmoothingEnabled = state;
		this._ui_ctx.webkitImageSmoothingEnabled = state;
	}

	smoothHUDPixels( state = false ) {
		this._hud_ctx.imageSmoothingEnabled = state;
		this._hud_ctx.webkitImageSmoothingEnabled = state;
	}

	setCanvasSize() {
		this._ctx.canvas.width = this._ui_ctx.canvas.width = this._hud_ctx.canvas.width = this.sparkshot.UI.main.clientWidth;
		this._ctx.canvas.height = this._ui_ctx.canvas.height = this._hud_ctx.canvas.height = window.innerHeight - this._heightPad.clientHeight - this._footerPad.clientHeight;
	}

	updateCanvasSize() {
		if ( this.data === null ) { return; }

		// Update Canvas Size
		this.setCanvasSize();

		// Re-track transforms
		this._trackTransforms( this._ctx );

		// Set The Zoom State
		this.setDefaultCanvasZoom();

		// Cancel any ongoing animations
		this.cancelAnimation();
	}

	// Reset to screen defaults
	resetToCanvasDefaults() {
		this.cancelAnimation();
		this.updateCanvasSize();
	}

	_trackTransforms(ctx) {
		const svg = document.createElementNS("http://www.w3.org/2000/svg", 'svg');
		let xform = svg.createSVGMatrix();
		ctx.getTransform = function () { return xform; };

		const savedTransforms = [];
		const save = ctx.save;
		ctx.save = function () {
			savedTransforms.push(xform.translate(0, 0));
			return save.call(ctx);
		};

		const restore = ctx.restore;
		ctx.restore = function () {
			xform = savedTransforms.pop();
			return restore.call(ctx);
		};

		const scale = ctx.scale;
		ctx.scale = function (sx, sy) {
			xform = xform.scaleNonUniform(sx, sy);
			return scale.call(ctx, sx, sy);
		};

		const rotate = ctx.rotate;
		ctx.rotate = function (radians) {
			xform = xform.rotate(radians * 180 / Math.PI);
			return rotate.call(ctx, radians);
		};

		const translate = ctx.translate;
		ctx.translate = function (dx, dy) {
			xform = xform.translate(dx, dy);
			return translate.call(ctx, dx, dy);
		};

		const transform = ctx.transform;
		ctx.transform = function (a, b, c, d, e, f) {
			const m2 = svg.createSVGMatrix();
			m2.a = a;
			m2.b = b;
			m2.c = c;
			m2.d = d;
			m2.e = e;
			m2.f = f;
			// xform = xform.multiply(m2);
			return transform.call(ctx, a, b, c, d, e, f);
		};

		const setTransform = ctx.setTransform;
		ctx.setTransform = function (a, b, c, d, e, f) {
			xform.a = a;
			xform.b = b;
			xform.c = c;
			xform.d = d;
			xform.e = e;
			xform.f = f;
			return setTransform.call(ctx, a, b, c, d, e, f);
		};

		const pt = svg.createSVGPoint();
		ctx.transformedPoint = function (x, y) {
			pt.x = x;
			pt.y = y;
			return pt.matrixTransform(xform.inverse());
		}
	}

	// #####################################################################
	// Settings
	// #####################################################################

	initBackgroundButtons() {
		// Add Main Button
		const div = document.querySelector('#ui-viewer-controls-background-color');

		// Add List
		const ul = document.createElement('ul');

		// Configure options
		const floatingActionOptions = { direction: 'right', hoverEnabled: false, };

		let imageLoadProm = this.background.source.map( Utils.imageLoader );
		Promise.all( imageLoadProm )
			.then( images => {
				images.forEach( ( img, index ) => {
					const ref = this._ctx.createPattern( img, 'repeat' );
					this.background.pattern.push( ref );

					// Create The Button
					const li = document.createElement( 'li' );
					const a = document.createElement( 'a' );
					let current_color;
					let sparkshot = this.sparkshot;
					a.classList.add( 'btn-floating' );
					a.setAttribute( 'style', `background-image: url(${this.background.source[index]}); background-repeat: repeat; background-size: 5px; border: 4px #424242 solid !important;`);

					a.addEventListener( "click", () => { this.setBackground( index ); this.drawImage(); }, false );
					a.addEventListener( "mouseenter", () => {
						current_color = sparkshot.Viewer.background.index;
						this.background.selected = this.background.pattern[index];
						this.dirty_image = true;
						this.drawImage(); },
						false );
					// a.addEventListener( "mouseleave", () => { this.setBackground( current_color ); this.drawImage(); }, false );
					li.appendChild( a );
					ul.appendChild( li );
				} );

			})
			.then( () => {
				div.appendChild( ul );
				M.FloatingActionButton.init(
					div,
					floatingActionOptions
				);
				this.setBackground(2);
			})
			.catch( err => {
				// console.error('Failed to load images', err );
			});
	}

	setBackground( color ) {
		this.background.index = color;
		this.background.selected = this.background.pattern[color];
		this.dirty_image = true;
	}

	getBackground() {
		// if ( this.background.selected === null ) this.background.selected = this.background.grey;
		return this.background.selected;
	}

	drawBackground() {
		if ( this.background.selected === null ) { return; }

		this._ctx.mozImageSmoothingEnabled    = false;
		this._ctx.oImageSmoothingEnabled      = false;
		this._ctx.webkitImageSmoothingEnabled = false;
		this._ctx.msImageSmoothingEnabled     = false;
		this._ctx.imageSmoothingEnabled       = false;


		this._ctx.fillStyle = this.getBackground();
		this._ctx.fillRect( 0, 0, this._artcanvas.width, this._artcanvas.height );
	}

	imageLoader( url ) {
		return new Promise( (resolve, reject) => {
			const img = new Image();
			img.src = url;
			img.onload = () => {
				resolve(img);
			};
			img.onerror = reject;
		});
	}

	set Mode( mode ) {
		if ( !this.modes.includes( mode ) ) {
			console.error( 'BAD MODE' );
			return;
		}

		this.mode = mode;
		if ( !this.pause_ui ) {
			this.updateHighlighter();
		}

		if ( this.mode === 'PROBE' );
			this.clearHightlightGroup();

		return this.mode;
	}

	get Mode() {
		return this.mode;
	}

	// #####################################################################
	// UI
	// #####################################################################

	// Update loop
	updateUI(dt) {
		// Don't run update if paused
		// if ( !this.canPauseHUD() ) { return; }

		// Update Floating Text
		if ( this._floatTextQueue.length > 0 ) { this.updateTextFloat(dt); }

		// Check and set cursor graphic
		this.updateMouseCursor();

		// Update
		this.price_radar.update( dt );

		// Zoom State
		if ( this.isZoomAnimPlaying ) {
			// Stop playing animation
			// Maybe there needs to be a stop all animations from playing
			this.isCenterOnCanvasAnimPlaying = false;
			this._ctx.getTransform().translate( this.data.width / 2, this.data.height / 2 );
			this.zoomCanvas( this.zoomDir );
		} else if ( this.isCenterOnCanvasAnimPlaying ) {
			const finished = this.centerCanvasOn(this.cameraTargetX, this.cameraTargetY, this.cameraTargetWidth, this.cameraTargetHeight );
			if ( finished && this.isCenterOnCanvasAnimPlaying ) {
				this.isCenterOnCanvasAnimPlaying = false;
			}
		}
	}

	// Render UI
	drawUI() {
		if ( !this.dirty_ui ) { return; }
		// this.dirty_ui = false;
		this.clearUI();
		this.clearHUD();

		// SET SMOOTHING
		// if ( this.getZoom() < 1.5 || this.isCenterOnCanvasAnimPlaying || this.isZoomAnimPlaying ) {
		// 	this.smoothHUDPixels( true );
		// 	this.smoothUIPixels( true );
		// } else {
		// 	this.smoothUIPixels( false );
		// 	this.smoothHUDPixels( false );
		// }

		// Draw Price Radar
		if ( this.price_radar.isEnabled() ) {
			this.price_radar.draw( this.getLastCellCoord().x, this.getLastCellCoord().y, this.data );
		}

		switch ( this.Mode ) {
			case 'PURCHASE':
				// Draw upper layer
				this.drawSelectedPixels();
				this.drawLocked();

				// DRAW SELECTED GROUP
				this.drawPurchaseGroupHighlight();

				// DRAW GRID HIGHLIGHT
				this.drawHighlight();
				break;
			case 'PROBE':
				// DRAW SELECTED GROUP
				this.drawPurchaseGroupHighlight();

				// DRAW GRID HIGHLIGHT
				this.drawHighlight();
				break;
			default:
				// Draw upper layer
				this.drawSelectedPixels();
				this.drawLocked();
				this.drawPurchaseGroupHighlight();
		}


		// Draw the on screen interaction indicator mouse or reticle
		if ( Utils.mobileCheck() ) {
			this.sparkshot.MobileViewer.drawReticule();
		} else {
			// Draw the mouse cursor icon
			if ( this.mouse_icons.current !== "blank" ) {
				this.drawMouseCursor();
			}
		}

		// Draw floating text panels
		if ( this._floatTextQueue.length > 0 ) { this.drawTextFloat(); }
	}

	clearUI() {
		// CLEAR FRAME
		const p1 = this._ctx.transformedPoint( 0, 0 );
		const p2 = this._ctx.transformedPoint( this._canvas.width, this._canvas.height );
		this._ui_ctx.clearRect( p1.x, p1.y, p2.x - p1.x, p2.y - p1.y );
	}

	clearHUD() {
		this._hud_ctx.clearRect( 0, 0, this._hud_canvas.width, this._hud_canvas.height );
	}

	updateMouseCursor() {
		if ( Utils.mobileCheck() ) { return; }
		if ( this.input_state === this.INPUT.DRAG ) {
			this.mouse_icons.current = "move";
			this.price_radar.pause();
			return;
		}

		if ( this.input_state === this.INPUT.DEFAULT ) {
			this.mouse_icons.current = "default";
			this.price_radar.unpause();
			return;
		}

		if ( this.input_state === this.INPUT.OOB ) {
			this.mouse_icons.current = "blocked";
			return;
		}

		if ( this.input_state === this.INPUT.NONE ) {
			this.mouse_icons.current = "blank";
			return;
		}

		switch ( this.Mode ) {
			case 'PURCHASE':
				if ( this.input_state === this.INPUT.ADD ) {
					this.mouse_icons.current = "add";
					this.price_radar.pause();
					return;
				}

				if ( this.input_state === this.INPUT.REMOVE ) {
					this.mouse_icons.current = "remove";
					this.price_radar.pause();
					return;
				}

				if ( this.input_state === this.INPUT.INFO ) {
					this.mouse_icons.current = "purchased";
					return;
				}

				if ( this._selected.length >= this.MAX_PIXEL_SELECTION ) {
					const pixel = this.getLocalPixelInfo( this.getLastCellCoord().x, this.getLastCellCoord().y );
					if ( this.isSelected( pixel.canvas.x, pixel.canvas.y ) ) {
						this.mouse_icons.current = "remove";
						return;
					} else {
						this.mouse_icons.current = "blocked";
						return;
					}
				}

				this.mouse_icons.current = "default";
				this.price_radar.unpause();
				break;
			case 'PROBE':
				this.mouse_icons.current = "default";
				break;
			default:
				this.mouse_icons.current = "default";
		}
	}

	// Draw the mouse cursor graphic
	drawMouseCursor() {
		if ( Utils.mobileCheck() ) { return; }
		if ( this.sparkshot.State.getState() !== 'viewer' ) { return; }

		// When anim changes reset spawn_complete state
		if ( this._previous_mouse_cursor !== this.mouse_icons.current ) {
			this._previous_mouse_cursor = this.mouse_icons.current;
			this.mouse_icons.spawn_complete = false;
		}

		const mouse = this.lastMousePosition();

		if ( this.mouse_icons.spawn_complete ) {
			this.mouse_icons.sprites[this.mouse_icons.current].animation.loop.play(
				mouse.x - this._hud_canvas.offsetParent.offsetLeft - 16,
				mouse.y - this._hud_canvas.offsetParent.offsetTop - 16,
				);
		} else {
			this.mouse_icons.sprites[this.mouse_icons.current].animation.spawn.play(
				mouse.x - this._hud_canvas.offsetParent.offsetLeft - 16,
				mouse.y - this._hud_canvas.offsetParent.offsetTop - 16,
				);
			// Update the frame and test if complete;
			this.mouse_icons.spawn_complete = this.mouse_icons.sprites[this.mouse_icons.current].animation.spawn.update();
		}
	}

	setClearHighlight() {
		this.clearHightlight = true;
	}

	getClearHighlight() {
		return this.clearHightlight;
	}

	// #####################################################################
	// Track Pixel Coordinates
	// #####################################################################

	getLocalPixelInfo( x, y, canvasCoord = true ) {
		// If input is canvas based coordinates convert them to data coordinates
		const coords = ( canvasCoord ) ? this.convertCoordBetweenDataAndCanvas( x, y ) : { x: x, y: y };
		if ( coords.x === -1 || coords.y === -1 ) {
			return {
				oob:			true,
				purchased: 		true,
				locked: 		false,
				satoshi_price: 	-1,
				color:			'',
				index: 			-1,
				chat_index:		-1,
				user_string_index: -1,
				canvas: {
					x:			-1,
					y:			-1,
				},
				data: {
					x:			-1,
					y:			-1,
				}
			};
		};
		let data = {};
		if ( coords.x < 0 || coords.y < 0 || coords.x >= this.data.state.length || coords.y >= this.data.state[0].length ) {
			data.oob = true;
		} else {
			data = this.data.state[coords.x][coords.y];
		}



		if ( data.bought === false ) { data.has_user_string = false; }

		// Find refence for user string
		// const user_string_data = this.data.user_strings.find( e => e.replay_index === data.index );
		// console.log( data.bought, coords.x, coords.y, data.has_user_string );
		// console.log( user_string_data );
		// IF THERE'S A USER STRING THEN THE INDEX REFERS TO THE user_strings_data array, if not it's the replay_data array.
		// To set the replay you can use user_strings_data[x].replay_index
		// console.log( data );
		const resp =  {
			oob:					( data.oob ) ? true : false,
			purchased:				data.purchased || data.bought,
			invoiced:				data.invoiced,
			is_coord:				( data.has_user_string === true ) ? this.data.user_strings[data.index].is_coord : -1,
			has_user_string:		data.has_user_string,
			user_string:			( data.has_user_string ) ? this.data.user_strings[data.index].user_string : this.NO_USER_MSG,
			target_short:			( data.has_user_string && !this.data.user_strings[data.index].is_coord ) ? this.data.user_strings[data.index].target_short : -1,
			target_long:			( data.has_user_string && !this.data.user_strings[data.index].is_coord ) ? this.data.user_strings[data.index].target_long : -1,
			replay_index:			( data.has_user_string ) ? this.data.user_strings[data.index].replay_index : data.index,
			mod_setting:			( data.has_user_string ) ? this.data.user_strings[data.index].mod_setting : "NONE",
			right_to_left:			( data.has_user_string ) ? this.data.user_strings[data.index].right_to_left : false,
			// user_string_index:		( data.has_user_string ) ? dat
			// user_string_discrete: 	data.user_string_discrete,
			overpay:				data.overpay,
			locked: 				this.isPixelLocked( x, y ),
			satoshi_price: 			data.msatoshis,
			timestamp:				( data.bought ) ? ( data.has_user_string ) ? this.data.replays[this.data.user_strings[data.index].replay_index].timestamp : this.data.replays[data.index].timestamp : -1,
			color:					data.color,
			index: 					data.index,
			canvas: {
				x:					coords.x,
				y:					( canvasCoord ) ? y : this.convertCoordBetweenDataAndCanvas( x, y ).y,
			},
			data: {
				x:					coords.x,
				y:					( canvasCoord ) ? this.convertCoordBetweenDataAndCanvas( x, y ).y : y,
			}
		};
		// console.log( resp );
		return resp;
	}

	setLocalPixelInfo( x, y, data ) {
		// console.log(`setLocalPixelInfo ${x} ${y}`);
		// console.log( data );
		this.data.state[x][y] = data;
	}

	getRemotePixelInfo( x, y ) {
		// console.log(`getRemotePixelInfo ${x} ${y}`);
		// Send WebSocket Request with pixel coordinates
		const pixel = this.convertSelectionToString( x, y );
		this.sparkshot.WS.request_pixel_info( pixel );
	}

	setLastCoord( coord = null ) {
		coord = ( coord === null ) ? this._ctx.transformedPoint(this._lastX, this._lastY) : coord;
		this._coord.x = coord.x;
		this._coord.y = coord.y;

		this._coord.x = Math.floor( this._coord.x );
		this._coord.y = Math.floor( this._coord.y );

		if ( this._coord.x < 0 ||
			 this._coord.y < 0 ||
			 this._coord.x > this._imageData.width - 1 ||
			 this._coord.y > this._imageData.height - 1 ) {

			this._coord.x = -1;
			this._coord.y = -1;
		}

		// For some reason it occasionally gets set as NaN
		// this is my bandaid
		if ( !Utils.mobileCheck() ) {
			if ( isNaN( this._coord.x ) || isNaN( this._coord.y ) || document.body.style.cursor !== 'none' ) {
				this._coord.x = -1;
				this._coord.y = -1;
			}
		}

		return this._coord;
	}

	// Show position of last cell the cursor passed over
	// return -1, -1 if cursor left art
	getLastCellCoord() {
		return this._coord;
	}

	convertToCoord( coord ) {
		coord.x = coord.x;
		coord.y = coord.y;

		coord.x = Math.floor( coord.x );
		coord.y = Math.floor( coord.y );

		if ( coord.x < 0 ||
			 coord.y < 0 ||
			 coord.x > this._imageData.width - 1 ||
			 coord.y > this._imageData.height - 1 ) {

			coord.x = -1;
			coord.y = -1;
		}

		// For some reason it occasionally gets set as NaN
		// this is my bandaid
		if( isNaN( coord.x ) || isNaN( coord.y ) ) {
			coord.x = -1;
			coord.y = -1;
		}

		return coord;
	}

	getCenterScreenPixel( canvasCoord = true ) {
		const pixel = this._ctx.transformedPoint( this._canvas.width / 2 - 1, this._canvas.height / 2 - 1 );
		return this.getLocalPixelInfo( Math.floor(pixel.x), Math.floor(pixel.y), canvasCoord );
	}

	// #####################################################################
	// Purchase Group Highlight
	// #####################################################################

	setHighlightGroup( array ) {
		this._highlightGroup =  this.convertPixelArray( array );
		this._highlightEdgeGroup = this.createHighlightEdgeGroupFromArray( array );
	}

	isHighlightGroupActive() {

		return ( this._highlightEdgeGroup.length === 0 ) ? false : true;
	}

	clearHightlightGroup() {
		this.sparkshot.Chat.clearSelect();
		this._highlightEdgeGroup = [];
	}

	createHighlightEdgeGroupFromArray( array ) {

		const outputArray = [];

		// Conver the array to local image coordinates (flip Y)
		const localArray = this.convertPixelArray( array );

		// Step through the array and perform edge finding
		localArray.forEach( pixel => {

			const edge = {
				x: 		pixel.x,
				y: 		pixel.y,
				p: 		{
					u:	!localArray.some( e => ( e.x === pixel.x 	&& e.y === pixel.y - 1 	) ),
					r:	!localArray.some( e => ( e.x === pixel.x + 1 && e.y === pixel.y 		) ),
					d:	!localArray.some( e => ( e.x === pixel.x 	&& e.y === pixel.y + 1 	) ),
					l:	!localArray.some( e => ( e.x === pixel.x - 1 && e.y === pixel.y 		) ),
				}
			}

			if ( edge.p.u === true || edge.p.r === true || edge.p.d === true || edge.p.l === true ) {
				outputArray.push( edge );
			}
		} );

		return outputArray;
	}

	drawPurchaseGroupHighlight() {
		if ( this._highlightEdgeGroup.length === 0 ) { return; }
		if ( this._highlightGroup.length === 0 ) { return; }

		// DRAW ALL PURCHASED PIXELS
		let offset = 0.2;
		let size = 0.6;

		const zoom = this._ctx.getTransform().a;
		let line_width = this._canvas.width / zoom / 100;
		if ( zoom < 5 ) {
			line_width = 2;
		}

		// DRAW PIXEL EDGE GROUP
		this._ui_ctx.lineCap 				  = "round";
		this._ui_ctx.lineWidth 				  = line_width;
		this._ui_ctx.strokeStyle 			  = this.purchase_group_body_bg_color;
		this._ui_ctx.setLineDash([]);
		this._highlightEdgeGroup.forEach( e => {
			if ( e.p.u ) {
				this._ui_ctx.beginPath();
				this._ui_ctx.moveTo( e.x, e.y );
				this._ui_ctx.lineTo( e.x + 1, e.y );
				this._ui_ctx.stroke();
			}
			if ( e.p.r ) {
				this._ui_ctx.beginPath();
				this._ui_ctx.moveTo( e.x + 1, e.y );
				this._ui_ctx.lineTo( e.x + 1, e.y + 1 );
				this._ui_ctx.stroke();
			}
			if ( e.p.d ) {
				this._ui_ctx.beginPath();
				this._ui_ctx.moveTo( e.x, e.y + 1 );
				this._ui_ctx.lineTo( e.x + 1, e.y + 1 );
				this._ui_ctx.stroke();
			}
			if ( e.p.l ) {
				this._ui_ctx.beginPath();
				this._ui_ctx.moveTo( e.x, e.y );
				this._ui_ctx.lineTo( e.x, e.y + 1 );
				this._ui_ctx.stroke();
			}
		} );

		// Set background colour of pixel based on zoom level
		let background_fill = this.purchase_group_body_bg_color;
		if ( zoom < 13 ) { background_fill = this.purchase_group_body_bg_zoom_1_color; }
		if ( zoom < 10 ) { background_fill = this.purchase_group_body_bg_zoom_2_color; }

		this._highlightGroup.forEach( pixel => {
			this._ctx.beginPath();
			this._ui_ctx.fillStyle = background_fill;
			this._ui_ctx.fillRect( pixel.x, pixel.y, 1, 1 );
			this._ui_ctx.fillStyle = this.purchase_group_body_color;
			this._ui_ctx.fillRect( pixel.x + offset, pixel.y + offset, size, size );
			this._ui_ctx.fill();
		});
	}

	// #####################################################################
	// Locked Pixels
	// #####################################################################

	lockPixels( pixels ) {
		pixels.forEach( pixel => {
			if ( pixel.locked ) return;
			this._locked.push( { x: pixel.x, y: pixel.y } );
		});

		// this._locked.push( pixels );
		this.drawLocked();
	}

	unlockPixels( x = -1, y = -1 ) {

		const loc = this._locked.map( e => JSON.stringify(e) ).indexOf( JSON.stringify( { x: x, y: y }) );
		if ( loc === -1 ) { return; }
		this._locked.splice( loc, 1 );
		// this.drawImage();
		this.updateToolsState();
	}

	isPixelLocked( x, y ) {

		return this._locked.some( e => JSON.stringify( e ) === JSON.stringify( { x: x, y: y } ) );

		// Step through each locked array to locate pixel
		// for ( let i = 0; i < this._locked.length; i++) {
		// 	const pixel = this._locked[i].some( e => JSON.stringify( e ) === JSON.stringify( { x: x, y: y } ) );
		// 	if ( pixel ) return pixel;
		// }
		//
		// // If no pixel found
		// return false;
	}

	clearLocked() {
		this._locked = [];

		if ( Utils.mobileCheck() ) {
			this.sparkshot.MobileViewer.setPixelButton();
		}

		this.dirty_image = true;
	}

	// Pass in an array of pixels to recolor as locked
	// If no array supplied, then all locked pixels will be recolored
	drawLocked( x = null, y = null ) {
		// Draw all pixels
		const offset = 0.25;
		const size = 0.5;
		if ( y === null || x === null ) {

			this._locked.forEach( pxl => {
				const pixel = this.getLocalPixelInfo( pxl.x, pxl.y );
				this._ctx.beginPath();
				this._ctx.fillStyle = "rgba(173, 10, 212, 1)";
				this._ctx.fillRect( pixel.canvas.x + offset, pixel.canvas.y + offset, size, size );
				this._ctx.fillStyle = "rgba(191, 52, 224, 1)";
				this._ctx.fillRect( pixel.canvas.x + offset + 0.1, pixel.canvas.y + offset + 0.1, size - 0.2, size - 0.2 );
			});
		} else {
			const pixel = this.getLocalPixelInfo( x, y );
			this._ctx.fillStyle = "rgba(173, 10, 212, 1)";
			this._ctx.fillRect( pixel.canvas.x + offset, pixel.canvas.y + offset, size, size );
			this._ctx.fillStyle = "rgba(191, 52, 224, 1)";
			this._ctx.fillRect( pixel.canvas.x + offset + 0.1, pixel.canvas.y + offset + 0.1, size - 0.2, size - 0.2 );
			this.drawHighlight();
		}
	}

	// #####################################################################
	// UI
	// #####################################################################

	drawHighlight( x = null, y = null) {
		if ( Utils.mobileCheck() ) { return; }
		x = ( x === null ) ? this.getLastCellCoord().x : x;
		y = ( y === null ) ? this.getLastCellCoord().y : y;

		// Default Opaque
		// this._ui_ctx.globalAlpha = 1;

		// If the cursor is off the canvas then don't draw
		if ( x === -1 || y === -1 ) { return; }

		// If the cursor is dragging the canvas don't draw
		if ( this._dragStart ) { return; }

		const zoom = this.getZoomLevel();

		// Change width of grid highlight line to make it easier to see when
		// zoomed out of art
		switch ( zoom ) {
			case 7:
				this._ui_ctx.lineWidth 		= "0.7";
				break;
			case 6:
				this._ui_ctx.lineWidth 		= "0.5";
				break;
			case 5:
				this._ui_ctx.lineWidth 		= "0.3";
				break;
			case 4:
				this._ui_ctx.lineWidth 		= "0.25";
				break;
			case 3:
				this._ui_ctx.lineWidth 		= "0.2";
				break;
			case 2:
				this._ui_ctx.lineWidth 		= "0.1";
				break;
			default:
				this._ui_ctx.lineWidth 		= "0.1";

		}

		// TODO: Set this up to better draw the right graphic depending on mode and zoom
		if ( !this.isZoomLevelNear() ) {
			this._ui_ctx.lineJoin 		= "round";
			this._ui_ctx.lineCap 		= "butt";
			this._ui_ctx.lineDashOffset = 0;
			this._ui_ctx.strokeStyle 	= "rgba(0,0,0,194)";
			this._ui_ctx.setLineDash([]);
			this._ui_ctx.strokeRect(x, y, 1, 1);

			// this._ui_ctx.lineDashOffset = 0.5;
			this._ui_ctx.strokeStyle = this.highlight[this.current_highlight].color;
			this._ui_ctx.setLineDash(this.highlight[this.current_highlight].linedash);
			this._ui_ctx.strokeRect(x, y, 1, 1);
			this._ui_ctx.lineDashOffset = 0;
		} else {
			this._ui_ctx.drawImage( this.highlight[this.current_highlight], x, y, 1, 1 );
		}

	}

	updateHighlighter( pixel = null ) {
		this.dirty_ui = true;
		this.setLastCoord();
		if ( pixel === null ) {
			pixel = this.getLocalPixelInfo( this.getLastCellCoord().x, this.getLastCellCoord().y );
		}

		switch ( this.Mode ) {
			case 'PURCHASE':
				if ( pixel.purchased && !pixel.oob ) {
					this.current_highlight = "purchased";
					return;
				}

				if ( this.input_state === this.INPUT.DEFAULT &&
					 this._selected.length < this.MAX_PIXEL_SELECTION ) {
					this.current_highlight = "default";
	 				return;
				}


				if ( this.input_state === this.INPUT.DEFAULT &&
					this._selected.length >= this.MAX_PIXEL_SELECTION &&
					 pixel.purchased === false &&
					 !this.isSelected( pixel.canvas.x, pixel.canvas.y )
					) {
					this.current_highlight = "blocked";
					return;
				}

				if ( this.input_state === this.INPUT.ADD &&
					 this._selected.length < this.MAX_PIXEL_SELECTION ) {
					this.current_highlight = "add";
 					return;
				}

				if ( this.input_state === this.INPUT.REMOVE ) {
					this.current_highlight = "remove";
					return;
				}

				if ( this.input_state === this.INPUT.ADD &&
					 this._selected.length >= this.MAX_PIXEL_SELECTION ) {
					this.current_highlight = "blocked";
 					return;
				}

				if ( !pixel.purchased )  {
					this.current_highlight = "default";
	 				return;
				}

				if ( pixel.canvas.x === -1 || pixel.canvas.y === -1 ) {
					this.current_highlight = "default";
					return;
				}

				break;
			case 'PROBE':
				this.current_highlight = "picker";
			break;
			default:
		}
	}

	// #####################################################################
	// ZOOM
	// #####################################################################

	setDefaultCanvasZoom() {
		// Set Default zoom
		if ( this._imageData === null ) { return; }

		const factorX = ( this._canvas.width / this._imageData.width ) * 0.85;
		const factorY = ( this._canvas.height / this._imageData.height ) * 0.85;
		const factor = ( Math.min( factorX, factorY ) > this._zoomMax ) ? this._zoomMax : Math.min( factorX, factorY );
		this._factor = factor;

		const pt = this._ctx.transformedPoint( this._canvas.width / 2, this._canvas.height / 2 );
		this._ctx.scale( factor, factor );
		this._ui_ctx.scale( factor, factor );
		const x = ( pt.x - this._imageData.width * factor / 2 ) / factor;
		const y = ( pt.y - this._imageData.height * factor / 2 ) / factor;
		this._ctx.translate( x, y );

		// Update UI Canvas
		this._ui_ctx.translate( x, y );

		this.ZOOM_MIN = this.getZoom();

		this.smoothImagePixels( false );

		this.dirty_ui = true;
		this.dirty_image = true;
	}

	isZoomLevelNear() {
		return ( this._ctx.getTransform().a > this.ZOOM_LEVEL_FAR ) ? true : false;
	}

	isZoomLevel( num ) {
		const range = ( num + 1 < this.ZOOM_LEVEL.length ) ? this.ZOOM_LEVEL[num + 1] : 0;
		// console.log( `ZOOM: ${num} :: ${this.ZOOM_LEVEL[num]} <> ${range} :: ${this._ctx.getTransform().a}` );
		return ( this._ctx.getTransform().a < this.ZOOM_LEVEL[num] && this._ctx.getTransform().a >= range ) ? true : false;
	}

	setZoomLevel( num ) {
		const trans = this._ctx.getTransform()
		.translate( this.data.width / 2,
			this.data.height / 2 );
			this.zoomCanvas( num, trans.e, trans.f );
		}

	getZoomLevel() {
		let num = -1;
		if ( this.isZoomLevel(0) ) { num = 0; }
		else if ( this.isZoomLevel(1) ) { num = 1; }
		else if ( this.isZoomLevel(2) ) { num = 2; }
		else if ( this.isZoomLevel(3) ) { num = 3; }
		else if ( this.isZoomLevel(4) ) { num = 4; }
		else if ( this.isZoomLevel(5) ) { num = 5; }
		else if ( this.isZoomLevel(6) ) { num = 6; }
		else if ( this.isZoomLevel(7) ) { num = 7; }
		return num;
	}

	// Zoom Controls
	// Animation to zoom in or out of image until max or min zoom

	// Triggers animation to start
	startZoomAnim( dir ) {
		this.zoomDir = dir * 0.3;
		if ( this.isZoomAnimPlaying ) { return; }
		this.cancelAnimation();
		this.isZoomAnimPlaying = true;
	}

	// Stops animation from playing
	clearZoomAnim() {
		this.isZoomAnimPlaying = false;
		this.zoomDir = null;
	}

	/**
	* Basic Search call without extra parameters
	* @param {int} amount - Amount to zoom by
	* @param {int} x - x center point of zoom
	* @param {int} y - y center point of zoom
	* @return {boolean} - has zoom reached min or max level
	*/
	zoomCanvas( amount, x, y ) {
		if ( this.data === null ) { return console.error('No Art Data Found. Are you on an Art page?'); }
		// Define scale based on screen setup
		const MAX_PIXEL_WIDTH = 100;
		const MIN_PIXEL_WIDTH = 0.1;

		// Store Transform in case needed
		const restore = this._ctx.getTransform();

		// Set center point of zoom
		if ( typeof x === 'undefined' || x === -1 ) {
			x = (this._canvas.width / 2);
		}
		if ( typeof y === 'undefined' || y === -1 ) {
			y = (this._canvas.height / 2);
		}

		let factor = Math.pow( this._scaleFactor, amount );
		const pt = this._ctx.transformedPoint( x, y );

		// Perform the translation
		this._ctx.translate( pt.x, pt.y );
		this._ui_ctx.translate( pt.x, pt.y );

		this._ctx.scale( factor, factor );
		this._ui_ctx.scale( factor, factor );

		this._ctx.translate( -pt.x, -pt.y );
		this._ui_ctx.translate( -pt.x, -pt.y );

		// Check to see if it crossed the boundaries and then set to max or min
		const trans = this._ctx.getTransform().a;

		const zoom = this.getZoom();
		const scale = Math.max(
			( this._canvas.width / this.data.width ) * zoom,
			( this._canvas.height / this.data.height ) * zoom );

		// Are we at max zoom?
		if ( trans > 0 && scale > MAX_PIXEL_WIDTH ) {
			this._ctx.setTransform( restore.a, restore.b, restore.c, restore.d, restore.e, restore.f );
			this._ui_ctx.setTransform( restore.a, restore.b, restore.c, restore.d, restore.e, restore.f );
			return false;
		}

		if ( trans < 1 && scale < MIN_PIXEL_WIDTH ) {
			this._ctx.setTransform( restore.a, restore.b, restore.c, restore.d, restore.e, restore.f );
			this._ui_ctx.setTransform( restore.a, restore.b, restore.c, restore.d, restore.e, restore.f );
			return false;
		}

		// Make sure cursor gets updated
		this.updateHighlighter();

		this.dirty_image = true;

		return true;
	}

	/**
	* Zoom in to canvas centered on an x, y coordinate
	* @param {float} scale - scale value to zoom to
	* @param {int} x - x center point of zoom, if none specified then center of screen is used ( 0 is top left )
	* @param {int} y - y center point of zoom, if none specified then center of screen is used ( 0 is top left )
	*/
	setZoom(scale, x, y) {
		if ( this.data === null ) { return console.error('No Art Data Found. Are you on an Art page?'); }
		const MAX_PIXEL_WIDTH = 100;
		const MIN_PIXEL_WIDTH = 1;


		// Define scale based on screen setup
		scale = Math.min(
			( this._canvas.width / this.data.width ) * scale,
			( this._canvas.height / this.data.height ) * scale );

		// Are we at max zoom?
		if ( Math.round(scale) > MAX_PIXEL_WIDTH || Math.round(scale) < MIN_PIXEL_WIDTH ) { return false; }

		if ( typeof x === 'undefined' ) {
			x = (this._canvas.width / 2) - ( this.data.width * scale ) / 2;
		} else {
			x = ( this._canvas.width / 2 ) + ( -x * scale );
		}

		if ( typeof y === 'undefined' ) {
			y = (this._canvas.height / 2) - ( this.data.height * scale ) / 2;
		} else {
			y = ( this._canvas.height / 2 ) + ( -y * scale );
		}

		this._ctx.setTransform( scale, 0, 0, scale, x, y );
		this._ui_ctx.setTransform( scale, 0, 0, scale, x, y );

		// Make sure cursor gets updated
		this.updateHighlighter();

		this.dirty_image = true;

		return true;
	}

	/**
	* Returns uniform zoom value of art
	* @return {float} zoom value
	*/
	getZoom() {
		const ratio = Math.min(
			( this._canvas.width / this.data.width ),
			( this._canvas.height / this.data.height ) );
		return Number((this._ctx.getTransform().a / ratio));
	}

	// #####################################################################
	// UI CONTROLS
	// #####################################################################

	addTextFloat( { x, y, text, font_size, font_color, panel_color, duration } ) {
		const size = font_size;
		const buffer = 50;
		this._floatTextQueue.push( {
			x: 				x,
			y: 				y,
			text: {
				x:			null,
				y:			null,
				size: 		font_size,
				string: 	text,
				color: 		font_color,
			},
			panel: {
				width: 		text.length * ( size / 2 ) + buffer,
				height:		size + ( size / 2 ),
				color: 		panel_color,
				x:			null,
				y:			null,
				bevel:		10,
				shadow:		true
			},
			opacity: 		1,
			offset:			0.4,
			change:			0.15,
			size:			size,
			duration: 		duration,
			_del: 			false,
		});
		// console.log( this._floatTextQueue[0] );
	}

	updateTextFloat(dt) {
		this._floatTextQueue.forEach( (t) => {
			t.duration -= dt;
			if ( t.duration <= 0 ) {
				t.delete = true;
			} else {
				// Calculate new transform change
				const trans = this._ctx.getTransform().translate( t.x + 0.5, t.y - t.offset );

				// Force on to screen
				if ( trans.e < t.panel.width ) { trans.e = t.panel.width / 2; }
				if ( trans.e > this._hud_canvas.width - t.panel.width ) { trans.e = this._hud_canvas.width - ( t.panel.width / 2 ); }

				// Update Opacity
				t.opacity = easing.easeOutQuint( 1 * t.duration );

				// Move Y axis
				t.y -= easing.easeOutQuint( ( t.change - this.update_tick ) / trans.a );

				// Update Panel
				t.panel.x = trans.e - ( t.panel.width / 2 );
				t.panel.y = trans.f - t.size;

				// Update Text
				t.text.x = trans.e + 10;
				t.text.y = trans.f;
			}
		});
		const filter = this._floatTextQueue.filter( e => !e.delete );
		this._floatTextQueue = filter;
	}

	drawTextFloat() {
		this._floatTextQueue.forEach( (t) => {

			this._hud_ctx.imageSmoothingEnabled = true;
			this._hud_ctx.webkitImageSmoothingEnabled = true;

			// Draw Panel
			const p = t.panel;
			const p_color = `rgba(${p.color.r}, ${p.color.g}, ${p.color.b}, ${t.opacity})`
			panel( this._hud_ctx, p.x, p.y, p.width, p.height, p_color, p.bevel, p.shadow );

			const s = t.text;
			const s_color = `rgba(${s.color.r}, ${s.color.g}, ${s.color.b}, ${t.opacity})`
			this._hud_ctx.font = `bold ${s.size}px 'IBM Plex Mono', monospace`;
			this._hud_ctx.textAlign = "center";
			this._hud_ctx.fillStyle = s_color;
			this._hud_ctx.fillText( s.string, s.x, s.y + 1 );

			// Draw Bitcoin Symbol
			drawBitcoinSymbol( this._hud_ctx, p.x + 5, p.y + 4, 0.25, t.opacity );
		});
	}

	// Check against all HUD animations to see if hud updates can be paused
	canPauseHUD() {
		if ( this.mouse_icons.current !== "blank" ) { return false; }
		if ( this._floatTextQueue.length > 0 ) { return false; }
		if ( this._deleteQueue.length > 0 ) { return false; }

		if ( this._x === this._lastX && this._y === this._lastY ) { return false; }

		if ( this.isCenterOnCanvasAnimPlaying ) { return false; }
		if ( this.isZoomLevelNear() ) {
			// Check against anim states for selected pixels
			// Add more states here or if too many get added
			// create a new boolean for when animating any sprite
			if ( this._selected.some( e => !e.hover_complete ) ) { return false; }
			if ( this._selected.some( e => !e.spawn_complete ) ) { return false; }
		}
		return true;
	}

	// #####################################################################
	// Info Box
	// #####################################################################

	clearShowInfoBoxTimer() {
		// Clears any running timers timer and prevent showing info box
		// This fires when the mouse moves
		clearTimeout( this.hoverInfoBoxTimer );
	}

	startPriceInfoBoxTimer( price ) {
		this.clearShowInfoBoxTimer();
		this.hoverInfoBoxTimer = setTimeout( () => {
			this.infobox.showPrice( price );
		}, this.INFOBOX_SHOW_DELAY, price );
	}

	showInfoBox( x, y, selectChat = true ) {
		// Show InfoBox using data from the x y state to find the pixel
		if ( x === -1 || y === -1 ) {
			this.clearShowInfoBoxTimer();
			this.infobox.clear();
			return;
		}

		// Make sure there's no existing info box
		this.infobox.clear();

		const pixel = this.getLocalPixelInfo( x, y );

		switch (this.Mode) {
			case 'PURCHASE':
				if ( pixel.purchased ) {
					if ( pixel.has_user_string ) {
						if ( pixel.mod_setting === "NONE" ) {
							this.infobox.showMessage( pixel.user_string, pixel.right_to_left );
						} else if ( pixel.mod_setting === "NSFW" && this.sparkshot.Settings.getNSFWContentStatus() ) {
							this.infobox.showMessage( this.sparkshot.Chat.CHAT_NSFW, false, 50 );
						} else if ( pixel.mod_setting === "NSFW" && !this.sparkshot.Settings.getNSFWContentStatus() ) {
							this.infobox.showMessage( pixel.user_string, pixel.right_to_left );
						} else if ( pixel.mod_setting === "NOPE" ) {
							this.infobox.showMessage( this.sparkshot.Chat.CHAT_NOPE, false, 150 );
						} else {
							// Catch
							this.infobox.showMessage( pixel.user_string, pixel.right_to_left );
						}
					} else {
						this.infobox.showMessage( this.NO_USER_MSG );
					}
					this.setHighlightGroup( this.data.replays[pixel.replay_index].coords );
					if ( selectChat ) { this.sparkshot.Chat.selectMessageByIndex( pixel.replay_index, true ); }
				}
				break;
			case 'PROBE':

			break;
			default:

		}
	}

	// #####################################################################
	// Tools
	// #####################################################################

	convertSelectionToString( x = null, y = null ) {
		// Sort the selection so that the output string is x order, then y order
		this.sortSelected();

		// Add overpay

		let request = '';

		if ( !x || !y ) {
			// Process the full selection
			this._selected.forEach( pixel => {
				let y = this._artcanvas.height - pixel.y - 1;
				let update = '';
				const overpay = this.sparkshot.IM.overpaySymByIndex( pixel.overpay );
				if ( this._selected.indexOf(pixel) < this._selected.length - 1 ) {
					update = `${pixel.x}${overpay}${y}_`;
				} else {
					update = `${pixel.x}${overpay}${y}`;
				}
				request = request.concat( update );
			});
		} else {
			// Process the pixel
			y = this._artcanvas.height - y - 1;
			request = `${x},${y}`;
		}

		return request;
	}

	convertPixelArray( array, serverToClient = true ) {

		const pixels = [];

		array.forEach( (pixel) => {
			const pxl = {
				x:  pixel.x,
				y:  ( serverToClient ) ? this._artcanvas.height - pixel.y - 1 : pixel.y - this._artcanvas.height - 1,
			}
			pixels.push( pxl );
		} );

		return pixels;
	}

	convertCoordBetweenDataAndCanvas( x, y ) {
		// Server stores Y data flipped compared to client
		if ( !Number.isInteger(x) || !Number.isInteger(y) ) {
			// console.warn(`convertCoordBetweenDataAndCanvas() not given integer`);
			return { x: -1, y: -1 };
		}

		const flipY = this._artcanvas.height - y - 1;
		return { x: x, y: flipY };
	}

	isArtComplete() {
		return ( ( this.data.height * this.data.width ) === this._totalPurchasedPixels ) ? true : false;
	}

	drawDebugBox() {
		// DRAW DEBUG FRAMES
		// outer frame
		const percentHeight = this._canvas.width / 100;
		const percentWidth = this._canvas.width / 100;

		const outerHeight = this._canvas.width - ( percentHeight * 60 );
		const outerWidth =  this._canvas.width - ( percentWidth * 60 );

		const innerHeight = this._canvas.width - ( percentHeight * 70 );
		const innerWidth =  this._canvas.width - ( percentWidth * 70 );

		const ox = ( this._canvas.width - outerWidth ) / 2;
		const oy = ( this._canvas.height - outerHeight ) / 2;

		const ix = ( this._canvas.width - innerWidth ) / 2;
		const iy = ( this._canvas.height - innerHeight ) / 2;

		this._hud_ctx.lineWidth = "2";
		this._hud_ctx.strokeStyle = "red";
		this._hud_ctx.strokeRect( ox, oy, outerWidth, outerHeight );
		this._hud_ctx.strokeStyle = "yellow";
		this._hud_ctx.strokeRect( ix, iy, innerWidth, innerHeight );
		this._hud_ctx.strokeStyle = "green";
		this._hud_ctx.lineWidth = "4";
		const pixel = - this._ctx.getTransform().a;
		this._hud_ctx.strokeRect( this._canvas.width / 2 - ( pixel / 2 ), this._canvas.height / 2 - ( pixel / 2), pixel, pixel );
	}

	// #####################################################################
	// Selection and Payment Methods
	// #####################################################################

	isSelected ( x, y ) {
		// Returns if the pixel is currently selected
		return this._selected.some( e => e.x === x && e.y === y );
	}

	sortSelected() {
		// Tricky sort function that has to take into account the y order of the server data
		this._selected.sort( ( a, b ) => a.x === b.x ? Number( this._artcanvas.height - a.y - 1 ) - Number( this._artcanvas.height - b.y - 1 ) : Number( a.x) - Number( b.x ) );
	}

	processSelected( x, y ) {
		// Fires when interaction happens to check if pixel has been de/selected
		// Don't process if out of bounds
		if ( x < 0 || x > this._ArtX ) {
			this.clearHightlightGroup();
			return;
		}
		if ( y < 0 || y > this._ArtY ) {
			this.clearHightlightGroup();
			return;
		}

		// Don't process if not valid
		const pixel = this.getLocalPixelInfo( x, y );

		switch ( this.Mode ) {
			case 'PURCHASE':

				switch ( this.input_state ) {
					case this.INPUT.REMOVE:
						// Is it already selected
						if ( !this.isSelected( pixel.canvas.x, pixel.canvas.y ) )
							return;

						// Remove and return confirmation
						if ( this.removePixelFromSelection( pixel.canvas.x, pixel.canvas.y ) ) {
							this._price -= pixel.satoshi_price;

							// Update Float Price
							this._floatPrice -= pixel.satoshi_price;

							this.sparkshot.UI.updateMessageLengthCounter();
							this.updateToolsState();
						}
						break;
					case this.INPUT.ADD:
						// Ensure highlight group is cleared when selecting
						this.clearHightlightGroup();
						this.selectPixel( pixel.canvas.x, pixel.canvas.y, false );
						break;
					case this.INPUT.INFO:
						if ( pixel.purchased ) {
							this.showInfoBox( x, y );
						}
						break;
					default:

				}
			break;
			case 'PROBE':
				this.sparkshot.WS.request_pixel_info( `${pixel.data.x},${pixel.data.y}` );
			break;
		default:

		}
	}

	selectPixel( x, y ) {
		// Is selection maxed out
		if (  this._selected.length >= this.MAX_PIXEL_SELECTION ) { return; }

		const pixel = this.getLocalPixelInfo( x, y );
		// Check pixel state
		if ( this.isSelected( pixel.canvas.x, pixel.canvas.y ) ) { return; }
		if ( pixel.oob ) { return; }
		if ( pixel.purchased ) { return; }

		this._selected.push( {
			x: pixel.canvas.x,
			y: pixel.canvas.y,
			overpay: 0,
			spawn_complete: false,
			hover_complete: true,
			sprite: this.addPixel( this.isZoomLevelNear() ),
		} );

		this._lastHover = { x: pixel.canvas.x, y: pixel.canvas.y };
		this.drawSelectedPixels( pixel.canvas.x, pixel.canvas.y );
		this._price += pixel.satoshi_price;

		// Update Float Price
		this._floatPrice += pixel.satoshi_price;

		this.sparkshot.UI.updateMessageLengthCounter();
		this.updateToolsState();

		this.sparkshot.Tutorial.completeHint( "select_pixels" );      // Mark Tutorial Complete
	}

	// Add pixel sprite for selection
	addPixel( animate_spawn = true ) {
		return new Sprite({
			sheet:  "/img/sprites/gem_green.png",
			frame_width: 32,
			frame_height: 32,
			frame_rate: 256,
			ctx: this._ui_ctx,
			scale: 0.5,
			animations: {
				loop: [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 22, 22, 23, 23, 24, 24 ],
				spawn: (animate_spawn) ? [ 37, 38, 39, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 0, 1, 2, 3, 4, 3, 2, 1, 0 ] : [0],
				hover: [ 1, 2, 3, 4, 5, 6, 5, 4, 3, 2, 1, 0, 21, 20, 19, 18, 17, 16, 15, 16, 17, 18, 19, 20, 21, 0, 1, 2, 3, 2, 1 ],
				despawn: [ 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36 ]
			}
		});
	}

	removePixelFromSelection( x, y ) {
		const loc = this._selected.findIndex( e => e.x === x && e.y === y );
		if ( loc === -1 ) { return false; }
		const pixel = this._selected[loc];
		pixel.despawn_complete = false;
		this._deleteQueue.push( pixel );
		this._lastDeleted = { x: pixel.x, y: pixel.y };
		this._selected.splice( loc, 1 );
		this.updateToolsState();
		this.sparkshot.UI.hideLoadingBar();
		return true;
	}

	// Show Price Floater Text
	showDragSelectPriceFloatText() {
		if ( this._floatPrice !== 0 ) {
			const symb = ( this._floatPrice > 0 ) ? '+' : '-';
			const num = Math.abs( this._floatPrice );
			let loc;
			if ( this._floatPrice > 0 ) {
				loc = {
					x: this._selected[this._selected.length - 1].x,
					y: this._selected[this._selected.length - 1].y
				}
			} else {
				if ( Utils.mobileCheck() ) {
					loc = { x: this.getCenterScreenPixel().canvas.x, y: this.getCenterScreenPixel().canvas.y };
				} else {
					loc = ( this.getLastCellCoord().x === -1 || this.getLastCellCoord().y === -1 ) ? this._lastDeleted : this.getLastCellCoord();
				}
			}
			const price = ( num < 1000 ) ? `0.${num}` : Utils.NumToBitcoin( num, '.' );
			const panel_color = { r: 26, g: 26, b: 26, a: 1 };
			const font_color = ( symb === '+' ) ? { r: 0, g: 230, b: 118, a: 1 } : { r: 255, g: 41, b: 41, a: 1 };

			this.addTextFloat( {
				x: loc.x,
				y: loc.y,
				text: `${symb}${price}`,
				font_size: 16,
				font_color: font_color,
				panel_color: panel_color,
				duration: 0.9
			});
		}

		this._floatPrice = 0;
	}

	// Cycle through delete queue and remove any completed
	updateDeleteQueue() {
		const filtered = this._deleteQueue;
		this._deleteQueue.forEach( pxl => {
			if (pxl.despawn_complete) {
				const loc = filtered.findIndex( e => e.x === pxl.x && e.y === pxl.y );
				if ( loc === -1 ) { return false; }
				filtered.splice( loc, 1 );
			}
		});
		this._deleteQueue = filtered;
	}

	addHoverState( x, y ) {
		if ( this._lastHover.x === x && this._lastHover.y === y ) { return; }
		const loc = this._selected.findIndex( e => e.x === x && e.y === y );
		if ( loc === -1 ) { return false; }
		if ( !this._selected[loc].spawn_complete ) { return; }
		this._selected[loc].hover_complete = false;
		this._lastHover = { x: x, y: y };
	}

	// Clear out any stored pixel data
	clearSelection() {
		// Remove the message hint if there
		this.sparkshot.Tutorial.removeHint( 'add_message' );
		this._selected = [];
		this._deleteQueue = [];
		this._lastHover = { x: -1, y: -1 };
		this.setLastCoord( { x: -1, y: -1 } );
		this._price = 0;
		this.updateToolsState();
		this.dirty_ui = true;
		this.dirty_image = true;
	}

	// Draw Selected Pixels
	drawSelectedPixels( x = null, y = null ) {
		// Draw all pixels
		const offset = 0.25;

		// Define default frame
		let default_frame = 0;
		if ( this._selected.length > 0 ) {
			default_frame = this._selected[0].sprite.animation.loop.getFrame() + 1;
		}

		// UPDATE FRAMES
		// Only update if camera is zoomed in to animation range
		let update_frames = false;
		if ( this.isZoomLevelNear() ) {
			this.anim_tick_count++;

			if ( this.anim_tick_count > this.anim_tick_per_frame ) {
				this.anim_tick_count = 0;
				this.anim_frame_index++;
				update_frames = true;
			}
		}

		// DRAW DELETE QUEUE
		this._deleteQueue.forEach( pxl => {
			if ( !this.isZoomLevelNear() ) {
				pxl.despawn_complete = true;
			} else {
				pxl.sprite.animation.despawn.play( pxl.x + offset, pxl.y + offset );
				const test = pxl.sprite.animation.despawn.update();
				if ( test ) { pxl.despawn_complete = true; }
			}
		});

		this.updateDeleteQueue();

		if ( y === null || x === null ) {
			// Fetch current anim frame of existing pixel
			// DRAW PIXELS
			this._selected.forEach( pxl => {

	 			if ( this._ctx.getTransform().a < 20 ) {
					this._ui_ctx.fillStyle = this.selected_pixel_color;
					this._ui_ctx.fillRect( pxl.x + 0.2, pxl.y + 0.2, 0.6, 0.6 );

				} else {
					// DRAW SPRITE
					if ( pxl.spawn_complete ) {
						if ( pxl.hover_complete ) {
							pxl.sprite.animation.loop.play( pxl.x + offset, pxl.y + offset );
							if ( update_frames ) { pxl.sprite.animation.loop.update(); }
						} else {
							pxl.sprite.animation.hover.play( pxl.x + offset, pxl.y + offset );
							if ( update_frames ) {
								const test = pxl.sprite.animation.hover.update();
								if ( test ) { pxl.hover_complete = true; }
							}
						}

					} else {
						pxl.sprite.animation.spawn.play( pxl.x + offset, pxl.y + offset );
						if ( update_frames ) {
							const test = pxl.sprite.animation.spawn.update();
							if ( test ) { pxl.spawn_complete = true; }
							pxl.sprite.animation.loop.setFrame( default_frame );
						}
					}
				}
			});
		}
	}

	updateToolsState() {
		// Monitor if the user has selected any content for purchase
		// Check to see if the selection has any content
		// Enable purchse if so
		if ( this._selected.length > 0 ) {
			this.sparkshot.UI.enablePaymentPanelButton();
			this.sparkshot.UI.enableToolsPanel();
			this.sparkshot.UI.setPriceCounter( this._price + this._message_price );

			// Enable Payment Button if message valid
			if ( this.sparkshot.UI.isMessageLengthValid() ) {
				this.sparkshot.UI.enablePaymentPanelButton();
			} else {
				this.sparkshot.UI.disablePaymentPanelButton();
			}

		} else {
			// Remove the message hint if there
			this.sparkshot.Tutorial.removeHint( 'add_message' );
			this.sparkshot.UI.disablePaymentPanelButton();
			this.sparkshot.UI.disableToolsPanel();
			this.sparkshot.UI.setPriceCounter( 0 + this._message_price );
			this._price = 0;

		}

		// Enable Payment if there are locked pixels
		// if ( this._locked.length > 0 && this.sparkshot.UI.isMessageLengthValid() ) {
		// 	this.sparkshot.UI.enablePaymentPanelButton();
		// }
	}

	requestToInvoiceSelection() {
		// Lock viewer interaction until invoice is state resolved;
		// This can be handled by the IM manager

		// Convert the selected pixels to a string
		// Pass the string, the message and the price to the IM Manager
		let request = this.convertSelectionToString();
		this.sparkshot.IM.requestInvoice( request, this.sparkshot.UI.getUserMessage(), this._price );
	}

	lockInvoicedSelection() {
		this.lockPixels( this._selected );
		this.clearSelection();
		this.sparkshot.UI.clearUserMessage();
	}

	closedInvoice() {
		// When invoice has been closed
        this.sparkshot.IM.cancelInvoice();

		// This should probably happen after the server comes back with a
		// confirmation of cancel
		this.clearLocked();
	}

	setMessagePrice( value ) {
		this._message_price = value;
	}

	// #####################################################################
	// Input Method
	// #####################################################################

	getArtCoords() {
		const tl = this._ctx.getTransform().translate( 0, 0 );
		const tr = this._ctx.getTransform().translate( this.data.width, 0 );
		console.log( `TL: ${tl.e}:${tl.f} --- TR: ${tr.e}:${tr.f}` );
	}

	// Checks if any part of the art is at the center of the screen
	isArtAtCenter() {
		const trans = this._ctx.transformedPoint(
							Math.floor( this._canvas.width / 2 ),
							Math.floor( this._canvas.height / 2 ) );
		if ( trans.x < 0 || trans.x > this.data.width ||
			 trans.y < 0 || trans.y > this.data.height ) {
				 return false
			 } else {
				 return true
			 }
	}

	isArtInBounds() {
		const TL = {
					x: this._ctx.getTransform().translate( 0, 0 ).e,
		 			y: this._ctx.getTransform().translate( 0, 0 ).f
				};
		const TR = {
					x: this._ctx.getTransform().translate( this.data.width, 0 ).e,
		 			y: this._ctx.getTransform().translate( this.data.width, 0 ).f
				};
		const BL = {
					x: this._ctx.getTransform().translate( 0, this.data.height ).e,
		 			y: this._ctx.getTransform().translate( 0, this.data.height ).f
				};
		const BR = {
					x: this._ctx.getTransform().translate( this.data.width, this.data.height ).e,
					y: this._ctx.getTransform().translate( this.data.width, this.data.height ).f
				};

		console.log( TL );
		console.log( TR );
		console.log( BL );
		console.log( BR );

		if ( TL.x > this._canvas.width ) { return false; }
		if ( TR.x < 50 ) { return false; }

		if ( TL.y > this._canvas.height ) { return false; }
		if ( BR.y < 50 ) { return false; }

		return true;

	}

	inputDown(e) {
		// Record loction of click or touch in canvas coordinates
		this._lastX = ( typeof e.offsetX === 'undefined' ) ? e.targetTouches[0].pageX  - this._canvas.offsetLeft : e.offsetX;
		this._lastY = ( typeof e.offsetY === 'undefined' ) ? e.targetTouches[0].pageY - this._canvas.offsetTop : e.offsetY;

		this.clearShowInfoBoxTimer();
		this.infobox.clear();

		// Set state of clicked button
		switch ( e.button ) {
			case 0:
				const x = this.getLastCellCoord().x;
				const y = this.getLastCellCoord().y;

				if ( x === -1 || y === -1 ) {
					this.input_state = this.INPUT.OOB;
				} else if ( this.getLocalPixelInfo( x, y ).purchased ) {
					this.input_state = this.INPUT.INFO;
				} else {
					if ( this.isSelected( x, y ) ) {
						this.input_state = this.INPUT.REMOVE;
					} else {
						this.input_state = this.INPUT.ADD;
					}
				}
				// Process the pixel at the selected co-ordinate
				this.processSelected( x, y );
			break;
			case 1:
				this.input_state = this.INPUT.DRAG;
				this._dragStart = this._ctx.transformedPoint( this._lastX, this._lastY );
				break;
			case 2:
				this.input_state = this.INPUT.DRAG;
				this._dragStart = this._ctx.transformedPoint( this._lastX, this._lastY );
			break;
		}

		// UPDATE GRID HIGHLIGHTER
		this.updateHighlighter();

		this.updateMouseCursor();

		// If the user interacted with the scene then cancel any camera animation
		if ( this.getLastCellCoord().x !== -1 && this.getLastCellCoord().y !== -1 ) {
			this.cancelAnimation();
		}
	}

	inputMove(e) {
		e.preventDefault();
		this._x = ( typeof e.offsetX === 'undefined' ) ? e.targetTouches[0].pageX  - this._canvas.offsetLeft : e.offsetX;
		this._y = ( typeof e.offsetY === 'undefined' ) ? e.targetTouches[0].pageY - this._canvas.offsetTop : e.offsetY;
		// console.log( `[${this._x }:${this._y}]`);
		// If the mouse hasn't moved then return
		if ( this._lastX === this._x && this._lastY === this._y ) { return; }
		this._lastX = this._x;
		this._lastY = this._y;

		// Record current mouse position for other functions to reference
		this._mouse_pos = {
			x: ( typeof e.offsetX === 'undefined' ) ? e.targetTouches[0].pageX : e.offsetX,
			y: ( typeof e.offsetY === 'undefined' ) ? e.targetTouches[0].pageY : e.offsetY
		};

		const pixel = this.getLocalPixelInfo( this.getLastCellCoord().x, this.getLastCellCoord().y );

		// UPDATE GRID HIGHLIGHTER
		this.updateHighlighter( pixel );

		// If no button is pressed
		if ( e.buttons === 0 ) {

			if ( this.input_state === this.INPUT.DRAG ) {
				this.input_state = this.INPUT.DEFAULT;
			} else if ( pixel.purchased && !pixel.oob ) {
				this.input_state = this.INPUT.INFO;
			} else if ( this.getLastCellCoord().x === -1 || this.getLastCellCoord().y === -1 ) {
				this.input_state = this.INPUT.OOB;
			} else {
				this.input_state = this.INPUT.DEFAULT;
			}
			this.updateMouseCursor();

			// ADD HOVER ANIMATION TO PIXEL
			if ( this.isZoomLevelNear() && this.isSelected( pixel.canvas.x, pixel.canvas.y ) ) {
				this.addHoverState( pixel.canvas.x, pixel.canvas.y );
			} else {
				this._lastHover = { x: -1, y: -1 };
			}

			// INFO BOX
			if ( pixel.oob ) {
				this.clearShowInfoBoxTimer();
				this.infobox.clear();
			} else if ( pixel.purchased ) {
				this.clearShowInfoBoxTimer();
				switch ( this.Mode ) {
					case 'PURCHASE':
						this.infobox.showMessage( pixel.user_string, pixel.right_to_left );
						break;
					case 'PROBE':
						this.infobox.showLocation( pixel.data.x, pixel.data.y );
						break;
					default:
						this.infobox.showMessage( pixel.user_string, pixel.right_to_left );
				}
			} else {
				this.infobox.clear();
				switch ( this.Mode ) {
					case 'PURCHASE':
						this.startPriceInfoBoxTimer( pixel.satoshi_price );
						break;
					case 'PROBE':
						this.infobox.showLocation( pixel.data.x, pixel.data.y );
						break;
					default:
						this.startPriceInfoBoxTimer( pixel.satoshi_price );
				}
			}
		} else {
			this.infobox.clear();
			this.clearShowInfoBoxTimer();
		}

		// Update the canvas position if move is drag
		// Drag Button is clicked and held
		if ( this.input_state === this.INPUT.DRAG ) {
			// Clear the group if not showing highlight group message
			this.infobox.clear();

			// Translate art to new location in window
			const pt = this._ctx.transformedPoint( this._lastX, this._lastY );

			// Update Art Canvas
			this._ctx.translate( pt.x - this._dragStart.x, pt.y - this._dragStart.y );
			// Update UI Canvas
			this._ui_ctx.translate( pt.x - this._dragStart.x, pt.y - this._dragStart.y );

			this.dirty_image = true;

			return;
		}

		// Process the pixel under the mouse if adding or removing pixels
		if ( this.input_state === this.INPUT.ADD ||
		 	 this.input_state === this.INPUT.REMOVE ) {
			this.processSelected( this.getLastCellCoord().x, this.getLastCellCoord().y );
		}
	}

	inputUp() {
		if ( this.getLastCellCoord().x === -1 || this.getLastCellCoord().y === -1 ) {
			this.input_state = this.INPUT.OOB;
		} else {
			this.input_state = this.INPUT.DEFAULT;
		}

		// UPDATE GRID HIGHLIGHTER
		this.updateHighlighter();

		this.clearInput();

		this.showDragSelectPriceFloatText();
		return false;
	}

	inputScroll(e) {
		// const delta = e.wheelDelta ? e.wheelDelta / 40 : e.detail ? -e.detail : 0;
		const delta = e.wheelDelta ? e.wheelDelta / 80 : e.detail ? -e.detail : 0;
		if ( !delta ) { return; }
		this.zoomCanvas( delta, e.layerX, e.layerY );

		this.sparkshot.Tutorial.completeHint( "zoom_in" );      // Mark Tutorial Complete

		this.cancelAnimation();

		return e.preventDefault() && false;
	}

	// Reset all input state
	clearInput() {
		this._dragStart = null;
		this._dragRelease = null;
		this._selector = false;
		this._nothing = false;
		this._dragger = false;
		this._lastSelected = null;
	}

	// #####################################################################
	// Animation Method
	// #####################################################################

	// PRICE PROBE
	togglePriceProbe() {
		if ( !this.price_radar.isEnabled() ) {
		// 	if ( this._ctx.getTransform().a < 20 )
				// this.setZoom( this.PRICE_PROBE_MIN_ZOOM );
			this.setPriceProbeEnabled(true);
		} else {
			this.setPriceProbeEnabled(false);
		}

	}

	setPriceProbeEnabled( state ) {
		if ( state ) {
			this.sparkshot.UI.controls.buttons.price_radar.firstElementChild.classList.add( 'spark-blue-text' );
			this.price_radar.setEnabled( true );
		} else {
			this.sparkshot.UI.controls.buttons.price_radar.firstElementChild.classList.remove( 'spark-blue-text' );
			this.price_radar.setEnabled( false, true );
		}
	}

	lastMousePosition(e) {
		if ( typeof e === 'undefined' ) { return this.mousePosition; }
		this.mousePosition.x = ( typeof e.offsetX === 'undefined' ) ? e.targetTouches[0].clientX : e.clientX;
		this.mousePosition.y = ( typeof e.offsetY === 'undefined' ) ? e.targetTouches[0].clientY : e.clientY;
		return this.mousePosition;
	}

	currentMousePosition(e, el = null ) {
		if ( typeof e === 'undefined' ) { return this.mousePos; }

		this.mousePos.x = ( typeof e.offsetX === 'undefined' ) ? e.targetTouches[0].clientX : e.clientX;
		this.mousePos.y = ( typeof e.offsetY === 'undefined' ) ? e.targetTouches[0].clientY : e.clientY;

		if ( el !== null ) {
			this.mousePos.offset = {
				x: this.mousePos.x + ( window.innerWidth - el.width ),
				y: this.mousePos.y,
			}
		}
		return this.mousePos;
	}

	// #####################################################################
	// Animation Method
	// #####################################################################

	cameraToPixelGroup( x, y, width, height ) {
		this.cameraTargetX = x;
		this.cameraTargetY = y;
		this.cameraTargetWidth = width;
		this.cameraTargetHeight = height;
		this.isCenterOnCanvasAnimPlaying = true;
	}

	cancelAnimation() {
		this.isCenterOnCanvasAnimPlaying = false;
	}

	centerCanvasOn( x, y ) {
		x = x + 0.5;
		y = y + 0.5;
		const zoom = this._ctx.getTransform().a;
		const pt = this._ctx.transformedPoint( -x * zoom, -y * zoom );
		const offsetX = (( this._canvas.width / 2 ) / zoom );
		const offSetY = (( this._canvas.height ) / 2 ) / zoom ;

		// Calculate Distance to Target
		const center_pixel = this.getCenterScreenPixel();
		const a = center_pixel.canvas.x - x;
		const b = center_pixel.canvas.y - y;
		const distance = Math.sqrt( a*a + b*b );

		// const dx = (( pt.x + offsetX ) *  Math.sqrt( this.PAN_VELOCITY / distance ) );
		// const dy = (( pt.y + offSetY ) *  Math.sqrt( this.PAN_VELOCITY / distance ) );
		const dx = (( pt.x + offsetX ) * this.PAN_VELOCITY);
		const dy = (( pt.y + offSetY ) * this.PAN_VELOCITY);

		const middle = this._ctx.transformedPoint( this._canvas.width / 2, this._canvas.height / 2 );
		const result = this.isImageFramed();

		// Finished?
		if ( result.result && result.centered ) {
			return true;
		}

		// Zoom OUT
		if ( result.reason === "close" ) {
			if ( !this.setZoom( this.getZoom() - ( this.getZoom() * Math.sqrt( this.ZOOM_VELOCITY / distance ) ), middle.x, middle.y ) ) {
				return true;
			}
		}

		// Zoom IN
		if ( result.reason === "far"  ) {
			if ( !this.setZoom( this.getZoom() + (this.getZoom() * Math.sqrt( this.ZOOM_VELOCITY / distance ) ), middle.x, middle.y ) ) {
				return true;
			}
		}

		// PAN
		if ( !result.centered ) {
			this._ctx.translate( dx, dy );
			this._ui_ctx.translate( dx, dy );
		}

		this.dirty_image = true;

		return false;
	}

	isImageFramed( iWidth = this.cameraTargetWidth, iHeight = this.cameraTargetHeight, x = this.cameraTargetX, y = this.cameraTargetY ) {
		x = x + 0.5;
		y = y + 0.5;

		// Minimum image size to prevent need for zooming in super close
		if ( iWidth < 20 )  { iWidth = 20; }
		if ( iHeight < 20 ) { iHeight = 20; }

		const zoom = this._ctx.getTransform().a;
		const percentHeight = this._canvas.width / 100;
		const percentWidth = this._canvas.width / 100;
		const center = this._ctx.transformedPoint( this._canvas.width / 2, this._canvas.height / 2 );

		const outerHeight = this._canvas.width - ( percentHeight * 60 );
		const outerWidth =  this._canvas.width - ( percentWidth * 60 );
		const innerHeight = this._canvas.width - ( percentHeight * 70 );
		const innerWidth =  this._canvas.width - ( percentWidth * 70 );
		const scaledImageHeight = iHeight * zoom;
		const scaledImageWidth = iWidth * zoom;

		// console.log( `Image: ${iWidth} x ${iHeight} :: Canvas: ${cWidth} x ${cHeight} :: Frame ${fWidth} x ${fHeight} :: Image: ${x} x ${y} Center: ${center.x.toFixed()} x ${center.y.toFixed()}` );
		let isCentered = true;

		// PRIORITIES
		if ( Math.abs(x - center.x) > 0.01 || Math.abs(y - center.y) > 0.01 ) {
			isCentered = false;
		}

		if ( scaledImageWidth > outerWidth || scaledImageHeight > outerHeight ) {
			return { result: false, reason: "close", centered: isCentered };
		}

		if ( scaledImageWidth < innerWidth && scaledImageHeight < innerHeight ) {
			return { result: false, reason: "far", centered: isCentered };
		}

		return { result: true, reason: "good", centered: isCentered };
	}
}

exports.Viewer = Viewer;


function drawBitcoinSymbol( ctx, x, y, size, opacity = 1 ) {
	ctx.save();
	ctx.translate( x, y );
	ctx.scale( size, size );

	// CIRCLE
	ctx.save();

	ctx.imageSmoothingEnabled = true;
	ctx.webkitImageSmoothingEnabled = true;

	ctx.fillStyle= `rgba(247, 147, 26, ${opacity})`;
	ctx.beginPath();
	ctx.moveTo(63.033,39.744);
	ctx.arc( 31.5, 31.5, 31.5, 0, 2 * Math.PI);
	ctx.fill();
	// ctx.stroke();
	ctx.closePath();
	ctx.restore();

	// LOGO
	ctx.save();
	ctx.fillStyle= `rgba( 0,0,0,${opacity})`;
	// ctx.font="   10px sans-serif";
	ctx.beginPath();
	ctx.moveTo(46.103,27.444);
	ctx.bezierCurveTo(46.74,23.186,43.498000000000005,20.897,39.065,19.369999999999997);
	ctx.lineTo(40.503,13.601999999999997);
	ctx.lineTo(36.992,12.726999999999997);
	ctx.lineTo(35.592,18.342999999999996);
	ctx.bezierCurveTo(34.669,18.112999999999996,33.721,17.895999999999997,32.778999999999996,17.680999999999997);
	ctx.lineTo(34.18899999999999,12.027999999999999);
	ctx.lineTo(30.679999999999993,11.152999999999999);
	ctx.lineTo(29.240999999999993,16.918999999999997);
	ctx.bezierCurveTo(28.476999999999993,16.744999999999997,27.726999999999993,16.572999999999997,26.99899999999999,16.391999999999996);
	ctx.lineTo(27.002999999999993,16.373999999999995);
	ctx.lineTo(22.160999999999994,15.164999999999996);
	ctx.lineTo(21.226999999999993,18.914999999999996);
	ctx.bezierCurveTo(21.226999999999993,18.914999999999996,23.831999999999994,19.511999999999997,23.776999999999994,19.548999999999996);
	ctx.bezierCurveTo(25.198999999999995,19.903999999999996,25.455999999999992,20.844999999999995,25.412999999999993,21.590999999999994);
	ctx.lineTo(23.77499999999999,28.161999999999992);
	ctx.bezierCurveTo(23.87299999999999,28.18699999999999,23.999999999999993,28.222999999999992,24.13999999999999,28.278999999999993);
	ctx.bezierCurveTo(24.02299999999999,28.249999999999993,23.89799999999999,28.217999999999993,23.76899999999999,28.186999999999994);
	ctx.lineTo(21.472999999999992,37.391999999999996);
	ctx.bezierCurveTo(21.298999999999992,37.824,20.857999999999993,38.471999999999994,19.86399999999999,38.226);
	ctx.bezierCurveTo(19.89899999999999,38.277,17.31199999999999,37.589,17.31199999999999,37.589);
	ctx.lineTo(15.56899999999999,41.608);
	ctx.lineTo(20.13799999999999,42.747);
	ctx.bezierCurveTo(20.987999999999992,42.96,21.82099999999999,43.183,22.64099999999999,43.393);
	ctx.lineTo(21.18799999999999,49.227000000000004);
	ctx.lineTo(24.694999999999993,50.102000000000004);
	ctx.lineTo(26.133999999999993,44.330000000000005);
	ctx.bezierCurveTo(27.09199999999999,44.59,28.02199999999999,44.830000000000005,28.931999999999995,45.056000000000004);
	ctx.lineTo(27.497999999999994,50.801);
	ctx.lineTo(31.008999999999993,51.676);
	ctx.lineTo(32.461999999999996,45.853);
	ctx.bezierCurveTo(38.449,46.986000000000004,42.95099999999999,46.529,44.846,41.114000000000004);
	ctx.bezierCurveTo(46.373,36.754000000000005,44.769999999999996,34.239000000000004,41.62,32.599000000000004);
	ctx.bezierCurveTo(43.913999999999994,32.07,45.641999999999996,30.561000000000003,46.102999999999994,27.444000000000003);
	ctx.closePath();
	ctx.moveTo(38.081,38.693);
	ctx.bezierCurveTo(36.996,43.053,29.655,40.696,27.275000000000006,40.105);
	ctx.lineTo(29.203000000000007,32.376);
	ctx.bezierCurveTo(31.583000000000006,32.97,39.215,34.146,38.081,38.693);
	ctx.closePath();
	ctx.moveTo(39.167,27.381);
	ctx.bezierCurveTo(38.177,31.347,32.067,29.332,30.085,28.838);
	ctx.lineTo(31.833000000000002,21.828000000000003);
	ctx.bezierCurveTo(33.815000000000005,22.322000000000003,40.198,23.244000000000003,39.167,27.381000000000004);
	ctx.closePath();
	ctx.fill();
	// ctx.stroke();
	ctx.restore();
	ctx.restore();
	// ctx.restore();

}

function panel( ctx, x, y, width, height, color, bevel = 15, shadow = true ) {
	ctx.save();

	ctx.imageSmoothingEnabled = false;
	ctx.webkitImageSmoothingEnabled = false;

	ctx.lineCap = 'round';
	ctx.fillStyle = color;

	// if ( shadow ) {
	// 	ctx.shadowColor = 'black';
	// 	ctx.shadowBlur = 4;
	// 	ctx.shadowOffsetY = 3;
	// 	ctx.shadowOffsetX = 3;
	// }

	const bv = bevel;

	ctx.translate( x, y );

	ctx.beginPath();

	ctx.moveTo( 0, bv );

	ctx.quadraticCurveTo( 0, 0, bv, 0 );
	ctx.lineTo( width - bv, 0 );

	ctx.quadraticCurveTo( width, 0, width, bv );
	ctx.lineTo( width, height - bv );

	ctx.quadraticCurveTo( width, height, width - bv, height );
	ctx.lineTo( bv, height );

	ctx.quadraticCurveTo( 0, height, 0, height - bv );
	ctx.closePath();

	ctx.fill();

	ctx.restore();
}
