/*
 * Copyright PrimeVR 2020
 * @author roskelld https://github.com/roskelld
 */

const bitcoin = require("bitcoinjs-lib");
const verify = require("bitcoinjs-message").verify;
const b11 = require("bolt11");

const TIMESTAMP_WINDOW = 60 * 15; // fifteen minutes
const MIN_BOLT11_EXPIRY = TIMESTAMP_WINDOW + 300; // five minutes beyond window
const MIN_LN_SATOSHIS = 1000;
const LOOKUP_URI = 'https://po1.sparkshot.io/lookup';
const LN_WITHDRAW_URI = 'https://po1.sparkshot.io/withdraw-ln';
const CHAIN_WITHDRAW_URI = 'https://po1.sparkshot.io/withdraw-chain';

class Payout {
    constructor() {

    }

    ///////////////////////////////////////////////////////////////////////////////

    bolt11_is_valid( bolt11 ) {
        try {
            const decoded = b11.decode( bolt11 );
            console.log( decoded );
            return true;
        }
        catch (e) {
            return false;
        }
    }

    check_withdraw_bolt11( bolt11 ) {
        if (!this.bolt11_is_valid( bolt11 ) ) {
            return { valid: false, reason: "Could Not Decode Bolt11" };
        }
        const decoded = b11.decode(bolt11);
        console.log(decoded);
        const currency = decoded.coinType;
        const msat = decoded.millisatoshis;
        const created_at = decoded.timestamp;
        const expiry = decoded.timeExpireDate - decoded.timestamp;
        const now = Date.now() / 1000;

        if (msat === null) {
            return {
                valid: false,
                reason: "Invoice Must Specify Millisatoshi Amount"
            };
        }
        if ((parseInt(msat) / 1000) < MIN_LN_SATOSHIS) {
            return {
                valid: false,
                reason: "Invoice Must Request At Least 1000 Satoshis"
            };
        }
        if (created_at < (now - TIMESTAMP_WINDOW)) {
            return {
                valid: false,
                reason: `Bolt11 Created More Than ${TIMESTAMP_WINDOW} Seconds Ago`
            };
        }
        if (currency !== 'bitcoin') {
            return {
                valid: false,
                reason: "Bolt11 Is For Currency Other Than Bitcoin"
            };
        }
        if (expiry < MIN_BOLT11_EXPIRY) {
            return {
                valid: false,
                reason: "Bolt11 Expires Too Soon"
            };
        }
        return { valid: true, reason: '' };
    }

    uuidv4() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    }

    ////////////////////////////////////////////////////////////////////////////

    address_is_valid( address ) {
        // Validate that the provided address is encoded properly and has a valid
        // checksum.
        try {
            bitcoin.address.toOutputScript(address);
            return true;
        }
        catch (e) {
            return false;
        }
    }

    verify_signed_message( message, address, signature ) {
        const buf = Buffer.from(signature, 'base64');
        let header = buf.readUInt8(0);

        if (header < 27 || header > 42) {
            return false;
        }
        /* Some wallet signers don't produce the correct header according to
         * BIP 137. Here, we check the header and adjust it to match the type
         * of address provided.
         */
        if (header>= 39) {
            // this is a bech32 signature
            if (address.startsWith('1')) {
                console.log("adjusting header value: " + header);
                header -= 8;
                console.log("to: " + header);
            } else if (address.startsWith("3")) {
                console.log("adjusting header value: " + header);
                header -= 4;
                console.log("to: " + header);
            }
        }
        else if (header >= 35) {
            // this is a segwit p2sh signature
            if (address.startsWith('1')) {
                console.log("adjusting header value: " + header);
                header -= 4;
                console.log("to: " + header);
            } else if (address.startsWith("bc1")) {
                console.log("adjusting header value: " + header);
                header += 4;
                console.log("to: " + header);
            }
        }
        else if (header >= 31) {
            // this is a compressed key signature
            if (address.startsWith('3')) {
                console.log("adjusting header value: " + header);
                header += 4;
                console.log("to: " + header);
            } else if (address.startsWith("bc1")) {
                console.log("adjusting header value: " + header);
                header += 8;
                console.log("to: " + header);
            }
        }
        const sigbytes = buf.slice(1);
        const headerbytes = Buffer.from([header]);
        const newbytes = Buffer.concat([headerbytes, sigbytes]);

        try {
            return verify(message, address, newbytes);
        }
        catch (e) {
            // console.error(e);
            return false;
        }
    }

    ////////////////////////////////////////////////////////////////////////////


    generate_lookup_message() {
        const ts = Math.round((new Date()).getTime() / 1000) - 6;
        const uuid = this.uuidv4();
        const msg = {'time': ts,
                   'app':  "Sparkshot Payouts",
                   'call': "Lookup",
                   'uuid': uuid,
               };
        return JSON.stringify(msg);
    }

    generate_ln_withdraw_message( bolt11 ) {
        if ( !this.bolt11_is_valid( bolt11 ) ) {
            console.error("BAD BOLT11");
            return null;
        }

        const ts = Math.round((new Date()).getTime() / 1000) - 6;
        const uuid = this.uuidv4();
        const msg = {'time':     ts,
                   'app':        "Sparkshot Payouts",
                   'call':       "Withdraw",
                   'uuid':       uuid,
                   'type':       "Lightning",
                   'bolt11':     bolt11
               };
        return JSON.stringify(msg);
    }

    generate_lookup_submit_message( msg, addr, sig ) {
        const head = "-----BEGIN BITCOIN SIGNED MESSAGE-----";
        const mid = "-----BEGIN SIGNATURE-----";
        const foot = "-----END BITCOIN SIGNED MESSAGE-----";

        return head + "\n" + msg + "\n" + mid + "\n" + addr + "\n" + sig + "\n" + foot;
    }


    is_message_valid( msg, addr, sig ) {
        let is_valid = false;
        console.log("checking: " + msg + " " + addr + " " + sig + "len " + sig.length);
        try {
            is_valid = this.verifySignedMessage(msg, addr, sig);
            console.log("no err: " +  is_valid);
        }
        catch(err) {
            console.log(err);
            is_valid = false;
        }
        console.log(is_valid);
        return is_valid;
    }

    send_lookup( signed_msg, callback, error ) {
        const msg = JSON.stringify({ 'signed_message': signed_msg });
        // console.log( msg );
        fetch( LOOKUP_URI, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: msg,
        })
        .then( res => res.json() )
        .then( data => {
            if ( data.err ) {
                error(data);
            } else {
                callback(data);
            }
        })
        .catch( err => {
            error(err);
        });
    }

    send_ln_withdraw( signed_msg, callback, error ) {
        const msg = JSON.stringify({ 'signed_message': signed_msg });
        fetch( LN_WITHDRAW_URI, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: msg,
        })
        .then( res => res.json() )
        .then( data => {
            if ( data.err ) {
                error(data);
            } else {
                callback(data);
            }
        })
        .catch( err => {
            error(err);
        });
    }

    ////////////////////////////////////////////////////////////////////////////

    generate_chain_withdraw_message( satoshis, address, block_target ) {
        const ts = Math.round((new Date()).getTime() / 1000);
        const uuid = this.uuidv4();
        if ( !satoshis ) { console.error('satoshi value missing'); return; }
        if ( !address ) { console.error('address value missing'); return; }
        if ( !block_target ) { console.error('block_target value missing'); return; }

        // TODO - A better UI would validate the address as soon as it is paseted in.
        // this is just done here to make sure the bitcoinjs-lib technique works.
        if ( !this.address_is_valid(address) ) { console.error("Address is no valid"); return; }

        // TODO validate that the conf target is int between 1 and 1008 (inclusive)

        // TODO validate that on-chain withdrawal amount is int and greater than
        // 1000 satoshis.

        // TODO validate that the satoshi amount is not something completely
        // orders-of-magnitude high (user might have confused msats with sats)?
        // Perhaps this only requires a warning - do we want to enforce that they
        // do lookup before requesting withdraw and enforce that the sats are less
        // than obtained value?


        const msg = {'time':       ts,
                   'app':        "Sparkshot Payouts",
                   'call':       "Withdraw",
                   'uuid':        uuid,
                   'type':        "On Chain",
                   'satoshis':    parseInt(satoshis),
                   'address':     address,
                   'conf_target': parseInt(block_target),
               };
        return JSON.stringify(msg);
    }

    send_chain_withdraw( signed_msg, callback, error ) {
        const msg = JSON.stringify({ 'signed_message': signed_msg });
        fetch( CHAIN_WITHDRAW_URI, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: msg,
        })
        .then( res => res.json() )
        .then( data => {
            if ( data.err ) {
                error(data);
            } else {
                callback(data);
            }
        })
        .catch( err => {
            error(err);
        });
    }
}

exports.Payout = Payout;
