/**
 * Copyright PrimeVR 2020
 * @author roskelld https://github.com/roskelld
 */

const Utils = require('./utils.js').Utils;
// const sparkshot = require('./app.js').sparkshot;

class InfoBox {
    constructor( sparkshot ) {

        this.div = null;
        this.message    = null;
        this.price      = null;
        this.sparkshot  = sparkshot;
        this.position = {
                            x: 0,
                            y: 0,
                        };
        this.init();
    }

    init() {
        const remove = document.querySelector( '#infobox' );
        if ( remove ) { document.body.removeChild( remove ); }
        this.container = document.createElement( 'div' );
        this.container.id = "infobox";
        this.container.classList.add( 'ui-infobox', 'spark-grey', 'spark-blue-text', 'no-select' );

        // Add mouse support
        document.addEventListener( 'mousemove', e => {
            if ( !Utils.mobileCheck() ) {
                this.position.x = e.clientX;
                this.position.y = e.clientY;
                this.setPositon( e.clientX, e.clientY );
            }
        }, false );

        this.icon = document.createElement('div');
        this.icon.classList.add('ui-infobox-icon', 'hide');
        this.icon.innerHTML = `<i class="fas fa-lg fa-comment"></i>`;
        this.container.appendChild(this.icon);

        this.content = document.createElement('span');
        this.container.appendChild(this.content);
        document.body.appendChild( this.container );
        this.showMessage( this.message );
        this.hideMessage();
    }

    setMinWidth( width = 150 ) {
        this.container.style.minWidth = `${width}px`;
    }

    setPositon( x, y ) {
        if ( this.container === null ) { return; }

        // Set to left align if box will go off screen
        if ( x + this.container.offsetWidth + 10 >= window.innerWidth ) {
            x -= this.container.offsetWidth + 20;
        }

        // Lower box if it will go off the top of the screen
        if ( y - this.container.clientHeight - 20 <= 0 ) {
            y += this.container.clientHeight + 20;
        }

        this.container.style.left = `${x }px`;
        this.container.style.top  = `${y - this.container.clientHeight - 20}px`;
    }


    // clear() {
    //     document.removeEventListener( 'mousemove', this.setPositon, false );
    //
    //     if ( this.div !== null ) document.body.removeChild( this.div );
    //     this.message = '';
    //     this.div = null;
    // }

    showMessage( message, right_to_left = false ) {
        this.content.innerHTML = `${message}`;
        if ( right_to_left === true ) {
            this.content.style.direction = 'rtl';
        } else {
            this.content.style.direction = 'ltr';
        }
        if ( !Utils.mobileCheck() ) {
            this.setMinWidth( 50 );
        } else {
            this.container.style.left = `${this.sparkshot.UI.main.offsetLeft + 4}px`;
            this.container.style.top  = `${this.sparkshot.UI.top_nav.panel.offsetHeight + 4}px`;
        }

        if ( Utils.mobileCheck() ) {
            this.icon.classList.remove('hide');
        }

        this.container.classList.remove( 'hide' );
        // this.setPositon( this.position.x, this.position.y );
    }

    showPrice( price ) {
        if ( price < 1000 ) { price = `0${price}`; }
        this.setMinWidth();
        this.content.innerHTML = `<div><span><i class="fab fa-bitcoin orange-text"></i> <code>${Utils.NumToCommas(price, '.')} Satoshi</code></span></div>`;
        this.content.classList.remove( 'hide' );
    }

    showLocation( x, y ) {
        this.setMinWidth( 70 );
        this.content.innerHTML = `<div>x: ${x}, y: ${y}</div>`;
        this.container.classList.remove( 'hide' );
    }

    hideMessage() {
        this.container.classList.add( 'hide' );
        this.icon.classList.add('hide');
    }

    clear() {
        this.content.innerHTML = '';
        this.container.classList.add( 'hide' );
        this.icon.classList.add('hide');
    }
}

exports.InfoBox = InfoBox;
