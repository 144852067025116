/**
 * Copyright PrimeVR 2018
 * @author roskelld https://github.com/roskelld
 */

class Invoice {
    constructor ( arg ) {
        Object.assign( this, arg );
        this.start_time      = new Date().getTime() / 1000;
        this.node            = ( arg.node ) ? arg.node : '02e389d861acd9d6f5700c99c6c33dd4460d6f1e2f6ba89d1f4f36be85fc60f8d7@54.188.180.102:9735';

        // Used to match against incoming invoice from server
        this.basket          = ( arg.basket ) ? arg.basket : null;
        this.estimate        = ( arg.estimate )? arg.estimate : null;
        this.isExpired       = false;
    }

    update( update ) {
        Object.assign( this, update );
    }
}

exports.Invoice = Invoice;
