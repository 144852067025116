/**
* Copyright PrimeVR 2018
* @author roskelld https://github.com/roskelld
*/
'use strict';

const Utils = require('./utils.js').Utils;

const NOTIFICATIONS = {
    0:  'INVOICE_ISSUED',
    1:  'INVOICE_STALE',
    2:  'INVOICE_DOUBLE',
    3:  'LOBBY_STATE',
    4:  '__NOT_USED__', // preserve numbering
    5:  'TROLLBOX_MSG',
    6:  'ART_STATE',
    7:  'ART_UPDATE',
    9:  'PIXEL_INFO',
    10: 'UPLOAD_RECIEVED',
    11: 'UPLOAD_ERROR',
    12: 'CHECKED_URL',
    13: 'CLIENT_RESET',
    14: 'SEARCH_SUGGESTIONS',
    15: 'PONG',
    16: 'ERROR'
};

const PIXEL_RECORD_SIZE = 6;

const OVERPAY_TYPES = {
    0: '1x',
    1: '10x',
    2: '100x',
    3: '1000x',
};

const MOD_SETTING = {
    0: 'NONE',
    1: 'NSFW',
    2: 'NOPE',
};

const FORMAT_VERSION = {
    0: 'FV_ZERO',
};

const KEEP_ALIVE_TIME = 10 * 1000;

///////////////////////////////////////////////////////////////////////////////
// within metadata plate:

const FORMAT_VERSION_START = 0;
const FORMAT_VERSION_SIZE = 4;
const FORMAT_VERSION_END = FORMAT_VERSION_START + FORMAT_VERSION_SIZE;

const ART_ID_START = FORMAT_VERSION_END;
const ART_ID_SIZE = 256;
const ART_ID_END = ART_ID_START + ART_ID_SIZE;
const TITLE_START = ART_ID_START + ART_ID_SIZE;
const TITLE_SIZE = 64;
const TITLE_END = TITLE_START + TITLE_SIZE;
const DESCRIPTION_START = TITLE_START + TITLE_SIZE;
const DESCRIPTION_SIZE = 256;
const DESCRIPTION_END = DESCRIPTION_START + DESCRIPTION_SIZE;

const TAG0_START = DESCRIPTION_END;
const TAG0_SIZE = 32;
const TAG0_END = TAG0_START + TAG0_SIZE;

const TAG1_START = TAG0_END;
const TAG1_SIZE = 32;
const TAG1_END = TAG1_START + TAG1_SIZE

const TAG2_START = TAG1_END;
const TAG2_SIZE = 32;
const TAG2_END = TAG2_START + TAG2_SIZE

const TAG3_START = TAG2_END;
const TAG3_SIZE = 32;
const TAG3_END = TAG3_START + TAG3_SIZE

const TAG4_START = TAG3_END;
const TAG4_SIZE = 32;
const TAG4_END = TAG4_START + TAG4_SIZE

const TAG5_START = TAG4_END;
const TAG5_SIZE = 32;
const TAG5_END = TAG5_START + TAG5_SIZE

const TAG6_START = TAG5_END;
const TAG6_SIZE = 32;
const TAG6_END = TAG6_START + TAG6_SIZE

const TAG7_START = TAG6_END;
const TAG7_SIZE = 32;
const TAG7_END = TAG7_START + TAG7_SIZE

const BTC_ADDR_START = TAG7_END;
const BTC_ADDR_SIZE = 64;
const BTC_ADDR_END = BTC_ADDR_START + BTC_ADDR_SIZE;
const MERKLE_ROOT_START = BTC_ADDR_START + BTC_ADDR_SIZE;
const MERKLE_ROOT_SIZE = 256;
const MERKLE_ROOT_END = MERKLE_ROOT_START + MERKLE_ROOT_SIZE;
const WIDTH_HEIGHT_START = MERKLE_ROOT_START + MERKLE_ROOT_SIZE;
const WIDTH_HEIGHT_SIZE = 3;
const WIDTH_HEIGHT_END = WIDTH_HEIGHT_START + WIDTH_HEIGHT_SIZE;

const TOTAL_PRICE_START = WIDTH_HEIGHT_END;
const TOTAL_PRICE_SIZE = 8;
const TOTAL_PRICE_END = TOTAL_PRICE_START + TOTAL_PRICE_SIZE;

const UPLOAD_TIME_START = TOTAL_PRICE_END;
const UPLOAD_TIME_SIZE = 8;
const UPLOAD_TIME_END = UPLOAD_TIME_START + UPLOAD_TIME_SIZE;

const FIRST_ATTACH_TIME_START = UPLOAD_TIME_END;
const FIRST_ATTACH_TIME_SIZE = 8;
const FIRST_ATTACH_TIME_END = FIRST_ATTACH_TIME_START + FIRST_ATTACH_TIME_SIZE;

const PAID_PX_COUNT_START = FIRST_ATTACH_TIME_END;
const PAID_PX_COUNT_SIZE = 8;
const PAID_PX_COUNT_END = PAID_PX_COUNT_START + PAID_PX_COUNT_SIZE;

const MSATOSHIS_PAID_START = PAID_PX_COUNT_END;
const MSATOSHIS_PAID_SIZE = 8;
const MSATOSHIS_PAID_END = MSATOSHIS_PAID_START + MSATOSHIS_PAID_SIZE;

const N_USER_STRING_START = MSATOSHIS_PAID_END;
const N_USER_STRING_SIZE = 3;
const N_USER_STRING_END = N_USER_STRING_START + N_USER_STRING_SIZE;

const N_REPLAY_START = N_USER_STRING_START + N_USER_STRING_SIZE;
const N_REPLAY_SIZE = 3;
const N_REPLAY_END = N_REPLAY_START + N_REPLAY_SIZE;

const ORIGINAL_REL_START = N_REPLAY_END;
const ORIGINAL_REL_SIZE = 256;
const ORIGINAL_REL_END = ORIGINAL_REL_START + ORIGINAL_REL_SIZE;

const PLATE_REL_START = ORIGINAL_REL_END;
const PLATE_REL_SIZE = 256;
const PLATE_REL_END = PLATE_REL_START + PLATE_REL_SIZE;

const ART_PAGE_REL_START = PLATE_REL_END;
const ART_PAGE_REL_SIZE = 256;
const ART_PAGE_REL_END = ART_PAGE_REL_START + ART_PAGE_REL_SIZE;

const PRICE_REL_START = ART_PAGE_REL_END;
const PRICE_REL_SIZE = 256;
const PRICE_REL_END = PRICE_REL_START + PRICE_REL_SIZE;

const MERKLE_REL_START = PRICE_REL_END;
const MERKLE_REL_SIZE = 256;
const MERKLE_REL_END = MERKLE_REL_START + MERKLE_REL_SIZE;

const SIGNED_MESSAGE_REL_START = MERKLE_REL_END;
const SIGNED_MESSAGE_REL_SIZE = 256;
const SIGNED_MESSAGE_REL_END = SIGNED_MESSAGE_REL_START + SIGNED_MESSAGE_REL_SIZE;

const THUMBNAIL_REL_START = SIGNED_MESSAGE_REL_END;
const THUMBNAIL_REL_SIZE = 256;
const THUMBNAIL_REL_END = THUMBNAIL_REL_START + THUMBNAIL_REL_SIZE;

// within art plate:
const METADATA_PLATE_START = 6;
const METADATA_PLATE_SIZE = THUMBNAIL_REL_END;
const METADATA_PLATE_END = METADATA_PLATE_START + METADATA_PLATE_SIZE;
const PIXEL_PLATE_START = METADATA_PLATE_START + METADATA_PLATE_SIZE;

///////////////////////////////////////////////////////////////////////////////
// user string plate


// fields in header
const REVISION_START = 0;
const REVISION_SIZE = 8;
const REVISION_END = REVISION_START + REVISION_SIZE;
const US_N_USER_STRINGS_START = REVISION_END;
const US_N_USER_STRINGS_SIZE = 4;
const US_N_USER_STRINGS_END = US_N_USER_STRINGS_START + US_N_USER_STRINGS_SIZE;
const USER_STRINGS_SIZE_START = US_N_USER_STRINGS_END;
const USER_STRINGS_SIZE_SIZE = 4;
const USER_STRINGS_SIZE_END = USER_STRINGS_SIZE_START + USER_STRINGS_SIZE_SIZE;
// plate header
const USER_STRINGS_HEADER_START = 0;
const USER_STRINGS_HEADER_END = USER_STRINGS_SIZE_END;
const USER_STRINGS_HEADER_SIZE = (USER_STRINGS_SIZE_END -
                                  USER_STRINGS_HEADER_START);

const USER_STRINGS_START = 0;

// individual user string header
const REPLAY_INDEX_START = USER_STRINGS_START;
const REPLAY_INDEX_SIZE = 3;
const REPLAY_INDEX_END = REPLAY_INDEX_START + REPLAY_INDEX_SIZE;
const TARGET_START = REPLAY_INDEX_END;
const TARGET_SIZE = 3;
const TARGET_END = TARGET_START + TARGET_SIZE;
const STRING_LENGTH_START = TARGET_END;
const STRING_LENGTH_SIZE = 1;
const STRING_LENGTH_END = STRING_LENGTH_START + STRING_LENGTH_SIZE;
const STRING_START = STRING_LENGTH_END;
const USER_STRING_HEADER_SIZE = STRING_LENGTH_END - REPLAY_INDEX_START;
const USER_STRING_HEADER_END = STRING_LENGTH_END;

const DISCRETE_TARGET_SHORT = {
    0: "No one",
    1: "Artist",
    2: "Jesus",
    3: "President Trump",
    4: "Satoshi",
    5: "Sparkshot Developers",
};

const DISCRETE_TARGET_LONG = {
    0: "No one in particular",
    1: "The artist that created this art.",
    2: "Always Praise Lord Jesus Christ",
    3: "The President of the United States, Donald J. Trump",
    4: "The author and original developer of the Bitcoin.",
    5: "The people that brought you this application.",
};

///////////////////////////////////////////////////////////////////////////////
// replay plate

// fields in header
const R_REVISION_START = 0;
const R_REVISION_SIZE = 8;
const R_REVISION_END = R_REVISION_START + R_REVISION_SIZE;
const R_N_REPLAYS_START = R_REVISION_END;
const R_N_REPLAYS_SIZE = 4;
const R_N_REPLAYS_END = R_N_REPLAYS_START + R_N_REPLAYS_SIZE;
const REPLAYS_SIZE_START = R_N_REPLAYS_END;
const REPLAYS_SIZE_SIZE = 4;
const REPLAYS_SIZE_END = REPLAYS_SIZE_START + REPLAYS_SIZE_SIZE;
// plate header
const REPLAYS_HEADER_START = 0;
const REPLAYS_HEADER_END = REPLAYS_SIZE_END;
const REPLAYS_HEADER_SIZE = (REPLAYS_SIZE_END - REPLAYS_HEADER_START);

const REPLAYS_START = REPLAYS_SIZE_END;

// indivisual replay heaer
const N_COORDS_START = R_REVISION_START;
const N_COORDS_SIZE = 3;
const N_COORDS_END = N_COORDS_START + N_COORDS_SIZE;
const TIMESTAMP_START = N_COORDS_END;
const TIMESTAMP_SIZE = 5;
const TIMESTAMP_END = TIMESTAMP_START + TIMESTAMP_SIZE;
const COORDS_START = TIMESTAMP_END;
const REPLAY_HEADER_START = N_COORDS_START;
const REPLAY_HEADER_END = COORDS_START;
const REPLAY_HEADER_SIZE = COORDS_START - REPLAY_HEADER_START;

// coordinate in replay
const XY_START = 0;
const XY_SIZE = 3;
const XY_END = XY_SIZE - XY_START;

class WS {
    constructor ( sparkshot, args ) {
        this.sparkshot = sparkshot
        this.host = ( args ) ? args.host : window.location.hostname;
        this.port = ( args ) ? args.port : 443;
        this.url = "wss://ws1.sparkshot.io";
        this._updateCount = 0;
        this.pako = require('pako');
    }

    init() {
        // Create a new socket
        this.socket = new WebSocket( this.url );
        // // console.log("WebSocket: " + this.url );
        // Bind the socket to this class instance
        this.socket.onmessage   = (e) => this.processReceivedNotification(e);
        this.socket.onclose     = (e) => this.onclose(e);
        this.socket.onopen      = (e) => this.onopen(e);
        this.socket.onerror     = (e) => this.onerror(e);

        this.messageCount = 0;
        this.requestCount = 0;

        window.addEventListener( "beforeunload", () => {
            // console.log( 'UNLOADING' );
            this.socket.close()}, false );
    }

    ///////////////////////////////////////////////////////////////////////////
    // query validation
    ///////////////////////////////////////////////////////////////////////////

    validate_query( query ) {
        const SEARCH_QUERY_MAX = 100;

        if (query.search_query.length > SEARCH_QUERY_MAX) {
            console.warn("invalid 1");
            return false;
        }
        const SORT_CHOICES = {'ATTACH_DATETIME':  true,
                              'SIZE_IN_PIXELS':   true,
                              'PERCENT_COMPLETE': true,
                              'CURRENT_VALUE':    true,
                              'NUM_PURCHASES':    true,
                              'HOTNESS':          true,
                              'SEARCH_MATCH':     true,
                              };
        if (! SORT_CHOICES[query.sort_choice]) {
            console.warn("invalid 5");
            return false;
        }
        const SORT_DIRECTION_CHOICES = {'ASCENDING':  true,
                                        'DESCENDING': true};
        if (! (query.sort_direction in SORT_DIRECTION_CHOICES)) {
            console.warn("invalid 6");
            return false;
        }

        if (query.complete_min < 0.0) {
            console.warn("invalid 2");
            return false;
        }
        if (query.complete_max > 1.0) {
            console.warn("invalid 3");
            return false;
        }
        if (query.complete_min > query.complete_max) {
            console.warn("invalid 4");
            return false;
        }

        if (query.attach_datetime_min < 0.0) {
            console.warn("invalid 8");
            return false;
        }
        if (isNaN(query.attach_datetime_max)) {
            if (query.attach_datetime_max != "+inf") {
                console.warn("invalid 8.1");
                return false;
            }
        } else if (query.attch_datetime_max > 1.0) {
            console.warn("invalid 9");
            return false;
        }

        const HOTNESS_WINDOW_CHOICES = {'DAY':   true,
                                        'WEEK':  true,
                                        'MONTH': true};
        if (! HOTNESS_WINDOW_CHOICES[query.hotness_window]) {
            console.warn("invalid 7");
            return false;
        }
        if (query.hotness_min < 0.0) {
            console.warn("invalid 8");
            return false;
        }
        if (isNaN(query.hotness_max)) {
            if (query.hotness_max != "+inf") {
                console.warn("invalid 8.1");
                return false;
            }
        } else if (query.hotness_max > 1.0) {
            console.warn("invalid 9");
            return false;
        }


        if (query.result_start < 0) {
            console.warn("invalid 10");
            return false;
        }
        if (query.result_count < 0) {
            console.warn("invalid 11");
            return false;
        }
        const MAX_RESULT_COUNT = 100;
        if (query.result_count > MAX_RESULT_COUNT) {
            console.warn("invalid 12");
            return false;
        }
        return true;
    }

    ///////////////////////////////////////////////////////////////////////////
    // Connection:
    ///////////////////////////////////////////////////////////////////////////

    onclose( e ) {
        console.log( 'WebSocket:', e.type );
        // TODO: Handle server loss better
        clearTimeout( this.keep_alive_timer );
    }

    onopen( e ) {
        // console.log( 'WebSocket:', e.type );

        // TODO: CHECK URI and run appropreate setup

        const query = {
            search_query:            "",
            sort_choice:            "HOTNESS",
            sort_direction:         "DESCENDING",
            complete_min:            0.0,
            complete_max:            1.0,
            attach_datetime_min:     0.0,
            attach_datetime_max:    "+inf",
            hotness_window:          "DAY",
            hotness_min:             0.0,
            hotness_max:             "+inf",
            result_start:            0,
            result_count:            50,
        }

        // this.request_art_list();
        this.sparkshot.State.processURL();

        this.keep_alive();
    }

    onerror( e ) {
        console.log( 'WebSocket:', e.type );
        setTimeout( () => this.sparkshot.UI.showMessage('Cannot connect to server', 'Refreshing browser window in 3 seconds', false ), 1000);

        setTimeout( () => {
            window.location.href = "/";
            // if ( payload.return_to_lobby ) {
            // } else {
            //
            //     window.location.reload();
            // }
        }, 3000);
    }

    keep_alive() {
        this.keep_alive_timer = setTimeout( () => {
            this.request_ping();
            // console.log( 'PING' );
        }, KEEP_ALIVE_TIME );
    }

    ///////////////////////////////////////////////////////////////////////////
    // Requests:
    ///////////////////////////////////////////////////////////////////////////

    request_trollbox_msg( message, art_id ) {
        // console.log(`request_trollbox_msg: ${message} ${art_id}`);

        // const req = {
        //                 request:    "TROLLBOX_MSG",
        //                 message:    message,
        //                 art_id:     art_id,
        //             };

        const req = { request:    "TROLLBOX_MSG",
                      request_no: this.requestCount,
                      payload:    message
                    };
        this.requestCount++;
        this.socket.send( JSON.stringify( req ) );
        // Send a chat message to the trollbox
    }

    request_trollbox_name( name ) {
        // console.log(`request_trollbox_name: ${name}`);
        const req = { request:    "TROLLBOX_NAME",
                      request_no: this.requestCount,
                      payload:    name
                    };
        this.requestCount++;
        this.socket.send( JSON.stringify( req ) );
        // Set the name of this client to be shown in the trollbox
    }

    request_mark_paid( label ) {
        // console.log(`request_mark_paid: ${label}`);
        const req = { request:    "MARK_PAID",
                      request_no: this.requestCount,
                      payload:    label
                    };
        this.requestCount++;
        this.socket.send( JSON.stringify( req ) );
        // This is a test-mode-only request to allow easy payment of an invoice
        // via a convenient frontend button.
    }

    request_invoice( pixels, message, target = '0,0' ) {
        console.log(`request_invoice: ${pixels} ${message}`);
        const req = { request:                "INVOICE",
                      request_no:             this.requestCount,
                      px_list:                pixels,
                      user_string:            message,
                      user_string_target:     target,
                    };
        this.requestCount++;
        this.socket.send( JSON.stringify( req ) );
        // requests an invoice for a collection of pixels with a particular
        // user message e.g. the string 1_2_3_4_5_6 specifies coordinates
        // (1,2), (3,4), and (5,7).
    }

    request_cancel_invoice( label ) {
        // console.log( `request_cancel_invoice ${label}` );
        const req = { request:    "CANCEL_INVOICE",
                      request_no: this.requestCount,
                      payload:    label
                    };
        this.requestCount++;
        this.socket.send( JSON.stringify( req ) );
    }

    request_goto_art( art_id ) {
        // console.log('request_goto_art', art_id);
        const req = { request:    "GOTO_ART",
                      request_no: this.requestCount,
                      payload:    art_id
                    };
        this.requestCount++;
        this.socket.send( JSON.stringify( req ) );
        // Requests to move to examining a piece of art
    }

    request_art_list( query = {} ) {
        // console.log( 'request_art_list' );
        this.messageCount++
        // console.log( `Messages: ${this.messageCount}`);
        // console.log( this.sparkshot.State );
        const payload = {
            search_query:            query.search_query || this.sparkshot.State.search_query,
            sort_choice:             query.sort_choice || this.sparkshot.State.sort_choice,
            sort_direction:          query.sort_direction || this.sparkshot.State.sort_direction,
            complete_min:            query.complete_min || this.sparkshot.State.complete_min,
            complete_max:            query.complete_max || this.sparkshot.State.complete_max,
            attach_datetime_min:     query.attach_datetime_min || this.sparkshot.State.attach_datetime_min,
            attach_datetime_max:     query.attach_datetime_max || this.sparkshot.State.attach_datetime_max,
            hotness_window:          query.hotness_window || this.sparkshot.State.hotness_window,
            hotness_min:             query.hotness_min || this.sparkshot.State.hotness_min,
            hotness_max:             query.hotness_min || this.sparkshot.State.hotness_max,
            result_start:            query.result_start || this.sparkshot.State.result_start,
            result_count:            query.result_count || this.sparkshot.State.result_count,
        }

        // console.log( 'SEARCHING FOR');
        // console.log( payload.result_count );
        let valid = this.validate_query( payload );
        if ( !valid ) console.log( 'SEARCH PAYLOAD IS NOT VALID' );

        // console.log( payload );

        const req = { request:    "ART_LIST",
                      request_no: this.requestCount,
                      payload:    payload
                    };
        this.requestCount++;
        this.socket.send( JSON.stringify( req ) );
    }

    request_pixel_info( pixel ) {
        // console.log( `request_pixel_info ${pixel}` );
        const req = { request:    "PIXEL_INFO",
                      request_no: this.requestCount,
                      payload:    pixel
                    };
        this.requestCount++;
        this.socket.send( JSON.stringify( req ) );
        // Queries the detailed info about a specific pixel.
        // "1_1" is the specification for for pixel (1,1)
    }

    request_check_url( art_id ) {
        // console.log( `Requesting: ${art_id}`);
        if ( art_id === '' ) return;
        const req = { request: "CHECK_URL",
                      request_no: this.requestCount,
                      payload: art_id
                    };
        this.requestCount++;
        this.socket.send( JSON.stringify( req ) );
        // queries about whether a particular proposed URL is available
    }

    request_search_suggest( prefix ) {

        const req = { request:    "SEARCH_SUGGEST",
                      request_no: this.requestCount,
                      payload:    prefix
                    };
        this.requestCount++;
        // console.log( 'REQUESTING: ', prefix );
        this.socket.send( JSON.stringify( req ) );
    }

    request_upload_art( data ) {
        const req = {
            request:        "UPLOAD_ART",
            request_no:     this.requestCount,
            art_id:         data.art_id,
            email:          data.email,
            contact:        data.twitter,
            tags:           data.tags,
            art: {
                base64:     data.art.base64,
                filename:   data.art.filename,
            },
            signed_msg:     data.signed_msg,
            price: {
                base64:     data.price.base64,
                filename:   data.price.filename,
            }
        };

        // console.log( JSON.stringify( req ) );

        this.requestCount++;
        this.socket.send( JSON.stringify( req ) );

    }

    request_ping( prefix ) {
        const req = { request:    "PING",
                      request_no: this.requestCount,
                    };
        this.requestCount++;
        // console.log( 'REQUESTING: ', prefix );
        this.socket.send( JSON.stringify( req ) );
    }

    ///////////////////////////////////////////////////////////////////////////////
    // user string plate

    parseUserStringHeader(dv, start) {
        let cursor = start;
        const revision = Utils.int8(dv, cursor);
        cursor = start + US_N_USER_STRINGS_START;
        const n_user_strings = Utils.int4(dv, cursor);
        cursor = start + USER_STRINGS_SIZE_START;
        const user_strings_size = Utils.int4(dv, cursor);
        return {'revision':          revision,
                'n_user_strings':    n_user_strings,
                'user_strings_size': user_strings_size,
               };
    }

    parseUserStringPlate(dv, start, user_strings_size, n_user_strings) {
        let decoder = new TextDecoder('utf-8');
        let cursor = start + USER_STRINGS_START;
        // Create list in memory - not used for anything but might be needed
        // in the real implementation
        const parsed_user_strings = new Array(n_user_strings);
        let parsed_counter = 0;
        let i = 0;
        while (i < user_strings_size) {
            let first_byte = dv.getUint8(cursor);
            const mod_setting = MOD_SETTING[((dv.getUint8(cursor) & 0x30) >> 4)];
            const replay_index = Utils.intM(dv, cursor);
            cursor = cursor + REPLAY_INDEX_SIZE;
            // skip parsing target for a moment
            let target_idx = cursor;
            cursor = cursor + TARGET_SIZE;
            const string_length = dv.getUint8(cursor);
            cursor = cursor + STRING_LENGTH_SIZE;
            const is_coord = ((dv.getUint8(target_idx) & 0x80) >> 7) != 0;
            const right_to_left = ((dv.getUint8(target_idx) & 0x40) >> 6) != 0;
            let parsed;
            if (is_coord) {
                const x = Utils.highIntC(dv, target_idx);
                const y = Utils.lowIntC(dv, target_idx);
                parsed = {'right_to_left': right_to_left,
                          'x':             x,
                          'y':             y,
                         };
            } else {
                const target_val = Utils.intM(dv, target_idx);
                const target_short = DISCRETE_TARGET_SHORT[target_val];
                const target_long = DISCRETE_TARGET_LONG[target_val];
                parsed = {'is_coord':      false,
                          'target_short':  target_short,
                          'target_long':   target_long,
                         };
            }
            parsed['replay_index'] = replay_index;
            parsed['right_to_left'] = right_to_left;
            parsed['mod_setting'] = mod_setting;
            parsed['user_string'] = Utils.utf8buf2string(decoder, dv, cursor,
                                                         cursor + string_length);
            cursor = cursor + string_length;
            parsed_user_strings[parsed_counter] = parsed;
            i = i + USER_STRING_HEADER_SIZE + string_length;
            parsed_counter++;
            //console.log(parsed);
        };
        const UserStringState = parsed_user_strings;
        return UserStringState;
    }

    ///////////////////////////////////////////////////////////////////////////////
    // replay plate

    parseReplayHeader(dv, start) {
        let cursor = start;
        const revision = Utils.int8(dv, cursor);
        cursor = cursor + R_REVISION_SIZE;
        const n_replays = Utils.int4(dv, cursor);
        cursor = cursor + R_N_REPLAYS_SIZE;
        const replays_size = Utils.int4(dv, cursor);
        return {'revision':     revision,
                'n_replays':    n_replays,
                'replays_size': replays_size,
               }
    }

    parseReplayPlate(dv, start, replays_size, n_replays) {
        const replays_start = start + REPLAYS_HEADER_SIZE
        let cursor = replays_start;
        const parsed_replays = new Array(n_replays);
        let parsed_counter = 0;
        let i = 0;
        while (i < replays_size) {
            const n_coords = Utils.intM(dv, cursor);
            cursor = cursor + N_COORDS_SIZE;
            const timestamp = Utils.int5(dv, cursor);
            cursor = cursor + TIMESTAMP_SIZE;
            const coords_size = n_coords * XY_SIZE;
            const  coords = new Array(n_coords);
            let j = 0;
            let coord_idx = 0;
            while (j < coords_size) {
                const x = Utils.highIntC(dv, cursor);
                const y = Utils.lowIntC(dv, cursor);
                j = j + XY_SIZE;
                coords[coord_idx] = {'x': x, 'y': y};
                coord_idx++;
                cursor = cursor + XY_SIZE;
            }
            const parsed_replay = {'timestamp': timestamp,
                                   'coords':    coords};
            parsed_replays[parsed_counter] = parsed_replay;
            i = i + REPLAY_HEADER_SIZE + (n_coords * XY_SIZE);
            parsed_counter++;
        }
        const ReplayState = parsed_replays;
        return parsed_replays;
    }

    ///////////////////////////////////////////////////////////////////////////////
    // pixel plate

    calcPixelPlateSize(width, height) {
        return width * height * PIXEL_RECORD_SIZE;
    }

    parsePixelInfo(dv, cursor) {

        const bought = ((dv.getUint8(cursor) & 0x80) >> 7) != 0;

        if (bought) {
            const overpay_val = (dv.getUint8(cursor) & 0x60) >> 5;
            const overpay = OVERPAY_TYPES[overpay_val];
            const has_user_string = ((dv.getUint8(cursor) & 0x10) >> 4) != 0;
            const index = Utils.intM(dv, cursor);

            const high = dv.getUint8(cursor + 3);
            const mid = dv.getUint8(cursor + 4);
            const low = dv.getUint8(cursor + 5);
            //const color_val = Utils.three2int(high, mid, low);

            //const color = "#" + ("000000" + color_val.toString(16)).substr(-6);
            return {'bought':          true,
                    'overpay':         overpay, // indicator of overpay
                    'has_user_string': has_user_string, // whether has user_string
                    'index':           index, // index into user string log or replay log
                    'color':           [high, mid, low], // color of pixel
                   };
        }
        const invoiced = ((dv.getUint8(cursor) & 0x40) >> 6) != 0;
        const msatoshis = Utils.intS(dv, cursor + 1);
        return {'bought':    false,
                'invoiced':  invoiced, // whether the pixel has an unpaid invoice
                'msatoshis': msatoshis, // price of pixel
               };
    }

    parsePixelPlate(dv, start, width, height) {
        //console.log("enter parsePixelPlate: " + new Date().toString());
        const pixel_state = new Array(width);
        const rgba_array = new Uint8ClampedArray(width * height * 4 /* 4-byte RGBA per pixel*/);
        for (let x = 0; x < width; x++) {
            pixel_state[x] = new Array(height);
            for (let y = 0; y < height; y++) {
                let cursor = start + (x * PIXEL_RECORD_SIZE * height) + (y * PIXEL_RECORD_SIZE);
                const pixel_info = this.parsePixelInfo(dv, cursor);
                pixel_state[x][y] = pixel_info;

                if (pixel_info.bought) {
                    let byte_offset = (((height - y - 1) * width) + x) * 4;
                    rgba_array[byte_offset] = pixel_info.color[0];
                    rgba_array[byte_offset + 1] = pixel_info.color[1];
                    rgba_array[byte_offset + 2] = pixel_info.color[2];
                    rgba_array[byte_offset + 3] = 255;
                }
            }
        }
        let image_data = new ImageData(rgba_array, width, height);
        let parsed_pixel_data = [pixel_state, image_data];
        //console.log("exit parsePixelPlate: " + new Date().toString());
        return parsed_pixel_data;
    }

    parseMetadataPlate(dv, start) {
        let decoder = new TextDecoder('utf-8');
        let cursor = start + FORMAT_VERSION_START;
        const format_version = FORMAT_VERSION[Utils.int4(dv, cursor)];
        cursor = start + ART_ID_START;
        const art_id = Utils.buf2string(dv, cursor, cursor + ART_ID_SIZE);
        cursor = start + TITLE_START;
        const title = Utils.utf8tag2string(decoder, dv, cursor, cursor + TITLE_SIZE);
        cursor = start + DESCRIPTION_START;
        const description = Utils.utf8tag2string(decoder, dv, cursor, cursor + DESCRIPTION_SIZE);
        cursor = start + TAG0_START;
        const tag0 = Utils.utf8tag2string(decoder, dv, cursor, cursor + TAG0_SIZE);
        cursor = start + TAG1_START;
        const tag1 = Utils.utf8tag2string(decoder, dv, cursor, cursor + TAG1_SIZE);
        cursor = start + TAG2_START;
        const tag2 = Utils.utf8tag2string(decoder, dv, cursor, cursor + TAG2_SIZE);
        cursor = start + TAG3_START;
        const tag3 = Utils.utf8tag2string(decoder, dv, cursor, cursor + TAG3_SIZE);
        cursor = start + TAG4_START;
        const tag4 = Utils.utf8tag2string(decoder, dv, cursor, cursor + TAG4_SIZE);
        cursor = start + TAG5_START;
        const tag5 = Utils.utf8tag2string(decoder, dv, cursor, cursor + TAG5_SIZE);
        cursor = start + TAG6_START;
        const tag6 = Utils.utf8tag2string(decoder, dv, cursor, cursor + TAG6_SIZE);
        cursor = start + TAG7_START;
        const tag7 = Utils.utf8tag2string(decoder, dv, cursor, cursor + TAG7_SIZE);
        const tags = [tag0, tag1, tag2, tag3, tag4, tag5, tag6, tag7];

        cursor = start + BTC_ADDR_START;
        const btc_addr = Utils.buf2string(dv, cursor, cursor + BTC_ADDR_SIZE);
        cursor = start + MERKLE_ROOT_START;
        const merkle_root = Utils.buf2string(dv, cursor, cursor + MERKLE_ROOT_SIZE);

        cursor = start + WIDTH_HEIGHT_START;
        // Utils.printHex(dv, cursor, 3);
        const width = Utils.highIntC(dv, cursor);
        const height = Utils.lowIntC(dv, cursor);

        cursor = start + TOTAL_PRICE_START;
        const total_price = Utils.int8(dv, cursor);

        cursor = start + UPLOAD_TIME_START;
        const upload_time = Utils.int8(dv, cursor);

        cursor = start + FIRST_ATTACH_TIME_START;
        const first_attach_time = Utils.int8(dv, cursor);

        cursor = start + PAID_PX_COUNT_START;
        const paid_px_count = Utils.int8(dv, cursor);

        cursor = start + MSATOSHIS_PAID_START;
        const msatoshis_paid = Utils.int8(dv, cursor);

        cursor = start + N_USER_STRING_START;
        const n_user_string = Utils.intM(dv, cursor);

        cursor = start + N_REPLAY_START;
        const n_replay = Utils.intM(dv, cursor);

        cursor = start + ORIGINAL_REL_START;
        const original_rel = Utils.buf2string(dv, cursor, cursor + ORIGINAL_REL_SIZE);

        cursor = start + PLATE_REL_START;
        const plate_rel = Utils.buf2string(dv, cursor, cursor + PLATE_REL_SIZE);

        cursor = start + ART_PAGE_REL_START;
        const art_page_rel = Utils.buf2string(dv, cursor, cursor + ART_PAGE_REL_SIZE);

        cursor = start + PRICE_REL_START;
        const price_rel = Utils.buf2string(dv, cursor, cursor + PRICE_REL_SIZE);

        cursor = start + MERKLE_REL_START;
        const merkle_rel = Utils.buf2string(dv, cursor, cursor + MERKLE_REL_SIZE);

        cursor = start + SIGNED_MESSAGE_REL_START;
        const signed_message_rel = Utils.buf2string(dv, cursor, cursor + SIGNED_MESSAGE_REL_SIZE);

        cursor = start + THUMBNAIL_REL_START;
        const thumbnail_rel = Utils.buf2string(dv, cursor, cursor + THUMBNAIL_REL_SIZE);

        return {'format_version':     format_version,
                'art_id':             art_id,
                'title':              title,
                'description':        description,
                'tags':               tags,
                'btc_addr':           btc_addr,
                'merkle_root':        merkle_root,
                'width':              width,
                'height':             height,
                'total_price':        total_price,
                'upload_time':        upload_time,
                'first_attach_time':  first_attach_time,
                'paid_px_count':      paid_px_count,
                'msatoshis_paid':     msatoshis_paid,
                'n_user_string':      n_user_string,
                'n_replay':           n_replay,
                'original_rel':       original_rel,
                'plate_rel':          plate_rel,
                'art_page_rel':       art_page_rel,
                'price_rel':          price_rel,
                'merkle_rel':         merkle_rel,
                'signed_message_rel': signed_message_rel,
                'thumbnail_rel':      thumbnail_rel,
               }
    }

    ///////////////////////////////////////////////////////////////////////////
    // FIND HOME:
    ///////////////////////////////////////////////////////////////////////////

    handleBinArtState(ab) {
        // console.log("starting binary parse of " + ab.byteLength + " bytes.");
        const dv = new DataView(ab);

        let start = METADATA_PLATE_START;

        // read metadata from the front:
        const metadata = this.parseMetadataPlate(dv, start);
        // console.log(metadata);
        // identify pixel data:
        const pixel_plate_size = this.calcPixelPlateSize(metadata['width'], metadata['height']);
        const pixel_plate_end = PIXEL_PLATE_START + pixel_plate_size;

        // parse pixel data
        const parsed_pixel_data = this.parsePixelPlate(dv, PIXEL_PLATE_START, metadata.width, metadata.height);

        const pixels = parsed_pixel_data[0];
        const image_data = parsed_pixel_data[1];

        // identify user string data:
        const user_string_plate_header_start = pixel_plate_end;
        const user_string_plate_header_end = user_string_plate_header_start + USER_STRINGS_HEADER_END;

        const user_string_header_info = this.parseUserStringHeader(dv, user_string_plate_header_start);
        const user_string_plate_start = user_string_plate_header_end
        const user_string_plate_end = (user_string_plate_start +
                                       user_string_header_info['user_strings_size']);

        // parse user string data
        const user_strings = this.parseUserStringPlate(dv, user_string_plate_start,
            user_string_header_info['user_strings_size'],
            user_string_header_info['n_user_strings']);
        // identify replay data:
        const replay_plate_header_start = user_string_plate_end;
        const replay_plate_header_end = replay_plate_header_start + REPLAYS_HEADER_SIZE;
        const replay_header_info = this.parseReplayHeader(dv, replay_plate_header_start);
        const replay_plate_start = replay_plate_header_start;
        const replay_plate_end = (replay_plate_start + REPLAYS_HEADER_SIZE,
                                  replay_header_info['replays_size']);
        // parse replay data
        const replays = this.parseReplayPlate(dv, replay_plate_start,
                                   replay_header_info['replays_size'],
                                   replay_header_info['n_replays']);
        metadata.state = pixels;
        metadata.image_data = image_data;
        metadata.user_strings = user_strings;
        metadata.replays = replays;

        // render parsed data
        this.art_state( metadata );
    }

    ///////////////////////////////////////////////////////////////////////////
    // Notifications:
    ///////////////////////////////////////////////////////////////////////////

    processReceivedNotification(event) {
        if (event.data instanceof Blob) {
            // console.log(`processReceivedNotification: RECIEVED BINARY BLOB`);
            this.processReceivedBinaryNotification(event);
        } else {
            // console.log(`processReceivedNotification: RECIEVED TEXT`);
            this.processReceivedTextNotification(event);
        }
    }

    processReceivedTextNotification( event ) {
        const data = JSON.parse( event.data );
        const payload = data.payload;
        switch ( data.notification ) {
            case 'INVOICE_ISSUED':     this.invoice_issued( payload );    break;
            case 'INVOICE_STALE':      this.invoice_stale( payload );     break;
            case 'INVOICE_DOUBLE':     this.invoice_double( payload );    break;
            case 'LOBBY_STATE':        this.lobby_state( payload );       break;
            case 'TROLLBOX_MSG':       this.trollbox_msg( payload );      break;
            case 'ART_UPDATE':         this.art_update( payload );        break;
            case 'PIXEL_INFO':         this.pixel_info( payload );        break;
            case 'UPLOAD_RECIEVED':    this.upload_recieved( payload );   break;
            case 'UPLOAD_ERROR':       this.upload_error( payload );      break;
            case 'CHECKED_URL':        this.checked_url( payload );       break;
            case 'CLIENT_RESET':       this.client_reset( payload );      break;
            case 'SEARCH_SUGGESTIONS': this.search_suggestions( payload); break;
            case 'PONG':               this.pong( payload );              break;
            case 'ERROR':              this.error( payload );             break;
        }
    }

    processReceivedBinaryNotification(event) {
        // console.log(`processReceivedBinaryNotification`);
        this.printBinary(event.data);
        this.handleBinary(event.data);
    }

    printBinary(data) {
        const reader = new FileReader();
        reader.readAsText(data);
        const txt = reader.result;
        // console.log(txt);
    }

    handleBinary(data) {
        // console.log(`handleBinary`);
	    const reader = new FileReader();

	    reader.onload = event => {
            // console.log("starting decompression of " + event.target.result.byteLength + " bytes.");
            const compressed = new Uint8Array(event.target.result);
            const inflated = this.pako.inflate(compressed);
            this.handleNotification(inflated.buffer);
	    };
	    reader.readAsArrayBuffer(data);
	}

    handleNotification(ab) {
        const dv = new DataView(ab, 0, 6);
        const request_no = dv.getUint32(0, false);
        const type = dv.getUint16(4, false);
        // console.log("request_no: " + request_no);
        // console.log("type: " + type);
        // console.log( `TYPE: ${type}`);
        // console.log( `handleNotification ${NOTIFICATIONS[type]}`);
        switch ( NOTIFICATIONS[type] ) {
            case 'ART_STATE':          this.handleBinArtState(ab);        break;
        }
    }

    lobby_state( payload ) {
        // This is automatically sent to the client upon connecting. Contains
        // the current 'menu' of art along with the vitals and stats.

        // console.log( '%c lobby_state ', 'color: white; background: blue' );
        // const search = this.sparkshot.UI.top_nav.search.field.value;
        // console.log( Object.entries( payload ).length );

        this.sparkshot.State.setState( 'browse', payload );
    }

    art_state( payload ) {
        // console.log( 'art_state', payload );
        this.sparkshot.State.setState( 'viewer', payload );
        // Upon requesting to GOTO_ART, this is sent to the client and the
        // payload includes the entire current state of the art pixels.
    }
    art_update( payload ) {
        // console.log( 'art_update', payload );
        this._updateCount++;
        // console.log( 'UPDATE + 1', this.sparkshot.WS._updateCount );
        // This is pushed to all clients in a particular piece of art when
        // there is an update to the state of the art. The information passed
        // is a minimal update of just the changed pixels.
        if ( this.sparkshot.State.isState('viewer') ) {
            // Check to see if this is an invoiced update
            if ( this.sparkshot.IM.updateMatchInvoice( payload ) ) {
                this.sparkshot.IM.removeInvoice();
                this.sparkshot.UI.blocker( false );
                this.sparkshot.UI.closeInvoicePanel();
                this.sparkshot.Tutorial.completeHint( "pay_invoice" );      // Mark Tutorial Complete
            }
            this.sparkshot.Viewer.processUpdate( payload );
        }
    }
    pixel_info( payload ) {
        console.log( payload );
        // A response to a PIXEL_INFO request that provided the info from
        // the database with details about a specific pixel.
        if ( this.sparkshot.State.isState('viewer') ) {
            this.sparkshot.UI.showProbeData( payload );
        }
    }
    trollbox_msg( payload ) {
        // // console.log( payload );
        // A message from the trollbox that is echoed to all clients.
        this.sparkshot.Chat.addMessage( payload.user, payload.message );
    }
    invoice_issued( payload ) {
        console.log( payload );

        // A notification of a successful invoice request. Payload includes
        // the bolt11 invoice
        if ( this.sparkshot.State.isState('viewer') ) {
            this.sparkshot.IM.addInvoice( payload );
        }
    }
    invoice_stale( payload ) {
        console.log( 'invoice_stale', payload );
        // A notification that an invoice request could not be fulfilled
        // because one or more of the pixels was already purchased. The details
        // of the 'collision' are provided.
        this.sparkshot.IM.removeInvoice( payload );


    }
    invoice_double( payload ) {
        console.log( payload );
        // A notification that an invoice request could not be fulfilled
        // because one or more of the pixels already has an outstanding invoice
        // that is not yet expired. The details of the 'collision' is provided.
        if ( this.sparkshot.State.isState('viewer') ) {
            this.sparkshot.IM.doubleInvoice( payload.overlap );
        }
    }
    invoice_delete( payload ) {
        // A notification that an invoice has been deleted
        if ( this.sparkshot.State.isState('viewer') ) {
            this.sparkshot.IM.removeInvoice( payload );
        }
    }
    upload_recieved( payload ) {
        // // console.log( payload );
        // A notification that the art submission has been received,
        // successfully validated, and added to the backed in the 'detached'
        // state.
        this.sparkshot.Upload.uploadComplete( payload );
    }
    upload_error( payload ) {
        // A notification that the art submission has been rejected.
        this.sparkshot.Upload.uploadFailed( payload );

    }
    checked_url( payload ) {
        // A notification of whether a particular art_id proposed by a CHECK_URL
        // request is currently taken by another file, directory or piece of art
        this.sparkshot.Upload.setURLState( !payload );
    }
    search_suggestions( payload ) {
        // a notification of search suggestions matching a prefix sent as a SEARCH_SUGGEST request
        // console.log( payload );
        this.sparkshot.UI.autocomplete( this.sparkshot.UI.top_nav.search.instance, payload );
    }
    client_reset( payload ) {
        const action = ( payload.browser_refresh )
            ? `Page will refresh in ${payload.delay} seconds`
            : `Please refresh browser to attempt to reconnect.`;

        // Show disconnection message
        setTimeout( () => {
            this.sparkshot.UI.showMessage( 'Sever Connection Lost',
                    `${payload.message}. ${action}`,
                    false );
        }, 1000 );

        // Resolve state
        if ( !payload.browser_refresh ) return;
        setTimeout( () => {
            if ( payload.browser_refresh ) {

                if ( payload.return_to_lobby ) {
                    window.location.href = "/";
                } else {

                    window.location.reload();
                }
            }
        }, (payload.delay + 1) * 1000);

    }
    pong( payload ) {
        // console.log("PONG")
        // console.log( payload );
        this.keep_alive();
    }
    error( payload ) {
        console.log( payload );
        // Catch upload errors
        if ( payload === 'price base64 not a string' ||
             payload === 'signature not base64 encoded' ||
             payload === 'zero length price base64' ||
             payload === 'Tag is too long: 119 (max 32)' ||
             payload === 'message not in expected format' ||
             payload === 'message signature not valid' ) {
            this.sparkshot.Upload.uploadFailed( payload );
            return;
        }

        if ( payload === 'Art id contains disallowed chars') {
            this.sparkshot.Upload.setURLState( false );
            return;
        }

        // An error notification that is overloaded to include many different
        // conditions. Details of the failure is included in the payload.
        if ( typeof payload.hint == undefined )
            this.sparkshot.UI.showToast( `ERROR: ${payload}` );
        else
            this.sparkshot.UI.showToast( `ERROR: ${payload.hint}` );

        switch ( payload.request ) {
            case 'invoice':
                this.sparkshot.IM.removeInvoice();
                this.sparkshot.UI.blocker( false );
                this.sparkshot.UI.closeInvoicePanel();
                break;
            break;
            default:
                this.sparkshot.IM.removeInvoice();
                this.sparkshot.UI.blocker( false );
                this.sparkshot.UI.closeInvoicePanel();

        }
    }
}

exports.WS = WS;
