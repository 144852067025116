/*
 * Copyright PrimeVR 2020
 * @author roskelld https://github.com/roskelld
 */

class SideNav {
    constructor() {
        // Fetch all nav buttons
        this.nav = document.querySelectorAll(".side-nav-button");

        // Fetch all tab pages
        this.tabs = document.querySelectorAll(".side-nav-tab");

        if (!this.nav) { console.error('SideNav is missing references to Nav buttons: Add class side-nav-button'); }
        if (!this.tabs) { console.error('SideNav is missing references to Tab pages: Add class side-nav-tab'); }

        // Ensure tabs are all hidden by default
        this.tabs.forEach( e => {
            e.classList.remove('active');
            e.event = {
                open:   new Event('open'),
                close:  new Event('close'),
            };
        });

        // Add button listeners for tab switching
        this.nav.forEach( e => {
            e.addEventListener('click', b => {
                b.preventDefault();
                this.nav.forEach( e => e.classList.remove('active'));
                e.classList.add('active');
                this.selectTab(e.dataset.targetTab);
            });
        });

        this.current_tab = null;
    }

    selectTab(id) {
        this.tabs.forEach( tab => {
            if (tab.classList.contains('active')) {
                tab.dispatchEvent(tab.event.close);
                tab.classList.remove('active');
            }
         });
        this.nav.forEach( e => e.classList.remove('active'));

        const tab = Array.from(this.tabs).find( e => e.dataset.tab === id );

        if (tab) {
            // Show tab
            tab.classList.add('active');
            // Broadcast Event
            tab.dispatchEvent(tab.event.open);
            // Record current tab
            this.current_tab = id;
        } else {
            console.error( `SideNav: No Tab Found for ${id}` );
            this.current_tab = null;
        }

        const nav = Array.from(this.nav).find( e => e.dataset.targetTab === id );

        if (nav) {
            nav.classList.add('active');
        } else {
            console.error( `SideNav: No Nav Found for ${id}` );
        }
    }

    renderCurrentTab() {
        this.selectTab(this.current_tab);
    }
}

exports.SideNav = SideNav;
