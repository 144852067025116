/**
* Copyright PrimeVR 2020
* @author roskelld https://github.com/roskelld
*/

class PriceRadar {
    constructor ( ctx ) {
        this.ctx = ctx;

        this.UPDATE_RATE = 0.025;
        this.RADAR_SIZE = 9;

        this.COLORS = {
			"16000": 	"hsl(360, 100%, 50%)",
			"8000": 	"hsl(360, 100%, 75%)",
			"4000":		"hsl(360, 100%, 87.5%)",
			"2000":		"hsl(360, 100%, 93.5%)",
			"1000":		"hsl(360, 100%, 100%)",
			"500": 		"hsl(228, 100%, 87%)",
			"250": 		"hsl(228, 100%, 75%)",
			"125": 		"hsl(204, 100%, 50%)",
		};

        // Line Style
        this.LINE_JOIN          = "round";
        this.LINE_CAP           = "butt";
        this.LINE_DASH_OFFSET   = 0;
        this.STROKE_STYLE       = "rgba(0,0,0,194)";
        this.LINE_WIDTH         = "0.1";
        this.LINE_DASH          = [];

        this._CURRENT_SIZE = 1;
        this._UPDATE_DELTA = 0;
        this._ENABLED = false;
        this._PAUSE = false;
    }

    /**
     * Adds a ToolTip to a DOM element
     * @param  {Boolean} state - Set enabled state
     * @param  {Boolean} state - Clear current frame
     * @return {Boolean} returns new state
     */
    setEnabled( state = false, clear = false ) {
        this._ENABLED = state;
        if (clear) { this.clear(); }
        return this._ENABLED;
    }

    isEnabled() {
        return this._ENABLED;
    }

    pause() {
        this._PAUSE = true;
    }

    unpause() {
        this._PAUSE = false;
    }

    clear() {
        this._CURRENT_SIZE = 1;
    }

    update(dt) {
        // Sanitize delta
        dt = (isNaN(dt)) ? 0 : dt;

		// Heatmap update
        this._UPDATE_DELTA += dt;

        // When paused reduce size then stop drawing
        if ( this._PAUSE ) {
            if ( this._UPDATE_DELTA >= this.UPDATE_RATE ) {
                if ( this._CURRENT_SIZE > 1 ) {
                    this._CURRENT_SIZE = ( this._CURRENT_SIZE === 1 ) ? this._CURRENT_SIZE : this._CURRENT_SIZE - 1;
                }
                this._UPDATE_DELTA = 0;
            }
            return;
        }

		if ( this._ENABLED ) {
			if ( this._UPDATE_DELTA >= this.UPDATE_RATE ) {
				this._CURRENT_SIZE = ( this._CURRENT_SIZE >= this.RADAR_SIZE ) ? this.RADAR_SIZE : this._CURRENT_SIZE + 1;
				this._UPDATE_DELTA = 0;
			}
		} else {
            if ( this._UPDATE_DELTA >= this.UPDATE_RATE ) {
                this._CURRENT_SIZE = ( this._CURRENT_SIZE === 1 ) ? this._CURRENT_SIZE : this._CURRENT_SIZE - 1;
                this._UPDATE_DELTA = 0;
            }
		}
    }

    draw( x, y, data ) {
        if ( this._CURRENT_SIZE <= 1 ) { return; }
        if ( !this._ENABLED ) { return; }
        if ( typeof data.art_id !== "string" ) { return; }
        if ( isNaN(x) || x > data.width || x < 0 ) { return; }
        if ( isNaN(y) || y > data.height || y < 0 ) { return; }

		// Let's draw an array out from here
		// Make sure minimum size is 1;
		const size =  this._CURRENT_SIZE
		const offset = Math.max(1, Math.floor(size / 2));

		// Define Shape
        this.ctx.save();

		this.ctx.lineJoin 		= this.LINE_JOIN;
		this.ctx.lineCap 		= this.LINE_CAP;
        this.ctx.lineDashOffset = this.LINE_DASH_OFFSET;
		this.ctx.strokeStyle 	= this.STROKE_STYLE;
        this.ctx.lineWidth 		= this.LINE_WIDTH;
		this.ctx.setLineDash(this.LINE_DASH);

        for (let ox = 0; ox < size; ox++) {
            for (let oy = 0; oy < size; oy++) {
                const pos_x = x - offset + ox;
                const pos_y = y - offset + oy;
                if ( pos_x < 0 || pos_y < 0 || pos_x > data.width - 1 || pos_y > data.height - 1 ) {
                    
                } else {
                    const pixel = data.state[pos_x][ data.height - pos_y - 1];
                    if ( !pixel.bought ) {
                        const alpha_x = Math.abs((Math.abs(ox - offset) / (size - offset)) - 1);
                        const alpha_y = Math.abs((Math.abs(oy - offset) / (size - offset)) - 1);
                        const alpha = alpha_x * alpha_y;
                        this.ctx.globalAlpha = alpha;
                        this.ctx.strokeStyle = this.COLORS[pixel.msatoshis.toString()];
                        this.ctx.strokeRect( pos_x + 0.1, pos_y + 0.1, 0.8, 0.8 );
                    }
                }
            }
        }

        // Return Canvas to original state so no settings are overridden
        this.ctx.restore();
    }
}

exports.PriceRadar = PriceRadar;
