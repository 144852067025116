/*
 * Copyright PrimeVR 2020
 * @author roskelld https://github.com/roskelld
 */


class Privacy {
    constructor( sparkshot, options ) {

        this.sparkshot = sparkshot
        // Add functions to call only if policy accepted
        // format is `{ host: host_object, call: function_name }`
        // example: { host: this.Alpha, call: this.Alpha.init }
        this.buttons = {};
        this.accepted = options.accepted;

        // Update Policy Date whenever it changes by setting this.policyDate with new Date() string
        // Paste formatted policy into this.policy
        // Policy also needs cloning to privacy.html
        this.policyDate = "Fri Jun 07 2019 00:00:00 GMT-0600 (Mountain Daylight Time)";
        this.policy = `
            <h2 class="spark-blue-text bold">PRIVACY POLICY</h2>
            <p class="">
                This page informs you of our policies regarding the collection, use and disclosure of personal information we receive from users of our site (https://sparkshot.io). We use your personal information to better understand your usage of the site, and to collect traffic statistics.
                By using the site, you agree to the collection and use of information in accordance with this policy.
            </p>

            <h5 class="spark-blue-text bold">
                Log Data
            </h5>

            <p class="">
                Like many site operators, we collect information that your browser sends whenever you visit our site ("Log Data"). This Log Data may include information such as your computer's Internet Protocol ("IP") address, browser type, browser version, the pages of our site that you visit, the time and date of your visit, the time spent on those pages and other statistics.
            </p>
            <h5 class="spark-blue-text bold">
                Cookies
            </h5>
            <p class="">
                Cookies are files with small amount of data, which may include an anonymous unique identifier. Cookies are sent to your browser from a web site and stored on your computer's hard drive. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our site.
            </p>

            <p class="">
                We use cookies for the following purposes:
            </p>

            <ul class="browser-default ">
                <li>
                    To keep track of whether you have pressed the "OK" button on the cookie disclaimer, so we don't bother you with the notification if you have.
                </li>
                <li>
                    Our Analytics software (Google Analytics) uses cookies to measure and better understand user-interactions on our Site. You can read more about how Google Analytics uses cookies <a tabindex="-1" class="" href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage">here</a>.
                </li>
            </ul>

            <h5 class="spark-blue-text bold">
                Google Analytics
            </h5>

            <p class="">
                We use a third-party JavaScript plug-in provided by Google called "Google Analytics" to provide us with useful traffic statistics and to better understand how you use our site. We do not have direct access to the information obtained from Google Analytics, but Google provides us with a summary through their dashboard.
            </p>

            <p class="">
                We may share the information obtained from Google Analytics with business partners who are interested in advertising on our website. The information shared with these business partners will not contain any personally identifying information (Google does not provide us with direct access to the data and therefore we cannot see this information).
            </p>

            <p class="">
                You can opt-out of having your information collected by Google Analytics by downloading the Google Analytics opt-out browser add-on provided by Google. This will prevent your information being used by Google Analytics. Doing this will not affect your ability to use our Site in any way. You can download the opt-out browser add-on <a class="" href="https://tools.google.com/dlpage/gaoptout">here</a>. We also honor the Do Not Track header and will not track visitors who have Do Not Track switched on.
            </p>
            <h5 class="spark-blue-text bold">
                Changes to this Privacy Policy
            </h5>

            <p class="">
                We may update this privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on the Site. You are advised to review this privacy policy periodically for any changes.
                This Privacy Policy was last updated: 7th June, 2019.
            </p>

            <h5 class="spark-blue-text bold">
                Contact Us
            </h5>
            <p class="">
                If you have any questions about our privacy policy, or how your data is being collected and processed, please e-mail <a tabindex="-1" class="" href="contact@primevr.io">contact@primevr.io</a>.
            </p>`;

        // Policy modal HTML wrapper
        this.containerHTML = `<div class="row no-margin ">
                    <nav class="no-margin z-depth-0 gradient-1">
                        <div class="nav-wrapper">
                            <ul >
                                <li class="right"><a id="privacy-close-btn" title="Close"><i class="fas fa-times  text-lighten-1 fa-2x"></i></a></li>
                            </ul>
                        </div>
                    </nav>
                </div>

                <section class="white-text scroller">
                    <article class="container block">

                        <div class="row">

                            <div id="privacy-policy" class="col s12">



                            </div>

                    </article>
                </section>`;
    }

    init() {
        // #####################################################################
        // PRIVACY PANEL
        // #####################################################################

        // Add HTML Container
        this.container = document.createElement('div');
        this.container.id = "privacy-modal";
        this.container.classList.add( "modal", "gradient-1", "z-depth-4" );
        this.container.style.maxHeight= "85% !important"; //
        this.container.style.top = "5% !important"; // !important; overflow-x: hidden;">
        this.container.innerHTML = this.containerHTML;

        document.body.appendChild( this.container );

        this.privacyModal = document.querySelector('#privacy-modal');

        M.Modal.init( this.privacyModal, {} );

        this.buttons.close = document.querySelector( `#privacy-close-btn` );

        this.buttons.close.addEventListener( "click", () => {
            M.Modal.getInstance( this.privacyModal ).close();
        });

        // Add latest policy to panel
        const div = document.querySelector( '#privacy-policy' );
        if ( div != null )
            div.innerHTML = this.policy;
        else
            console.error("Could not find Privacy Policy Element");

        // Check to see if user accepted policy already
        this.date = this.sparkshot.Settings.getLocalData( 'privacy-policy' );

        if ( this.date === null || new Date( this.date ) < new Date( this.policyDate ) ) {
            // Show button panel to review / accept
            this.addButtonModalHTML();
        } else {
            // Run any post accept functions
            this.onAccept();
        }

        // Find  all buttons that open the privacy panel
        document.querySelectorAll( '.privacy-open-btn' ).forEach( e => {
            e.style.cursor = 'pointer';
            e.addEventListener( "click", btn => {
                M.Modal.getInstance( this.privacyModal ).open();
            });
        });
    }

    closePopup() {
        // Hide the Button Panel
        M.Modal.getInstance( this.privacyModal ).close();
        M.Modal.getInstance( this.buttonModal ).close();
    }

    accept() {
        // Save date of accept
        this.sparkshot.Settings.saveLocalData( 'privacy-policy', new Date() );
        this.closePopup();
        this.onAccept();
    }

    onAccept() {
        // If Array of functions are given, then run each of them
        if ( Array.isArray(this.accepted) ) {
            this.accepted.forEach( (obj, idx) => {
                if ( typeof obj.host[obj.call] === 'function' ) {
                    // Call the fuction from the hosting object
                    // keep intended scope
                    obj.host[obj.call]();
                } else {
                    console.error(`Privacy (Index ${idx}): Cannot call ${obj.call} not a function`);
                }
            } );
        }
    }

    addButtonModalHTML() {
        // Generate Modal
        this.div = document.createElement('div');
        this.div.id = "privacy-accept-modal";
        this.div.classList.add("modal", "bottom", "right", "gradient-1", "z-depth-4", "white-text");
        this.div.innerHTML = `
            <div class="row">
                <div class="col s12">
                    <h4 class="center">
                        <i class="fas fa-meh"></i>
                    </h4>
                    <h6 class="white-text center">
                        Please review our privacy policy.
                    </h6>
                    <div class="center">
                        <a id="" class="privacy-open-btn waves-effect waves-light btn grey" style="width: 40%;">REVIEW</a>
                        <a id="privacy-policy-accept-btn" class="waves-effect waves-light btn gradient-4" style="width: 40%;">ACCEPT</a>
                    </div>
                </div>
            </div>`;
        document.body.appendChild(this.div);

        this.buttonModal = document.querySelector('#privacy-accept-modal');

        M.Modal.init( this.buttonModal, {} );

        M.Modal.getInstance( this.buttonModal ).open();

        this.buttons.accept = document.querySelector( '#privacy-policy-accept-btn' );

        this.buttons.accept.addEventListener( 'click', () => {
            this.accept();
        }, false );
    }
}

exports.Privacy = Privacy;
