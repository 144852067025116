/**
 * Copyright PrimeVR 2020
 * @author roskelld https://github.com/roskelld
 * Based on: https://css-tricks.com/building-progress-ring-quickly/
 */

class ProgressCircle {
    constructor( options ) {

        // options
        this.width = ( options.size ) ? options.size : 50;
        this.height = this.width;
        this.stroke_width = ( options.stroke_width ) ? options.stroke_width : 4;
        this.id = ( options.id ) ? options.id : "progress-circle";

        this.base_color = ( options.base_color ) ? options.base_color : "red";
        this.mid_color = ( options.mid_color ) ? options.mid_color : "yellow";
        this.high_color = ( options.high_color ) ? options.high_color : "orange";
        this.vhigh_color = ( options.vhigh_color ) ? options.vhigh_color : "green";

        this.mid_percent = ( options.mid_percent ) ? options.mid_percent : 60;
        this.high_percent = ( options.high_percent ) ? options.high_percent : 80;
        this.vhigh_percent = ( options.vhigh_percent ) ? options.vhigh_percent : 100;

        // Is the color change handled manually
        this.manual_color_set = ( options.manual_color_set ) ? options.manual_color_set : false;

        this.background_color = ( options.background_color ) ? options.background_color : "#3f3f3f";
        this.current_color = ( options.base_color ) ? options.base_color : "white";

        this.reverse_color = ( options.reverse_color ) ? options.reverse_color : false;

        this.parent_id = ( options.parent_id ) ? options.parent_id : null;

        // generated
        this.radius = this.width / 2 - ( this.stroke_width / 2 );
        this.circumference = this.radius * 2 * Math.PI;

        this.el = null;

        // create circle
        this.init();
    }

    init() {
        const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
        svg.classList.add( 'progress-ring' );
        svg.setAttributeNS( null, 'width', this.width );
        svg.setAttributeNS( null, 'height', this.height );
        if ( this.background_color === 'none' ) {
            svg.setAttribute( 'style', `border-radius: 100%;` );
        } else {
            svg.setAttribute( 'style', `background: ${this.background_color}; border-radius: 100%;` );
        }
        const circle = document.createElementNS("http://www.w3.org/2000/svg", "circle");
        circle.id = this.id;
        circle.classList.add( 'progress-ring__circle' );
        circle.setAttribute( 'style', 'transition: stroke-dashoffset 0.35s; transform: rotate(-90deg); transform-origin: 50% 50%');
        circle.setAttributeNS( null, 'stroke', this.current_color );
        circle.setAttributeNS( null, 'stroke-dasharray', this.circumference,  this.circumference );
        circle.setAttributeNS( null, 'stroke-dashoffset', this.circumference );
        circle.setAttributeNS( null, 'fill', 'transparent' );
        circle.setAttributeNS( null, 'stroke-width', this.stroke_width );
        circle.setAttributeNS( null, 'r', this.radius );
        circle.setAttributeNS( null, 'cx', this.width / 2 );
        circle.setAttributeNS( null, 'cy', this.height / 2 );

        svg.appendChild( circle );
        this.circle = circle;
        this.el = svg;

        if ( this.parent_id !== null ) {
            this.parentEl = document.querySelector( `#${this.parent_id}`);
            if ( this.parentEl !== null ) {
                this.parentEl.appendChild( this.el );
            } else {
                console.error(`ProgressCircle Error: Cannot find element id: ${parent_id}`);
            }
        }
    }

    setProgress( percent ) {
        // Convert input percent to max
        if ( percent > 100 ) percent = 100;
        if ( percent < 0 ) percent = 0;
        const offset = this.circumference - percent / 100 * this.circumference;
        this.circle.setAttributeNS( null, 'stroke-dashoffset', offset );

        if ( !this.manual_color_set && !this.reverse_color ) {
            if ( percent >= this.vhigh_percent ) this.setColor( this.vhigh_color );
            else if ( percent >= this.high_percent ) this.setColor( this.high_color );
            else if ( percent >= this.mid_percent ) this.setColor( this.mid_color );
            else if ( percent < this.mid_percent ) this.setColor( this.base_color );
        } else if ( !this.manual_color_set ) {
            if ( percent >= this.vhigh_percent ) this.setColor( this.base_color );
            else if ( percent >= this.high_percent ) this.setColor( this.mid_color );
            else if ( percent >= this.mid_percent ) this.setColor( this.high_color );
            else if ( percent < this.mid_percent ) this.setColor( this.vhigh_color );

        }

    }

    setManualColorChange( value = false ) {
        this.manual_color_set = value;
    }

    setColor( color ) {
        this.circle.setAttributeNS( null, 'stroke', color );
        this.current_color = color;
    }

    getColor() {
        return this.current_color;
    }
}

exports.ProgressCircle = ProgressCircle;
