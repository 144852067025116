/**
* Copyright PrimeVR 2020
* @author roskelld https://github.com/roskelld
*/

const Utils = require('./utils.js').Utils;
const ProgressCircle = require('./progress-circle.js').ProgressCircle;
let EXTERNAL_PAY_WINDOW = null;

class UI {
    constructor ( sparkshot ) {
        const args = ( arguments[0] ) ? arguments[0] : {};

        ////////////////////////////////////////////////////////////////////////
        // Data
        ////////////////////////////////////////////////////////////////////////
        this.sparkshot = sparkshot;
        this.searchquery = '';

        this.VALID_MESSAGE_CHARACTERS = /[a-zA-Z0-9!$&\-=_+':\",.? ]/gm;
        // this.INVALID_MESSAGE_CHARACTERS = /[^a-zA-Z0-9!$&\-=_+':\",.? ]/gm;
        this.INVALID_MESSAGE_CHARACTERS = /([\n\t])+/gm;
        this.MESSAGE_CHAR_PRICE = 100;


        this.BAD_CHARACTERS_MESSAGE = "Text contains disallowed characters.";

        this.MAX_MESSAGE_LENGTH = 255;
        this.current_message_encode = 0;
        this.current_message_remain = 0;


        // .....................................................................
        // TIMER COLOR CONFIG
        this.color = {
            normal: 'rgb(57, 188, 237)',
            moderate: '#F15524',
            low: '#EF2B2D'
        };
        this.expired = {
            qrcode: '../img/expired-qr.png',
        };

        // .....................................................................
        // INITIALIZE MATERIALIZE
        const tabs = document.querySelectorAll('.tabs');
        const tabOptions = {
        };


        const modals = document.querySelectorAll('.modal');
        const modalOptions = {
            opacity: 1,
            outDuration: 0,
            dismissible: false,
            preventScrolling: true,
            startingTop: '1%',
            endingTop: '5%'
        };
        this.modals = modals;

        const chips = document.querySelectorAll('.chips');
        const chipOptions = {
            limit:                  8,
            placeholder:            'Enter a tag',
            secondaryPlaceholder:   '+Tag',
            onChipAdd:              this.addedChip,
            minLength:              3
        };


        const toolTips = document.querySelectorAll('.tooltipped');
        const toolTipOptions = {
            exitDelay: 0,
            outDuration: 10,
        };

        const dropDowns = document.querySelectorAll('.dropdown-trigger');
        const dropDownOptions = {
            coverTrigger: true,
            hover: false,
            constrainWidth: false,
            autoTrigger: false,
            closeOnClick: true,
        };

        const autocomplete = document.querySelectorAll('.autocomplete');
        M.Autocomplete.init( autocomplete, {
                                                 minLength: 0,
                                                 limit: 10,
                                            });
        M.Autocomplete.getInstance( autocomplete[0] ).dropdown.options.closeOnClick = true;

        this.floatingActionButtons = document.querySelectorAll('.fixed-action-btn');
        this.floatingActionOptions = { direction: 'right', hoverEnabled: true, };

        this.chipInstances = M.Chips.init( chips, chipOptions );
        this.tabInstances = M.Tabs.init( tabs, tabOptions );
        this.modalInstances = M.Modal.init( modals, modalOptions );
        this.toolTipInstances = M.Tooltip.init( toolTips, toolTipOptions );
        this.floatingActionInstance = M.FloatingActionButton.init( this.floatingActionButtons, this.floatingActionOptions );
        this.dropdownInstances = M.Dropdown.init( dropDowns, dropDownOptions );

        // .....................................................................
        // MAIN WINDOW LAYOUT
        this._heightPad = document.getElementsByTagName('header')[0];
		this._footerPad = document.getElementsByTagName('footer')[0];

        // .....................................................................
        // MAIN APP PANEL
        this.main = document.querySelector( '#app' );
        this.content = null;

        // .....................................................................
        // CHAT PANEL
        this.chat = {
            panel:          document.querySelector( '#ui-chat-panel' ),
            controls:       document.querySelector( '#ui-chat-control' ),
        };

        this.controls = {
            panel:          document.querySelector( '#ui-viewer-controls' ),
            buttons: {
                zoom_in:    document.querySelector( '#ui-viewer-controls-zoom-in' ),
                zoom_out:   document.querySelector( '#ui-viewer-controls-zoom-out' ),
                frame:      document.querySelector( '#ui-viewer-controls-zoom-max' ),
                price_radar:document.querySelector( '#ui-viewer-controls-price-probe' ),
                background_color:document.querySelector( '#ui-viewer-controls-background-color' ),

            }
        };

        this.mobile_controls = {
            panel:                  document.querySelector( '#mobile-art-controls' )
        };

        // .....................................................................
        // BOTTOM NAV PANEL
        this.top_nav = {
            panel:          document.querySelector( `#nav-header` ),
            hover:          document.querySelector( `#${args.hover}` ),
            selected:       document.querySelector( `#${args.selected}` ),
            search: {
                instance:   M.Autocomplete.getInstance( autocomplete[0] ),
                field:      document.querySelector( `#search-field` ),
                button:     document.querySelector( `#search-button` ),
            },
            buttons: {
                artist:     document.querySelector( `#artist-button` ),
                about:      document.querySelectorAll('[data-sparkshot-button="open-about"]'),
                upload:     document.querySelectorAll('[data-sparkshot-button="open-upload"]'),
                profile:    document.querySelectorAll('[data-sparkshot-button="open-profile"]'),
                wallet:     document.querySelectorAll('[data-sparkshot-button="open-wallet"]'),
                payments:   document.querySelectorAll('[data-sparkshot-button="open-payment"]'),
                faq:        document.querySelector( `#faq-button` ),
            }
        };

        // .....................................................................
        // SORT PANEL
        this.sort = {
            panel:          document.querySelector( `#ui-sort-panel` ),
            buttons: {
                all:        document.querySelector( `#ui-sort-all-btn` ),
                incomplete: document.querySelector( `#ui-sort-incomplete-btn` ),
                complete:   document.querySelector( `#ui-sort-complete-btn` ),
                new:        document.querySelector( `#ui-sort-new-btn` ),
                hot:        document.querySelector( `#ui-sort-hot-btn` ),
                almost:     document.querySelector( `#ui-sort-almost-btn` ),
            },
            views: {
                all:      'ALL',
                fresh:      'FRESH',
                gallery:    'GALLERY',
            },
            current_view:   'FRESH',
        };
        this.circle = null;


        // .....................................................................
        // BOTTOM NAV PANEL
        this.bottom_nav = {
            panel:          document.querySelector( `#nav-footer` ),
            hover:          document.querySelector( `#${args.hover}` ),
            selected:       document.querySelector( `#${args.selected}` ),
            invoiceEnabled: false,
            buttons: {
                invoicePanel: document.querySelector( `#invoice-button` ),
            },
            tools: {
                panel:      document.querySelector( '#ui-tools-panel' ),
                trash:      document.querySelector( '#ui-tools-trash' ),
                count:      document.querySelector( '#ui-tools-price' ),
                message:    document.querySelector( '#ui-tools-message' ),
                total_message: new ProgressCircle({  size: 45,
                                                        stroke_width: 2,
                                                        id: 'ui-tools-message-total',
                                                        parent_id: 'ui-tools-total-message',
                                                        manual_color_set: true,
                                                        background_color: 'none'
                                                    }),
                message_progress: new ProgressCircle({  size: 45,
                                                        stroke_width: 2,
                                                        id: 'ui-tools-message-limit',
                                                        parent_id: 'ui-tools-message-progress',
                                                        base_color: '#39bced',
                                                        mid_color:  '#39bced',
                                                        high_color: '#39bced',
                                                        vhigh_color:  '#39bced',
                                                        background_color: '#252525'
                                                    }),
                message_count:    document.querySelector( '#ui-tools-message-characters' ),
            },
        };

        // .....................................................................
        // MODAL PANELS
        this.modal = {
            blocker:                    document.querySelector( `#ui-blocker` ),
            message: {
                instance:               this.modalInstances.find( ( obj ) => { return obj.id === 'message-modal'; } ),
                title:                  document.querySelector( `#message-title` ),
                body:                   document.querySelector( `#message-body` ),
            },
            about: {
                panel:                  document.querySelector( `#about-modal` ),
                instance:               this.modalInstances.find( ( obj ) => { return obj.id === 'about-modal'; } ),
                buttons: {
                    close:              document.querySelector( `#about-close-btn` ),
                    help:               document.querySelector( `#about-help-btn` ),

                },
                cards: [
                                        document.querySelector( `#about-card-one` ),
                                        document.querySelector( `#about-card-two` ),
                                        document.querySelector( `#about-card-three` ),
                                        document.querySelector( `#about-card-four` ),
                ],
                videos: [
                                        document.querySelector( `#about-video-one` ),
                                        document.querySelector( `#about-video-two` ),
                                        document.querySelector( `#about-video-three` ),
                                        document.querySelector( `#about-video-four` ),
                ],
            },
            art_info: {
                instance:               this.modalInstances.find( ( obj ) => { return obj.id === 'art-info-modal'; } ),
                panel:                  document.querySelector( `#art-info-modal` ),
                title:                  document.querySelector( `#art-info-title` ),
                description:            document.querySelector( `#art-info-description` ),
                views:                  document.querySelector( `#art-info-views` ),
                sold:                   document.querySelector( `#art-info-sold` ),
                total:                  document.querySelector( `#art-info-total` ),
                share:                  document.querySelector( `#art-info-share` ),
                artist:                 document.querySelector( `#art-info-artist` ),
                tags:                   document.querySelector( `#art-info-tags` ),
                merkle_root:            document.querySelector( `#art-info-merkle-root` ),
                signature:              document.querySelector( `#art-info-signature` ),
                buttons: {
                    toggle:             document.querySelector( `#art-info-toggle-btn` ),
                    close:              document.querySelector( `#art-info-close-btn` ),
                    share:              document.querySelector( `#art-info-share-btn` ),
                    download:           document.querySelector( `#art-info-download-btn` ),
                    copy_signature:     document.querySelector( `#signature-copy-btn` ),
                    pixel_probe:        document.querySelector( `#art-info-pixel-probe-btn` ),
                },
                hide:                   true,
                revealActive:           false,
            },
            payment: {
                instance:               this.modalInstances.find( ( obj ) => { return obj.id === 'payment-modal'; } ),
                panel:                  document.querySelector( `#payment-modal` ),
                price:                  document.querySelector( `#payment-price` ),
                expand_btn:             document.querySelector( `#payment-expand` ),
                bolt11:                 document.querySelector( `#payment-invoice` ),
                expandEl:               document.querySelector( `#payment-expand-el` ),
                node:                   document.querySelector( `#payment-node` ),
                invoiceaddress:         document.querySelector( `#payment-invoice` ),
                isExpanded:             false,
                buttons: {
                    help:               document.querySelector( `#payment-help-btn` ),
                    toggle:             document.querySelector( `#payment-toggle-btn` ),
                    close:              document.querySelector( `#payment-close-btn` ),
                    copyinvoice:        document.querySelector( `#payment-invoice-copy-btn` ),
                    copyinvoice_main:   document.querySelector( `#payment-invoice-copy-main-btn` ),
                    copynode:           document.querySelector( `#payment-node-copy-btn` ),
                    pay:                document.querySelector( `#payment-pay-btn` ),
                    qrcode:             document.querySelector( `#payment-qrcode` ),
                },
            },
            timer: {
                bar:                    document.querySelector( `#invoice-timer` ),
            },
            help: {
                instance:               this.modalInstances.find( ( obj ) => { return obj.id === 'ui-help-modal'; } ),
                panel:                  document.querySelector( `#ui-help-panel` ),
                video:                  document.querySelector( `#ui-help-video` ),
                page_1:                 document.querySelector( '#ui-help-page-1' ),
                page_2:                 document.querySelector( '#ui-help-page-2' ),
                buttons: {
                    close:              document.querySelector( `#ui-help-close-btn` ),
                    page_2:             document.querySelector( `#ui-help-page-2-btn` ),
                    page_1:             document.querySelector( `#ui-help-page-1-btn` ),
                }
            },
            share: {
                instance:               this.modalInstances.find( ( obj ) => { return obj.id === 'ui-share-modal'; } ),
                panel:                  document.querySelector( `#ui-share-panel` ),
                field: {
                    art_title:          document.querySelector( `#ui-share-art-title` ),
                    art_page_url:       document.querySelector( `#ui-share-art-page-url` ),
                    wide_image_url:     document.querySelector( `#ui-share-wide-image-url` ),
                    square_image_url:   document.querySelector( `#ui-share-square-image-url` ),
                    tall_image_url:     document.querySelector( `#ui-share-tall-image-url` ),
                },
                buttons: {
                    close:              document.querySelector( `#ui-share-close-btn` ),
                    twitter:            document.querySelector( `#ui-share-twitter` ),
                    instagram:          document.querySelector( `#ui-share-instagram` ),
                    reddit:             document.querySelector( `#ui-share-reddit` ),
                    facebook:           document.querySelector( `#ui-share-facebook` ),
                    tumblr:             document.querySelector( `#ui-share-tumblr` ),
                    email:              document.querySelector( `#ui-share-email` ),
                    art_page_copy:      document.querySelector( `#ui-share-page-copy` ),
                    wide_image_copy:    document.querySelector( `#ui-share-wide-image-copy` ),
                    square_image_copy:  document.querySelector( `#ui-share-square-image-copy` ),
                    tall_image_copy:    document.querySelector( `#ui-share-tall-image-copy` ),
                    wide_image_download:    document.querySelector( `#ui-share-wide-image-download` ),
                    square_image_download:  document.querySelector( `#ui-share-square-image-download` ),
                    tall_image_download:    document.querySelector( `#ui-share-tall-image-download` ),
                },
            },
            pixel: {
                panel:                  document.querySelector( `#ui-pixel-probe-modal` ),
                instance:               this.modalInstances.find( ( obj ) => { return obj.id === 'ui-pixel-probe-modal'; } ),
                purchased:              document.querySelector( `#ui-pixel-probe-pixel-purchased-section` ),
                unpurchased:            document.querySelector( `#ui-pixel-probe-pixel-unpurchased-section` ),
                unpurchased_x:          document.querySelector( `#ui-pixel-probe-unpurchased-x` ),
                unpurchased_y:          document.querySelector( `#ui-pixel-probe-unpurchased-y` ),
                unpurchased_price:      document.querySelector( `#ui-pixel-probe-unpurchased-price` ),
                x:                      document.querySelector( `#ui-pixel-probe-x` ),
                y:                      document.querySelector( `#ui-pixel-probe-y` ),
                color:                  document.querySelector( `#ui-pixel-probe-color` ),
                price:                  document.querySelector( `#ui-pixel-probe-price` ),
                payment_hash:           document.querySelector( `#ui-pixel-probe-pay-hash` ),
                preimage:               document.querySelector( `#ui-pixel-probe-preimage` ),
                pixel_preimage: {
                    full:               document.querySelector( `#ui-pixel-probe-pixel-preimage` ),
                    version:            document.querySelector( `#ui-pixel-probe-pixel-preimage-version` ),
                    checksum:           document.querySelector( `#ui-pixel-probe-pixel-preimage-checksum` ),
                    x:                  document.querySelector( `#ui-pixel-probe-pixel-preimage-x` ),
                    y:                  document.querySelector( `#ui-pixel-probe-pixel-preimage-y` ),
                    rgb:                document.querySelector( `#ui-pixel-probe-pixel-preimage-rgb` ),
                    price:              document.querySelector( `#ui-pixel-probe-pixel-preimage-price` ),
                    salt:               document.querySelector( `#ui-pixel-probe-pixel-preimage-salt` ),
                },
                pre2image:              document.querySelector( `#ui-pixel-probe-pre2image` ),
                merkle:                 document.querySelector( `#ui-pixel-probe-merkle-proof` ),
                buttons: {
                    close:              document.querySelector( `#ui-pixel-probe-close-btn` ),
                    pixel_probe:        document.querySelector( `#ui-pixel-probe-action-btn` ),
                }
            }
        };

        this.scroller = null;
    }

    // Initialize the UI fields and listeners
    init() {
        // console.log("%c UI INIT", "color: red");
        // #####################################################################
        // MAIN APP
        // #####################################################################

        // #####################################################################
        // Button Keyboard Controls
        // #####################################################################
        // Add 'Enter' to click
        let pointers = document.querySelectorAll( '.pointer' );
        pointers.forEach( p => { p.addEventListener( "keyup", e => {
            if ( e.keyCode !== 13 ) return;
            e.preventDefault();
            p.click();
        }, false ) } );

        // #####################################################################
        // TOP NAV
        // #####################################################################

        // SEARCH BUTTON
        this.top_nav.search.button.addEventListener( "click", () => {
            this.sparkshot.State.setResultStartIndex();
            this.sparkshot.UI.setSearchQuery( this.getSearchQuery() );
            if ( this.getSearchQuery() == "" ) {
                this.sparkshot.State.searchAdvanced({
                    search_query:               "",
                    result_start:               0,
                    sort_choice:                this.sparkshot.State.sort_choice,
                    sort_direction:             this.sparkshot.State.sort_direction,
                    complete_min:               this.sparkshot.State.complete_min,
                    complete_max:               this.sparkshot.State.complete_max,
                    attach_datetime_min:        this.sparkshot.State.attach_datetime_min,
                    attach_datetime_max:        this.sparkshot.State.attach_datetime_max,
                    hotness_window:             this.sparkshot.State.hotness_window,
                    hotness_min:                this.sparkshot.State.hotness_min,
                    hotness_max:                this.sparkshot.State.hotness_max,
                    result_count:               this.sparkshot.State.result_count,
                });
            } else {
                // include comleted art in specific searches
                this.sparkshot.State.searchAdvanced({
                    search_query:               this.getSearchQuery(),
                    result_start:               0,
                    sort_direction:             "DESCENDING",
                    sort_choice:                "SEARCH_MATCH",
                    complete_max:               1.0,
                });
            }
        }, false );

        this.top_nav.search.field.addEventListener( "keydown", e => {
            if ( e.key === "Enter" ) {
                e.preventDefault();
                if ( Object.keys( this.top_nav.search.instance.options.data ).length === 0 )
                    this.top_nav.search.instance.close();
                return;
            }
        }, false );

        this.top_nav.search.field.addEventListener( "keyup", e => {
            if ( e.key !== "Enter" ) return;

            // if ( this.top_nav.search.instance.count === 0 )
            this.top_nav.search.instance.close();

            this.sparkshot.State.setResultStartIndex();
            this.sparkshot.UI.setSearchQuery( this.getSearchQuery() );
            if ( this.getSearchQuery() == "" ) {
                this.sparkshot.State.searchAdvanced({
                    search_query:               "",
                    result_start:               0,
                    sort_choice:                this.sparkshot.State.sort_choice,
                    sort_direction:             this.sparkshot.State.sort_direction,
                    complete_min:               this.sparkshot.State.complete_min,
                    complete_max:               this.sparkshot.State.complete_max,
                    attach_datetime_min:        this.sparkshot.State.attach_datetime_min,
                    attach_datetime_max:        this.sparkshot.State.attach_datetime_max,
                    hotness_window:             this.sparkshot.State.hotness_window,
                    hotness_min:                this.sparkshot.State.hotness_min,
                    hotness_max:                this.sparkshot.State.hotness_max,
                    result_count:               this.sparkshot.State.result_count,
                });
            } else {
                // include comleted art in specific searches
                this.sparkshot.State.searchAdvanced({
                    search_query:               this.getSearchQuery(),
                    result_start:               0,
                    sort_direction:             "DESCENDING",
                    sort_choice:                "SEARCH_MATCH",
                    complete_max:               1.0,
                });
            }
        }, false );

        this.top_nav.search.instance.el.addEventListener( "keyup", e => {
            if ( Utils.isKeyChar( e.key ) ) {
                if ( e.target.value.length !== 0 ) {
                    this.sparkshot.WS.request_search_suggest( e.target.value );
                }
            } else {
                if ( e.target.value.length === 0 ) {
                    this.autocomplete( this.top_nav.search.instance,  [] );
                    this.top_nav.search.instance.dropdown.dropdownEl.innerHTML = "";
                }
            }
        }, false );

        // SEARCH SUGGESTIONS
        this.top_nav.search.instance.dropdown.options.onItemClick = e => {

            this.top_nav.search.instance.close();
            this.sparkshot.State.setResultStartIndex();
            this.sparkshot.UI.setSearchQuery( e.textContent );
            if ( this.getSearchQuery() === "" ) {
                this.sparkshot.State.searchAdvanced({
                    search_query:               "",
                    result_start:               0,
                    sort_choice:                this.sparkshot.State.sort_choice,
                    sort_direction:             this.sparkshot.State.sort_direction,
                    complete_min:               this.sparkshot.State.complete_min,
                    complete_max:               this.sparkshot.State.complete_max,
                    attach_datetime_min:        this.sparkshot.State.attach_datetime_min,
                    attach_datetime_max:        this.sparkshot.State.attach_datetime_max,
                    hotness_window:             this.sparkshot.State.hotness_window,
                    hotness_min:                this.sparkshot.State.hotness_min,
                    hotness_max:                this.sparkshot.State.hotness_max,
                    result_count:               this.sparkshot.State.result_count,
                });
            } else {
                // include comleted art in specific searches
                this.sparkshot.State.searchAdvanced({
                    search_query:               this.getSearchQuery(),
                    result_start:               0,
                    sort_direction:             "DESCENDING",
                    sort_choice:                "SEARCH_MATCH",
                    complete_max:               1.0,
                });
            }
        };

        // UPLOAD PANEL BUTTONS
        this.top_nav.buttons.upload.forEach( btn => {
            btn.addEventListener( "click", e => {
                e.preventDefault();
                this.sparkshot.Upload.form.instance.open();
            }, false );
        });

        // PROFILE PANEL BUTTON
        this.top_nav.buttons.profile.forEach( btn => {
            btn.addEventListener( "click", e => {
                e.preventDefault();
                this.sparkshot.Profile.sideNav.selectTab("user");
                this.sparkshot.Profile.instance.open();
            }, false );
        });

        // PROFILE WALLET BUTTON
        this.top_nav.buttons.wallet.forEach( btn => {
            btn.addEventListener( "click", e => {
                e.preventDefault();
                this.sparkshot.Profile.sideNav.selectTab("artist");
                this.sparkshot.Profile.instance.open();
            }, false );
        });

        // PROFILE PAYOUT PANEL BUTTON
        this.top_nav.buttons.payments.forEach( btn => {
            btn.addEventListener( "click", e => {
                e.preventDefault();
                this.sparkshot.Profile.sideNav.selectTab("payments");
                this.sparkshot.Profile.instance.open();
            }, false );
        });

        // #####################################################################
        // SORT AND FILTER NAV
        // #####################################################################

        // Set Gallery to ALL art listings and refresh search
        this.sort.buttons.all.addEventListener( "click", () => {
            this.showAll();
        }, false );

        // Set Gallery to INCOMPLETE art listings and refresh search
        this.sort.buttons.incomplete.addEventListener( "click", () => {
            this.showIncomplete();
        }, false );

        // Set Gallery to COMPLETE art listings and refresh search
        this.sort.buttons.complete.addEventListener( "click", () => {
            this.showComplete();
        }, false );

        this.sort.buttons.new.addEventListener( "click", () => {
            // this.sparkshot.State.search( this.top_nav.search.field.value, 0, this.SEARCH_LENGTH );
            this.selectSort( 'new' );
            this.sparkshot.State.search( this.getSearchQuery(), this.SEARCH_LENGTH );
            // console.log( 'SEARCH FOR NEW' );
        }, false );

        this.sort.buttons.hot.addEventListener( "click", () => {
            // this.sparkshot.State.search( this.top_nav.search.field.value, 0, this.SEARCH_LENGTH );
            if (!this.sort.buttons.almost.classList.contains('disabled')) {
                this.selectSort( 'hot' );
                this.sparkshot.State.search( this.getSearchQuery(), this.SEARCH_LENGTH );
            }
            // console.log( 'SEARCH FOR HOT' );
        }, false );

        this.sort.buttons.almost.addEventListener( "click", () => {
            // this.sparkshot.State.search( this.top_nav.search.field.value, 0, this.SEARCH_LENGTH );
            if (!this.sort.buttons.almost.classList.contains('disabled')) {
                this.selectSort( 'almost' );
                this.sparkshot.State.search( this.getSearchQuery(), this.SEARCH_LENGTH );
            }
            // console.log( 'SEARCH FOR ALMOST' );
        }, false );

        // #####################################################################
        // ABOUT MODAL
        // #####################################################################

        // Open About Button
        this.top_nav.buttons.about.forEach( btn => {
            btn.addEventListener( "click", e => {
                e.preventDefault();
                this.modal.about.instance.open();
            } );
        });

        // Close About Button
        this.modal.about.buttons.close.addEventListener( "click", () => {

            this.modal.about.instance.close();
        } );

        this.modal.about.instance.options.onOpenStart = () => {
            this.closeAllOtherModals(this.modal.about.instance.id);
            this.blocker( true );
            this.playHowTo();
        };

        // If closed by any call
        this.modal.about.instance.options.onCloseStart = () => {
            this.blocker( false );
            this.stopHowTo();
        };

        this.modal.about.buttons.help.addEventListener( "click", () => {
            this.modal.help.instance.open();
        } );

        // #####################################################################
        // HELP
        // #####################################################################

        this.modal.help.instance.options.onOpenStart = () => {
            this.openHelpPanel();
        };

        // If closed by any call
        this.modal.help.instance.options.onCloseStart = () => {
            this.closeHelpPanel();
        };

        // Close Helpt Modal Button
        this.modal.help.buttons.close.addEventListener( "click", () => {
            this.modal.help.instance.close();
        } );

        this.modal.help.buttons.close.addEventListener( "keydown", e => {
            if (e.key === "Enter") {
                this.modal.help.instance.close();
            }
        } );

        this.modal.help.buttons.page_2.addEventListener( 'click', () => this.selectHelpPage( 2 ), false );
        this.modal.help.buttons.page_1.addEventListener( 'click', () => this.selectHelpPage( 1 ), false );

        this.modal.help.instance.el.addEventListener( "keydown", e => {
            if (e.key === "Escape") {
                this.modal.help.instance.close();
            }
        }, false);

        // #####################################################################
        // INVOICE
        // #####################################################################

        this.bottom_nav.tools.trash.addEventListener( "click", () => {
            this.sparkshot.Viewer.clearSelection();
        }, false );

		this.bottom_nav.buttons.invoicePanel.addEventListener( "click", () => {
             this.sparkshot.Viewer.requestToInvoiceSelection();
         }, false );

        this.bottom_nav.buttons.invoicePanel.addEventListener( "keydown", e => {
            if (e.key === "Enter") {
                this.sparkshot.Viewer.requestToInvoiceSelection();
            }
        }, false );

		this.modal.payment.buttons.close.addEventListener( "click", () => {
             this.modal.payment.instance.close();
         }, false );

        this.modal.payment.instance.options.onCloseStart = () => {
             this.blocker( false );
             this.sparkshot.Viewer.closedInvoice();
         };

        this.modal.payment.instance.options.onOpenEnd = () => {
            this.modal.payment.buttons.pay.focus();
            M.Tooltip.getInstance(this.modal.payment.buttons.pay).open();
        };

        // Expand Invoice
        this.modal.payment.buttons.toggle.addEventListener( "click", () => {
            this.togglePaymentExpand( this.modal.payment.buttons.toggle );
        });

        this.modal.payment.buttons.toggle.addEventListener( "keydown", e => {
            if (e.key === "Enter") {
                this.togglePaymentExpand( this.modal.payment.buttons.toggle );
            }
        });

        // Track Expand Transition
        this.modal.payment.panel.addEventListener( "transitionend", this._setExpandedDisplay.bind(this) );

        // Close Invoice
        this.modal.payment.buttons.close.addEventListener( "click", () => {
            this.closeInvoicePanel();
        });

        this.modal.payment.buttons.close.addEventListener( "keydown", e => {
            if (e.key === "Enter") {
                this.closeInvoicePanel();
            }
        });

        // Copy Invoice Main
        this.modal.payment.buttons.copyinvoice_main.addEventListener( "click", () => {
            this.copyBolt11Invoice();
        });

        // Copy Invoice
        this.modal.payment.buttons.copyinvoice.addEventListener( "click", () => {

            this.modal.payment.invoiceaddress.parentElement.addEventListener( "transitionend", () => {
                this.modal.payment.invoiceaddress.parentElement.classList.remove ( 'copied' );
                this.modal.payment.invoiceaddress.parentElement.removeEventListener( "transitionend", () => {});
            } );
            this.copyBolt11Invoice();
            this.modal.payment.invoiceaddress.parentElement.classList.add ( 'copied' );
        });

        this.modal.payment.buttons.copyinvoice_main.addEventListener( "keydown", e => {
            if (e.key === "Enter") {
                this.copyBolt11Invoice();
            }
        });

        // Copy Node
        this.modal.payment.buttons.copynode.addEventListener( "click", () => {

            this.modal.payment.node.parentElement.addEventListener( "transitionend", () => {
                this.modal.payment.node.parentElement.classList.remove ( 'copied' );
                this.modal.payment.node.parentElement.removeEventListener( "transitionend", () => {});
            } );

            Utils.copyText( this.modal.payment.node.textContent );
            this.modal.payment.node.parentElement.classList.add ( 'copied' );
            this.showToast( 'Copied' );
        });

        // Payment Button
        this.modal.payment.buttons.pay.addEventListener( "click", e => {
            e.preventDefault();
            this.openPaymentApp();
        }, false);

        this.modal.payment.buttons.pay.addEventListener( "keydown", e => {
            if (e.key === "Enter") {
                e.preventDefault();
                this.openPaymentApp();
            }
        }, false);

        // QR Copy pay Button
        this.modal.payment.buttons.qrcode.addEventListener( "click", e => {
            e.preventDefault();
            this.copyBolt11Invoice();
        }, false);

        this.modal.payment.buttons.help.addEventListener( 'click', () => {
            this.modal.help.instance.open();
        }, false );

        this.modal.payment.buttons.help.addEventListener( 'keydown', e => {
            if (e.key === "Enter") {
                this.modal.help.instance.open();
            }
        }, false );

        this.modal.payment.buttons.help.addEventListener( "mouseover", () => {
            this.sparkshot.Tutorial.completeHint( "pay_invoice" );      // Mark Tutorial Complete
        } );

        // #####################################################################
        // SETTINGS
        // #####################################################################

        // .....................................................................
        // ART INFO INIT

        this.modal.art_info.revealActive = false;

        // Prevent Escape button from closing
        this.modal.art_info.instance.options.dismissible = false;
        //
        this.modal.art_info.panel.addEventListener( 'mouseover', e => this.hoverPopArtMetaPanel( true ), false );

        this.modal.art_info.panel.addEventListener( 'mouseout', e => this.hoverPopArtMetaPanel( false ), false );

        this.modal.art_info.buttons.toggle.addEventListener( 'click', e => {
            this.toggleArtMetaPanel();
        }, false );

        this.modal.art_info.buttons.close.addEventListener( 'click', e => {
            this.closeArtMetaPanel();
        }, false );

        this.modal.art_info.artist.addEventListener( 'click', e => {
            const address = this.modal.art_info.artist.dataset.address;
            this.sparkshot.State.setResultStartIndex();
            this.sparkshot.UI.setSearchQuery( address );
            this.sparkshot.State.searchAdvanced({
                search_query:               this.getSearchQuery(),
                result_start:               0,
                sort_direction:             "DESCENDING",
                search_choice:              "SEARCH_MATCH",
                complete_max:               1.0,
            });
        }, false );

        // Copy Signature
        this.modal.art_info.buttons.copy_signature.addEventListener( "click", () => {
            Utils.copyText( this.modal.art_info.signature.textContent );
            this.showToast( 'Copied' );
        });

        // Pixel Probe
        this.modal.art_info.buttons.pixel_probe.addEventListener( "click", () => {
            this.startPixelProbe();
        });


        // #####################################################################
        // PIXEL PROBE PANEL
        // #####################################################################


        // Menu Pixel Probe
        this.modal.pixel.buttons.pixel_probe.addEventListener( "click", () => {
            this.closeProbeData();
            this.startPixelProbe();
        });

        // Pixel Probe
        this.modal.pixel.buttons.close.addEventListener( "click", () => {
            this.closeProbeData();
        });

        // #####################################################################
        // INSTRUCTION PANEL
        // #####################################################################

        this.modal.about.videos.forEach( (e, idx) => {
            e.style.cursor = "pointer";
            e.addEventListener( 'click', () => {
                this.stopHowTo();
                this.playVideo( idx );
            }, false );

        } );

        // #####################################################################
        // SHARE PANEL
        // #####################################################################

        this.modal.art_info.buttons.share.addEventListener( 'click', () => this.modal.share.instance.open(), false );

        this.modal.share.buttons.close.addEventListener( 'click', () => this.modal.share.instance.close(), false );

        this.modal.share.instance.options.onOpenStart = () => {
            this.closeAllOtherModals(this.modal.share.instance.id);
            this.openSharePanel();
        };

        this.modal.share.instance.options.onCloseStart = () => {
            this.closeSharePanel();
        };

        this.modal.share.buttons.art_page_copy.addEventListener( 'click', () => this.createSocialLink( 'copy' ), false );

        this.modal.share.buttons.wide_image_copy.addEventListener( 'click', () => this.createSocialLink( 'wide-image' ), false );
        this.modal.share.buttons.square_image_copy.addEventListener( 'click', () => this.createSocialLink( 'square-image' ), false );
        this.modal.share.buttons.tall_image_copy.addEventListener( 'click', () => this.createSocialLink( 'tall-image' ), false );

        this.modal.share.buttons.wide_image_download.addEventListener( 'click', () => this.createSocialLink( 'wide-download' ), false );
        this.modal.share.buttons.square_image_download.addEventListener( 'click', () => this.createSocialLink( 'square-download' ), false );
        this.modal.share.buttons.tall_image_download.addEventListener( 'click', () => this.createSocialLink( 'tall-download' ), false );

        // Twitter
        this.modal.share.buttons.twitter.addEventListener( 'click', () => this.createSocialLink( 'twitter' ), false );
        this.modal.share.buttons.instagram.addEventListener( 'click', () => this.createSocialLink( 'instagram' ), false );
        this.modal.share.buttons.reddit.addEventListener( 'click', () => this.createSocialLink( 'reddit' ), false );
        this.modal.share.buttons.facebook.addEventListener( 'click', () => this.createSocialLink( 'facebook' ), false );
        this.modal.share.buttons.tumblr.addEventListener( 'click', () => this.createSocialLink( 'tumblr' ), false );
        this.modal.share.buttons.email.addEventListener( 'click', () => this.createSocialLink( 'email' ), false );

        // #####################################################################
        // DOWNLOAD
        // #####################################################################

        this.modal.art_info.buttons.download.addEventListener( 'click', () => {
            this.downloadArt();
        }, false );

        // #####################################################################
        // TOOLS
        // #####################################################################

        // Input to user message field by keypress
        this.bottom_nav.tools.message.addEventListener( "keydown", e => {
            if ( e.key === "Enter" ) {
                this.sparkshot.Viewer.requestToInvoiceSelection();
            }
        });

        // Input to user message field
        this.bottom_nav.tools.message.addEventListener( "input", e => {
            const field = this.bottom_nav.tools.message;

            // Signal bad input
            if ( !Utils.validateText( e.data, /([^\n\t])/ ) ) { Utils.flashElement( field ); }

            // Replace Bad Characters
            field.value = field.value.replace( this.INVALID_MESSAGE_CHARACTERS, '');

            // Track Message Length
            this.enforceMessageLength( field, this.MAX_MESSAGE_LENGTH );

            // Update Progress Bar
            this.updateMessageLengthCounter();

            if ( field.textLength === 0 ) {
                this.shrinkUserMessageHeight();
            } else {
                field.classList.add( 'vertical-expand' );
            }

            this.sparkshot.Viewer.updateToolsState();
            // Add estimate char price on UI
            // this.setPriceCounter( this.sparkshot.Viewer._price + ( field.textLength * this.MESSAGE_CHAR_PRICE ) );
        }, false );

        // Input to user message field
        this.bottom_nav.tools.message.addEventListener( "paste", e => {
            const field = this.bottom_nav.tools.message;

            // Replace Bad Characters
            field.value = field.value.replace( this.INVALID_MESSAGE_CHARACTERS, '');

            // Signal bad input
            if ( !Utils.validateText( e.clipboardData.getData('text/plain'), /([^\n\t])/ ) ) { Utils.flashElement( field ); }

            // Track Message Length
            this.enforceMessageLength( field, this.MAX_MESSAGE_LENGTH );

            // Update Progress Bar
            this.updateMessageLengthCounter();

            if ( field.textLength === 0 ) {
                this.shrinkUserMessageHeight();
            } else {
                field.classList.add( 'vertical-expand' );
            }

            // Add estimate char price on UI
            this.sparkshot.Viewer.updateToolsState();
        });

        window.addEventListener( 'resize', Utils.debounce( () => this.appPanelResize(), 300 ));

        // Custom Modal Options
        this.modal.message.instance.options.onCloseStart = () => { this.blocker(false); };

        // #####################################################################
        // ART UI CONTROLS
        // #####################################################################

        // UI Controls

        // MOUSE CONTROLS
		this.controls.buttons.zoom_in.addEventListener( 'pointerdown', () => {
            this.sparkshot.Viewer.startZoomAnim( 1 );
            this.sparkshot.Tutorial.completeHint( "zoom_in" );      // Mark Tutorial Complete
		}, false );

		this.controls.buttons.zoom_in.addEventListener( 'pointerup', e => {
            e.preventDefault();
            this.sparkshot.Viewer.clearZoomAnim();
		}, false );

        this.controls.buttons.zoom_in.addEventListener( 'pointerleave', e => {
            e.preventDefault();
            this.sparkshot.Viewer.clearZoomAnim();
        }, false );

        this.controls.buttons.zoom_out.addEventListener( 'pointerdown', () => {
            this.sparkshot.Viewer.startZoomAnim( -1 );
            this.sparkshot.Tutorial.completeHint( "zoom_in" );      // Mark Tutorial Complete
        }, false );

		this.controls.buttons.zoom_out.addEventListener( 'pointerup', e => {
            e.preventDefault();
            this.sparkshot.Viewer.clearZoomAnim();
		}, false );

        this.controls.buttons.zoom_out.addEventListener( 'pointerleave', e => {
            e.preventDefault();
            this.sparkshot.Viewer.clearZoomAnim();
        }, false );

        this.controls.buttons.frame.addEventListener( 'pointerup', e => {
            e.preventDefault();
            this.sparkshot.Viewer.resetToCanvasDefaults();
            this.sparkshot.Tutorial.completeHint( "zoom_in" );      // Mark Tutorial Complete
        }, false );

        this.controls.buttons.price_radar.addEventListener( 'pointerup', e => {
            e.preventDefault();
            this.sparkshot.Viewer.togglePriceProbe();
            // this.sparkshot.Tutorial.completeHint( "zoom_in" );      // Mark Tutorial Complete
        }, false );

        // Background Select Button
        this.controls.buttons.background_color.addEventListener( 'pointerdown', () => {
            M.Tooltip.getInstance(this.controls.buttons.background_color.querySelector('.tooltipped')).close();
        }, false );

        this.controls.buttons.background_color.addEventListener( 'pointerleave', () => {
            this.sparkshot.Viewer.setBackground(this.sparkshot.Viewer.background.index);
            this.sparkshot.Viewer.drawImage();
        }, false );

        // .....................................................................
        // Set Panel Sizes
        this.appPanelResize();

        // SET DEFAULT STATE
    }

    // #####################################################################
    // UI SIZES
    // #####################################################################

    // Used to reconfigure panel sizes
    appPanelResize() {
        // Set App Panel Height
        const sortOffset = ( this.isSortHidden() ) ? 0 : 60;
        const panelHeight = window.innerHeight - this._heightPad.clientHeight - this._footerPad.clientHeight - sortOffset;
        this.chat.panel.setAttribute( 'style', `height: ${panelHeight}px` );
        this.chat.controls.setAttribute( 'style', `height: ${panelHeight}px` );

        // Set App Panel Width
        // calculate size with an extra 20 pixels to cater for scroll bar
        const appPanelWidth = window.innerWidth - this.chat.panel.offsetWidth - this.chat.controls.offsetWidth - 1;
        this.main.setAttribute( 'style', `width: ${appPanelWidth}px; height: ${panelHeight}px;` );
        if ( this.sparkshot.State.isState( 'viewer' ) ) this.sparkshot.Viewer.updateCanvasSize();

        if ( this.sparkshot.State.isState( 'browse' ) ) {
            this.sparkshot.Browse.updateCardBackground();
            this.main.classList.add( 'browse' );
        } else {
            this.main.classList.remove( 'browse' );
        }


        // Calculate the placement of the info panel
        const infoLeft = ( appPanelWidth - this.modal.art_info.panel.offsetWidth ) / 2 + this.chat.controls.offsetWidth + this.chat.panel.offsetWidth;

        // Set the info panel ( needs to be closed when resetting )
        if ( this.modal.art_info.instance.isOpen ) {
            this.modal.art_info.instance.close();
            this.modal.art_info.panel.setAttribute( 'style', `left: ${infoLeft}px` );
            this.modal.art_info.instance.open();
        } else {
            this.modal.art_info.instance.close();
            this.modal.art_info.panel.setAttribute( 'style', `left: ${infoLeft}px` );
        }
    }

    modalOpen(e) {
        // Have to set this on a time out or it doesn't update correctly
        setTimeout( () => { e.updateTabIndicator(); }, 500);
    }

    addedChip( e ) {
        const index = e[0].children.length - 2;
        const el = e[0].children[index];
        const i = el.getElementsByTagName("i")[0];
        i.classList.remove('material-icons');
        i.classList.add('fas', 'fa-times', 'fa-2x', 'grey-text', 'text-lighten-1' );
        i.textContent = '';
    }

    validateChipLength( chips ) {
        // Check chips length
    }

    // #####################################################################
    // SCROLLER
    // #####################################################################

    setupScroller() {

        this.main.addEventListener( 'scroll', Utils.debounce( (e) => {
            if ( e.srcElement === undefined ) return;
            const scroll = e.srcElement.scrollTop;

            this.sparkshot.Browse.updateCardBackground();

            // Configure Sort Panel to close when not at top of window
            // if ( scroll > 30 )
            //     this.closeSortPanel();
            //
            // if ( scroll === 0 && this.sparkshot.State.getState() === 'browse' )
            //     this.openSortPanel();

            // Request More Art when at page bottom
            const pageBase = e.srcElement.scrollHeight - e.srcElement.offsetHeight;

            // console.log( '%c SCROLL REQUEST', 'color: blue');
            if ( scroll >= pageBase - 20 && this.sparkshot.State.getState() === 'browse' )
                this.sparkshot.State.searchAdvanced({
                    search_query:               this.getSearchQuery(),
                    // sort_direction:             "ASCENDING",
                    result_start:               this.sparkshot.State.result_start,

                    sort_choice:                this.sparkshot.State.sort_choice,
                    sort_direction:             this.sparkshot.State.sort_direction,
                    complete_min:               this.sparkshot.State.complete_min,
                    complete_max:               this.sparkshot.State.complete_max,
                    attach_datetime_min:        this.sparkshot.State.attach_datetime_min,
                    attach_datetime_max:        this.sparkshot.State.attach_datetime_max,
                    hotness_window:             this.sparkshot.State.hotness_window,
                    hotness_min:                this.sparkshot.State.hotness_min,
                    hotness_max:                this.sparkshot.State.hotness_max,
                    result_count:               this.sparkshot.State.result_count,
                });
        }, 70 ));
    }

    scrollToTop() {
        this.enableToTop = false;
        this.main.scrollTo( 0, 0 );
        this.openSortPanel();
    }

    scheduleScrollToTop() {
        this.enableToTop = true;
    }

    removeScroller() {
        this.scroller = null;
    }

    // #####################################################################
    // SEARCH
    // #####################################################################

    clearCurrentSearch() {
        this.searchquery = '';
        this.top_nav.search.field.value = '';
    }

    setSearchQuery( search = '') {
        this.searchquery = search;
        this.top_nav.search.field.value = search;
    }

    getSearchQuery() {
        return this.top_nav.search.field.value;
    }

    getSearchType() {
        const query = this.getSearchQuery();

        if ( query[0] === "#" ) {
            return "tag";
        } else if ( this.sparkshot.Profile.Payout.address_is_valid( query ) ) {
            return "bitcoin_address";
        } else {
            return "string";
        }
    }

    openSortPanel() {
        this.sort.panel.classList.remove( 'hide' );
        this.appPanelResize();
        this.enableToTop = false;
        // if ( this.scroller !== null ) this.scroller.showScrollbar();
    }

    closeSortPanel() {
        this.sparkshot.UI.sort.panel.classList.add( 'hide' );
        this.appPanelResize();
    }

    isSortHidden() {
        return this.sort.panel.classList.contains( 'hide' );
    }

    // All View
    showAll() {
        this.sort.current_view = this.sort.views.all;

        this.setActiveSearchFilterButton( 'all' );

        // Set default state
        this.selectSort( 'almost' );

        // Set Sort Button States
        this.sort.buttons.hot.classList.remove( 'disabled' );
        this.sort.buttons.new.classList.remove( 'disabled' );
        this.sort.buttons.almost.classList.remove( 'disabled' );

        this.sparkshot.State.setSearchRange( 'all' );

        this.sparkshot.State.search( this.top_nav.search.field.value, 0, this.SEARCH_LENGTH );
    }

    // Fresh View
    showIncomplete() {
        this.sort.current_view = this.sort.views.fresh;

        this.setActiveSearchFilterButton( 'incomplete' );

        // Set Sort Button States
        this.selectSort( 'almost' );

        // Set Sort Button States
        this.sort.buttons.hot.classList.remove( 'disabled' );
        this.sort.buttons.new.classList.remove( 'disabled' );
        this.sort.buttons.almost.classList.remove( 'disabled' );

        this.sparkshot.State.setSearchRange( 'incomplete' );
        this.sparkshot.State.search( this.top_nav.search.field.value, 0, this.SEARCH_LENGTH );
    }

    // Gallery View
    showComplete() {
        this.sort.current_view = this.sort.views.gallery;

        this.setActiveSearchFilterButton( 'complete' );

        // Set default state
        this.selectSort( 'new' );

        // Set Sort Button States
        this.sort.buttons.hot.classList.add( 'disabled' );
        this.sort.buttons.almost.classList.add( 'disabled' );


        this.sparkshot.State.setSearchRange( 'complete' );
        this.sparkshot.State.search( this.top_nav.search.field.value, 0, this.SEARCH_LENGTH );
    }

    setActiveSearchFilterButton( filter ) {
        // console.log( `FILTER: ${filter}`);
        // Reset buttons
        this.sort.buttons.all.classList.remove( 'darken-2' );
        this.sort.buttons.incomplete.classList.remove( 'darken-2' );
        this.sort.buttons.complete.classList.remove( 'darken-2' );
        this.sort.buttons.all.classList.remove( 'darken-3' );
        this.sort.buttons.incomplete.classList.remove( 'darken-3' );
        this.sort.buttons.complete.classList.remove( 'darken-3' );

        switch ( filter ) {
            case 'all':
                this.sort.current_view = this.sort.views.all;
                this.sort.buttons.all.classList.add( 'darken-2' );
                this.sort.buttons.incomplete.classList.add( 'darken-3' );
                this.sort.buttons.complete.classList.add( 'darken-3' );
                break;
            case 'incomplete':
                this.sort.current_view = this.sort.views.fresh;
                this.sort.buttons.incomplete.classList.add( 'darken-2' );
                this.sort.buttons.all.classList.add( 'darken-3' );
                this.sort.buttons.complete.classList.add( 'darken-3' );
                break;
            case 'complete':
                this.sort.current_view = this.sort.views.gallery;
                this.sort.buttons.complete.classList.add( 'darken-2' );
                this.sort.buttons.all.classList.add( 'darken-3' );
                this.sort.buttons.incomplete.classList.add( 'darken-3' );
                break;
            default:

        }
    }

    setActiveSortButton( sort ) {
        // console.log( `SORT: ${sort}`);
        // Reset buttons
        this.sort.buttons.new.classList.remove( 'darken-3' );
        this.sort.buttons.hot.classList.remove( 'darken-3' );
        this.sort.buttons.almost.classList.remove( 'darken-3' );

        this.sort.buttons.new.classList.remove( 'darken-2' );
        this.sort.buttons.hot.classList.remove( 'darken-2' );
        this.sort.buttons.almost.classList.remove( 'darken-2' );
        switch ( sort ) {
            case 'new':
                this.sort.buttons.new.classList.add( 'darken-2' );
                this.sort.buttons.hot.classList.add( 'darken-3' );
                this.sort.buttons.almost.classList.add( 'darken-3' );
                break;
            case 'hot':
            this.sort.buttons.hot.classList.add( 'darken-2' );
            this.sort.buttons.new.classList.add( 'darken-3' );
            this.sort.buttons.almost.classList.add( 'darken-3' );
                break;
            case 'almost':
                this.sort.buttons.almost.classList.add( 'darken-2' );
                this.sort.buttons.new.classList.add( 'darken-3' );
                this.sort.buttons.hot.classList.add( 'darken-3' );
                break;
            default:

        }
    }

    selectSort( filter ) {
        switch ( filter ) {
            case 'new':
                this.setActiveSortButton( 'new' );
                this.sparkshot.State.setSort( 'ATTACH_DATETIME' );
                this.sparkshot.State.setDirection( false );
                this.sparkshot.State.setResultStartIndex();

                switch ( this.sort.current_view ) {
                    case 'ALL':
                        this.sparkshot.State.setSearchRange( 'all' );
                        break;
                    case 'FRESH':
                        this.sparkshot.State.setSearchRange( 'incomplete' );
                        break;
                    case 'GALLERY':
                        this.sparkshot.State.setSearchRange( 'complete' );
                        break;
                    default:

                }
                // this.sparkshot.State.search( this.getSearchQuery(), this.SEARCH_LENGTH );
                break;
            case 'hot':
                this.setActiveSortButton( 'hot' );

                this.sparkshot.State.setSort( 'HOTNESS' );
                this.sparkshot.State.setHotnessWindow( 'MONTH' );
                this.sparkshot.State.setDirection( false );
                this.sparkshot.State.setResultStartIndex();

                switch ( this.sort.current_view ) {
                    case 'ALL':
                        this.sparkshot.State.setSearchRange( 'all' );
                        break;
                    case 'FRESH':
                        this.sparkshot.State.setSearchRange( 'incomplete' );
                        break;
                    case 'GALLERY':
                        this.sparkshot.State.setSearchRange( 'complete' );
                        break;
                    default:
                        this.sparkshot.State.setSearchRange( 'incomplete' );
                }

                // this.sparkshot.State.search( this.getSearchQuery(), this.SEARCH_LENGTH );
                break;
            case 'almost':
                this.setActiveSortButton( 'almost' );

                this.sparkshot.State.setSort( 'PERCENT_COMPLETE' );
                this.sparkshot.State.setHotnessWindow( 'MONTH' );

                this.sparkshot.State.setDirection( false );

                switch ( this.sort.current_view ) {
                    case 'ALL':
                        this.sparkshot.State.setSearchRange( 'all' );
                        break;
                    case 'FRESH':
                        this.sparkshot.State.setSearchRange( 'incomplete' );
                        // this.sparkshot.State.setMinComplete( 0.3 );
                        break;
                    case 'GALLERY':
                        this.sparkshot.State.setSearchRange( 'complete' );
                        break;
                    default:
                        this.sparkshot.State.setSearchRange( 'incomplete' );
                        // this.sparkshot.State.setMinComplete( 0.3 );
                }

                this.sparkshot.State.setResultStartIndex();
                // this.sparkshot.State.search( this.getSearchQuery(), this.SEARCH_LENGTH );
                break;
            default:
                this.setActiveSortButton( 'almost' );
                this.setActiveSearchFilterButton( 'incomplete' );
                this.sparkshot.State.setSort( 'CURRENT_VALUE' );
                this.sparkshot.State.setHotnessWindow( 'DAY' );
                this.sparkshot.State.setDirection( true );
                this.sparkshot.State.setResultStartIndex();
                // this.sparkshot.State.search( this.getSearchQuery(), this.SEARCH_LENGTH );
                break;
        }

    }

    autocomplete( el, results ) {
        const data = results.reduce((json, key, value) => { json[key] = null; return json; }, {});
        el.updateData( data );
        // const count = Object.keys( el.options.data ).length;
        if ( results.length === 0 ) {
            el.close();
        } else {
            if ( el.count === 0 ) el.close();
            el.open();
        }
    }

    // #####################################################################
    // TOOLS PANEL METHODS
    // #####################################################################

    enableToolsPanel() {
        if ( Utils.mobileCheck() ) { return; }
        if ( !this.bottom_nav.tools.panel.classList.contains( 'hide' ) ) {return;}

        this.bottom_nav.tools.panel.classList.remove( 'hide' );
    }

    disableToolsPanel() {
        if ( this.bottom_nav.tools.panel.classList.contains( 'hide' ) ) {return;}
        this.bottom_nav.tools.panel.classList.add( 'hide' );
    }

    setPriceCounter( price ) {
        this.setPricetoDOM( this.bottom_nav.tools.count, price );
        this.pulse( this.bottom_nav.tools.count );
    }

    setPricetoDOM( dom, number ) {
        if ( number < 1000 ) number = `0${number}`;
        const parts = Utils.NumToBitcoin( number, '.' ).split( '.' );
        dom.innerHTML = '';
        const price = document.createElement( 'span' );
        const thou = document.createElement( 'span' );
        thou.classList.add( 'price-thousands' );

        parts.forEach( (part, index) => {
            if ( index !== parts.length - 1 ) {
                price.textContent += part;
                if ( index !== parts.length - 2 ) price.textContent += '.';
            } else {
                if ( index === 0 ) {
                    thou.textContent = `${parts[parts.length - 1]}`;
                } else {
                    thou.textContent = `.${parts[parts.length - 1]}`;
                }
            }
        } );

        dom.appendChild( price );
        dom.appendChild( thou );
    }

    // Returns current message set in the tools message element
    getUserMessage() {
        if ( this.bottom_nav.tools.message.value === '' ) {
            return '';
        } else {
            return this.bottom_nav.tools.message.value;
        }
    }

    clearUserMessage() {
        this.bottom_nav.tools.message.value = '';
        this.shrinkUserMessageHeight();
        this.bottom_nav.tools.message_progress.setProgress( 0 );
        this.bottom_nav.tools.total_message.setProgress( 0 );
    }

    // Shrink user message height if text is short enough
    shrinkUserMessageHeight() {
        if ( this.bottom_nav.tools.message.textLength < 50 ) {
            this.bottom_nav.tools.message.classList.remove( 'vertical-expand' );
        }
    }

    // Track message length
    enforceMessageLength( field, length ) {

        const enc = new TextEncoder( 'utf-8' ).encode( field.value );

        if ( enc.byteLength > length ) {
            // console.log( enc.byteLength );
            const slice = enc.slice( 0, this.MAX_MESSAGE_LENGTH );
            field.value = new TextDecoder( 'utf-8' ).decode( slice );
            this.updateMessageLengthCounter();
            Utils.flashElement( field );
        }
    }

    updateProgressBar( num, max ) {
        // Generate current max message size from total possible size
        // based on number of pixels selected
        const percent_of_current_max = max / this.MAX_MESSAGE_LENGTH * 100;

        // Generate current amount used of generated total
        const count =  num / this.MAX_MESSAGE_LENGTH * 100;

        // Generate percent used of current total
        const percent_chars_used = count / percent_of_current_max * 100;

        // Manually set the color of the sub progress bar
        if ( percent_chars_used >= 99 ) this.bottom_nav.tools.total_message.setColor( '#e0245e' );
        else if ( percent_chars_used >= 90 ) this.bottom_nav.tools.total_message.setColor( 'orange' );
        else if ( percent_chars_used >= 80 ) this.bottom_nav.tools.total_message.setColor( 'yellow' );
        else if ( percent_chars_used <  60 ) this.bottom_nav.tools.total_message.setColor( 'white' );

        // Set the UI
        this.bottom_nav.tools.message_progress.setProgress( percent_of_current_max );
        this.bottom_nav.tools.total_message.setProgress( count );
    }

    updateMessageLengthCounter() {
        const pixels = this.sparkshot.Viewer._selected.length * 3;
        const value = ( pixels > this.MAX_MESSAGE_LENGTH ) ? this.MAX_MESSAGE_LENGTH : pixels;

        this.current_max_message_length = value;

        // Calculate byte size of message
        this.current_message_encode =  new TextEncoder( 'utf-8' ).encode( this.bottom_nav.tools.message.value );

        // Set the price of the message byte length
        this.sparkshot.Viewer.setMessagePrice( this.current_message_encode.byteLength * this.MESSAGE_CHAR_PRICE );

        this.current_message_remain = value - this.current_message_encode.byteLength;

        this.updateProgressBar( this.current_message_encode.byteLength, this.current_max_message_length );

        this.setMessageLengthCounterColor( this.bottom_nav.tools.total_message.getColor() );

        // Set UI to current number
        this.bottom_nav.tools.message_count.textContent = this.current_message_remain;

        if ( this.current_message_encode.byteLength > 0 ) {
            this.sparkshot.Tutorial.completeHint( "add_message" );      // Mark Tutorial Complete
        }
    }

    setMessageLengthCounterColor( color ) {
        this.bottom_nav.tools.message_count.style.color = color;
    }

    isMessageLengthValid() {
        return ( this.current_message_remain >= 0 ) ? true : false;
    }


    // #####################################################################
    // ART DETAILS METHODS
    // #####################################################################

    // Setup the art meta data panel
    setArtMetaData( args ) {
        args = args || {};
        this.modal.art_info.title.textContent = ( args.title ) ? args.title : 'Missing Title';
        this.modal.art_info.description.textContent = ( args.description ) ? args.description : 'Missing Description';
        // this.setArtMetaViewCount(  ( args.views ) ? args.views : 0 );
        this.setArtMetaSoldCount( ( args.sold ) ? args.sold : 0 );
        this.setArtMetaTotalCount( ( args.total ) ? args.total : 0 );

        this.modal.art_info.artist.dataset.address = args.artist;

        // Check if there's a username in the description
        if ( args.description.includes('@') ) {
            const words = args.description.split( ' ' );
            const user = words.find( word => word.charAt(0) === '@' );

            if ( user.length > 1 ) {
                this.modal.art_info.artist.textContent = user;
                this.modal.art_info.artist.title = args.artist;
            } else {
                this.modal.art_info.artist.textContent = `${args.artist}`;
            }

        } else {
            this.modal.art_info.artist.textContent = `${args.artist}`;
        }

        // this.modal.art_info.artist.textContent = ( args.artist ) ? args.artist : 'Missing Artist';
        this.modal.art_info.merkle_root.textContent = ( args.merkle_root ) ? args.merkle_root : 'Missing Merkle Root';

        if ( this.modal.art_info.sold.textContent === this.modal.art_info.total.textContent )
            this.showArtDownloadButton();
        else
            this.hideArtDownloadButton();

        if ( args.signatureFile )
		    Utils.loadTextFileFromURL( args.signatureFile, this.modal.art_info.signature );
        else
            this.modal.art_info.signature.textContent = 'N/A';

        if ( args.tags ) {
            args.tags.forEach( tag => {
                if ( tag === '' ) return;
                const tagEl = document.createElement( 'div' );
                tagEl.classList.add( 'tag', 'spark-blue-text' );
                tagEl.textContent = tag;
                tagEl.addEventListener( 'click', () => {
                    const address = tag;
                    this.sparkshot.State.setResultStartIndex();
                    this.sparkshot.UI.setSearchQuery( `#${tag}` );
                    this.sparkshot.State.searchAdvanced({
                        search_query:               this.getSearchQuery(),
                        result_start:               0,
                        sort_direction:             "DESCENDING",
                        complete_max:               1.0,
                    });
                }, false );
                this.modal.art_info.tags.appendChild( tagEl );
            } );
        } else {
            this.modal.art_info.tags.textContent = "No Tags";
            this.modal.art_info.tags.classList.add( "grey-text", "text-lighten-1", "no-select" );
        }
    }

    // Open Art Meta Panel to a state or just default open
    openArtMetaPanel( state = '' ) {
        if ( this.isRevealActive() ) return;

        if ( !this.modal.art_info.instance.isOpen ) this.modal.art_info.instance.open();

        switch ( state ) {
            case 'open':
                this.modal.art_info.panel.classList.remove( 'full' );
                this.modal.art_info.panel.classList.remove( 'title' );
                this.modal.art_info.panel.classList.remove( 'hover' );

                this.modal.art_info.buttons.share.classList.add( 'hide' );
                this.modal.art_info.buttons.toggle.classList.add( 'hide' );
                this.modal.art_info.buttons.close.classList.add( 'hide' );
            break
            case 'title':
                this.modal.art_info.panel.classList.add( 'title' );
                this.modal.art_info.panel.classList.remove( 'full' );
                this.modal.art_info.panel.classList.remove( 'hover' );

                this.modal.art_info.buttons.share.classList.remove( 'hide' );
                this.modal.art_info.buttons.toggle.classList.remove( 'hide' );
                this.modal.art_info.buttons.close.classList.remove( 'hide' );

                this.modal.art_info.buttons.toggle.dataset.tooltip = 'Show more details';
            break
            case 'full':
                this.modal.art_info.panel.classList.add( 'full' );
                this.modal.art_info.panel.classList.remove( 'title' );
                this.modal.art_info.panel.classList.remove( 'hover' );

                this.modal.art_info.buttons.share.classList.remove( 'hide' );
                this.modal.art_info.buttons.toggle.classList.remove( 'hide' );
                this.modal.art_info.buttons.close.classList.remove( 'hide' );

                this.modal.art_info.buttons.toggle.dataset.tooltip = 'Show fewer details';
            break
            default:

        }
    }

    // Toggle between open states
    toggleArtMetaPanel() {
        if ( this.isRevealActive() ) return;

        if ( this.modal.art_info.panel.classList.contains( 'title' ) ) {
            this.openArtMetaPanel( 'full' );
        } else {
            this.openArtMetaPanel( 'title' );
        }
    }

    // Open About Panel
    openAboutPanel() {
        // this.blocker( true );
        // this.modal.about.panel.classList.remove( 'hide' );
        this.playHowTo();
    }

    closeAboutPanel() {
        this.blocker( false );
        this.modal.about.panel.classList.add( 'hide' );
        this.stopHowTo();
    }

    // #####################################################################
    // SHARE MODAL
    // #####################################################################

    openSharePanel() {
        this.blocker( true );
        const data = this.sparkshot.Viewer.data;

        // Set Share Panel fields
        this.modal.share.field.art_title.textContent         = `${data.title}`;
        this.modal.share.field.art_page_url.textContent      = `${window.location.href}`;
        this.modal.share.field.wide_image_url.textContent    = `${window.location.origin}/card${window.location.pathname}.png`;
        this.modal.share.field.square_image_url.textContent  = `${window.location.origin}/ig_square${window.location.pathname}.png`;
        this.modal.share.field.tall_image_url.textContent    = `${window.location.origin}/ig_story${window.location.pathname}.png`;

        this.sparkshot.Tutorial.removeAllHints();      // Remove hint if toolbar force closed
    }

    closeSharePanel() {
        this.blocker( false );

        // Set Share Panel fields
        this.modal.share.field.art_title.textContent         = ``;
        this.modal.share.field.art_page_url.textContent      = ``;
        this.modal.share.field.wide_image_url.textContent    = ``;
        this.modal.share.field.square_image_url.textContent  = ``;
        this.modal.share.field.tall_image_url.textContent    = ``;

        this.sparkshot.Tutorial.initHints();            // Reset Tutorial hints
    }

    // Close Art Panel
    closeArtMetaPanel() {
        // Set the art panel back to the default open state
        this.openArtMetaPanel( 'open' );
    }


    // Clear Data and Hide Panel
    resetArtMetaPanel() {
        // Clear all information
        this.modal.art_info.panel.classList.remove( 'full' );
        this.modal.art_info.panel.classList.remove( 'title' );
        this.modal.art_info.panel.classList.remove( 'hover' );

        this.modal.art_info.title.textContent = '';
        this.modal.art_info.description.textContent = '';
        // this.modal.art_info.views.textContent = '';
        this.modal.art_info.sold.textContent = '';
        this.modal.art_info.artist.textContent = '';
        this.modal.art_info.merkle_root.textContent = '';

        this.modal.art_info.tags.innerHTML = '';

        // Ensure it is closed
        this.modal.art_info.instance.close();
    }

    // Set panel to hover state
    hoverPopArtMetaPanel( state ) {
        if ( this.isRevealActive() ) return;
        if ( typeof state !== 'boolean' ) return
        if ( !this.modal.art_info.instance.isOpen ) return;
        if ( !this.modal.art_info.hide )            return;
        if ( this.modal.art_info.panel.classList.contains( 'min' ) ) return
        if ( this.modal.art_info.panel.classList.contains( 'title' ) ) return
        if ( this.modal.art_info.panel.classList.contains( 'full' ) ) return

        if ( state ) {
            this.modal.art_info.panel.classList.add( 'hover' );
            this.modal.art_info.panel.addEventListener( 'click', this.openArtMetaPanel.bind( this, 'title' ), { once: true }, false );
        } else {
            this.modal.art_info.panel.classList.remove( 'hover' );
            this.modal.art_info.panel.removeEventListener( 'click', this.expandArtMetaPanel, false );
        }

    }

    setHoverHide( state ) {
        this.modal.art_info.hide = state;
    }

    // Show art title panel state for x seconds before hiding
    // Used for initial page loads
    revealArtMetaPanel( seconds ) {
        // Bock other functions from running till this completes
        this.modal.art_info.revealActive = true;

        if ( !this.modal.art_info.instance.isOpen ) this.modal.art_info.instance.open();

        this.modal.art_info.panel.classList.add( 'title' );
        this.modal.art_info.panel.classList.remove( 'full' );
        this.modal.art_info.panel.classList.remove( 'hover' );
        this.modal.art_info.buttons.toggle.classList.add( 'hide' );
        this.modal.art_info.buttons.close.classList.add( 'hide' );

        setTimeout( () => {

            this.modal.art_info.panel.classList.remove( 'title' );
            this.modal.art_info.revealActive = false;
        }, seconds * 1000 );
    }

    isRevealActive() {
        return this.modal.art_info.revealActive;
    }

    showArtDownloadButton() {
        this.modal.art_info.buttons.download.classList.remove( 'hide' );
    }

    hideArtDownloadButton() {
        this.modal.art_info.buttons.download.classList.add( 'hide' );
    }

    // Trigger download of art image
    downloadArt() {
        const a = document.createElement('a');
        a.download = `${this.sparkshot.Viewer.data.art_id}.png`;
        a.href = `${window.location.origin}/artifact/${this.sparkshot.Viewer.data.art_id}/original.png`;
        a.target = "_blank";
        document.body.appendChild(a);
        // console.log( a );
        a.click();
        this.showToast( `${this.sparkshot.Viewer.data.art_id}.png downloaded` );
        document.body.removeChild(a);
    }

    createSocialLink( site ) {
        const data = this.sparkshot.Viewer.data;

        switch ( site ) {
            case 'twitter':
                if ( this.sparkshot.Viewer.isArtComplete() ) {
                    window.open( `https://twitter.com/intent/tweet?text=Check out ${data.title} ${window.location.href} - Successfully crowdfunded by fans on @sparkshot_io` );
                } else {
                    window.open( `https://twitter.com/intent/tweet?text=Check out ${data.title} ${window.location.href} - Now crowdfunding pixel-by-pixel @sparkshot_io` );
                }
                this.modal.share.instance.close();
                break;
            case 'instagram':
                this.showHTMLMessage("INSTAGRAM POST",
                    `<h6>
                        Bah, Instagram doesn't let you post from the web, lame.
                    </h6>
                    <h6>
                        Click <a class="bold" download href="/ig_square${window.location.pathname}.png">SQUARE</a> or <a class="bold" download href="/ig_story${window.location.pathname}.png">STORY</a> to download the latest image.
                    </h6>
                    <h6>
                        Feel free to post it on your Instagram and share the link to the art.
                    </h6>`);
                    this.modal.share.instance.close();
                break;
            case 'facebook':
                // ${data.title} -  @sparkshot_io the social micro-payment art platform
                window.open( `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}` );
                this.modal.share.instance.close();
                break;
            case 'reddit':
                window.open( `https://www.reddit.com/submit?url=${window.location.href}&title=Check out ${data.title} on Sparkshot - Crowdfunded art revealed pixel-by-pixel` );
                this.modal.share.instance.close();
                break;
            case 'tumblr':
                window.open( `http://tumblr.com/widgets/share/tool?canonicalUrl=${window.location.href}` );
                this.modal.share.instance.close();
                break;
            case 'email':
                const mail = `mailto:?&subject=${data.title}&body=Check out this art:%0A%0A${data.title}%0A${data.description}%0A%0A${window.location.href}%0A%0A Sparkshot - New Art Discovered Togther.%0A%0Ahttps://sparkshot.io %0Ahttps://twitter.com/sparkshot_io %0A%0A`;
                const mlink = document.createElement('a');
                mlink.setAttribute('href', mail);
                mlink.setAttribute('target', "_blank");
                mlink.click();
                this.modal.share.instance.close();
                break;
            case 'copy':
                Utils.copyText( window.location.href );
                this.showToast( 'Copied Art Link to clipboard' );
                break;
            case 'wide-image':
                Utils.copyText( `${window.location.origin}/card${window.location.pathname}.png` );
                this.showToast( 'Copied Image Link to clipboard' );
                break;
            case 'square-image':
                Utils.copyText( `${window.location.origin}/ig_square${window.location.pathname}.png` );
                this.showToast( 'Copied Image Link to clipboard' );
                break;
            case 'tall-image':
                Utils.copyText( `${window.location.origin}/ig_story${window.location.pathname}.png` );
                this.showToast( 'Copied Image Link to clipboard' );
                break;
            case 'wide-download':
                const wide = document.createElement('a');
                wide.download = `${data.art_id}_card.png`;
                wide.href = `${window.location.origin}/card${window.location.pathname}.png`;
                wide.target = "_blank";
                document.body.appendChild(wide);
                wide.click();
                this.showToast( `${data.art_id}_card.png downloaded` );
                document.body.removeChild(wide);
                break;
            case 'square-download':
                const square = document.createElement('a');
                square.download = `${data.art_id}_square.png`;
                square.href = `${window.location.origin}/ig_square${window.location.pathname}.png`;
                square.target = "_blank";
                document.body.appendChild(square);
                square.click();
                this.showToast( `${data.art_id}_square.png downloaded` );
                document.body.removeChild(square);
                break;
            case 'tall-download':
                const tall = document.createElement('a');
                tall.download = `${data.art_id}_tall.png`;
                tall.href = `${window.location.origin}/ig_story${window.location.pathname}.png`;
                tall.target = "_blank";
                document.body.appendChild(tall);
                tall.click();
                this.showToast( `${data.art_id}_tall.png downloaded` );
                document.body.removeChild(tall);
                break;
            default:

        }

    }

    // Set META Data
    setArtMetaViewCount( num ) {
        this.modal.art_info.views.textContent = Utils.NumToCommas( num );
    }

    setArtMetaTotalCount( num ) {
        this.modal.art_info.total.textContent = Utils.NumToCommas( num )
    }

    setArtMetaSoldCount( num ) {
        this.modal.art_info.sold.textContent = Utils.NumToCommas( num )
    }


    // #####################################################################
    // PIXEL PROBE METHODS
    // #####################################################################
    showProbeData( data ) {
        this.modal.pixel.x.textContent = data.x
        this.modal.pixel.y.textContent = data.y
        this.modal.pixel.color.style.color = `#000000`;
        this.modal.pixel.color.textContent = `???`;
        this.modal.pixel.price.textContent = data.msatoshi_price;
        this.modal.pixel.payment_hash.textContent = data.payment_hash;
        this.modal.pixel.preimage.textContent = data.preimage;

        this.modal.pixel.pixel_preimage.full.textContent = data.pixel_preimage_data;

        if ( data.purchased ) {
            // Set model sections
            this.modal.pixel.purchased.classList.remove( 'hide' );
            this.modal.pixel.unpurchased.classList.add( 'hide' );

            const preimage = Utils.parsePixelPreimage( data.pixel_preimage_data );
            const color = `rgb(${Utils.hex2rgb(("000000" + data.color.toString(16)).substr(-6))})`;
            this.modal.pixel.color.style.color = color;
            this.modal.pixel.color.textContent = `#${preimage.rgb_str}`;
            this.modal.pixel.pixel_preimage.version.firstChild.nodeValue = preimage.version_str;
            this.modal.pixel.pixel_preimage.checksum.firstChild.nodeValue = preimage.art_checksum_id_str;
            this.modal.pixel.pixel_preimage.x.firstChild.nodeValue = preimage.x_coord_str;
            this.modal.pixel.pixel_preimage.y.firstChild.nodeValue = preimage.y_coord_str;
            this.modal.pixel.pixel_preimage.rgb.firstChild.nodeValue = preimage.rgb_str;
            this.modal.pixel.pixel_preimage.price.firstChild.nodeValue = preimage.price_str;
            this.modal.pixel.pixel_preimage.salt.firstChild.nodeValue = preimage.salt_str;

            // Pre2Image
            const pre2_start = data.pre2image.substr( 0, data.pre2image.indexOf( data.pixel_preimage_data ) );
            const pre2_end = data.pre2image.substr( data.pre2image.indexOf( data.pixel_preimage_data ) + data.pixel_preimage_data.length, data.pre2image.length );

            const pre2_start_span = document.createElement( 'span' );
            pre2_start_span.textContent = pre2_start;

            const pre2_bold_span = document.createElement( 'span' );
            pre2_bold_span.classList.add( 'bold', 'black-text' );
            pre2_bold_span.textContent = data.pixel_preimage_data;

            const pre2_end_span = document.createElement( 'span' );
            pre2_end_span.textContent = pre2_end;

            this.modal.pixel.pre2image.innerHTML = '';

            this.modal.pixel.pre2image.appendChild( pre2_start_span );
            this.modal.pixel.pre2image.appendChild( pre2_bold_span );
            this.modal.pixel.pre2image.appendChild( pre2_end_span );

            // Merkle Proof
            let pp = data.pixel_preimage_data;
            let pp_idx1 = data.merkle_proof.indexOf( pp );
            let pp_len = data.pixel_preimage_data.length;
            let proof_len = data.merkle_proof.length;
            const start_nonbold = data.merkle_proof.substr( 0, pp_idx1 );

            let remainder = data.merkle_proof.substr( pp_idx1 + pp_len, proof_len );

            const pp_idx2 = remainder.indexOf( pp );

            const mid1_nonbold = remainder.substr( 0, pp_idx2 );

            let remainder2 = remainder.substr( pp_idx2 + pp_len, proof_len );

            let root_len = 64;
            const mid2_nonbold = remainder2.substr( 0, remainder2.length - root_len);

            const root_bold = remainder2.substr(remainder2.length - root_len, remainder2.length);

            const start_nonbold_span = document.createElement( 'span' );
            start_nonbold_span.textContent = start_nonbold;

            const pp1_bold_span = document.createElement( 'span' );
            pp1_bold_span.classList.add( 'bold', 'black-text' );
            pp1_bold_span.textContent = pp;

            const mid1_nonbold_span = document.createElement( 'span' );
            mid1_nonbold_span.textContent = mid1_nonbold;

            const pp2_bold_span = document.createElement( 'span' );
            pp2_bold_span.classList.add( 'bold', 'black-text' );
            pp2_bold_span.textContent = pp;

            const mid2_nonbold_span = document.createElement( 'span' );
            mid2_nonbold_span.textContent = mid2_nonbold;

            const root_bold_span = document.createElement( 'span' );
            root_bold_span.classList.add( 'bold', 'black-text' );
            root_bold_span.textContent = root_bold;

            this.modal.pixel.merkle.innerHTML = '';

            this.modal.pixel.merkle.appendChild( start_nonbold_span );
            this.modal.pixel.merkle.appendChild( pp1_bold_span );
            this.modal.pixel.merkle.appendChild( mid1_nonbold_span );
            this.modal.pixel.merkle.appendChild( pp2_bold_span );
            this.modal.pixel.merkle.appendChild( mid2_nonbold_span );
            this.modal.pixel.merkle.appendChild( root_bold_span );
        } else {
            // Set model sections
            this.modal.pixel.purchased.classList.add( 'hide' );
            this.modal.pixel.unpurchased.classList.remove( 'hide' );

            this.modal.pixel.unpurchased_x.textContent = data.x;
            this.modal.pixel.unpurchased_y.textContent = data.y;
            this.modal.pixel.unpurchased_price.textContent = data.msatoshi_price;

            let pp = "NOT_YET_PURCHASED";
            let pp_idx1 = data.merkle_proof.indexOf( pp );
            let pp_len = pp.length;
            let proof_len = data.merkle_proof.length;
            const start_nonbold = data.merkle_proof.substr( 0, pp_idx1 );

            let remainder = data.merkle_proof.substr( pp_idx1 + pp_len, proof_len );

            const pp_idx2 = remainder.indexOf( pp );

            const mid1_nonbold = remainder.substr( 0, pp_idx2 );

            let remainder2 = remainder.substr( pp_idx2 + pp_len, proof_len );

            let root_len = 64;
            const mid2_nonbold = remainder2.substr( 0, remainder2.length - root_len);

            const root_bold = remainder2.substr(remainder2.length - root_len, remainder2.length);

            const start_nonbold_span = document.createElement( 'span' );
            start_nonbold_span.textContent = start_nonbold;

            const pp1_bold_span = document.createElement( 'span' );
            pp1_bold_span.classList.add( 'bold', 'black-text' );
            pp1_bold_span.textContent = pp;

            const mid1_nonbold_span = document.createElement( 'span' );
            mid1_nonbold_span.textContent = mid1_nonbold;

            const pp2_bold_span = document.createElement( 'span' );
            pp2_bold_span.classList.add( 'bold', 'black-text' );
            pp2_bold_span.textContent = pp;

            const mid2_nonbold_span = document.createElement( 'span' );
            mid2_nonbold_span.textContent = mid2_nonbold;

            const root_bold_span = document.createElement( 'span' );
            root_bold_span.classList.add( 'bold', 'black-text' );
            root_bold_span.textContent = root_bold;

            this.modal.pixel.merkle.innerHTML = '';

            this.modal.pixel.merkle.appendChild( start_nonbold_span );
            this.modal.pixel.merkle.appendChild( pp1_bold_span );
            this.modal.pixel.merkle.appendChild( mid1_nonbold_span );
            this.modal.pixel.merkle.appendChild( pp2_bold_span );
            this.modal.pixel.merkle.appendChild( mid2_nonbold_span );
            this.modal.pixel.merkle.appendChild( root_bold_span );
        }

        this.modal.pixel.instance.open();
    }

    startPixelProbe() {
        this.openArtMetaPanel('title');
        this.sparkshot.Viewer.Mode = 'PROBE';
        this.showToast( 'PIXEL PROBE' );
    }

    closeProbeData() {
        this.modal.pixel.instance.close();
        this.sparkshot.Viewer.Mode = "PURCHASE";
        this.showToast( 'PURCHASE MODE' );
    }

    togglePixelProbe() {
        if ( this.sparkshot.Viewer.Mode === 'PROBE' ) {
            this.closeProbeData();
        } else {
            this.startPixelProbe();
        }
    }

    // #####################################################################
    // INVOICE METHODS
    // #####################################################################

    showInvoice( invoice ) {
        this.setQRCode( invoice.invoice );
        this.setPricetoDOM( this.modal.payment.price, invoice.msatoshi );
        this.setBolt11( invoice.invoice );
        this.setNode( invoice.node );
        this.setPayButton( invoice.invoice );
        this.modal.payment.instance.open();

        // console.log(this.sparkshot.IM.invoice);

        // Disable the Tools Menu
        this.disableToolsPanel();

        this.sparkshot.Tutorial.removeAllHints();      // Remove hint if toolbar force closed
        this.sparkshot.Tutorial.completeHint( "request_invoice" );      // Mark Tutorial Complete

        this.modal.payment.buttons.pay.focus();
    }

    enablePaymentPanelButton() {
        if ( !this.bottom_nav.buttons.invoicePanel.classList.contains( 'disabled' ) ) return;
        this.bottom_nav.buttons.invoicePanel.classList.remove('disabled');

        this.pulse( this.bottom_nav.buttons.invoicePanel );
    }

    disablePaymentPanelButton( close = true ) {
        if ( close ) this.modal.payment.instance.close();
        if ( this.bottom_nav.buttons.invoicePanel.classList.contains( 'disabled' ) ) return;

        this.bottom_nav.buttons.invoicePanel.classList.add('disabled');
    }

    setPayButton( invoice ) {
        const button = this.modal.payment.buttons.pay;

        if ( invoice._isExpired ) {
            button.classList.add( 'disabled' );
        } else {
            button.classList.remove( 'disabled' );
        }
    }

    isPaymentPanelEnabled() {
        return !this.bottom_nav.buttons.invoicePanel.classList.contains( 'disabled' );
    }

    // Open invoice in any associted app or fail silently
    silentOpenPaymentApp() {
        this.payer
        try {
          WebLN.requestProvider()
            .then( res => {
                this.payer = res;
                if ( res.isEnabled ) res.sendPayment( this.sparkshot.IM.invoice.invoice );
            })
            .catch( err => {
            } );
        } catch (err) {

        }
    }

    openPaymentApp() {
        this.payer
        try {
          WebLN.requestProvider()
            .then( res => {
                this.payer = res;
                if ( res.isEnabled ) {
                    res.sendPayment( this.sparkshot.IM.invoice.invoice );
                }
                else {
                    this.copyBolt11Invoice( "No App Found: Copied Invoice to Clipboard" );
                }
            })
            .catch( err => {
                console.error(err)
                this.openExternalPaymentApp( `lightning:${this.sparkshot.IM.invoice.invoice}` );
            } );
        } catch (err) {
          // Handle users without WebLN
          console.log( "NO WEBLN SETUP, TRY lightning: associated app" );
          this.openExternalPaymentApp( `lightning:${this.sparkshot.IM.invoice.invoice}` );
        }
    }

    copyBolt11Invoice( message = null ) {
        Utils.copyText( this.modal.payment.invoiceaddress.textContent );
        message = ( message === null ) ? 'Copied Invoice to Clipboard' : message;
        this.showToast( message );
    }

    // A fudge that opens up a browser window to trigger whatever might be
    // associated with the lightning url
    openExternalPaymentApp( url ) {

        if ( EXTERNAL_PAY_WINDOW === null || EXTERNAL_PAY_WINDOW.closed ) {
            EXTERNAL_PAY_WINDOW = document.createElement( "iframe" );
            EXTERNAL_PAY_WINDOW.setAttribute( "src", url );
            EXTERNAL_PAY_WINDOW.style.width = "100%";
            EXTERNAL_PAY_WINDOW.style.height = "200px";
            document.body.appendChild( EXTERNAL_PAY_WINDOW );
        } else {
            EXTERNAL_PAY_WINDOW.setAttribute( "src", url );
        }
    }

    setBolt11( value ) {
        this.modal.payment.bolt11.textContent = value;
    }

    setNode( value ) {
        this.modal.payment.node.textContent = value;
    }

    setQRCode( string ) {
        string = string.toUpperCase();
        const qr = new QRious({
            value:  string,
            level:  "M",
            size:   425,
            padding: null,
        });

        this.modal.payment.buttons.qrcode.src = qr.toDataURL();
        this.modal.payment.buttons.qrcode.setAttribute("style", "cursor: pointer;" );
    }

    _clearQRCode(){
        console.log( 'removing QR');
        this.modal.payment.buttons.qrcode.src = this.expired.qrcode;
        this.modal.payment.buttons.copyqr.removeAttribute("href");
        this.modal.payment.buttons.copyqr.removeAttribute("title");
    }

    // The Button on the nav bar that opens the invoice panel
    setInvoiceMenuState( state ) {
        this.bottom_nav.invoiceEnabled = state;

        if ( this.bottom_nav.invoiceEnabled ) {
            this.bottom_nav.button.openinvoice.classList.remove( 'disabled' );
        } else {
            this.bottom_nav.button.openinvoice.classList.add( 'disabled' );
        }
    }

    togglePaymentExpand() {
        if ( this.modal.payment.panel.classList.contains( 'large' ) ) {
            this.shrinkPaymentPanel();
        } else {
            this.expandPaymentPanel();
        }
    }

    expandPaymentPanel() {
        this.modal.payment.panel.classList.add( 'large' );
        this.modal.payment.expandEl.classList.remove( 'fade-hide' );
    }

    shrinkPaymentPanel() {
        this.modal.payment.panel.classList.remove( 'large' );
        this.modal.payment.expandEl.classList.add( 'fade-hide' );
    }

    _setExpandedDisplay() {
        if ( this.modal.payment.panel.classList.contains( 'large' ) ) {
            this.modal.payment.isExpanded = true;
            this.modal.payment.expandEl.classList.remove( 'fade-hide' );
        } else {
            this.modal.payment.isExpanded = false;
            this.modal.payment.expandEl.classList.add( 'fade-hide' );
        }
    }

    setSelectedText( text ) {
        if ( text ) {
            this.bottom_nav.selected.textContent = text;
        } else {
            this.bottom_nav.selected.textContent = '';
        }
    }

    setHoverText( text ) {
        if ( text ) {
            this.bottom_nav.hover.textContent = text;
        } else {
            this.bottom_nav.hover.textContent = '';
        }
    }

    animateExpiryTimer() {
        if ( window.s.IM.invoice === null ) return;
        const startTime     = window.s.IM.invoice.start_time;
        const endTime       = window.s.IM.invoice.expires_at;
        const currentTime   = new Date().getTime() / 1000;
        const length        = endTime - startTime;
        const percent       = Math.floor( 100 - (currentTime - startTime) * 100 / length );
        // const remaining     = length - (currentTime - startTime);
        const remaining     = endTime - currentTime;

        // console.log( `%c Percent: ${percent} Current: ${currentTime} End: ${endTime} Remain: ${remaining} `, 'color: white; background: black');

        // Set bar length
        if ( percent > 0 ) {
            window.s.UI.modal.timer.bar.style.width = `${percent}%`;

            // Set bar colour
            if ( remaining < 10 ) {
                window.s.UI.modal.timer.bar.style.backgroundColor = window.s.UI.color.low;
            } else if ( remaining < 25 ) {
                window.s.UI.modal.timer.bar.style.backgroundColor = window.s.UI.color.moderate;
            } else {
                window.s.UI.modal.timer.bar.style.backgroundColor = window.s.UI.color.normal;
            }

        } else {
            window.s.IM.invoice.isExpired = true;
            window.s.UI.modal.payment.buttons.qrcode.src = '/img/qr-expired.png';
            window.s.UI.modal.timer.bar.style.width = "100%";
            window.s.UI.modal.payment.buttons.qrcode.removeAttribute("style", "cursor: pointer;" );
        }

        if ( !window.s.IM.invoice.isExpired ) {
            window.requestAnimationFrame( window.s.UI.animateExpiryTimer );
        }
    }

    // #####################################################################
    // MESSAGES
    // #####################################################################

    // Pop up message used for notifications
    showToast( message ) {
        M.toast( { html: message } );
    }

    // Dismissable pop up message used for important alerts
    showMessage ( title, description, showClose = true, closeText = "CLOSE" ) {
        this.sparkshot.Viewer.infobox.clear();
        if ( showClose ) {
            this.modal.message.instance.el.querySelector('a').classList.remove( 'hide' );
        } else {
            this.modal.message.instance.el.querySelector('a').classList.add( 'hide' );
        }

        // Add Text in UPPERCASE for style
        this.modal.message.title.textContent = title.toLocaleUpperCase();

        this.modal.message.body.textContent = description;

        this.modal.message.instance.$el.children()[1].children[0].textContent = closeText.toLocaleUpperCase();

        if ( !this.modal.message.instance.isOpen ) {
            this.modal.message.instance.open();
            this.blocker( true );
        }
    }

    showHTMLMessage( title, content, showClose = true, closeText = "CLOSE" ) {
        this.sparkshot.Viewer.infobox.clear();
        if ( showClose ) {
            this.modal.message.instance.el.querySelector('a').classList.remove( 'hide' );
        } else {
            this.modal.message.instance.el.querySelector('a').classList.add( 'hide' );
        }

        // Add Text in UPPERCASE for style
        this.modal.message.title.textContent = title.toLocaleUpperCase();

        this.modal.message.body.innerHTML = content;

        this.modal.message.instance.$el.children()[1].children[0].textContent = closeText.toLocaleUpperCase();

        if ( !this.modal.message.instance.isOpen ) {
            this.modal.message.instance.open();
            this.blocker( true );
        }
    }

    // #####################################################################
    // Invoice Methods
    // #####################################################################

    openInvoicePanel( invoice ) {
        if ( !this.modal.payment.instance.isOpen ) {
            // Try to send invoice to a payment app
            this.silentOpenPaymentApp();

            // Show invoice panel
            // Cannot confidently not do this yet as there's no fallback if
            // installed payment apps fail, such as have no funds or cannot find
            // payment route
            this.showInvoice( invoice );
            this.animateExpiryTimer();
        }
    }

    closeInvoicePanel() {
        if ( this.modal.payment.instance.isOpen ) {
            this.sparkshot.Tutorial.removeAllHints();      // Remove hint if toolbar force closed
            this.blocker( false );
            this.modal.payment.instance.close();
            this.bottom_nav.buttons.invoicePanel.classList.add('disabled');
            this.sparkshot.Tutorial.initHints();            // Reset Tutorial hints
        }
    }

    invoiceButton() {

    }

    // #####################################################################
    // Help Methods
    // #####################################################################

    openHelpPanel() {
        this.blocker( true );
        this.sparkshot.Tutorial.completeHint( "pay_invoice" );      // Mark Tutorial Complete
    }

    closeHelpPanel() {
        this.blocker( false );
    }

    selectHelpPage( page ) {

        switch ( page ) {
            case 1:
                this.modal.help.page_2.classList.add( 'hide' );
                this.modal.help.page_1.classList.remove( 'hide' );
                break;
            case 2:
                this.modal.help.page_1.classList.add( 'hide' );
                this.modal.help.page_2.classList.remove( 'hide' );
            default:

        }
    }

    playHowTo() {
        this.modal.about.videos.forEach( (e, idx) => {
            const num = ( idx + 1 === this.modal.about.videos.length ) ? 0 : idx + 1;

            e.addEventListener( 'ended', vid => {
                this.playVideo( num );
                this.modal.about.videos[idx].load();
            }, false );
        });

        this.modal.about.videos[0].currentTime = 0;
        this.playVideo( 0 );

    }

    stopHowTo() {
        this.modal.about.cards.forEach( e => e.classList.remove( 'selected-card' ) );
        this.modal.about.videos.forEach( e => {
            e.load();
        } );
    }

    playVideo( num ) {
        this.modal.about.cards.forEach( e => e.classList.remove( 'selected-card' ) );
        this.modal.about.cards[num].classList.add( 'selected-card' );


        this.modal.about.videos[num].play()
        .then( () => {
            this.modal.about.cards[num].children[1].classList.add( 'hide' );
        })
        .catch( err => {
            this.modal.about.cards[num].children[1].classList.remove( 'hide' );
        } );
    }

    // #####################################################################
    // CONTROLS
    // #####################################################################

    showArtControls() {
        if ( Utils.mobileCheck() ) {
            this.controls.panel.classList.add( 'hide' );
            this.mobile_controls.panel.classList.remove( 'hide' );
        } else {
            this.mobile_controls.panel.classList.add( 'hide' );
            this.controls.panel.classList.remove( 'hide' );
        }
    }

    hideArtControls() {
        this.mobile_controls.panel.classList.add( 'hide' );
        this.controls.panel.classList.add( 'hide' );
    }

    setArtControlVisibility( state ) {
        if ( state ) {
            this.showArtControls();
        } else {
            this.hideArtControls();
        }
    }

    // #####################################################################
    // Page Loading
    // #####################################################################

    showLoadingBar() {
        document.querySelector( '#ui-loader-bar' ).classList.remove( 'hide' );
    }

    hideLoadingBar() {
        document.querySelector( '#ui-loader-bar' ).classList.add( 'hide' );
    }

    // #####################################################################
    // Features
    // #####################################################################

    pulse( dom ) {
        dom.classList.add( 'pulse' );
        setTimeout( () => { dom.classList.remove( 'pulse' ); }, 600 );
    }

    // Panel sits behind modal menus and main interface
    // Blocks input
    // Track the number of calls and stack them so they cannot be closed
    blocker( state ) {
        if ( isNaN(this._blocker_count ) ) { this._blocker_count = 0; }
        if ( state ) {
            this._blocker_count++;
            this.modal.blocker.style.display = 'block';

            // Set UI buttons to not be tab selected
            this.top_nav.buttons.about.tabIndex = "-1";
            // this.top_nav.buttons.faq.tabIndex = "-1";
            // this.top_nav.buttons.upload.tabIndex = "-1";
            this.top_nav.search.field.tabIndex = "-1";
            this.top_nav.search.button.tabIndex = "-1";

        } else {
            this._blocker_count--;
            if ( this._blocker_count <= 0 ) {
                this.modal.blocker.style.display = 'none';
            }
        }
    }

    closeBlocker() {
        console.log("don't call");
        this._blocker_count = 0;
        this.sparkshot.UI.blocker( false );
    }

    isaPanelOpen( ) {
        this.modal.help.instance.classList.contains( 'hide' );
    }

    // #####################################################################
    // Tools
    // #####################################################################
    /**
	 * Adds a ToolTip to a DOM element
	 * @param  {Object} el - Element to bind the tip to
	 * @param  {String} text - Text or HTML of Tool Tip
	 * @return {Object} returns ToolTip Element
	 */
    addStaticToolTip( el, text, options = {} ) {
        if (!el) {
            console.error("UI Error: Missing element from addToolTip");
            return;
        }
        if ( Object.entries(options).length === 0 ) {
            options = {
                position: "top",
                html: text,
                enterDelay: 0,
                exitDelay: 10000000
            };
        } else {
            options.html = text;
            if ( typeof(options.position) === "undefined" ) options.position = "top";
            if ( typeof(options.enterDelay) === "undefined" ) options.enterDelay = 0;
            if ( typeof(options.exitDelay) === "undefined" ) options.exitDelay = 10000000;
        }

        const tip = M.Tooltip.init(el, options);
        if ( typeof(options.classList) !== "undefined" && Array.isArray(options.classList)) {
            options.classList.forEach( item => {
                tip.tooltipEl.classList.add(item);
            });
        }

        if ( typeof(options.parentClassList) !== "undefined" && Array.isArray(options.parentClassList)) {
            options.parentClassList.forEach( item => {
                tip.el.classList.add(item);
            });
        }

        return tip;
    }

    removeStaticToolTip( toolTipSourceEl, classListToRemove ) {
        if ( typeof(toolTipSourceEl) === "undefined" ) {
            console.error("UI Error: Missing element from removeStaticToolTip");
            return;
        }

        if ( typeof(toolTipSourceEl.M_Tooltip) === "undefined") { return; }

        // Remove the tool tip link
        toolTipSourceEl.M_Tooltip.tooltipEl.remove();

        if ( typeof(classListToRemove) !== "undefined" && Array.isArray(classListToRemove)) {
            classListToRemove.forEach( item => {
                toolTipSourceEl.classList.remove(item);
            });
        } else {
            toolTipSourceEl.classList.remove(classListToRemove);
        }
    }

    // Closes all modals that can be closed i.e they are dismissable
    closeAllOtherModals( than ) {
        this.modalInstances.forEach( e => { if (e.options.dismissible && e.id !== than) e.close(); } );
    }

}

exports.UI = UI;
