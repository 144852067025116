/**
 * Copyright PrimeVR 2020
 * @author roskelld https://github.com/roskelld
 */

const Utils = require('./utils.js').Utils;
const ProgressCircle = require('./progress-circle.js').ProgressCircle;
const SimpleBar = require("simplebar");

class Chat {
    constructor( sparkshot, args ) {

        this.sparkshot = sparkshot;
        this.moment = require("moment");

        this.MAX_CHAT_MESSAGES = 10000;
        this.MAX_CHAT_BATCH_SIZE = 20;
        this.DEFAULT_MESSAGE = '➤';
        this.NO_MESSAGE = "[NO MESSAGE]";
        this.NO_MESSAGE_TEXT = "No Message";
        this.CHAT_NSFW = '[NSFW]';
        this.CHAT_NOPE = '[MESSAGE REMOVED]';
        this.VALID_MESSAGE_CHARACTERS = /([a-zA-Z0-9!$&\-=_+':\",.? ])/;
        this.INVALID_MESSAGE_CHARACTERS = /([\n\t])+/gm;
        this.BAD_CHARACTERS_MESSAGE = "Text contains disallowed characters.";
        this.MAX_MESSAGE_LENGTH = 255;
        this.CHAT_INSTRUCTION = 'Send a temporary live chat message';
        this.MSG_LINK_TEST = /(@[0-9]+)/g;
 
        this.panels = {
            main:           document.querySelector( '#ui-chat-panel' ),
            messages:       document.querySelector( '#ui-chat-messages-panel' ),
            submit:         document.querySelector( '#ui-chat-submit-panel' ),
            content:        null,
        };


        setTimeout( () => {
            this.panels.content = this.panels.messages.querySelector( '.simplebar-content' );
        }, 0);

        this.message =      document.querySelector( '#ui-chat-message-area' );

        this.buttons = {
            submit:         document.querySelector( '#ui-chat-message-submit' ),
            toggle:         document.querySelector( '#ui-chat-toggle' ),
            settings:       document.querySelector( '#ui-chat-settings-button' ),
            more:           document.querySelector( '#ui-chat-more-btn' ),
        };

        this.icons = {
            open:         document.querySelector( '#ui-chat-icon-open' ),
            close:        document.querySelector( '#ui-chat-icon-close' ),
        };

        this.chat_message_progress = new ProgressCircle( {
                                            size: 20,
                                            stroke_width: 2,
                                            id: 'ui-chat-message-limit',
                                            parent_id: 'ui-chat-message-progress',
                                            base_color: '#39bced'
                                        } );

        this._selected = null;

        this._isOpen = null;
        this.isDisabled = null;

        this.chatPanelResize();

        // .....................................................................
        // Set Scroll Bars
        // Using: https://github.com/Grsmto/simplebar
        this.scrollBars = {
            chat: new SimpleBar( this.panels.messages ),
            message: new SimpleBar( this.message ),
        };
    }

    init() {
        window.addEventListener( 'resize', Utils.debounce( () => {
            if ( this.sparkshot.Chat.isOpen() ) {
                this.sparkshot.Chat.chatPanelResize();
            }
        }, 300 ));

        // #####################################################################
        // CHAT INPUT
        // #####################################################################

        // Submit message via Enter key
        this.message.addEventListener( 'keydown', e => {
            if ( ( e.keyCode || e.which ) === 13 ) {
                e.preventDefault();
                this.submitChat();
                return;
            }
        });

        // Input to user message field
        this.message.addEventListener( "input", e => {
            const field = this.message;

            // Signal bad input
            if ( !Utils.validateText( e.data, /([^\n\t])/ ) ) { Utils.flashElement( field ); }

            // Replace Bad Characters
            field.value = field.value.replace( this.INVALID_MESSAGE_CHARACTERS, '');

            // Track Message Length
            this.updateMessageLengthCounter( field, field.value );
        });

        // Input to user message field
        this.message.addEventListener( "paste", e => {
            const field = this.message;

            // Replace Bad Characters
            field.value = field.value.replace( this.INVALID_MESSAGE_CHARACTERS, '');

            // Signal bad input
            // if ( !Utils.validateText( e.clipboardData.getData('text/plain') ) ) Utils.flashElement( field );
            if ( !Utils.validateText( e.clipboardData.getData('text/plain'), /([^\n\t])/ ) ) { Utils.flashElement( field ); }

            this.updateMessageLengthCounter( field, field.value );
        });

        this.buttons.submit.addEventListener( 'click', () => this.submitChat() );
        this.buttons.toggle.addEventListener( 'click', () => this.toggleChat() );
        this.buttons.settings.addEventListener( 'click', () => {
            this.sparkshot.Profile.sideNav.selectTab("user");
            this.sparkshot.Profile.instance.open();
         });
        this.buttons.more.addEventListener( 'click', () => { this.scrollToBottom(); }, false );
        this.message.addEventListener( 'focus', () => this.checkUserName() );

        // Fetch generated content elements that stores chat messages
        this.panels.content = this.panels.messages.querySelector( '.simplebar-content' );

        // Report scroll height
        this.scrollBars.chat.getScrollElement().addEventListener( 'scroll', Utils.debounce( () => {
            if ( this.sparkshot.Chat.panels.content.firstElementChild === null ) { return; }


            if ( this.sparkshot.Chat.isOpen() &&
                 this.sparkshot.Chat.isScrollAtTop() &&
                 this.sparkshot.Chat.panels.content.firstElementChild.dataset.index !== "0" ) {

                    // If there are earlier messages to generate then add a new batch
                    const idx = Number(this.sparkshot.Chat.panels.content.firstElementChild.dataset.index);
                    const start = idx - this.sparkshot.Chat.MAX_CHAT_BATCH_SIZE - 1;
                    const end = start + this.sparkshot.Chat.MAX_CHAT_BATCH_SIZE;
                    this.sparkshot.Chat.addReceiptsByRange(
                        this.sparkshot.Viewer.data,
                        start,
                        end );
            }

            if ( !this.sparkshot.Chat.isScrollAtBottom() ) {
                this.showMoreButton();
            } else  {
                this.hideMoreButton();
            }

        }, 300 ), false );

        // Set the default placeholder message
        this.message.placeholder = this.CHAT_INSTRUCTION;

        this.chat_message_progress.setProgress(0);

        // Setup NSFW Filter
        this.setNSFW();
    }

    // Clear all chat messages and message box text
    clear() {
        this.chat_message_progress.setProgress(0);
        this.panels.content.innerHTML = '';
        this.message.value = '';
    }

    checkUserName() {
        const name = this.sparkshot.Settings.getLocalData( 'name' );
        if ( name === null || name === '' ) {
            this.sparkshot.Profile.sideNav.selectTab("user");
            this.sparkshot.Profile.instance.open();
            return false;
        } else {
            return true;
        }
    }

    chatPanelResize() {
        this.panels.messages.setAttribute( 'style', `height: ${this.panels.main.clientHeight - 117}px`);
    }

    toggleChat() {
        if ( this.isDisabled ) { return; }

        if ( this._isOpen ) {
            this.closeChat();
        } else {
            this.openChat();
        }
    }

    isOpen() {
        return this._isOpen;
    }

    closeChat() {
        this._isOpen = false;
        this.panels.main.classList.add( 'close' );
        this.panels.main.classList.remove( 'open' );

        this.buttons.toggle.style.cursor = "pointer";

        this.icons.close.style.display = "none";
        this.icons.open.style.display = "block";

        this.sparkshot.UI.appPanelResize();
    }

    openChat() {
        this._isOpen = true;
        this.panels.main.classList.add( 'open' );
        this.panels.main.classList.remove( 'close' );

        this.buttons.toggle.style.cursor = "pointer";

        this.icons.open.style.display = "none";
        this.icons.close.style.display = "block";


        this.chatPanelResize();
        this.sparkshot.UI.appPanelResize();
    }

    disableChat() {
        this.isDisabled = true;
        this._isOpen = false;
        this.panels.main.classList.add( 'close' );
        this.panels.main.classList.remove( 'open' );

        this.buttons.toggle.style.cursor = "default";

        this.icons.close.style.display = "none";
        this.icons.open.style.display = "none";
        this.icons.close.style.pointer = "default";


        this.sparkshot.UI.appPanelResize();
    }

    enableChat() {
        this.isDisabled = false;
        this.buttons.toggle.style.cursor = "pointer";

        this.panels.main.classList.add( 'close' );
        this.icons.open.style.display = "block";

        // Check if user has name
        const name = this.sparkshot.Settings.getLocalData( 'name' );
        if ( name !== null ) {
            this.sparkshot.WS.request_trollbox_name( this.sparkshot.Settings.getLocalData( 'name' ) );
            // this.personalChatMessage( `Welcome ${this.sparkshot.Settings.getLocalData( 'name' )}` );
        }
    }

    // Handles the message length based in bytes and sets the progress bar
    updateMessageLengthCounter( field, string ) {

        // Calculate byte size of message
        const encode =  new TextEncoder( 'utf-8' ).encode( string );

        // Clamp message
        if ( encode.byteLength > this.MAX_MESSAGE_LENGTH ) {
            const slice = encode.slice( 0, this.MAX_MESSAGE_LENGTH );
            field.value = new TextDecoder( 'utf-8' ).decode( slice );
            Utils.flashElement( field );
        }

        // Message Length Tracking
        this.chat_message_progress.setProgress( encode.byteLength / this.MAX_MESSAGE_LENGTH * 100 );
    }

    updateProgressBar( num, max ) {
        // Generate current max message size from total possible size
        // based on number of pixels selected
        const percent_of_current_max = max / this.MAX_MESSAGE_LENGTH * 100;

        // Generate current amount used of generated total
        const count =  num / this.MAX_MESSAGE_LENGTH * 100;

        // Generate percent used of current total
        const percent_chars_used = count / percent_of_current_max * 100;

        // Manually set the color of the sub progress bar
        switch ( true ) {
            case ( percent_chars_used >= 99 ): this.bottom_nav.tools.total_message.setColor( '#e0245e' ); break;
            case ( percent_chars_used >= 90 ): this.bottom_nav.tools.total_message.setColor( 'orange' ); break;
            case ( percent_chars_used >= 80 ): this.bottom_nav.tools.total_message.setColor( 'yellow' ); break;
            case ( percent_chars_used <  60 ): this.bottom_nav.tools.total_message.setColor( 'white' ); break;
        }

        // Set the UI
        this.bottom_nav.tools.message_progress.setProgress( percent_of_current_max );
        this.bottom_nav.tools.total_message.setProgress( count );
    }

    getMessages() {

    }

    addMessage( user, message, rating = 0 ) {

        const div = document.createElement( 'div' );
        div.classList.add( 'ui-chat-message', 'spark-off-white-text', 'grey', 'darken-3' );
        div.dataset.name = user;

        /// HEADER
        const div_header = document.createElement( 'div' );
        div_header.classList.add( 'ui-chat-message-header', 'grey', 'darken-3' );

        // NAME
        const span_name = document.createElement( 'span' );
        span_name.classList.add( 'ui-chat-message-name' );
        span_name.textContent = `${user}`;

        div_header.appendChild( span_name );

        // BODY
        const span_body = document.createElement( 'div' );
        span_body.classList.add( 'ui-chat-message-body' );

        // Test for link
        if ( message.match( this.MSG_LINK_TEST ) ) {
            let split_string = message.split( this.MSG_LINK_TEST );

            split_string.forEach( frag => {
                if ( !frag.match( this.MSG_LINK_TEST ) ) {
                    const text = document.createTextNode( frag );
                    span_body.appendChild( text );
                    return;
                }
                const id = frag.substr( 1, frag.length );
                if ( id > this.sparkshot.Viewer.data.replays.length - 1 ) {
                    const text = document.createTextNode( frag );
                    span_body.appendChild( text );
                    return; // Can't link to self or future
                }
                span_body.appendChild( this.addLink( id ) );
            });
        } else {
            span_body.textContent = `${message}`;
        }

        div.appendChild( div_header );
        div.appendChild( span_body );

        if ( this.isScrollAtBottom() ) {
            this.panels.content.appendChild( div );
            this.scrollToBottom();
        } else {
            this.panels.content.appendChild( div );
            this.showMoreButton();
        }
        this.scrollBars.chat.recalculate();
    }

    addReceipts( entry, max = -1 ) {
        max = ( max === -1 ) ? entry.replays.length : max;
        const start = entry.replays.length - max - 1;

        this.addReceiptsByRange( entry, start, start + max );

        if ( !this.isScrollAtBottom() )
            this.showMoreButton();
    }

    addReceiptsByRange( entry, start, end ) {
        const fragment = document.createDocumentFragment();

        if ( start < 0 ) {
            console.warn( `Cannot add message with index < 0 - Index: ${start}` );
            start = 0;
        }

        // Run through each entry based on the replays
        // for ( let i = end; i >= start; i-- ) {
        for ( let i = start; i <= end; i++ ) {
            const info = this.purchaseGroupInfo( i );
            fragment.appendChild ( this.addReceipt( info, true ) );
        }

        const lastPos = ( this.panels.content.firstElementChild === null ) ? 0 : this.panels.content.firstElementChild.dataset["index"];
        const addPosition = ( lastPos > start ) ? false : true;

        if ( addPosition ) {
            this.panels.content.appendChild( fragment );
        } else {
            this.panels.content.prepend( fragment );
        }

        // Process the URL to see if a message needs to be selected
        this.urlParamToMessage();

        if ( !this.isScrollAtBottom() ) this.showMoreButton();
    }

    urlParamToMessage() {
        // CHECK SEARCH TO SEE IF WE NEED TO SELECT A MESSAGE
        const params = new URLSearchParams( window.location.search );
        if ( params.get( 'msg' ) ) {
            // Select the message found in the URL
            const found = this.selectMessageByIndex( params.get('msg'), false );

            if ( found ) {
                // Simulate click event to select and move camera in
                const clicker = document.createEvent( 'MouseEvents' );
                clicker.initEvent( "mousedown", true, true );
                this._selected.dispatchEvent( clicker );

                // Block the typical popups when visiting a link for first time users
                this.sparkshot.Tutorial.removeAllHints();
                this.sparkshot.Tutorial.closeWelcomePopup();

                setTimeout( () => {
                    this.jumpTo();
                }, 0 );
            }
        } else {
            setTimeout( () => this.scrollToBottom(), 1000 );
        }
    }

    addReceiptByIndex( i ) {
        const info = this.purchaseGroupInfo( i );

        if ( this.isScrollAtBottom() ) {
            this.panels.content.appendChild( this.addReceipt ( info ) );
            this.scrollToBottom();
        } else {
            this.panels.content.appendChild( this.addReceipt ( info ) );
            this.showMoreButton();
        }
    }

    addReceipt( entry, start = false ) {
        const div       = document.createElement( 'div' );
        let index       = entry.replay_index;;
        const date      = new Date( entry.timestamp * 1000 - 2000 );

        div.id = `chat-${entry.replay_index}`;
        div.classList.add( 'ui-chat-message', 'pointer', 'spark-off-white-text', 'spark-grey-dark' )

        div.dataset.index = `${Utils.NumToCommas(entry.replay_index)}`;
        div.dataset.date = `${date}`;

        /// HEADER
        const div_header = document.createElement( 'div' );
        div_header.classList.add( 'ui-chat-message-header' );

        // INDEX
        const span_index = document.createElement( 'span' );
        span_index.classList.add( 'ui-chat-message-index' );
        span_index.textContent = `@${index}`;

        // BOOST
        const span_boost = document.createElement( 'span' );
        span_boost.classList.add( 'ui-chat-message-boost' );
        // span_boost.textContent = ` ⭐ 1 💗 20 🔥 666 🌠 10 `;
        span_boost.textContent = ``;

        // DATE
        const span_date = document.createElement( 'span' );
        span_date.classList.add( 'ui-chat-message-date' );
        span_date.textContent = `${this.moment( date ).fromNow()}`;

        div_header.appendChild( span_index );
        div_header.appendChild( span_boost );
        div_header.appendChild( span_date );

        // BODY
        const span_body = document.createElement( 'div' );
        span_body.classList.add( 'ui-chat-message-body' );

        if ( entry.mod_setting === 'NOPE' ) {
            span_body.textContent = `${this.CHAT_NOPE}`;
            span_body.classList.add( 'chat-nope' );
        } else {

            // Test for link
            if ( entry.user_string.match( this.MSG_LINK_TEST ) ) {
                let split_string = entry.user_string.split( this.MSG_LINK_TEST );

                split_string.forEach( frag => {
                    if ( !frag.match( this.MSG_LINK_TEST ) ) {
                        const text = document.createTextNode( frag );
                        span_body.appendChild( text );
                        return;
                    }
                    const id = frag.substr( 1, frag.length );
                    if ( id >= index ) {
                        const text = document.createTextNode( frag );
                        span_body.appendChild( text );
                        return; // Can't link to self or future
                    }
                    span_body.appendChild( this.addLink( id ) );
                });
            } else {
                // Test if message has no text or default text
                if ( entry.user_string === this.DEFAULT_MESSAGE || entry.user_string === this.NO_MESSAGE ) {
                    span_body.textContent = this.DEFAULT_MESSAGE;
                    span_body.classList.add( 'empty' );
                    span_body.title = this.NO_MESSAGE_TEXT;
                } else {
                    span_body.textContent = `${entry.user_string}`;
                }
            }
        }

        if ( entry.mod_setting === 'NSFW' ) {
            span_body.classList.add( 'chat-nsfw' );
        }

        if ( entry.right_to_left ) {
            span_body.classList.add( 'right-to-left' );
        }

        div.appendChild( div_header );
        div.appendChild( span_body );

        const pixelGroup = this.sparkshot.Viewer.createHighlightEdgeGroupFromArray( entry.coords );
        const camera = this.getCameraPositionFromGroup( pixelGroup );

        div.addEventListener( 'contextmenu', e => {
            e.preventDefault();

        }, false );

        div.addEventListener( 'mousedown', e => {
            if ( e.button === 2 ) {
                e.preventDefault();
                const text = `${window.location.protocol}//${window.location.host}${window.location.pathname}?msg=${entry.replay_index}`;
                Utils.copyText( text );
                this.sparkshot.UI.showToast( 'Copied Message Link' );
                Utils.flashElement( e.target, 'white' );
            } else {
                this.selectMessage( div.children[0], false );
                this.sparkshot.Viewer.cameraToPixelGroup( camera.x, camera.y, camera.width, camera.height );
                this.sparkshot.Viewer.setHighlightGroup( entry.coords );
            }
        }, true );


        return div;
    }

    purchaseGroupInfo( replay_index ) {
        const DATA = this.sparkshot.Viewer.data.user_strings;
        const i = replay_index;

        // Check and Fetch Message
        const user_string_index = DATA.findIndex( x => x.replay_index == i );
        const user_string = ( user_string_index !== -1 ) ? DATA[user_string_index].user_string : this.DEFAULT_MESSAGE;
        const mod_setting = ( user_string_index !== -1 ) ? DATA[user_string_index].mod_setting : 'NONE';
        const right_to_left = ( user_string_index !== -1 ) ? DATA[user_string_index].right_to_left : false;
        const is_coord = ( user_string_index !== -1 ) ? DATA[user_string_index].is_coord : -1;
        const target_long = ( user_string_index !== -1 &&  is_coord === false ) ? DATA[user_string_index].target_long : -1;
        const target_short = ( user_string_index !== -1 && is_coord === false ) ? DATA[user_string_index].target_short : -1;

        const coords =  this.sparkshot.Viewer.data.replays[i].coords;
        let overpays = [];
        let colors = [];

        coords.forEach( loc => {
            overpays.push( this.sparkshot.Viewer.data.state[loc.x][loc.y].overpay );
            colors.push( this.sparkshot.Viewer.data.state[loc.x][loc.y].color );
        });

        // Add Chat Message if there is one
        const info = {
            art_id:                 this.sparkshot.Viewer.data.art_id,
            replay_index:           i,
            user_string:            user_string,
            coords:                 coords,
            overpays:               overpays,
            colors:                 colors,
            mod_setting:            mod_setting,
            right_to_left:          right_to_left,
            // user_string_coord:      user_string_coord,
            timestamp:              this.sparkshot.Viewer.data.replays[i].timestamp,
            target_long:            target_long,
            target_short:           target_short,
        }

        return info;
    }

    /**
     * Returns center position with group width/height information
     * @param {array} group - Array of x and y coordinates
     * @returns {object}  {x, y, width, height}
     */
    getCameraPositionFromGroup( group ) {
        const sortX = group.slice();
        const sortY = group.slice();

        sortX.sort( (a, b) => a.x - b.x );
        sortY.sort( (a, b) => a.y - b.y );

        const x = Math.round( sortX[0].x + ( ( sortX[sortX.length -1].x - sortX[0].x ) / 2 ) );
        const y = Math.round( sortY[0].y + ( ( sortY[sortY.length -1].y - sortY[0].y ) / 2 ) );
        const width  = 1 + sortX[sortX.length -1].x - sortX[0].x;
        const height = 1 + sortY[sortY.length -1].y - sortY[0].y;

        return { x: x, y: y, width: width, height: height };
    }

    addLink( idx ) {
        const el = document.createElement( 'chat-link' );
        el.textContent = `@${idx}`;
        el.dataset.index = `${idx}`;

        const entry = this.purchaseGroupInfo( idx );
        const pixel = this.sparkshot.Viewer.getLocalPixelInfo( entry.coords[0].x, entry.coords[0].y, false );
        const pixelGroup = this.sparkshot.Viewer.createHighlightEdgeGroupFromArray( entry.coords );
        const camera = this.getCameraPositionFromGroup( pixelGroup );

        el.addEventListener( 'mouseenter', () => {
            this.sparkshot.Viewer.showInfoBox( pixel.canvas.x, pixel.canvas.y, false );
        }, false );

        el.addEventListener( 'mouseleave', () => { this.sparkshot.Viewer.infobox.clear(); }, false );

        el.addEventListener( 'click', () => {
            this.sparkshot.Viewer.cameraToPixelGroup( camera.x, camera.y, camera.width, camera.height );
            this.sparkshot.Viewer.setHighlightGroup( entry.coords );
        }, false );

        el.addEventListener( 'dblclick', e => {
            e.preventDefault();
            this.selectMessageByIndex( idx );
            this.sparkshot.Viewer.cameraToPixelGroup( camera.x, camera.y, camera.width, camera.height );
            this.sparkshot.Viewer.setHighlightGroup( entry.coords );
        }, false );

        return el;
    }

    submitChat() {
        // If there's nothing to send then do nothing
        if ( this.message.textLength === 0 ) return;

        const chat = this.message.value;

        this.sparkshot.WS.request_trollbox_msg( chat, this.sparkshot.Viewer.data.art_id );

        this.message.value = '';
        this.chat_message_progress.setProgress( 0 );
    }

    jumpTo() {
        if ( this._selected == null ) return;
        this._selected.scrollIntoView({ behavior: 'auto', block: 'nearest' })
    }

    scrollToBottom() {
        if ( !this.panels.content.hasChildNodes() ) return;
        const el = this.scrollBars.chat.getScrollElement();
        if ( el.scrollTop + el.offsetHeight < el.scrollHeight - 10 ) {
            el.scrollTop = el.scrollHeight - el.clientHeight;
        }
        this.hideMoreButton();
    }

    isScrollAtBottom() {
        const el = this.scrollBars.chat.getScrollElement();
        return (el.scrollTop + el.offsetHeight >= el.scrollHeight - 10 ) ? true : false ;
    }

    isScrollAtTop() {
        const el = this.scrollBars.chat.axis.y.scrollbar.rect;
        return ( this.scrollBars.chat.getScrollElement().scrollTop <= 0 ) ? true : false;
    }

    selectMessageByIndex( num, scroll = true ) {
        // If auto scroll happens then remove the hide more button
        this.hideMoreButton();
        const c = this.panels.content.querySelector( `#chat-${num}` );
        if ( c !== null ) {
            this.selectMessage( c.children[0], scroll );
            return true;
        } else {
            this.clearSelect();
            console.error( `MESSAGE ${num} NOT FOUND`);
            return false;
        }
    }

    selectMessage( el, scrollTo = true ) {
        this.clearSelect();
        el.classList.add( 'selected' );
        this._selected = el;
        if ( scrollTo ) {
            this._selected.parentElement.scrollIntoView( { behavior: "smooth", block: "nearest" } );
        }
    }

    isMessageInView( el ) {
        const rect = el.getBoundingClientRect();
        const elemTop = rect.top;
        const elemBottom = rect.bottom;

        // Only completely visible elements return true:
        const isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);
        // Partially visible elements return true:
        //isVisible = elemTop < window.innerHeight && elemBottom >= 0;
        return isVisible;
    }

    isMessageInViewByIndex( num ) {
        const c = this.panels.content.querySelector( `#chat-${num}`);
        if ( c !== null ) {
            return this.isMessageInView( c );
        } else {
            console.error( `MESSAGE ${num} NOT FOUND`);
            return null;
        }
    }

    clearSelect() {
        if ( this._selected !== null )
            this._selected.classList.remove( 'selected', 'flash' );
    }

    personalChatMessage( message ) {
        const div       = document.createElement( 'div' );
        const content   = document.createElement( 'span' );

        div.classList.add( 'ui-chat-message' );
        content.classList.add( 'ui-chat-content' );
        content.textContent = message;

        div.appendChild( content );
        this.panels.content.appendChild( div );

        this.scrollToBottom();
    }

    showMoreButton() {
        this.buttons.more.classList.remove( 'hide' );
    }

    hideMoreButton() {
        this.buttons.more.classList.add( 'hide' );
    }

    setNSFW() {
        if ( this.sparkshot.Settings.getShowNSFWContentStatus() ) {
            this.panels.content.classList.remove( 'nsfw' );
        } else {
            this.panels.content.classList.add( 'nsfw' );
        }
    }

}

exports.Chat = Chat;
