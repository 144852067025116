/**
* Copyright PrimeVR 2020
* @author roskelld https://github.com/roskelld
* Based on https://github.com/rezoner/playground/blob/master/src/Keyboard.js
*/

class Keyboard {
    constructor() {
        this.doubleTapTime = 200;

        this.enabled = true;

        // track
        this.clean();

        // Special Keys
        this.specialKeys = ["ctrl", "alt", "shift", "F3", "F4", "F5", "F6", "F7", "F8", "F9", "F10", "F11", "F12", "Backspace", "Pageup", "Pagedown", "End", "Home", "ArrowDown", "ArrupUp", "ArrowLeft", "ArrupRight"];

        this.keydownEvent = new CustomEvent("keyboarddown");
        this.keyupEvent = new CustomEvent("keyboardup");

        // Get Browser Keyboard events
        document.addEventListener( "keydown", e => { this.keydown(e); }, false );
        document.addEventListener( "keyup", e => { this.keyup(e); }, false );

        // Do I need a blur kill event to make sure keys don't get stuck?
        window.addEventListener( "blur", () => { this.clean(); }, true );

        this.keycodes = {
            37: "left",
            38: "up",
            39: "right",
            40: "down",
            45: "insert",
            46: "delete",
            8: "backspace",
            9: "tab",
            13: "enter",
            16: "shift",
            17: "ctrl",
            18: "alt",
            19: "pause",
            20: "capslock",
            27: "escape",
            32: "space",
            33: "pageup",
            34: "pagedown",
            35: "end",
            36: "home",
            96: "numpad0",
            97: "numpad1",
            98: "numpad2",
            99: "numpad3",
            100: "numpad4",
            101: "numpad5",
            102: "numpad6",
            103: "numpad7",
            104: "numpad8",
            105: "numpad9",
            106: "numpadmul",
            107: "numpadadd",
            109: "numpadsub",
            110: "numpaddec",
            111: "numpaddiv",
            112: "f1",
            113: "f2",
            114: "f3",
            115: "f4",
            116: "f5",
            117: "f6",
            118: "f7",
            119: "f8",
            120: "f9",
            121: "f10",
            122: "f11",
            123: "f12",
            144: "numlock",
            145: "scrolllock",
            173: "dash",
            186: "semicolon",
            187: "equal",
            188: "comma",
            189: "dash",
            190: "period",
            191: "slash",
            192: "graveaccent",
            219: "openbracket",
            220: "backslash",
            221: "closebracket",
            222: "singlequote"
        };
    }

    keydown(e) {
        if  ( !this.enabled ) { return; }
        // console.log( `${e.which} + ${e.code} + ${e.key}` );
        // Check if key is already down
        if ( e.repeat ) { return; }

        // Set using keycodes to track case states
        let keyName;
        if ( e.which >= 48 && e.which <= 90 ) {
            keyName = String.fromCharCode(e.which).toLowerCase();
        } else {
            keyName = this.keycodes[e.which];
        }

        if (this.mapping[keyName]) { keyName = this.mapping[keyName]; }

        if (this.keys[keyName]) { return; }

        this.anyKey++;

        // Register key press
        this.keys[keyName] = true;
        this.keydownEvent.key = keyName;
        this.keydownEvent.event = e;
        this.target = e.target;

        // Check if the last key pressed was this one for double tap
        if ( this.lastKey === e.key &&
            Date.now() - this.timestamps[keyName] < this.doubleTapTime ) {
            // console.log(`DOUBLE TAP ${e.key}`);
            this.timestamps[keyName] = this.keydownEvent.time = Date.now() - this.doubleTapTime;
            this.keydownEvent.doubleTap = true;
        } else {
            // console.log( 'nope');
            this.timestamps[keyName] = this.keydownEvent.time = Date.now();
            this.keydownEvent.doubleTap = false;
        }

        // Broadcast this custom event
        document.dispatchEvent(this.keydownEvent);

        // Check special keys
        if ( e.preventDefault && document.activeElement === document.body ) {
            // console.log( 'are we?');
            let bypass = e.metaKey;

            if (!bypass) {
                for (var i = 0; i < this.specialKeys.length; i++) {
                    if ( this.specialKeys[i].toLowerCase() === e.key.toLowerCase() ) {
                        bypass = true;
                        break;
                    }
                }
            }

            if (!bypass) {
                // console.log( 'bypass');
                e.preventDefault();
                e.stopPropagation();
            }
        }


        this.lastKey = e.key;
    }

    keyup(e) {
        if  ( !this.enabled ) { return; }

        // Set using keycodes to track case states
        let keyName;
        if ( e.which >= 48 && e.which <= 90 ) {
            keyName = String.fromCharCode(e.which).toLowerCase();
        } else {
            keyName = this.keycodes[e.which];
        }

        if (this.mapping[keyName]) { keyName = this.mapping[keyName]; }

        this.anyKey--;

        delete this.keys[keyName];

        // UGH FIX: If anyKey falls out of sync
        if ( Object.keys(this.keys).length === 0 ) { this.anyKey = 0; }
        if ( this.anyKey === 0 ) { this.keys = {}; }
        this.keyupEvent.key = `"${keyName}"`;
        this.keyupEvent.event = e;

        // console.log( `KEY UP ${e.key}`);
        document.dispatchEvent(this.keyupEvent);
    }

    isKeyDown( key ) {
        let state = false;
        if ( Array.isArray(key) ) {
            key.forEach( x => { if ( this.keys[x.toLowerCase()] ) { state = true; } } );
        } else {
            state = this.keys[key.toLowerCase()];
        }

        return state;
    }

    // Clear all data
    clean() {
        this.keys = {};
        this.mapping = {};
        this.timestamps = {};
        this.anyKey = 0;
        this.lastKey = -1;
        this.target = {};
    }
}

exports.Keyboard = Keyboard;
