/**
* Copyright PrimeVR 2018
* @author roskelld https://github.com/roskelld
*/

const Utils = require('./utils.js').Utils;

class Settings {
    constructor ( sparkshot ) {
        this.sparkshot = sparkshot;
        this.defaults = {
            fields: {
                nsfw:           'false',
                art_controls:   'true',
                name:           ''
            }
        };
    }

    init() {

    }

    // #####################################################################
    // LOAD & SAVE
    // #####################################################################

    getLocalData( key = null ) {
        if ( key === null ) {
            console.error(`Error Loading Data: No Key supplied`);
            return;
        }
        // Set Default if no value found
        const data = localStorage.getItem( key );
        if ( data === null || data === "undefined" ) {
            if ( this.defaults.fields[key] === null ) {
                return console.error(`Error Loading Data: No defaut value for ${key}`);
            }
            if ( this.defaults.fields[key] === undefined ) {
                return null;
            } else {
                return this.defaults.fields[key];
            }
        } else {
            return localStorage.getItem( key );
        }

    }

    saveLocalData( key = null, value = null ) {
        if ( key === null || value === null ) {
            console.error(`Error Saving Data: key ${key} value: ${value}`);
            return null;
        }
        return localStorage.setItem( key, value );
    }

    // #####################################################################
    // GET SETTINGS DATA
    // #####################################################################

    getShowNSFWContentStatus() {
        if ( this.getLocalData( 'nsfw' ).toLowerCase() === 'false' ) {
            return false;
        } else {
            return true;
        }
    }

    getArtControlsStatus() {
        if ( this.getLocalData( 'art_controls' ).toLowerCase() === 'false' ) {
            return false;
        } else {
            return true;
        }
    }
}

exports.Settings = Settings;
