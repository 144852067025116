/**
 * Copyright PrimeVR 2018
 * @author roskelld https://github.com/roskelld
 */

const Utils = require('./utils.js').Utils;
const Invoice = require('./lninvoice.js').Invoice;

class InvoiceManager {
    constructor (sparkshot) {
        this.sparkshot = sparkshot;
        this._invoices = [];
        this.invoice = null;
        this.invoiceRequested = false;
        this.overpay        = [',','.',';',':'];    // , 1x . 10x ; 100x : 1000x
        this.overpayAmount  = [',','.',';',':'];    // , 1x . 10x ; 100x : 1000x
        this.overpay = [
            { symbol: ',', text: '1x' },
            { symbol: '.', text: '10x' },
            { symbol: ';', text: '100x' },
            { symbol: ':', text: '1000x' },
        ];
    }

    addInvoice ( invoice ) {
        // Creates a new invoice for display

        // We didn't request an invoice
        if ( !this.isInvoiceRequested() ) {
            console.error(`Error: An Invoice has not been requested`);
            return;
        }

        if ( this.invoice === null ) {
            // TODO Resolve multi art invoices
            // If a users requests an invoice, then jumps to other art
            // and Requests another invoice, how do we resolve?

            console.error( `Error: There is no invoice to fill` );
            return;
        }

        // Check to see data matches request data
        // Increase match points to improve this or find another solution
        if ( this.invoice.user_string !== invoice.user_string ) {
            console.error( `Error: Invoice doesn't match request` );
            return;
        }

        // Don't think this code does anything. Maybe I should have commented
        // when I wrote it, or just wrote it better so it was obvious
        this.invoice.update( invoice );


        // Recieved invoice lock pixels
        this.sparkshot.Viewer.lockInvoicedSelection();        

        // Do we need to show the invoice or can we just pay it via extention?
        this.sparkshot.UI.openInvoicePanel( invoice );

        this.clearInvoiceRequested();

        // Clear Loading Bar
        this.sparkshot.UI.hideLoadingBar();

    }

    removeInvoice() {
        this.invoice = null;
        this.clearInvoiceRequested();
        this.sparkshot.UI.blocker( false );
    }

    removeExpiredInvoices() {

    }

    requestInvoice( basket, user_string, price ) {
        if ( !this.sparkshot.UI.isPaymentPanelEnabled() ) return;
        // Set the application state into waiting for a response from the server
        // Until resolved or timed out, no more interactions can be performed

        // Validate request data
        if ( basket === '' || user_string === null || price === '' ) {
            console.error(`Error Requesting Invoice: ${basket} ${user_string} ${price}`);
            return;
        }

        // Check this isn't a double request
        if ( this.isInvoiceRequested() ) {
            console.error(`Error: An Invoice has already been requested`);
            return;
        }

        // Calculate Final Price
        // price = price + ( this.sparkshot.UI.bottom_nav.tools.message.length * this.sparkshot.UI.MESSAGE_CHAR_PRICE );

        // Mark that an invoice has now been requested
        this.setInvoiceRequested();

        // Create the invoice
        this.invoice = new Invoice( {
            basket:         basket,
            user_string:    user_string,
            estimate:       price
        } );

        // Send request to server
        this.sparkshot.WS.request_invoice( this.invoice.basket, this.invoice.user_string );

        // Disable application interaction
        this.sparkshot.UI.blocker( true );

        // Show Loading Bar
        this.sparkshot.UI.showLoadingBar();
    }

    updateMatchInvoice( data ) {
        // if we have no invoice then we don't care
        if ( this.invoice === null ) return false;

        let basket = '';
        // Convert Data Coordinates to string
        data.coords.forEach( (pixel, idx) => {
            let update = '';
            const overpay = this.sparkshot.IM.overpaySymByAmount( data.overpays[idx] );
            // Not at the last entry
            if ( idx < data.coords.length - 1 ) {
                update = `${pixel.x}${overpay}${pixel.y}_`;
            } else {
                update = `${pixel.x}${overpay}${pixel.y}`;
            }
            basket = basket.concat( update );
        })

        if ( basket === this.invoice.basket ) {
            return true;
        } else {
            return false;
        }
    }

    hasInvoices () {
        return ( this._invoices.length > 0 ) ? true : false;
    }

    getLastInvoice() {
        return this._invoices[ this._invoices.length - 1];
    }

    cancelInvoice() {
        this.sparkshot.UI.closeInvoicePanel();
        if ( this.invoice ) {
            this.sparkshot.WS.request_cancel_invoice( this.invoice.label );
            this.invoice = null;
        }
    }

    doubleInvoice( data ) {
        // If an invoice is requested by contains already invoiced items
        // Respond and let the user know it wasn't a valid request and
        // no invoice was generated

        // Conver to pixel object array
        const rawPixels = this.convertPixelStringToObjectArray( data );
        // console.log( rawPixels );

        rawPixels.forEach( pxl => {
            // console.log( pxl );
            const flipPxl = this.sparkshot.Viewer.getLocalPixelInfo( pxl.x, pxl.y );
            // console.log( flipPxl );
            // console.log(`Pixel: ${flipPxl.canvas.x} ${flipPxl.canvas.y}`);
            this.sparkshot.Viewer.removePixelFromSelection( flipPxl.data.x, flipPxl.data.y );
        } );


        // Let the user know
        let message = '';

        if ( rawPixels.length > 1 ) {
            message =
            `${rawPixels.length} Pixels are already pending payment.
            <br>We've removed them from your selection.`;
        } else {
            message =
            `This pixel is already pending payment.<br>
            Select a different pixel or try again later.`;
        }

        this.sparkshot.UI.showToast( message );

        // Reset the invoice state
        this.invoice = null;
        this.clearInvoiceRequested();

        // Allow the user to edit
        this.sparkshot.UI.blocker( false );

        // Update the tools to reflect removed pixels
        this.sparkshot.UI.updateMessageLengthCounter();
        this.sparkshot.Viewer.updateToolsState();

    }

    getInvoiceFromBasket( basket ) {
        let invoice = null;
        this._invoices.map( inv => {
            if ( inv.basket === basket ) { invoice = inv; }
        });

        return invoice
    }

    isInvoiceRequested() {
        return this.invoiceRequested;
    }

    setInvoiceRequested() {
        return this.invoiceRequested = true;
    }

    clearInvoiceRequested() {
        return this.invoiceRequested = false;
    }

    ////////////////////////////////////////////////////////////////////////////
    // OVERPAY
    ////////////////////////////////////////////////////////////////////////////

    overpaySymByIndex( num ) {
        return ( this.overpay[num] ) ? this.overpay[num].symbol : this.overpay[0].symbol;
    }

    overpaySymByAmount( amount ) {
        const op = this.overpay.find( ob => ob.text === amount );
        return op.symbol
    }

    convertPixelStringToObjectArray( data ) {
        // Takes a string on pixels and converts to array of: { x: 0, y: 0 } objects
        // String format example: 10_100_34_22_12_2
        // Take a string
        if ( data === null ) return;
        // console.log('convertPixelStringToObjectArray');
        // console.log( data );
        let pixels = [];

        data.forEach( item => {
            let pixel = {
                x: Number( item.substring( 0, item.indexOf(',') ) ),
                y: Number( item.substring( item.indexOf(',') + 1, item.length ) ),
            }
            if ( pixel.x === 'NaN' || pixel.y === 'NaN') console.error(`Error: convertPixelStringToObjectArray: ${item} contains NaN`);

            pixels.push ( pixel );
        });

        return pixels;
    }

}

exports.InvoiceManager = InvoiceManager;
