/**
* Copyright PrimeVR 2020
*/

const msg = require("bitcoinjs-message");
const bip39 = require("bip39");
const bip32 = require("bip32");
const bitcoin = require("bitcoinjs-lib");

const N_WORDS = 12;

const ENGLISH_SET = new Set(bip39.wordlists.english);
const SPANISH_SET = new Set(bip39.wordlists.spanish);
const ITALIAN_SET = new Set(bip39.wordlists.italian);
const FRENCH_SET = new Set(bip39.wordlists.french);
const KOREAN_SET = new Set(bip39.wordlists.korean);
const JAPANESE_SET = new Set(bip39.wordlists.japanese);
const CHINESE_TRADITIONAL_SET = new Set(bip39.wordlists.chinese_traditional);
const CHINESE_SIMPLIFIED_SET = new Set(bip39.wordlists.chinese_simplified);

class Wallet {
    constructor() {
        this.detected_language = null;
    }

    get_hd_node() {
        const mnemonic = this.get_mnemonic();
        const seed = bip39.mnemonicToSeedSync(mnemonic);
        return bip32.fromSeed(seed);
    }

    get_first_hd_child( hd_node ) {
        return hd_node.derivePath("m/44'/0'/0'/0/0");
    }

    get_child_address( child ) {
        return bitcoin.payments.p2pkh({ pubkey: child.publicKey }).address;
    }

    get_address_wif() {
        const hd_node = this.get_hd_node();
        const child = this.get_first_hd_child(hd_node);
        return {address: this.get_child_address(child),
                wif:     child.toWIF()};
    }

    get_signature( message, wif ) {
        const keyPair = bitcoin.ECPair.fromWIF(wif);
        const privateKey = keyPair.privateKey
        return msg.sign(message, privateKey, keyPair.compressed).toString('base64');
    }

    ////////////////////////////////////////////////////////////////////////////

    has_mnemonic() {
        const p = localStorage.getItem('bip39mnemonic');
        // console.log("has_mnemonic " + (p != null));
        return p != null;
    }

    delete_mnemonic() {
        console.log("deleting");
        localStorage.removeItem('bip39mnemonic');
    }

    get_mnemonic() {
        const m = localStorage.getItem('bip39mnemonic');
        // console.log("got: " + m);
        return m;
    }

    generate_mnemonic() {
        return bip39.generateMnemonic();
    }

    store_mnemonic( mnemonic ) {
        return localStorage.setItem('bip39mnemonic', mnemonic);
    }

    generate_store_mnemonic( list ) {
        console.log("gen seed mnemonic");
        const mnemonic = bip39.generateMnemonic(128, false, list);
        this.store_mnemonic(mnemonic);
    }

    ////////////////////////////////////////////////////////////////////////////

    generate_english() {
        this.generate_store_mnemonic(bip39.wordlists.english);
    }

    generate_spanish() {
        this.generate_store_mnemonic(bip39.wordlists.spanish);
    }

    generate_italian() {
        this.generate_store_mnemonic(bip39.wordlists.italian);
    }

    generate_french() {
        this.generate_store_mnemonic(bip39.wordlists.french);
    }

    generate_korean() {
        this.generate_store_mnemonic(bip39.wordlists.korean);
    }

    generate_japanese() {
        this.generate_store_mnemonic(bip39.wordlists.japanese);
    }

    generate_chinese_traditional() {
        this.generate_store_mnemonic(bip39.wordlists.chinese_traditional);
    }

    generate_chinese_simplified() {
        this.generate_store_mnemonic(bip39.wordlists.chinese_simplified);
    }

    ///////////////////////////////////////////////////////////////////////////////

    check_mnemonic_valid( mnemonic ) {
        const words = mnemonic.split(" ");
        const list = this.classify_word(words[0]).list;
        return bip39.validateMnemonic(mnemonic, list);
    }

    ///////////////////////////////////////////////////////////////////////////////

    sortedIndex(array, value) {
        let low = 0;
        let high = array.length;

        while (low < high) {
            const mid = (low + high) >>> 1;
            if (array[mid] < value) {
                low = mid + 1;
            }
            else {
                high = mid;
            }
        }
        return low;
    }

    get_word_count() {
        return N_WORDS;
    }

    get_word_index(word, list) {
        let low = 0;
        let high = list.length;

        while (low < high) {
            const mid = (low + high) >>> 1;
            if (list[mid].localeCompare(word) < 0) {
                low = mid + 1;
            }
            else {
                high = mid;
            }
        }
        return low;
    }

    classify_word(word) {
        let is_seed_word = false;
        let lang = null;
        let set = null;
        let index = null;

        if (ENGLISH_SET.has(word)) {
            is_seed_word = true;
            lang = "english"
            set = ENGLISH_SET;
            index = this.get_word_index(word, bip39.wordlists.english);
        }
        else if (SPANISH_SET.has(word)) {
            is_seed_word = true;
            lang = "spanish"
            set = SPANISH_SET;
            index = this.get_word_index(word, bip39.wordlists.spanish);
        }
        else if (ITALIAN_SET.has(word)) {
            is_seed_word = true;
            lang = "italian"
            set = ITALIAN_SET;
            index = this.get_word_index(word, bip39.wordlists.italian);
        }
        else if (FRENCH_SET.has(word)) {
            is_seed_word = true;
            lang = "french"
            set = FRENCH_SET;
            index = this.get_word_index(word, bip39.wordlists.french);
        }
        else if (KOREAN_SET.has(word)) {
            is_seed_word = true;
            lang = "korean"
            set = KOREAN_SET;
            index = this.get_word_index(word, bip39.wordlists.korean);
        }
        else if (JAPANESE_SET.has(word)) {
            is_seed_word = true;
            lang = "japanese"
            set = JAPANESE_SET;
            index = this.get_word_index(word, bip39.wordlists.japanese);
        }
        else if (CHINESE_TRADITIONAL_SET.has(word)) {
            is_seed_word = true;
            lang = "chinese_traditional"
            set = CHINESE_TRADITIONAL_SET;
            index = this.get_word_index(word, bip39.wordlists.chinese_traditional);
        }
        else if (CHINESE_SIMPLIFIED_SET.has(word)) {
            is_seed_word = true;
            lang = "chinese_simplified"
            set = CHINESE_SIMPLIFIED_SET;
            index = this.get_word_index(word, bip39.wordlists.chinese_simplified);
        }
        return {word: word, is_seed_word: is_seed_word, lang: lang, index: index};
    }

    get_bad_reason( result, lang ) {
        if (result.word.toLowerCase() !== result.word) return `Word is not lowercase`;
        // if (/\s/g.test(result.word)) return `Cannot contain spaces`;
        if (result.word === "") return `No seed word entered`;
        if (! result.is_seed_word) return `Not a valid BIP39 seed word`;
        if (result.lang !== lang) return `Different language than ${lang}`;
        return "";
    }

    check_word(word) {
        const lang = this.detected_language;
        const result = this.classify_word(word);

        // If language null then first word
        if (lang === null && result.is_seed_word) {
            this.detected_language = result.lang;
            return { valid: true, language: result.lang, reason: null };
        }

        // Language Mismatch
        if (lang !== null && result.lang !== lang) {
            return { valid: false, reason: this.get_bad_reason( result, lang ) };
        }

        // All good
        if (result.is_seed_word) {
            return { valid: true, language: result.lang, reason: null };
        } else {
            return { valid: false, reason: this.get_bad_reason( result, lang ) };
        }
    }

    check_seed_phrase(words) {
        let lang = null;
        let mnemonic_valid = false;
        let good_words = 0;
        let mnemonic = "";

        for (let i = 0; i < N_WORDS; i++) {
            const result = this.classify_word(words[i]);

            if (result.is_seed_word) {
                good_words++;
                mnemonic = `${mnemonic} ${words[i]}`;
                lang = result.lang;
            }
        }

        mnemonic = mnemonic.trim();
        mnemonic_valid = this.check_mnemonic_valid(mnemonic);

        if (!mnemonic_valid) {
            return {
                valid: false,
                reason: 'INVALID SEED - CHECK WORDS ARE CORRECT AND IN CORRECT ORDER'
            };
        }

        if (good_words === N_WORDS && mnemonic_valid) {
            this.store_mnemonic(mnemonic);
            return { valid: true, reason: null };
        }
    }

    clear_language() {
        this.detected_language = null;
    }


    ///////////////////////////////////////////////////////////////////////////////

    setup_mnemonic_entry_divs() {
        var phrase_div = document.getElementById('phrase');
        var i;
        for (i = 0; i < N_WORDS; i++) {
            var div = document.createElement("div");
            div.setAttribute("id", "word-" + i);

            var status_div = document.createElement("div");
            status_div.setAttribute("id", "word-status-" + i);

            var label_div = document.createElement("div");
            label_div.setAttribute("id", "word-label-" + i);

            var input_div = document.createElement("div");
            input_div.setAttribute("id", "word-input-" + i);

            div.appendChild(status_div);
            div.appendChild(label_div);
            div.appendChild(input_div);
            phrase_div.appendChild(div);
        }
    }

    set_inputs_labels() {
        for (let i = 0; i < N_WORDS; i++) {
            var label_div = document.getElementById("word-label-" + i);
            var w = document.createTextNode("Word " + i + ":");
            label_div.appendChild(w);

            var input_div = document.getElementById("word-input-" + i);
            var input = document.createElement("input");
            input.setAttribute("id", "phrase" + i);
            input.setAttribute("type", "text");
            input.setAttribute("size", "30");
            input.setAttribute("maxlength", "30");
            input.setAttribute("value", "");
            input_div.appendChild(input);
        }
    }

    set_status_none() {
        for (let i = 0; i < N_WORDS; i++) {
            var status_div = document.getElementById("word-status-" + i);
            var e = document.createElement("i");
            e.setAttribute("class", "fas fa-exclamation-circle fa-2x");
            e.setAttribute("style", "color:grey");
            e.setAttribute("aria-hidden", "true");
            status_div.appendChild(e);
        }
    }

    set_word_status_good( word_n ) {
        this.delete_children('word-status-' + word_n);
        var status_div = document.getElementById("word-status-" + word_n);
        var e = document.createElement("i");
        e.setAttribute("class", "fa fa-check-square fa-2x");
        e.setAttribute("style", "color:green");
        e.setAttribute("aria-hidden", "true");
        status_div.appendChild(e);
    }

    set_word_status_bad( word_n, reason) {
        this.delete_children('word-status-' + word_n);
        var status_div = document.getElementById("word-status-" + word_n);
        var e = document.createElement("i");
        var t = document.createTextNode(reason);
        e.setAttribute("class", "fa fa-window-close fa-2x");
        e.setAttribute("style", "color:red");
        e.setAttribute("aria-hidden", "true");
        status_div.appendChild(e);
        status_div.appendChild(t);
    }

    set_wrote_seed() {
        window.localStorage.setItem( "wrote_down_seed", true );
    }

    /**
	 * Check if user has written down Mnemonic Seed Phrase
	 * @return {boolean} returns state of form
	 */
    get_wrote_seed_status() {
        return window.localStorage.getItem( "wrote_down_seed", true ) ? true : false;
    }

    clear_wrote_seed() {
        window.localStorage.removeItem("wrote_down_seed");
    }

    ///////////////////////////////////////////////////////////////////////////////
}

exports.Wallet = Wallet;
