/**
* Copyright PrimeVR 2020
*/

const WIDTH = 150;

class AccountingHistory {
    constructor(events, btc_addr) {
        this.events = events;
        this.btc_addr = btc_addr;
    }
    ///////////////////////////////////////////////////////////////////////////

    fmt_time(timestamp) {
        var d = new Date();
        d.setTime(timestamp*1000);
        return d.toUTCString();
    }

    add_line(s, line) {
        let pad = WIDTH - line.length - 1;
        if (pad < 0) {
            return s + line + "*\n";
        }
        return s + line + " ".repeat(pad) + "*\n";
    }

    add_multiline(s, multiline) {
        let ml = multiline.split("\n");
        for (var i = 0; i < ml.length; i++) {
            s = this.add_line(s, ml[i]);
        }
        return s;
    }

    divider() {
        return "*".repeat(WIDTH) + "\n";
    }

    add_divider(s) {
        return s + this.divider();
    }


    ///////////////////////////////////////////////////////////////////////////

    text_header() {
        let r = this.divider();
        r = this.add_line(r, "Artist Address: " + this.btc_addr, "   # Events " + this.events.length);
        return this.add_divider(r);
    }

    ///////////////////////////////////////////////////////////////////////////

    lookup_request_event_row(event) {
        let r = "";
        r = this.add_line(r, "Event: Lookup      UUID: " + event['uuid']);
        r = this.add_line(r, "Time: "  + this.fmt_time(event['time']));
        r = this.add_multiline(r, event['info']['msg_txt']);
        return this.add_divider(r);
    }

    ///////////////////////////////////////////////////////////////////////////

    chain_withdraw_request_event_row(event) {
        let r = "";
        r = this.add_line(r, "Event: On-chain Withdraw      UUID: " + event['uuid']);
        r = this.add_line(r, "Time: "  + this.fmt_time(event['time']));
        r = this.add_line(r, "Satoshis: "  + event['info']['satoshis'] + " satoshis");
        r = this.add_line(r, "Destination: "  + event['info']['dst_address']);
        r = this.add_line(r, "Conf Target: "  + event['info']['conf_target']);
        r = this.add_multiline(r, event['info']['msg_txt']);
        return this.add_divider(r);
    }


    lightning_withdraw_request_event_row(event) {
        let r = "";
        let satoshis = event['info']['bolt11_msatoshi'] / 1000.0;

        r = this.add_line(r, "Event: Lightning Withdraw      UUID: " + event['uuid']);
        r = this.add_line(r, "Time: "  + this.fmt_time(event['time']));
        r = this.add_line(r, "Satoshis: "  + satoshis + " satoshis");
        r = this.add_line(r, "Bolt11: "  + event['info']['bolt11']);
        r = this.add_multiline(r, event['info']['msg_txt']);
        return this.add_divider(r);
    }

    withdraw_request_event_row(event) {
        if (event['info']['withdraw_type'] == "On Chain") {
            return this.chain_withdraw_request_event_row(event);
        }
        return this.lightning_withdraw_request_event_row(event);
    }

    ///////////////////////////////////////////////////////////////////////////

    overdraw_event_row(event) {
        let r = "";
        r = this.add_line(r, "Event: Overdrawn           UUID: " + event['uuid']);
        r = this.add_line(r, "Time: "  + this.fmt_time(event['time']));
        return this.add_divider(r);
    }

    request_event_row(event) {
        let r = "";
        r = this.add_line(r, "Event: Started Withdrawal  UUID: " + event['uuid']);
        r = this.add_line(r, "Time: "  + this.fmt_time(event['time']));
        return this.add_divider(r);
    }

    failed_attempt_event_row(event) {
        let r = "";
        r = this.add_line(r, "Event: Failed Attempt      UUID: " + event['uuid']);
        r = this.add_line(r, "Time: "  + this.fmt_time(event['time']));
        r = this.add_line(r, "Reason: " + event['info']['fail_reason']);
        return this.add_divider(r);
    }

    final_failure_event_row(event) {
        let r = "";
        r = this.add_line(r, "Event: Failed Attempt      UUID: " + event['uuid']);
        r = this.add_line(r, "Time: "  + this.fmt_time(event['time']));
        r = this.add_line(r, "Reason: " + event['info']['fail_reason']);
        return this.add_divider(r);
    }

    success_event_row(event) {
        let r = "";
        r = this.add_line(r, "Event: Successfully Paid   UUID: " + event['uuid']);
        r = this.add_line(r, "Time: "  + this.fmt_time(event['time']));
        if (event['info']['txid'] != "") {
            r = this.add_line(r, "TXID: " + event['info']['txid']);
        } else {
            r = this.add_line(r, "Preimage: " + event['info']['preimage']);
        }
        return this.add_divider(r);
    }

    outgoing_event_row(event) {
        switch (event['info']['event']) {
            case 'OVERDRAWN':
                return this.overdrawn_event_row(event);
            case 'REQUEST':
                return this.request_event_row(event);
            case 'FAILED_ATTEMPT':
                return this.failed_attempt_event_row(event);
            case 'FINAL_FAILURE':
                return this.final_failure_event_row(event);
            case 'SUCCESS':
                return this.success_event_row(event);
        }
    }

    ///////////////////////////////////////////////////////////////////////////

    generate_text_history() {
        let history = "";
        history = history + this.text_header();
        for (var i = 0; i < this.events.length; i++) {
            var type = this.events[i]['type'];
            //console.log(JSON.stringify(this.events[i]));
            if (type == "LOOKUP_EVENT") {
                history = history + this.lookup_request_event_row(this.events[i]);
            } else if (type == "WITHDRAW_EVENT") {
                history = history + this.withdraw_request_event_row(this.events[i]);
            } else {
                history = history + this.outgoing_event_row(this.events[i]);
            }
        }
        //console.log(history);
        return history;
    }

    generate_json_history() {
        return JSON.stringify({'history': this.events}, null, 1);
    }
}


exports.AccountingHistory = AccountingHistory;
