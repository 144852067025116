/**
* Copyright PrimeVR 2020
* Uses: https://avatars.dicebear.com/
*/

const Avatars = require('@dicebear/avatars').default;
const Sprites = require('@dicebear/avatars-jdenticon-sprites').default;

class Avatar {
    constructor() {
        this.options = { radius: 5, base64: true };
        this.avatar = new Avatars( Sprites, this.options );
    }

    create( seed ) {
        return this.avatar.create( seed );
    }
}

exports.Avatar = Avatar;
