/**
* Copyright PrimeVR 2018
* @author roskelld https://github.com/roskelld
*/
console.log('%c               \n     ¸.·☼      \n               \n   sparkshot   \n               ', 'background: #272727; color: #39bced');

require("@babel/polyfill");

const Keyboard = require("./keyboard.js").Keyboard;
const Settings = require('./settings.js').Settings;
const UI = require('./ui.js').UI;
const Upload = require("./upload.js").Upload;
const Chat = require("./chat.js").Chat;
const Browse = require("./browse.js").Browse;
const Viewer = require("./viewer.js").Viewer;
const MobileViewer = require("./mobileViewer.js").MobileViewer;
const InvoiceManager = require("./invoiceManager.js").InvoiceManager;
const State = require("./state.js").State;
const Wallet = require("./wallet.js").Wallet;
const WS = require("./socket.js").WS;
const Privacy = require("./privacy.js").Privacy;
const Tutorial = require("./tutorial.js").Tutorial;
const Profile = require("./profile.js").Profile;
const Beta = require("./beta.js").Beta;
const Utils = require("./utils.js").Utils;
const Avatar = require("./avatar.js").Avatar;
const Moment = require("moment");

class Sparkshot {
    constructor() {
        this.Keyboard       = new Keyboard(this);
        this.Beta           = new Beta();
        this.Settings       = new Settings(this);
        this.UI 	        = new UI(this);
        this.Upload 	    = new Upload(this);
        this.WS 	        = new WS(this);
        this.IM             = new InvoiceManager(this);
        this.Chat           = new Chat(this);
        this.Wallet         = new Wallet(this);
        this.Browse         = new Browse(this);
        this.Avatar         = new Avatar();
        this.Viewer	        = new Viewer(this);
        this.MobileViewer   = new MobileViewer(this);
        this.Profile        = new Profile(this);
        this.State          = new State(this, {
            stateslist: {
                viewer: 'viewer',
                browse: 'browse',
            }
        } );
        this.Tutorial       = new Tutorial(this);
        this.Privacy        = new Privacy(this, {
            accepted: [{ host: this.Beta, call: 'init'  },
                       { host: this, call: 'noMobileSupport' }],
        });
        this.Moment = Moment;
        // this.pause = false;

        this.pause_loop = false;

        // Update Loop
        this._update_tick = 0;
        this._last_update = 0;

        // Keyboard Controls
        this.keys = {
            viewer: {
                toggle_pixel_probe: { key: "p", ctrl: false, alt: false, shift: false },
                toggle_price_radar: { key: "r", ctrl: false, alt: false, shift: false },
                toggle_art_info: { key: "i", ctrl: false, alt: false, shift: false },
                zoom_in: { key: ["Equal","NumpadAdd","="], ctrl: false, alt: false, shift: false },
                zoom_out: { key: ["dash","NumpadSub"], ctrl: false, alt: false, shift: false },
                zoom_in_fast: [{ key: "+", ctrl: false, alt: false, shift: true }, { key: "=", ctrl: false, alt: false, shift: true }],
                zoom_out_fast: [{ key: "-", ctrl: false, alt: false, shift: true }, { key: "_", ctrl: false, alt: false, shift: true }],
                reset_camera: { key: "0", ctrl: false, alt: false, shift: false },
                toggle_select_pixel: { key: "s", ctrl: false, alt: false, shift: false },
                request_invoice: { key: "enter", ctrl: false, alt: false, shift: false },
                select_message_panel:  { key: "m", ctrl: false, alt: false, shift: false },
                switch_panel: { key: "tab", ctrl: false, alt: false, shift: false },
                select_pixel: { key: "space", ctrl: false, alt: false, shift: false },
                toggle_background: { key: "g", ctrl: false, alt: false, shift: false },
                // mobile_pixel: { key: "y", ctrl: false, alt: false, shift: false },
            },
            browse: {

            },
            settings: {

            },
            general: {
                close: { key: "escape", ctrl: false, alt: false, shift: false },
            }
        };

        document.addEventListener( 'DOMContentLoaded', () => {
            this.init();
        }, false );

    }

    init() {
        // Init all default classes
        this.State.init();
        this.Settings.init();
        this.Privacy.init();
        this.UI.init();
        this.Chat.init();
        this.Profile.init();
        this.Upload.init();
        this.Browse.init();
        this.Tutorial.init();
        this.WS.init();

        // Key Bindings
        document.addEventListener( "keyboarddown", e => {
            // console.log( e.key );
            const target = e.event.target;
            let keys;

            // if ( e.target.tagName !== "BODY" ) { return; }
            switch ( this.State.getState() ) {
                case 'browse':
                    keys = this.keys.browse;
                    break;
                case 'viewer':
                    keys = this.keys.viewer;

                    // PRICE RADAR
                    if ( e.key === keys.toggle_price_radar.key && target.tagName === "BODY" ) {
                        this.Viewer.togglePriceProbe();
                    }

                    // PIXEL PROBE
                    if ( e.key === keys.toggle_pixel_probe.key && target.tagName === "BODY" ) {
                        this.UI.togglePixelProbe();
                    }

                    // BACKGROUND SELECT
                    if ( e.key === keys.toggle_background.key && target.tagName === "BODY" ) {
                        const button = M.FloatingActionButton.getInstance(this.UI.controls.buttons.background_color);

                        if (button.isOpen) {
                            button.close();
                        } else {
                            button.open();
                        }
                    }

                    // RESET CAMERA
                    if ( e.key === keys.reset_camera.key && target.tagName === "BODY" ) {
                        this.Viewer.resetToCanvasDefaults();
                    }

                    // ART PANEL
                    if ( e.key === keys.toggle_art_info.key && target.tagName === "BODY" ) {
                        this.UI.toggleArtMetaPanel();
                    }

                    // REQUEST INVOICE
                    // User has pixels selected
                    if ( e.key === keys.request_invoice.key && target.tagName === "BODY" && this.Viewer._selected.length > 0 ) {
                        this.Viewer.requestToInvoiceSelection();
                    }

                    // TAB ORDER
                    // User has pixels selected
                    if ( e.key === keys.switch_panel.key && target.tagName === "BODY" && this.Viewer._selected.length > 0 ) {
                        setTimeout( () => {
                            this.UI.bottom_nav.tools.message.select();
                        }, 1);
                    }

                    // Select Payment button if invoice panel is selected
                    if ( e.key === keys.switch_panel.key && target.id === "payment-modal" ) {
                        setTimeout( () => {
                            this.UI.modal.payment.buttons.pay.focus();
                        }, 0);
                    }

                    if ( e.key === keys.toggle_background.key && !this.Keyboard.isKeyDown("shift") && target.id === "payment-close-btn" ) {
                        setTimeout( () => {
                            this.UI.modal.payment.buttons.pay.focus();
                        }, 1);
                    }

                    if ( e.key === keys.switch_panel.key && this.Keyboard.isKeyDown("shift") && target.id === "payment-pay-btn" ) {
                        setTimeout( () => {
                            this.UI.modal.payment.buttons.close.focus();
                        }, 0);
                    }

                    // if ( e.key === keys.mobile_pixel.key ) {
                    //     this.MobileViewer.touchPixelButton();
                    // }

                break;
                default:
            }

            keys = this.keys.general;

            // Close Help Panel
            if ( e.key === keys.close.key && target.closest(".modal") ) {
                switch (target.closest(".modal").id) {
                    case this.UI.modal.help.instance.id: this.UI.modal.help.instance.close(); break;
                    case this.UI.modal.message.instance.id: this.UI.modal.message.instance.close(); break;
                    case this.UI.modal.about.instance.id: this.UI.modal.about.instance.close(); break;
                    case this.UI.modal.art_info.instance.id: this.UI.closeArtMetaPanel(); break;
                    case this.UI.modal.share.instance.id: this.UI.modal.share.instance.close(); break;
                    case this.UI.modal.pixel.instance.id: this.UI.modal.pixel.instance.close(); break;
                    case this.UI.modal.payment.instance.id: this.UI.modal.payment.instance.close(); break;
                    case this.Upload.form.instance.id: this.Upload.form.instance.close(); break;
                    default: console.error(`BUG: Trying to close ${target.id} but missing code`);
                }
            }

        }, false );

        // Start update loop
        this.loop();

    }

    // Mobile Brower Warning
    noMobileSupport() {
        if ( Utils.mobileCheck() && !this.Settings.getLocalData( 'mobile-beta' ) ) {
            this.UI.showMessage( 'Oops',
            "Sparkshot is in the early test phase of mobile support and many features do not work yet. We recommend using a desktop or laptop for the best experience.", true, "Continue anyway" );
            this.Settings.saveLocalData( 'mobile-beta', true );
        }
    }

    // Add class methods that need updating
    update(dt) {
        // console.log( `${this.Keyboard.anyKey}` );
        // console.log( this.Keyboard.keys );
        // Keyboard

        // STATE
        switch (this.State.getState()) {
            case "browse":

                break;
            case "viewer":
                const keys = this.keys.viewer;
                // ZOOM CONTROLS
                if ( this.Keyboard.isKeyDown(keys.zoom_in.key) && this.Keyboard.target.tagName === "BODY") {
                    this.Viewer.isZoomAnimPlaying = false;
                    this.Viewer.cancelAnimation();
                    if ( this.Keyboard.isKeyDown("Shift") ) { this.Viewer.zoomCanvas(0.5); } else { this.Viewer.zoomCanvas(0.3); }
                    this.Tutorial.completeHint( "zoom_in" );      // Mark Tutorial Complete
                }

                if ( this.Keyboard.isKeyDown(keys.zoom_out.key) && this.Keyboard.target.tagName === "BODY") {
                    this.Viewer.isZoomAnimPlaying = false;
                    this.Viewer.cancelAnimation();
                    if ( this.Keyboard.isKeyDown("Shift") ) { this.Viewer.zoomCanvas(-0.5); } else { this.Viewer.zoomCanvas(-0.3); }
                    this.Tutorial.completeHint( "zoom_in" );      // Mark Tutorial Complete
                }

                this.Viewer.updateUI( dt );
                break;
            default:

        }
    }

    render() {
        // STATE
        switch (this.State.getState()) {
            case "browse":

                break;
            case "viewer":
                this.Viewer.drawImage();
                this.Viewer.drawUI();
                break;
            default:
        }
    }


    loop(last_update) {
        if ( window.s.pause_loop ) { return; }
        const dt = window.s._update_tick = Math.min((last_update - ( window.s._last_update || last_update)), 66)/1000;
		window.s._last_update = last_update;

        window.s.update(dt);
        window.s.render();

		window.requestAnimationFrame( window.s.loop );
    }

    pause() {
        this.pause_loop = true;
    }

    play() {
        this.pause_loop = false;
        this.loop();
    }
}

window.s = new Sparkshot();
