/**
* Copyright PrimeVR 2020
* @author roskelld https://github.com/roskelld
*/

const Utils = require('./utils.js').Utils;

class Browse {
    constructor ( sparkshot, args ) {
        this.sparkshot = sparkshot
        this.CURRENCY_UNIT = 'Satoshi';
        this.CURRENCY_UNIT_SMALL = 'Sat';
        this.HEAT_WEIGHT_VALUE = 3500000;
        this.HEAT_WEIGHT_STAT = 'cog_month_weight';

        this.art_list = {};
        this.art_update_list = {};
        this._currentQuery = '';
        this.scroller = null;
        this._selectedArt = null;
    }

    init() {
        this.eventListeners();
        this.sparkshot.UI.setupScroller();
    }

    eventListeners() {
    }

    close() {

    }

    processContent( list ) {
        // console.log( '%c Browse ProcessContent ', 'color: orange; background: black' );

        this.sparkshot.UI.bottom_nav.panel.classList.add('hide');

        // Disable Chat in Browse Menu
        this.sparkshot.Chat.disableChat();

        // Detect if the search was fresh or extended
        // console.log( this.sparkshot.State.result_start );
        if ( this.sparkshot.State.result_start === 0 ) {
            // console.log( 'FRESH SEARCH', this.sparkshot.State.result_start );
            // Clear Current Main Window
            this.sparkshot.UI.main.innerHTML = '';

            this.art_list = {};
            this.sparkshot.UI.scheduleScrollToTop();
        }

        if ( list.length === 0 && this.sparkshot.State.result_start === 0 ) {
            // console.log( '%c NOTHING TO PROCESS ', 'color: orange; background: black' );

            this.showNoResults();
            return;
        }

        this.sparkshot.State.setResultStartIndex( list.length );
        // const title = ( this._currentQuery === '' ) ? '' : `?${this._currentQuery}`;

        // If both lists are empty then show no results
        if ( list.length === 0 &&
             Object.entries( this.art_list ).length === 0 ) {
            this.showNoResults();
            return null;
        }

        // Build diff list
        // Update full list
        // console.log( '%c DIFFLIST ', 'color: orange; background: black' );
        this.art_update_list = {};
        list.forEach( entry => {
            if ( !this.art_list[entry.art_id] ) {
                this.art_list[entry.art_id] = entry;
                this.art_update_list[entry.art_id] = entry;
                // console.log( entry.art_id );
            }
        });

        // console.log( '%c INCOMING LIST ', 'color: orange; background: black' );
        // console.log( list );

        // console.log( '%c DIFFLIST ', 'color: orange; background: black' );
        // console.log( this.art_update_list );

        // console.log( '%c ART LIST ', 'color: orange; background: black' );
        // console.log( this.art_list );

        // console.log( '%c TOOT LIST ', 'color: green; background: black' );
        // console.log( this.toot );

        // Build the list
        this.buildList( this.art_update_list );
    }

    setCurrentQuery( query ) {
        this.sparkshot.UI.top_nav.search.field.value = query;
        this.currentQuery = query;
    }

    buildList( list ) {
        // Add Content
        const number = Object.keys( list ).length;
        Object.entries( list ).forEach( (entry, index) => {

            this.art_list[entry[0]].element = this.createEntry( entry );

            // Scroll to top if scheduled
            if ( number - 1 === index && this.sparkshot.UI.enableToTop ) {
                this.sparkshot.UI.scrollToTop();
            }
        });

        this.updateCardBackground();
        this.sparkshot.UI.hideLoadingBar();
    }

    // Generate DOM element for entry
    createEntry( entry ) {
        // console.log( entry[1].cog_weight );
        const units = ( entry[1].msatoshis_paid / 1000 ).toFixed(0);
        const fraction = ( entry[1].msatoshis_paid / 1000 % 1 > 0 )
                ? (entry[1].msatoshis_paid / 1000 % 1).toFixed(3).substr(1, 4)
                : '.000';

        const number = `${Utils.NumToCommas( units, ',' )}`;

        // Card Container
        const mainDiv = document.createElement( 'div' );
        mainDiv.classList.add( 'col', 's12', 'm6', 'l4', 'xl3', 'art-card' );
        mainDiv.id = `${entry[1].art_id}`;

        const innerDiv = document.createElement( 'div' );
        innerDiv.classList.add( 'card', 'grey', 'darken-4', 'z-depth-0' );

        if (entry[1].px_count === entry[1].paid_px_count) {
            innerDiv.classList.add( 'complete' );
        }

        if ( entry[1][this.HEAT_WEIGHT_STAT] >= this.HEAT_WEIGHT_VALUE ) {
            innerDiv.appendChild( this.createCardEnergyElments() );
        }

        const imageDiv = document.createElement( 'a' );
        imageDiv.href = `/${entry[1].art_id}`;
        imageDiv.classList.add( "card-image", "waves-effect", "waves-block", "waves-light" );
        const image = document.createElement( 'img' );

        image.src = `/thumb/${entry[1].art_id}.png`;
        image.alt = `${entry[1].art_id}`;
        image.onerror = `this.src='/thumb/small.png'`;
        const bgDiv = document.createElement( 'div' );
        bgDiv.classList.add( 'thumb-image' );

        imageDiv.appendChild( bgDiv );

        imageDiv.addEventListener( 'click', e => {
            e.preventDefault();
            this.openEntry( entry[1] );
        });
        imageDiv.appendChild( image );


        // DESCRIPTION
        const descriptionSpan = document.createElement( 'span' );
        descriptionSpan.classList.add( 'entry-content-description', 'spark-off-white-text' );
        descriptionSpan.textContent = entry[1].description;
        imageDiv.appendChild( descriptionSpan );

        const contentDiv = document.createElement( 'div' );
        contentDiv.classList.add( "entry-content", "grey-text", "text-lighten-2" );

        // Add complete graphic wrapper if art is finished
        if (entry[1].px_count === entry[1].paid_px_count) {
            contentDiv.classList.add( 'complete' );
        }

        // TITLE
        const title = document.createElement( 'a' );
        title.href = `/${entry[1].art_id}`;
        title.classList.add( "entry-content-title", "pointer", "grey-text", "text-lighten-2" );
        title.textContent = `${entry[1].title}`;
        title.addEventListener( 'click', e => {
            e.preventDefault();
            this.openEntry( entry[1] );
        });

        // TAGS
        const tagsDiv = document.createElement( 'div' );
        tagsDiv.classList.add( 'entry-content-tag-container' );

        entry[1].tags.forEach( tag => {
            const tagEl = document.createElement( 'a' );
            tagEl.classList.add( 'entry-content-tag' );
            tagEl.textContent = `${tag} `;
            tagEl.href = `/?=#${tag}`;
            tagEl.addEventListener( 'click', e => {
                e.preventDefault();
                this.sparkshot.UI.setSearchQuery( `#${tag}` );
                this.sparkshot.State.setResultStartIndex();
                this.sparkshot.State.searchAdvanced({
                    search_query:               `#${tag}`,
                    result_start:               0,
                    sort_direction:             "DESCENDING",
                    complete_max:               1.0,
                });
            });
            tagsDiv.appendChild( tagEl );
        });

        // ARTIST
        const artistDiv = document.createElement( "div" );
        artistDiv.classList.add( "entry-content-details" );
        const artist = document.createElement( "a" );
        const artist_span = document.createElement( "span" );
        artist_span.classList.add('entry-content-details-item');
        artist.classList.add( "entry-content-details-link", "entry-content-artist" );
        artist.href = `/?=${entry[1].btc_addr}`;
        artist.addEventListener( 'click', e => {
            e.preventDefault();
            this.sparkshot.UI.setSearchQuery( `${entry[1].btc_addr}` );
            this.sparkshot.State.setResultStartIndex();
            this.sparkshot.State.searchAdvanced({
                search_query:               `${entry[1].btc_addr}`,
                result_start:               0,
                sort_direction:             "DESCENDING",
                complete_max:               1.0,
            });
        });

        if ( entry[1].description.includes('@') ) {
            const words = entry[1].description.split( ' ' );
            const user = words.find( word => word.charAt(0) === '@' );

            if ( user.length > 1 ) {
                artist_span.textContent = user;
                artist.title = `Artist: ${entry[1].btc_addr}`;
            } else {
                artist_span.textContent = `${entry[1].btc_addr}`;
                artist.title = `Artist: ${entry[1].btc_addr}`;
            }

        } else {
            artist_span.textContent = `${entry[1].btc_addr}`;
            artist.title = `Artist: ${entry[1].btc_addr}`;
        }
        artist.appendChild( artist_span );

        // REVEALED PIXELS
        const pixels = document.createElement( 'span' );
        pixels.classList.add( 'entry-content-details-item', 'entry-content-pixels' );
        pixels.title = "Revealed Pixels";
        pixels.textContent = `${Utils.NumToCommas(entry[1].paid_px_count)} / ${Utils.NumToCommas(entry[1].px_count)}`;

        // DOT SEPARATER
        const separater = document.createElement( 'span' );
        separater.classList.add( 'entry-item-separater', 'grey-text', 'text-lighten-2' );
        separater.textContent = ' • ';

        // SATS EARNED
        const currency = document.createElement( 'span' );
        currency.classList.add( 'grey-text', 'text-lighten-2' );
        currency.textContent = ` ${this.CURRENCY_UNIT_SMALL}`;

        const fraction_value = document.createElement( 'span' );
        fraction_value.classList.add( 'grey-text' );
        fraction_value.textContent = `${fraction}`;
        fraction_value.appendChild( currency );

        const earned = document.createElement( 'span' );
        earned.title = "Earned";
        earned.classList.add( 'entry-content-details-item', 'entry-content-earning' );
        earned.textContent = `${number}`;
        earned.appendChild( fraction_value );

        // ART DETAILS SECTION (PIXELS AND SATS)
        const artDetailsDiv = document.createElement( 'div' );
        artDetailsDiv.classList.add( 'entry-content-details' );
        artDetailsDiv.appendChild( pixels );
        artDetailsDiv.appendChild( separater );
        artDetailsDiv.appendChild( earned );

        // AVATAR
        const avatarDiv = document.createElement( 'div' );
        avatarDiv.classList.add( 'entry-avatar' );
        avatarDiv.innerHTML = `<a href="/?=${entry[1].btc_addr}" title="Artist: ${entry[1].btc_addr}"><img class="entry-avatar-image" src="${this.sparkshot.Avatar.create(entry[1].btc_addr)}"></a>`;


        // AVATAR AND ART DATA SECTION
        const dataDiv = document.createElement( 'div' );
        dataDiv.classList.add( 'entry-data' );
        dataDiv.appendChild( avatarDiv );

        const detailsDiv = document.createElement( 'div' );
        detailsDiv.classList.add( 'entry-details-block' );
        detailsDiv.appendChild( artDetailsDiv );
        detailsDiv.appendChild( artistDiv );

        dataDiv.appendChild( detailsDiv );

        // STRUCTURE CARD
        artistDiv.appendChild( artist );

        contentDiv.appendChild( title );
        contentDiv.appendChild( dataDiv );
        contentDiv.appendChild( tagsDiv );

        innerDiv.appendChild( imageDiv );
        innerDiv.appendChild( contentDiv );

        mainDiv.appendChild( innerDiv );

        this.sparkshot.UI.main.appendChild( mainDiv );
        return mainDiv;
    }

    createCardEnergyElments( num = 5 ) {
        const energy_count = num;
        const max_x = 10;
        const min_x = 6;

        const max_y = 4.5;
        const min_y = 3;
        const max_delay_y = max_y - 1;
        const min_delay_y = 0;
        const min_img_width = 4;
        const max_img_width = 5;

        const frag = document.createDocumentFragment();

        for (let i = 1; i < energy_count; i++) {
            const energy = document.createElement( 'div' );
            energy.classList.add("art-energy");
            energy.style.left = `${ -10 + (i / energy_count * 100)}%`;
            const duration_x = Math.random() * (max_x - min_x + 1) + min_x;
            energy.style.animationDuration = `${duration_x}s`;

            const img = document.createElement( 'img' );
            img.src = "/img/sprites/energy_flame.gif";
            img.classList.add(`lanternY${i}`);

            // const width = Math.floor(Math.random() * (max_img_width - min_img_width + 1)) + min_img_width;
            const width = (Math.random() * (max_img_width - min_img_width + 1) + min_img_width).toFixed(2);
            const duration_y = Math.random() * (max_y - min_y + 1) + min_y;
            const delay_y = Math.random() * (max_delay_y - min_delay_y + 1) + min_delay_y;
            img.style.width = `${width}rem`;
            img.style.animationDuration = `${duration_y}s`;
            img.style.opacity = Utils.convertNumberRange( width, min_img_width, max_img_width, 0.5, 1 );
            img.style.animationDelay = `${delay_y}s`;

            energy.appendChild(img);
            frag.appendChild(energy);
        }

        return frag;
    }

    // Clicking on Entry requests art to open
    openEntry ( entry ) {
        // console.log( '%copenEntry', 'color: green');
        // console.log( entry );
        this.sparkshot.UI.showLoadingBar();
        this._selectedArt = entry;
        this.sparkshot.State.setUrl( entry.title, entry.description, entry.art_id );
        this.sparkshot.WS.request_goto_art( entry.art_id );
    }

    // No Results
    showNoResults() {
        // Clear Current Main Window
        this.sparkshot.UI.main.innerHTML = '';

        // Create Results Message
        let message;
        let query = this.sparkshot.UI.getSearchQuery();
        switch (this.sparkshot.UI.getSearchType()) {
            case "bitcoin_address":
                message = `The artist known as <span class="white-text long-shadow" style="font-size: 1.7rem;">${query}</span> has no art here yet.`;
                break;
            case "tag":
                message = `No one has uploaded any <span class="white-red-shadow">${query}</span> art yet, maybe it's time to start a new trend?`;
                break;
            case "string":
                message = `We're still waiting for the masterpiece known as <span class="white-red-shadow">${query}</span> to be added. It sure sounds amazing.`;
                break;
            default:
                message = `Are you sure you were looking for <span class="white-red-shadow">${query}</span>?`;
        }


        const div = document.createElement('div');
        div.classList.add('center-align')
        div.innerHTML =  `
            <div style="padding-top: 10%;" class="col s12 m8 push-m2 l6 push-l3">
                <h1 class="spark-blue-text bold">${message}</h1>
            </div>`;

        div.addEventListener( 'click', () => { this.openEntry( entry[1] ) });
        this.sparkshot.UI.main.appendChild(div);
    }


    updateCardBackground() {
        Object.entries( this.art_list ).forEach( entry => {
            if ( entry[1].element.offsetTop + entry[1].element.clientHeight > this.sparkshot.UI.main.scrollTop && entry[1].element.offsetTop <= this.sparkshot.UI.main.clientHeight + this.sparkshot.UI.main.scrollTop ) {
                // entry[1].element.children[0].children[0].children[0].classList.add( 'thumb-animation' );
                entry[1].element.querySelector('.thumb-image').classList.add('thumb-animation');
            } else {
                // entry[1].element.children[0].children[0].children[0].classList.remove( 'thumb-animation' );
                entry[1].element.querySelector('.thumb-image').classList.remove('thumb-animation');
            }

        });
    }
}

exports.Browse = Browse;
