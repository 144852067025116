/**
 * Copyright PrimeVR 2020
 * @author      roskelld https://github.com/roskelld
 * @description Handle the navigation of the site so that page loads are
 *              simulated by both navigation buttons and browser navigation
 * @version     0.1
 */

const Utils = require('./utils.js').Utils;

class State {
    constructor( sparkshot, args ) {
        this.sparkshot = sparkshot
        this.stateslist = args.stateslist;
        this._state = null;
        this._enabled = true;
        this.pop = false;


        this.payload = null;
        // this.start = 0;
        // this.end = 200;

        // Search States
        // this.setComplete( false );                                              // Sets records to be default full range
        this.setSearchRange();
        this.setSort();
        this.setDirection();
        this.setMinAttachDatetime();
        this.setMaxAttachDatetime();
        this.setMinHotness();
        this.setMaxHotness();
        this.setHotnessWindow();

        this.search_query = '';
        this.setResultStartIndex();
        this.result_count = 15;
    }

    init() {
        // this.processURL();

        // Event Listners if the user changes the browser
        // page via the back/forward button
        window.addEventListener( 'popstate', e => {
            this.sparkshot.UI.closeAllOtherModals();
            this.pop = true;
            this.processURL(e);
        }, false );


    }

    getState() {
        return this._state;
    }

    setState( state, payload ) {
        if ( !this._enabled ) return;

        const oldState = this._state;
        // Process new State
        switch ( state ) {
            case 'viewer':
                this.sparkshot.UI.setSearchQuery( "" );
                this.sparkshot.Viewer.processContent( payload );
                this.sparkshot.UI.closeSortPanel();
                this.sparkshot.UI.hideLoadingBar();
                this.sparkshot.UI.setArtControlVisibility( this.sparkshot.Settings.getArtControlsStatus() );
                break;
            case 'browse':
                this.sparkshot.Browse.processContent( payload );
                this.sparkshot.UI.hideLoadingBar();
                this.sparkshot.UI.hideArtControls();
                break;
            default:
                return console.warn(`Tried to set a state that doesn''t exist: ${state}`);
        }

        this._state = state;

        // Clean up previous state
        switch ( oldState ) {
            case 'viewer':
                this.sparkshot.Viewer.close(); break;
            case 'browse':
                this.sparkshot.Browse.close(); break;
            default:
        }

        // Run Hint System to init any hints
        this.sparkshot.Tutorial.removeAllHints();
        this.sparkshot.Tutorial.initHints();

        // Close any possible open panels

        // drop focus on any previous element
        document.activeElement.blur();

    }

    isState( state = null ) {
        return this._state === state;
    }

    disableStateChange() {
        this._enabled = false;
    }

    enableStateChange() {
        this._enabled = true;
    }

    /**
     * Set the url to match app update
     * TODO: Rework this whole URL manipulation code as it's gank
     * @param {string} path - art
     * @param {string} description - Title string to describe url
     * @param {string} id - url
     * @param {boolean} replace - set history state or replace current state
     */
    setUrl( path, description, id, replace = false ) {
        // console.log( `Seturl ${path}`);
        https://artproject.local:8080/accepted-coal?msg=26
        if ( id === '' ) {
            id = '/';
            document.title = `Sparkshot.io`;
        } else {
            document.title = `${path} - Sparkshot.io`;
        }

        if ( replace ) {
            if ( path.includes( 'Search:' ) && id !== '/' ) {
                window.history.replaceState( { id: id }, description, `/?=${id}` );
            } else {
                window.history.replaceState( { id: id }, description, id );
            }
        } else {
            if ( path.includes( 'Search:' ) && id !== '/' ) {
                window.history.pushState( { id: id }, description, `/?=${id}` );
            } else {
                window.history.pushState( { id: id }, description, id );
            }
        }
    }


    processURL( state = {} ) {
        this.params = this.parseQuery();
        // console.log( '////////////////////////////////////////////////' );
        // console.log( this.params );
        // console.log( "params.get: " + this.params.get('') );
        // console.log( "pathname: " +  window.location.pathname );
        // console.log( "hash: " +  window.location.hash );
        // console.log( '////////////////////////////////////////////////' );

        // ART PAGE
        if ( window.location.pathname.length > 1 ) {
            // Load Art
            // console.log("art page load");
            if ( state.state == null ) {
                state.state = { id: window.location.pathname.substring( 1, window.location.pathname.length ) };
            }

            this.sparkshot.WS.request_goto_art( state.state.id );
            return;
        }

        // BROWSE
        if ( window.location.pathname === '/' && this.params.get('') === undefined ) {

            // Setup Nav UI Buttons
            this.sparkshot.UI.setActiveSearchFilterButton( "incomplete" );
            this.sparkshot.UI.selectSort( "almost" );

            this.search( '', 0, this.result_count );
            document.title = `Sparkshot.io`;
            return;
        }

        // Standard Search
        if ( window.location.pathname === '/' && this.params.get('') !== ""  ) {
            const query = decodeURI( window.location.search.substring(2) );
            //console.log("param load " + query);
            this.setMinComplete( 0.0 );
            this.setMaxComplete( 1.0 );
            this.setSort( "SEARCH_MATCH" );
            this.search( query, 0, this.result_count );

            // Setup Nav UI Buttons
            this.sparkshot.UI.setActiveSearchFilterButton( "all" );
            this.sparkshot.UI.setActiveSortButton( "almost" );
            document.title = `Sparkshot.io`;
            return;
        }

        // Hash Search
        if ( window.location.pathname === '/' && window.location.hash !== '' ) {
            const query = decodeURI( window.location.hash );
            //console.log("hash search " + query);
            this.setMinComplete( 0.0 );
            this.setMaxComplete( 1.0 );
            this.setSort( "SEARCH_MATCH" );

            // Setup Nav UI Buttons
            this.sparkshot.UI.setActiveSearchFilterButton( "incomplete" );
            this.sparkshot.UI.setActiveSortButton( "hot" );

            this.search( query, 0, this.result_count );
            document.title = `Sparkshot.io`;
            return;
        }

    }

    parseQuery() {
        return new Map(location.search.slice(1).split('&').map(kv => kv.split('=')));
    }

    // #####################################################################
    // SEARCH
    // #####################################################################

    /**
     * Basic Search call without extra parameters
     * @param {string} search_query - Search string
     * @param {int} result_start - starting record index
     * @param {int} result_count - max number of records to request
     */
    search( search_query, result_count = null ) {
        this.sparkshot.UI.showLoadingBar();
        // Reset the search index number
        this.setResultStartIndex();
        this.sparkshot.UI.setSearchQuery( search_query );

        const payload = {
            search_query:            search_query,
            sort_choice:             this.sort_choice,
            sort_direction:          this.sort_direction,
            complete_min:            this.complete_min,
            complete_max:            this.complete_max,
            attach_datetime_min:     this.attach_datetime_min,
            attach_datetime_max:     this.attach_datetime_max,
            hotness_window:          this.hotness_window,
            hotness_min:             this.hotness_min,
            hotness_max:             this.hotness_max,
            result_start:            this.result_start,
            result_count:            ( result_count ) ? result_count :
                                                        this.result_count,
        };
        // console.log( `%cSEARCH:`, 'color: red; background: black;');
        this.sparkshot.WS.request_art_list( payload );
    }

    /**
     * Advanced Search call with all parameters
     * @param {string} search_query - Search string
     * @param {boolean} complete - Search only completed works
     * @param {SORT_TYPES} sort - Sort results by type
     * @param {boolean} direction_up - Return results in up order
     * @param {FILTER_TYPES} filter - Filter search by type
     * @param {int} record_index - return results from index number
     * @param {int} result_count - max number of records to request
     */
    searchAdvanced( query ) {
        this.sparkshot.UI.showLoadingBar();
        this.setUrl( `Search: ${query.search_query}`,
                     `Searching for ${query.search_query}`,
                      query.search_query );

       // console.log( `%cADVANCED SEARCH:`, 'color: green; background: black;');
       // console.log( `START: ${this.sparkshot.State.result_start}`);
        this.sparkshot.WS.request_art_list( query );
    }

    /**
     * Set valid sorting option
     * DEFAULT: Popular
     * @param {string} option - Desired record sort option
     */
    setResultStartIndex( value = 0 ) {
        // console.log( 'setResultStartIndex', value );
        this.result_start = value;
    }

    addResultStartIndex( value ) {
        // console.log( `addResultStartIndex() ${this.result_start} + ${value}` );
        this.result_start += value;
    }

    /**
     * Set valid sorting option
     * DEFAULT: Popular
     * @param {string} option - Desired record sort option
     */
    setSort( option = 'HOTNESS' ) {
        switch ( option ) {
            case 'ATTACH_DATETIME':     this.sort_choice = 'ATTACH_DATETIME'; break;
            case 'SIZE_IN_PIXELS':      this.sort_choice = 'SIZE_IN_PIXELS'; break;
            case 'PERCENT_COMPLETE':    this.sort_choice = 'PERCENT_COMPLETE'; break;
            case 'CURRENT_VALUE':       this.sort_choice = 'CURRENT_VALUE'; break;
            case 'NUM_PURCHASES':       this.sort_choice = 'NUM_PURCHASES'; break;
            case 'HOTNESS':             this.sort_choice = 'HOTNESS'; break;
            case 'SEARCH_MATCH':        this.sort_choice = 'SEARCH_MATCH'; break;
            default:                    this.sort_choice = 'HOTNESS'; break;
        }
    }

    /**
     * Set valid filter option
     * DEFAULT: DAY
     * @param {string} option - Desired record sort option
     */
    setHotnessWindow( option = 'DAY' ) {
        switch ( option ) {
            case 'WEEK':    this.hotness_window = 'WEEK'; break;
            case 'MONTH':   this.hotness_window = 'MONTH'; break;
            default:        this.hotness_window = 'DAY'; break;
        }
    }

    /**
     * Sets record types to be in a complete or incomplete
     * DEFAULT: incomplete
     * @param {string} range - Desired record state option
     */
    setSearchRange( range ) {
        // console.log( `Setting Search Range: ${range}`);
        switch ( range) {
            case 'all':
                this.setMinComplete(0.0);
                this.setMaxComplete(1.0);
                break;
            case 'incomplete':
                this.setMinComplete(0.0);
                this.setMaxComplete(0.999999);
                break;
            case 'complete':
                this.setMinComplete(1.0);
                this.setMaxComplete(1.0);
                break;
            default:
                this.setMinComplete(0.0);
                this.setMaxComplete(0.999999);

        }
    }

    setMinComplete( float = 0.0 ) {
        if ( float > 1.0 ) float = 1.0;
        if ( float < 0.0 ) float = 0.0;
        this.complete_min = float;
    }

    setMaxComplete( float = 1.0 ) {
        if ( float > 1.0 ) float = 1.0;
        if ( float < 0.0 ) float = 0.0;
        this.complete_max = float;
    }

    setMinAttachDatetime( float = 1.0 ) {
        if ( float > 1.0 ) float = 1.0;
        if ( float < 0.0 ) float = 0.0;
        this.attach_datetime_min = float;
    }

    setMaxAttachDatetime( option = "+inf") {
        if (! isNaN(option)) {
            if ( option > 1.0 ) option = 1.0;
            if ( option < 0.0 ) option = 0.0;
        }
        this.attach_datetime_max = option;
    }

    setMinHotness( float = 0.0 ) {
        if ( float > 1.0 ) float = 1.0;
        if ( float < 0.0 ) float = 0.0;
        this.hotness_min = float;
    }

    /**
     * Sets max hotness of records to be returned
     * DEFAULT: 0.0
     * @param {float} float - Desired amount from 0.0 to 1.0
     */
    setMaxHotness( option = "+inf") {
        if (! isNaN(option)) {
            if ( option > 1.0 ) option = 1.0;
            if ( option < 0.0 ) option = 0.0;
        }
        this.hotness_max = option;
    }

    /**
     * Sets record return data to be sorted in up and down direction
     * DEFAULT: "ASCENDING"
     * @param {boolean} bool - Desired records data direction
     */
    setDirection( bool = false ) {
        this.sort_direction = ( bool ) ? "ASCENDING" : "DESCENDING";
    }
}

exports.State = State;
