/**
 * Copyright PrimeVR 2020
 * @author roskelld https://github.com/roskelld
 */
class MobileViewer {
    constructor( sparkshot ) {
        this.PIXEL_BUTTON_STATES = ["add", "remove", "info", "oob"];
        this.PIXEL_STROKE_COLORS = ["green", "red", "white", "yellow"];
        this.sparkshot = sparkshot;

        // Tracking
        this._canvas_touch_num = 0;
        this._lastTouchDistance = 0;

        this.stroke = this.PIXEL_STROKE_COLORS[0];

        // UI
        this.buttons = {
            message:        document.querySelector( `#ui-mobile-message-button` ),
            pixel:          document.querySelector( `#ui-mobile-pixel-button` ),
            purchase:       document.querySelector( `#ui-mobile-invoice-button` ),
            trash:          document.querySelector( `#ui-mobile-trash-button` )
        };

        this.pixel_button_state = "";
        this.pixel_button_down = false;

        // Add Event Listeners
        this.addEventListeners();
    }

    init() {
        // HIDE DESKTOP UI ELEMENTS
        this.sparkshot.UI.modal.art_info.instance.el.classList.add('hide');

        this.setPixelButton();
        this.disablePaymentButton();
    }

    addEventListeners() {

        // Canvas Touch
        this.sparkshot.Viewer._canvas.addEventListener('touchstart', e => {
            e.preventDefault();
            this.artTouchStart(e);
        }, false );
        this.sparkshot.Viewer._canvas.addEventListener('touchmove', e => {
            e.preventDefault();
            this.artTouchMove(e);
        }, false );
        this.sparkshot.Viewer._canvas.addEventListener('touchend', e => {
            e.preventDefault();
            this.artTouchEnd(e);
        }, false );

        // Buttons
        this.buttons.message.addEventListener( 'touchstart', e => {
            e.preventDefault();
        }, false);

        this.buttons.pixel.addEventListener( 'touchstart', e => {
            e.preventDefault();
            this.touchPixelButton();
        }, false);

        this.buttons.pixel.addEventListener( 'touchend', e => {
            e.preventDefault();
            this.pixel_button_down = false;
            this.setPixelButton();
            this.sparkshot.Viewer.showDragSelectPriceFloatText();
        }, false);

        this.buttons.purchase.addEventListener( 'touchstart', e => {
            e.preventDefault();
            if ( this.buttons.purchase.classList.contains( 'disabled' ) ) { return; }
            this.sparkshot.Viewer.requestToInvoiceSelection();
            this.setPixelButton();
        }, false);

        this.buttons.trash.addEventListener( 'touchstart', e => {
            e.preventDefault();
            this.sparkshot.Viewer._floatPrice = -1 * this.sparkshot.Viewer._price; // Set negative float price
            this.sparkshot.Viewer.showDragSelectPriceFloatText();
            this.sparkshot.Viewer.clearSelection();
            this.disablePaymentButton();
            this.setPixelButton();
        }, false);
    }

    close() {

    }

    artTouchStart(e) {
        // console.log('touchStart');
        const viewer = this.sparkshot.Viewer;
        // Set Canvas Touch
        let num = 0;
        for ( const touch in e.touches ) {
            if ( e.touches.hasOwnProperty(touch) ) {
                if (e.touches[touch].target.tagName === "CANVAS" ) { num++; }
            }
        }
        this._canvas_touch_num = num;

        // Initialize Drag
        if ( this._canvas_touch_num === 1 ) {
            viewer._lastX = e.targetTouches[0].pageX;
            viewer._lastY = e.targetTouches[0].pageY;
            viewer._dragStart = viewer._ctx.transformedPoint( viewer._lastX, viewer._lastY );
        } else {
            viewer._dragStart = null;
        }
    }

    artTouchMove(e) {
        // console.log('touchMove');
        const viewer = this.sparkshot.Viewer;
        // Fetch all CANVAS touches
        const touches = [];
        for ( const touch in e.touches ) {
            if ( e.touches.hasOwnProperty(touch) && e.touches[touch].target.tagName === "CANVAS" ) {
                touches.push( e.touches[touch] );
            }
        }

        // Record coordinate
        // console.log( touches[0].pageX, touches[0].pageY );
        viewer._lastX = touches[0].pageX;
        viewer._lastY = touches[0].pageY;
        // console.log( viewer._lastX, viewer._lastY );

        // *******************************************
        // PAN
        // One Finger Touch therefore pan image
        if ( touches.length === 1 && viewer._dragStart !== null ) {
            const pt = viewer._ctx.transformedPoint( viewer._lastX, viewer._lastY );
            viewer._ctx.translate( pt.x - viewer._dragStart.x, pt.y - viewer._dragStart.y );
            viewer._ui_ctx.translate( pt.x - viewer._dragStart.x, pt.y - viewer._dragStart.y );
            viewer.dirty_image = true;
        } else if ( touches.length > 1 ) {
            // *******************************************
            // ZOOM
            viewer._dragStart = null;
            // Calculate Distance
            let dist = 0;
            if ( touches.length > 1 ) {
                dist = Math.hypot( touches[0].screenX - touches[1].screenX, touches[0].screenY - touches[1].screenY );
            } else {
                dist = Math.hypot( touches[0].screenX, touches[0].screenY );
            }

            const amount = (dist - this._lastTouchDistance);

            if ( dist > 100 && Math.abs(amount) > 0.1 ) {
                if ( amount > 0 ) {
                    viewer.zoomCanvas( 0.5 );
                } else {
                    viewer.zoomCanvas( -0.5 );
                }
            }

            // Record distance for next comparison
            this._lastTouchDistance = dist;
        }


        // Process the current pixel if user is holding down button
        if ( this.pixel_button_down ) {
            this.touchPixelButton();
            // Force update if the user has hit max pixels
            if ( viewer._selected.length >= viewer.MAX_PIXEL_SELECTION ) {
                this.stroke = this.PIXEL_STROKE_COLORS[1];
            }
        } else {
            // Update the UI button
            this.setPixelButton();
        }
    }

    artTouchEnd(e) {
        // console.log('touchEnd');
        const viewer = this.sparkshot.Viewer;
        // Set Canvas Touch
        let num = 0;
        for ( const touch in e.touches ) {
            if ( e.touches.hasOwnProperty(touch) ) {
                if (e.touches[touch].target.tagName === "CANVAS" ) { num++; }
            }
        }
        this._canvas_touch = num;

        if ( this._canvas_touch === 1 ) {
            viewer._lastX = e.targetTouches[0].pageX;
            viewer._lastY = e.targetTouches[0].pageY;
            viewer._dragStart = viewer._ctx.transformedPoint( viewer._lastX, viewer._lastY );
        }
    }

    // #####################################################################
    // UI
    // #####################################################################

    drawReticule() {
        const viewer = this.sparkshot.Viewer;
        viewer._hud_ctx.strokeStyle = this.stroke;
        viewer._hud_ctx.lineWidth = "4";
        const pixel = - viewer._ctx.getTransform().a;
        const x = viewer._canvas.width / 2 - ( pixel / 2 );
        const y = viewer._canvas.height / 2 - ( pixel / 2);
        viewer._hud_ctx.strokeRect( x, y, pixel, pixel );
    }

    // #####################################################################
    // BUTTONS
    // #####################################################################

    setPixelButton( override = false ) {
        // Don't update button if already being held down;
        if ( this.pixel_button_down && !override ) { return; }

        const viewer = this.sparkshot.Viewer;
        const pixel = viewer.getCenterScreenPixel();
        // Hide All buttons
        this.buttons.pixel.children.forEach( x => x.classList.add('hide'));

        viewer.setLastCoord();

        // Set Button
        if ( pixel.oob ) {
            this.buttons.pixel.children[3].classList.remove('hide');
            this.pixel_button_state = this.PIXEL_BUTTON_STATES[3];
            this.stroke = this.PIXEL_STROKE_COLORS[3];
        } else if ( pixel.purchased ) {
            this.buttons.pixel.children[2].classList.remove('hide');
            this.pixel_button_state = this.PIXEL_BUTTON_STATES[2];
            this.stroke = this.PIXEL_STROKE_COLORS[2];
        } else if ( viewer.isSelected( pixel.canvas.x, pixel.canvas.y ) ) {
            this.buttons.pixel.children[1].classList.remove('hide');
            this.pixel_button_state = this.PIXEL_BUTTON_STATES[1];
            this.stroke = this.PIXEL_STROKE_COLORS[1];
        } else if ( viewer._selected.length >= viewer.MAX_PIXEL_SELECTION ) {
            this.buttons.pixel.children[3].classList.remove('hide');
            this.pixel_button_state = this.PIXEL_BUTTON_STATES[0];
            this.stroke = this.PIXEL_STROKE_COLORS[1];
        } else {
            this.buttons.pixel.children[0].classList.remove('hide');
            this.pixel_button_state = this.PIXEL_BUTTON_STATES[0];
            this.stroke = this.PIXEL_STROKE_COLORS[0];
        }

        // ADD HOVER ANIMATION TO PIXEL
        if ( viewer.isZoomLevelNear() && viewer.isSelected( pixel.canvas.x, pixel.canvas.y ) ) {
            viewer.addHoverState( pixel.canvas.x, pixel.canvas.y );
        }
    }

    touchPixelButton() {
        this.pixel_button_down = true;
        const viewer = this.sparkshot.Viewer;
        const pixel = viewer.getCenterScreenPixel();
        switch ( this.pixel_button_state ) {
            case "add":
                viewer.clearHightlightGroup();
                viewer.infobox.clear();
                viewer.selectPixel( pixel.canvas.x, pixel.canvas.y );
                this.enablePaymentButton();
                break;
            case "remove":
                // if remove true then reduce refund price
                if ( viewer.removePixelFromSelection( pixel.canvas.x, pixel.canvas.y ) ) {
                    viewer._price -= pixel.satoshi_price;                       // Reduce cart price
                    viewer._floatPrice -= pixel.satoshi_price;                  // Reduce float price
                }
                if ( viewer._selected.length === 0 ) { this.disablePaymentButton(); }
                break;
            case "info":
                viewer.clearHightlightGroup();
                viewer.infobox.clear();
                viewer.setHighlightGroup( viewer.data.replays[pixel.replay_index].coords );

                if ( pixel.purchased ) {
                    viewer.showInfoBox( pixel.canvas.x, pixel.canvas.y);
                }
                break;
            case "oob":
                viewer.clearHightlightGroup();
                this.disablePaymentButton();
                viewer.infobox.clear();
                break;
            default:


        }
    }

    disablePaymentButton() {
        this.buttons.purchase.classList.add( 'disabled' );
    }

    enablePaymentButton() {
        this.buttons.purchase.classList.remove( 'disabled' );
    }

    // #####################################################################
    //
    // #####################################################################

}

exports.MobileViewer = MobileViewer;
